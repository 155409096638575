import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators,FormArray } from '@angular/forms';
import { ProjectService } from 'src/app/shared/services/project.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
@Component({
  selector: 'app-change-request',
  templateUrl: './change-request.component.html',
  styleUrls: ['./change-request.component.scss'],
  providers: [DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class ChangeRequestComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar.change_request;
  projectId: any;
  projectChangeRequestForm: FormArray | any;
  CRList:any;
  deletedCrList:any=[];

  constructor(
    private datepipe: DatePipe,
    private toastr: ToastrService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>) { 
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    this.getprojectId();
    this.createForm();
  }
  createForm() {
   this.projectChangeRequestForm = this.fb.array([])
  }
  getprojectId() {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectId = atob(params.id);
      this.getCRList();
    });
  }
  getCRList() {
    this.spinner.show();
    this.CRList =[];
    this.projectService.getProjectCRList(this.projectId,).subscribe((response: any) => {
      if (response?.length > 0) {
       this.CRList = response;
       this.CRList.forEach((elm:any)=>{
          this.projectChangeRequestForm.push(
            this.fb.control(elm)
          )
       })
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
  }
  removeCR(i:any){
    var crId = this.projectChangeRequestForm.controls[i]?.value?.id;
    if(crId && !this.deletedCrList.includes(crId)){
      this.deletedCrList.push(crId);
    }
    this.projectChangeRequestForm.controls.splice(i, 1);
  }
  addCR(i:any){
    var data = {
      id: null,
      change_request_description:null,
      cr_type: null,
      cr_effective_date: null,
      project_id:null,
      cr_value: null,
      status: null,
    }
    this.projectChangeRequestForm.push(
      this.fb.control(data));
  }
  saveProjectCR(){
    var created_cr:any = [];
    var updated_cr:any = [];
    this.projectChangeRequestForm.controls.forEach((control:any)=>{
        control = control.value;
        var data = {
          id: control?.id,
          change_request_description:control?.change_request_description,
          cr_type: control?.cr_type,
          cr_effective_date: this.datepipe.transform(control?.cr_effective_date,"yyyy-MM-dd"),
          project_id:this.projectId,
          cr_value: control?.cr_value,
          status: control?.status,
        }
        if(control.id == null){
          created_cr.push(data);
        }else{
          updated_cr.push(data);
        }
    });
    this.spinner.show();
    var payLoad = {
      "created_cr" : created_cr,
      "updated_cr" : updated_cr,
      "deleted_cr" : this.deletedCrList
    }
    this.projectService.submitCRForm(this.projectId,payLoad).subscribe((res: any) => {
     this.spinner.hide();
      this.toastr.success(res?.message);
      this.projectChangeRequestForm.clear();
      this.deletedCrList = [];
      this.CRList = res?.data;
      res?.data?.forEach((elm:any)=>{
        this.projectChangeRequestForm.push(
          this.fb.control(elm)
        )
      });
     },err=>{this.spinner.hide()
     });
  }
  undoProcess(){
    this.projectChangeRequestForm.clear();
    this.deletedCrList = [];
    this.CRList.forEach((elm:any)=>{
        this.projectChangeRequestForm.push(
          this.fb.control(elm)
        )
    });
  }
  toggleEvent(event:any){
    if(event.target.ariaExpanded === "true"){
       event.srcElement.classList.remove('rotate-minus-90deg');
       event.srcElement.classList.add('rotate-90deg');
    }
    if(event.target.ariaExpanded === "false"){
       event.srcElement.classList.remove('rotate-90deg');
       event.srcElement.classList.add('rotate-minus-90deg');
    } 
   }
}


<div class="row field-adjust" id="custom-height">
  <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="row">
  <div class="pr-0 col-12">
    <div style="height: calc(100vh - 340px); overflow: scroll">
      <div class="d-flex align-items-center">
        <mat-icon class="text-warning cursor-pointer fs-5"> star</mat-icon>
        <span> - Recommended </span>
      </div>
      <app-selection-tree-listing *ngIf="frequencyList.length > 0"
        [(items)]="termsArray"
        selectedOkr="selectedOkr"
        [disabled]="false"
        [tree]="'program'"
        [context]="context"
        [fromPage]="'toe'"
        [(frequencyList)]="frequencyList"
        [(userList)]="userList"
      ></app-selection-tree-listing>
    </div>
    <div
      style="display: flex; justify-content: right"
      class="mx-2"
      *ngIf="!user?.is_vendor"
    >
      <button
        mat-button
        mat-flat-button
        class="bg-primary text-whtie"
        color="primary"
        (click)="saveSelection()"
      >
        Save
      </button>
    </div>
  </div>
</div>

<div class="row field-adjust" id="custom-height">
    <app-view-page-header [headerName]="'Spend Tracker'"></app-view-page-header>
</div>
<div class="custom-table fixed-table-header" style="max-height: calc(100vh - 300px);">
    <table class="table table-bordered text-center"  >
        <thead>
            <tr>
                <th>#</th>
                <th>Month</th>
                <th class="w-25">Spend (US$)</th>
                <th>YTD (US$)</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody [formGroup]="spendTrackerForm" class="spendtracker">
            <tr *ngFor="let spend of spendTrackerForm.controls; let spendIndex=index">
                <td>{{spendIndex+1}}</td>
                <td>{{spend.value.month_name}}</td>
                <td>
                    <span *ngIf="spend.value.field_disable">{{spend.value.actual_invoiced}}</span>
                    <mat-form-field class="col-12"  *ngIf="!spend.value.field_disable" appearance="outline" >
                        <mat-icon matPrefix>attach_money</mat-icon>
                        <input type="text" matInput status="basic"
                        [(ngModel)]="spend.value.actual_invoiced" [ngModelOptions]="{standalone: true}" name="actual_invoiced">
                    </mat-form-field>
                </td>
                <td>{{getYTD(spend.value.month)}}</td>
                <td>
                    <button id="save_btn" *ngIf="!spend.value.field_disable" mat-button mat-flat-button color="primary" class="bg-primary text-white" (click)="submitSpendTracker(spend.value,spendIndex)">
                       Submit
                    </button>
                    <mat-icon *ngIf="spend.value.field_disable && !user?.is_vendor" (click)="enableField(spendIndex)" >launch</mat-icon>
                </td>
            </tr>
        </tbody>
    </table>
</div>        



import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-f-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  
  }
   

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-view-page-header',
  templateUrl: './view-page-header.component.html',
  styleUrls: ['./view-page-header.component.scss']
})
export class ViewPageHeaderComponent implements OnInit {
  @Input() headerName : string;
  projectID: any;
  projectdetail: any;
  programDetail: any;
  breadcrumb: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private projectService: ProjectService,
    private breadCrumService: BreadcrumbService
  ) { }

  ngOnInit(): void {
   
    this.programDetail = JSON.parse(localStorage.getItem('program') || '{}');

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })

    this.getprojectId();
    this.getprojectdetails();
    
  }

  getprojectId() {
      this.activatedRoute.parent?.params.subscribe(params => {
      this.projectID = atob(params.id);
    });
  }

  getprojectdetails() {
    this.spinner.show();
    this.projectService.getprojectdetailsOverview(this.projectID).subscribe((response: any) => {
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: this.programDetail.portfolio_name, url: '/delivery/projects', queryParams: {portfolio: this.programDetail.portfolio_id} },
        { label: this.programDetail.subportfolio_name, url: '/delivery/projects', queryParams: {subportfolio: this.programDetail.subportfolio_id} },
        { label: this.programDetail.program_name, url: '/delivery/projects', queryParams: {program: this.programDetail.program_id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);

      this.projectdetail = response;
      let data:any = {'projectId':response.project_id,'projectName':response.project_name}
      localStorage.setItem("project",data);
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error
    });
  }

  getTagObject(){
    return {
      entity_id: this.projectID,
      entity_type: "project"
    }
  }
}


import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ActivatedRoute,Router } from '@angular/router';
import {  FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'app-project-training',
  templateUrl: './project-training.component.html',
  providers: [DatePipe],
  styleUrls: ['./project-training.component.scss']
})
export class ProjectTrainingComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery.training.title;
  goalsPresentStatus:boolean = false;
  projectID: any;
  projectGoalTrainingForm: FormArray | any;
  projectGoals:any;
  deletedTrainingList:any;
  filteredTeamList:any;
  removable = true;
  toggleStatus:any;
  user: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private projectService: ProjectService,
    private ProgramService : ProgramService,
    public dialog: MatDialog,
    private router: Router,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.messageService.setGnews('Project training')
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.getprojectId();
    this.createForm();
    this.getProjectTrainingsByProjectId();
    this.getTeamList();
  }
  getprojectId() {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectID = atob(params.id);
    });
  }
  getProjectTrainingsByProjectId(){
    this.spinner.show();
    this.projectGoals = [];
    this.projectService.getTrainingProjectGoals(this.projectID).subscribe((res: any) => {
        this.spinner.hide();
        this.projectGoals = res;
        if(res.length > 0){
        this.goalsPresentStatus = true;
        this.projectGoals.forEach((elm:any)=>{
          if(elm.trainings.length > 0){
            this.projectGoalTrainingForm.push(
              this.fb.control(elm)
            )
          }
        });
        console.log(this.projectGoalTrainingForm);
      }else{
        this.callGoalEmptyPopUp();
      }
    });
  }

  callGoalEmptyPopUp(){
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = {"name":"empty_goal","section":"training"};
    dialog.afterClosed().subscribe(data => {
      if (!!data) {
        this.router.navigate([`view/`+btoa(this.projectID)+`/okrlog`]);
      }
    })
  }
  
  searchGoal(index: any){
    return this.getGoal(index)
  }

  getGoal(index: any){
      var filteredArray = this.projectGoals;
      this.projectGoalTrainingForm?.controls.forEach((val:any)=>{
        filteredArray = filteredArray.filter((f:any) => f.id !== val?.value?.id);
      });
      return filteredArray;
  }

  getGoalTitle(goalId: any){
    if(goalId){
      return this.projectGoals.find((item: any) => item.id === goalId)?.goal_name;
    }
  }
  getGoalDesc(goalId: any){
    if(goalId){
      return this.projectGoals.find((item: any) => item.id === goalId)?.goal_description;
    }
  }
  onselectGoal(event: any, i: any){
    var data = this.projectGoals[this.projectGoals.findIndex((e: any) => e.id == event.option.value)];
    this.projectGoalTrainingForm.controls[i].value = data;
    var id= document.getElementById('collapseOne'+i);
    console.log('collapseOne'+i);
    id?.classList.add('show');
  }

  removeTrainingItems(goalIndx: any, trainingIndx: any){
    var trainingId = this.projectGoalTrainingForm.controls[goalIndx]?.value?.trainings[trainingIndx]?.id;
    this.projectGoalTrainingForm.controls[goalIndx].value.trainings.splice(trainingIndx, 1)
    if(trainingId && !this.deletedTrainingList.includes(trainingId)){
      this.deletedTrainingList.push(trainingId);
    }
  }
  removeGoalAllTraining(i:any){
    this.projectGoalTrainingForm.controls[i].value.trainings.forEach((trainingId:any)=>{
      if(trainingId && !this.deletedTrainingList?.includes(trainingId.id)){
        this.deletedTrainingList?.push(trainingId.id);
      }
    })
    this.projectGoalTrainingForm.controls.splice(i, 1);
  }
  addGoalItems(index: any){
    var data = {
      id: null,
      goal_name: null,
      goal_description: null,
      projectgoal_id:null,
      trainings: []
    }
    this.projectGoalTrainingForm.push(
      this.fb.control(data));
  }
  addTrainingItems(index: any){
    this.projectGoalTrainingForm.controls[index].value.trainings.push({
      id: null,
      training_name: null,
      due_date: null,
      users:[]
    })
    
  }
  createForm() {
    this.projectGoalTrainingForm = this.fb.array([])
  }

  removeTeam(team: string, indx: any, trainingIndx:any): void {
    const index = this.projectGoalTrainingForm.controls[indx].value.trainings[trainingIndx].users?.indexOf(team);

    if (index >= 0) {
      this.projectGoalTrainingForm.controls[indx].value.trainings[trainingIndx].users?.splice(index, 1);
    }
  }

  searchTeam(index: any,trainingIndx:any){
    return this.filteredTeamListFilter(index,trainingIndx)
  }

  filteredTeamListFilter(index:any,trainingIndx:any){
    var filteredArray = this.filteredTeamList;
    this.projectGoalTrainingForm?.controls[index]?.value?.trainings[trainingIndx]?.users?.forEach((val:any)=>{
      filteredArray = filteredArray?.filter((f:any) => f.name !== val);
    });
    return filteredArray;
  }

  selectedTeam(event: MatAutocompleteSelectedEvent, index: any,trainingIndx:any): void {
    this.projectGoalTrainingForm.controls[index].value.trainings[trainingIndx].users?.push(event.option.value);
  }

  getTeamList(type?: any) {
    this.ProgramService.getOpportunityFormDataWithoutChache('opportunity_teams').subscribe((res: any) => {
     var wholeTeamList = JSON.parse(JSON.stringify(res.records));
     if (wholeTeamList) {
        const teamList = wholeTeamList.find((i: any) => i.field_name == "opportunity_team");
        this.filteredTeamList = teamList.field_values;
      }
    });
  }
  saveProjectGoalTraining(){
    var created_training:any = [];
    var updated_training:any = [];
    this.projectGoalTrainingForm.controls.forEach((control:any)=>{
      control.value.trainings.forEach((training:any)=>{
        var data = {
          "projectgoal_id" : control?.value?.projectgoal_id,
          "training_name" : training?.training_name,
          "due_date" : this.datepipe.transform(training?.due_date,"yyyy-MM-dd"),
          "users" : training?.users,
          "id" : training?.id
        }
        if(training.id == null){
          created_training.push(data);
        }else{
          updated_training.push(data);
        }
      });
    });
    this.spinner.show();
    var payLoad = {
      "created_training" : created_training,
      "updated_training" : updated_training,
      "deleted_training" : this.deletedTrainingList
    }
    this.projectService.saveProjectGoalTrainings(payLoad,this.projectID).subscribe((res: any) => {
     this.spinner.hide();
      this.toastr.success(res?.message);
      this.projectGoalTrainingForm.clear();
      this.deletedTrainingList = [];
      this.projectGoals = res?.data;
      res?.data?.forEach((elm:any)=>{
        if(elm.trainings.length > 0){
          this.projectGoalTrainingForm.push(
            this.fb.control(elm)
          )
        }
      });
     },err=>{this.spinner.hide()
     });
  }
  undoProcess(){
    this.projectGoalTrainingForm.clear();
    this.deletedTrainingList = [];
    this.projectGoals.forEach((elm:any)=>{
      if(elm.trainings.length > 0){
        this.projectGoalTrainingForm.push(
          this.fb.control(elm)
        )
      }
    });
  }

  toggleEvent(event:any){
   if(event.target.ariaExpanded === "true"){
      event.srcElement.classList.remove('rotate-minus-90deg');
      event.srcElement.classList.add('rotate-90deg');
   }
   if(event.target.ariaExpanded === "false"){
      event.srcElement.classList.remove('rotate-90deg');
      event.srcElement.classList.add('rotate-minus-90deg');
   } 
  }
}

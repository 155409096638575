<div class="row field-adjust" id="custom-height">
  <app-view-page-header [headerName]="staticText.delivery.left_bar.financials"></app-view-page-header>
</div>
<div class="row w-100 m-0">
  <div class="col-md-auto p-0 px-0" style="width: 100%">
    <!--Tabs Started -->
    <mat-tab-group
      mat-align-tabs="start"
      class="head tabs"
      (selectedTabChange)="changeTab($event)"
    >
      <mat-tab label="{{ staticText.delivery.financials.budget }}">
        <div *ngIf="activeTab == 0 && projectID">
          <app-f-budget projectID="{{ projectID }}"></app-f-budget>
        </div>
      </mat-tab>
      <mat-tab label="{{ staticText.delivery.financials.spend }}">
        <div *ngIf="activeTab == 1 && projectID">
          <app-f-spend projectID="{{ projectID }}"></app-f-spend>
        </div>
      </mat-tab>
      <mat-tab label="{{ staticText.delivery.financials.insights }}">
        <div *ngIf="activeTab == 2">
          <app-f-insights></app-f-insights>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

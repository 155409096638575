import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { DatePipe, formatDate } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LodashService } from "src/app/shared/services/lodash.service";
import { ProjectService } from "src/app/shared/services/project.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { NgxPermissionsService } from "ngx-permissions";
import * as Highcharts from "highcharts";
import { Chart } from "angular-highcharts";
import { MatDialog } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { id } from "@swimlane/ngx-datatable";
import { cloneDeep, forEach } from "lodash";
declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");
let threeD = require("highcharts/highcharts-3d");
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
@Component({
  selector: "app-risk-log",
  templateUrl: "./risk-log.component.html",
  styleUrls: ["./risk-log.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [DatePipe],
})
export class RiskLogComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar.risk;
  activeTab = this.staticText?.delivery.risk_log.setup;
  searchText = "";
  risklogs: any[] = [];
  risklogsnew: any[] = [];

  masterRiskLogs: any[] = [];
  slaveRiskLogs: any[] = [];
  patchCloneData: any[] = [];
  riskFormInputValues: any = {};
  riskFormArray: any[] = [];
  projectId: any;
  statusList: any[] = [];
  allRisks: any = [];
  pieChart: any;
  opportunityData: any;
  projectWorkType: any;
  riskHeatMapData: any;
  selectedRisk = "";
  updateData: any;
  filterTitle: any[] = [];
  showStatusRightPane = false;
  filteredRisks: any = [];
  statusListNew = [
    { id: "Open", display_name: "Open" },
    { id: "Closed", display_name: "Close" },
  ];
  stock: Chart = new Chart();
  riskDomainForm = this.fb.group({
    riskDomain: ["aaaa", Validators.required],
  });
  isFirstLoad: boolean = true;
  riskRadarData: any;
  riskDomains: any[] = [];
  projectRisks: any[] = [];
  projectRiskIds: any[] = [];

  riskHeatmapArr: any[] = [];
  likelyHood = {
    id: 6,
    field_name: "delivery_risk_likelihood",
    display_name: "Delivery Risk Likelihood",
    lookup_field: "''",
    field_type: "drop-down",
    field_status: true,
    model_reference: "valuelist",
    form_details: {
      id: 1,
      form_name: "delivery_risk_management",
    },
    field_values: [
      {
        id: 109,
        name: "unlikely",
        display_name: "Unlikely",
        type: "delivery_risk_likelihood",
        is_custom: false,
      },
      {
        id: 110,
        name: "rare",
        display_name: "Rare",
        type: "delivery_risk_likelihood",
        is_custom: false,
      },
      {
        id: 111,
        name: "possible",
        display_name: "Possible",
        type: "delivery_risk_likelihood",
        is_custom: false,
      },
      {
        id: 112,
        name: "likely",
        display_name: "Likely",
        type: "delivery_risk_likelihood",
        is_custom: false,
      },
      {
        id: 113,
        name: "certain",
        display_name: "Certain",
        type: "delivery_risk_likelihood",
        is_custom: false,
      },
    ],
  };

  riskScoreMap: Map<any, any> = new Map();

  riskSetupFormArray = this.fb.group({
    riskSetupList: this.fb.array([]),
  });
  riskDetailFormArray = this.fb.group({
    riskDetailList: this.fb.array([]),
  });

  riskArray: any;
  selectedRisks: any;
  context: any;

  riskMetric: any;
  riskMetricCreateForm: FormGroup | any;
  riskMetricAcceptCreateForm: FormGroup | any;
  riskMetricTranferCreateForm: FormGroup | any;
  riskOwners: any;
  riskArrayHeatMap: any;
  user: any;

  constructor(
    private permissionsService: NgxPermissionsService,
    private projectService: ProjectService,
    private lodashService: LodashService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public fb: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    this.permissionsService.loadPermissions(
      JSON.parse(localStorage.getItem("permission") || "{}").permissions
    );
    // this.getAllMandatoryData();
    this.getProjectid();
    this.getRisk();
    // this.getRiskLogDropDownValues();
    // this.handleriskradarresponse();
    // this.getRiskOwners();
    // this.riskMetricCreateForm = this.fb.array([]);
    // this.riskMetricAcceptCreateForm = this.fb.group(
    //   {
    //     comments: [""]
    //   });
    // this.riskMetricTranferCreateForm = this.fb.group(
    //   {
    //     comments: [""],
    //     transfer_to: [""]
    //   })
  }

  //   selectdomainforriskradar(index:any,domainName?:any,event?:any){
  // if(event.isUserInput){
  //   this.handleriskradarresponse(domainName);

  // }

  //   }

  // handleriskradarresponse(domainName?:any){
  //   let risks :any[]= []
  //   let scores :any[]=[]
  //     for (let key in this.riskRadarData?.risk_domain_score) {
  //       if(!domainName){
  //         risks.push(key)
  //         scores.push(this.riskRadarData?.risk_domain_score[key])
  //       }
  //         if(!domainName||key==domainName){
  //           for (let keyone in this.riskRadarData?.risk_sub_domain_score) {
  //             if(key==keyone){
  //             let value = this.riskRadarData.risk_sub_domain_score[key];
  //             for (let keytwo in value) {
  //               if(domainName){
  //                 risks.push(keytwo)
  //                 scores.push(value[keytwo])
  //                 console.log(keytwo+" ------------ "+value[keytwo])

  //               }

  //             }
  //           }
  //           }
  //         }

  //     }
  //     setTimeout(() => {
  //       this.stock=new Chart({
  //         chart: {
  //         polar: true,
  //         type: 'line',
  //         backgroundColor: 'transparent',
  //         zoomType: 'xy',
  //         scrollablePlotArea: {
  //           minHeight:1000,
  //           minWidth:500}
  //         },title: {text: domainName?domainName:"All Domain",align: 'center'},pane: { size: '90%'},xAxis: {labels: {reserveSpace: true,/*align: 'center',*/
  //           style: {fontSize:'12px'}},
  //           categories: risks,
  //           tickmarkPlacement: 'on',lineWidth: 0},
  //           yAxis: {gridLineInterpolation: 'polygon',
  //           tickInterval:25,
  //           minorTickInterval:12.5,lineWidth: 0,min: 0,max:50},legend: {enabled: false},credits: {enabled: false},series: [{name: 'Serie',
  //           color: '#88a682',type: 'area',
  //           data: scores,
  //            pointPlacement: 'on'}],responsive: {rules: [{condition: {maxWidth: 500},chartOptions: {legend: {align: 'center',verticalAlign: 'bottom'
  //           },
  //           pane: {
  //             size: '70%'}}}]}}
  //             );
  //     }, 100);

  //   }
  //  getRiskRadarData(){
  //   this.spinner.show();
  //   this.projectService.getRiskRadarData(this.projectId).subscribe((riskRadar: any) => {
  //     this.riskRadarData =riskRadar;
  //     this.handleriskradarresponse();
  //     this.spinner.hide();

  //   },err=>this.spinner.hide())
  //  }
  // getAllMandatoryData() {
  //   this.spinner.show();
  //   this.getProjectid();
  //   this.getRiskStatusList();
  //   this.patchRiskLog();

  //   this.riskMetricCreateForm = this.fb.array([]);
  // }

  // getRiskOwners(){
  //   this.projectService.getRiskOwners().subscribe((getRiskOwner:any) => {
  //     this.riskOwners = getRiskOwner.risk_owners;
  //   })
  // }

  getRisk() {
    this.spinner.show();
    this.projectService
      .getProjectRiskData(this.projectId)
      .subscribe((getRisk: any) => {
        getRisk.risk_hierarchy.forEach((domain: any) => {
          domain.level = "domain";
          if (domain.sub_domains.length > 0) {
            domain.children = domain.sub_domains;
            domain.children.forEach((sub: any) => {
              sub.level = "subdomain";
              sub.domain_id = domain.id;
              if (sub.risks.length > 0) {
                sub.children = sub.risks;
                sub.children.forEach((risk: any) => {
                  risk.level = "risk";
                  risk.subdomain_id = sub.id;
                  risk.domain_id = domain.id;

                  if (
                    getRisk.selected_risk_ids.findIndex(
                      (e: any) => e.risk_id == risk.uuid
                    ) > -1
                  ) {
                    let indx = getRisk.selected_risk_ids.findIndex(
                      (e: any) => e.risk_id == risk.uuid
                    );
                    risk.project_risk_id = getRisk.selected_risk_ids[indx].id;
                    risk.selected = true;
                  } else {
                    risk.selected = false;
                  }
                });

                sub.selected = false;

                if (
                  sub.children.filter((e: any) => e.selected === true).length ==
                  sub.children.length
                ) {
                  sub.selected = true;
                  sub.selection_type = "all";
                } else if (
                  sub.children.filter((e: any) => e.selected === true).length >
                  0
                ) {
                  sub.selected = true;
                  sub.selection_type = "intermediate";
                } else {
                  sub.selected = false;
                  sub.selection_type = "none";
                }
              }
            });

            domain.selected = false;

            if (
              domain.children.filter((e: any) => e.selection_type === "all")
                .length == domain.children.length
            ) {
              domain.selected = true;
              domain.selection_type = "all";
            } else if (
              domain.children.filter(
                (e: any) => e.selection_type === "intermediate"
              ).length > 0 ||
              domain.children.filter((e: any) => e.selection_type === "all")
                .length > 0
            ) {
              domain.selected = true;
              domain.selection_type = "intermediate";
            } else {
              domain.selected = false;
              domain.selection_type = "none";
            }
            domain.sub_domains.forEach((subDomain: any) => {
              subDomain.risks.forEach((risk: any) => {
                risk["status"] = this.getRiskStatus(
                  risk.uuid,
                  getRisk.selected_risk_ids
                );
              });
            });
          }
        });
        this.riskArray = getRisk.risk_hierarchy;
        this.riskArrayHeatMap = cloneDeep(this.riskArray);
        this.selectedRisks = getRisk.selected_risk_ids;
        this.spinner.hide();
      });
  }

  getRiskStatus(riskId: any, selectedRisks: any) {
    const risk = selectedRisks.find((item: any) => item.risk_id === riskId);
    const riskStatus = risk ? risk.risk_response : "";
    return riskStatus;
  }

  collapse(item: any) {
    if (item.level == "domain") {
      this.riskArray.forEach((element: any) => {
        if (element.id != item.id) element.expanded = false;
      });
    } else if (item.level == "subdomain") {
      let riskIndex = this.riskArray.findIndex(
        (e: any) => e.id == item.domain_id
      );

      if (this.riskArray[riskIndex].children.length > 0) {
        this.riskArray[riskIndex].children.forEach((element: any) => {
          if (element.id != item.id) element.expanded = false;
        });
      }
    }
  }

  selectGoals(item: any) {
    if (item.level == "domain") {
      this.riskArray.forEach((domain: any) => {
        if (domain.id == item.id) {
          domain.selected = item.selected;
          domain.selection_type = item.selected ? "all" : "none";

          domain.children.forEach((sub: any) => {
            sub.selected = item.selected;
            sub.selection_type = item.selected ? "all" : "none";

            sub.children.forEach((risk: any) => {
              risk.selected = item.selected;
              risk.selection_type = item.selected ? "all" : "none";
            });
          });
        }
      });
    } else if (item.level == "subdomain") {
      let subIndex = this.riskArray.findIndex(
        (e: any) => e.id == item.domain_id
      );

      if (this.riskArray[subIndex].children.length > 0) {
        this.riskArray[subIndex].children.forEach((sub: any) => {
          if (sub.id == item.id) {
            sub.selected = item.selected;
            sub.selection_type = item.selected ? "all" : "none";

            sub.children.forEach((risk: any) => {
              risk.selected = item.selected;
              risk.selection_type = item.selected ? "all" : "none";
            });
          }
        });

        if (
          this.riskArray[subIndex].children.filter(
            (e: any) => e.selection_type === "all"
          ).length == this.riskArray[subIndex].children.length
        ) {
          this.riskArray[subIndex].selected = true;
          this.riskArray[subIndex].selection_type = "all";
        } else if (
          this.riskArray[subIndex].children.filter(
            (e: any) => e.selection_type === "intermediate"
          ).length > 0 ||
          this.riskArray[subIndex].children.filter(
            (e: any) => e.selection_type === "all"
          ).length > 0
        ) {
          this.riskArray[subIndex].selected = true;
          this.riskArray[subIndex].selection_type = "intermediate";
        } else {
          this.riskArray[subIndex].selected = false;
          this.riskArray[subIndex].selection_type = "none";
        }
      }
    } else if (item.level == "risk") {
      let subIndex = this.riskArray.findIndex(
        (e: any) => e.id == item.domain_id
      );
      let riskIndex = this.riskArray[subIndex].children.findIndex(
        (e: any) => e.id == item.subdomain_id
      );

      if (this.riskArray[subIndex].children[riskIndex].children.length > 0) {
        this.riskArray[subIndex].children[riskIndex].children.forEach(
          (risk: any) => {
            if (risk.id == item.id) {
              risk.selected = item.selected;
              risk.selection_type = item.selected ? "all" : "none";
            }
          }
        );

        if (
          this.riskArray[subIndex].children[riskIndex].children.filter(
            (e: any) => e.selected === true
          ).length ==
          this.riskArray[subIndex].children[riskIndex].children.length
        ) {
          this.riskArray[subIndex].children[riskIndex].selected = true;
          this.riskArray[subIndex].children[riskIndex].selection_type = "all";
        } else if (
          this.riskArray[subIndex].children[riskIndex].children.filter(
            (e: any) => e.selected === true
          ).length > 0
        ) {
          this.riskArray[subIndex].children[riskIndex].selected = true;
          this.riskArray[subIndex].children[riskIndex].selection_type =
            "intermediate";
        } else {
          this.riskArray[subIndex].children[riskIndex].selected = false;
          this.riskArray[subIndex].children[riskIndex].selection_type = "none";
        }

        if (
          this.riskArray[subIndex].children.filter(
            (e: any) => e.selection_type === "all"
          ).length == this.riskArray[subIndex].children.length
        ) {
          this.riskArray[subIndex].selected = true;
          this.riskArray[subIndex].selection_type = "all";
        } else if (
          this.riskArray[subIndex].children.filter(
            (e: any) => e.selection_type === "intermediate"
          ).length > 0 ||
          this.riskArray[subIndex].children.filter(
            (e: any) => e.selection_type === "all"
          ).length > 0
        ) {
          this.riskArray[subIndex].selected = true;
          this.riskArray[subIndex].selection_type = "intermediate";
        } else {
          this.riskArray[subIndex].selected = false;
          this.riskArray[subIndex].selection_type = "none";
        }
      }
    }
  }

  saveSelection() {
    let rArray: any = [];

    this.riskArray.forEach((category: any) => {
      category?.children?.forEach((sub: any) => {
        sub?.children?.forEach((term: any) => {
          if (term.selected) {
            rArray.push(term.id);
          }
        });
      });
    });

    let postdata = {
      project_id: this.projectId,
      risk_ids: rArray,
    };

    delete this.riskArrayHeatMap;
    this.projectService
      .selectProjectRisk(postdata)
      .subscribe((getTerms: any) => {
        this.riskArrayHeatMap = [];
        this.toastrService.success("Risk mapped successfully");
        this.riskArrayHeatMap = cloneDeep(this.riskArray);
        this.spinner.hide();
      });
  }

  // getFilteredArray(array: any){
  //   return array != undefined ? array.filter((e: any) => e.selected == true) : []
  // }

  // getTrucatedName(subdomain:string,name:string){

  //   if(!name){
  // return "NA";
  //   }else{
  //     if(name.length>subdomain.length){
  //       return name.substring(0,subdomain.length-1)+"..."
  //     }
  //     else{
  //       return name;
  //     }

  //   }
  // }

  // onClickRisk(domain: any, sub: any, risk: any, modal: any){
  //   this.riskMetric = {
  //     domain: domain.name,
  //     subdomain: sub.name,
  //     risk: risk.name,
  //     risk_id: risk.uuid,
  //     risk_response: ""
  //   }
  //   this.spinner.show();
  //   this.riskMetricCreateForm.clear()
  //   this.projectService.getRiskProjectMapped(this.projectId, this.riskMetric.risk_id).subscribe((res: any) => {
  //     this.spinner.hide();
  //     if(res){
  //       this.riskMetric.risk_response = res.risk_response;

  //       if(this.riskMetric.risk_response == 'Mitigate'){
  //         res.risk_mitigation.forEach((element: any) => {
  //           this.riskMetricCreateForm.push(
  //             this.fb.group(
  //               {
  //                 mitigation_task: [element.risk_mitigation.action_tasks],
  //                 due_date: [element.planned_end_date],
  //                 status: [element.status],
  //                 comments: [element.remarks],
  //                 owner: element.owner ? [element.owner.email] : null
  //             })
  //           )
  //         });
  //       }

  //       if(this.riskMetric.risk_response == 'Accept'){
  //         this.riskMetricAcceptCreateForm = this.fb.group(
  //         {
  //           comments: [res.comments]
  //         })
  //       }

  //       if(this.riskMetric.risk_response == 'Transfer'){
  //         this.riskMetricTranferCreateForm = this.fb.group(
  //           {
  //             comments: [res.comments],
  //             transfer_to: [res.risk_transfer.email]
  //           })
  //       }
  //     }
  //     this.dialog.open(modal, {
  //       width: '80vw',
  //       height: '70vh',
  //     });
  //   })
  // }

  // addMetricItems(){
  //   this.riskMetricCreateForm.push(
  //     this.fb.group(
  //       {
  //         mitigation_task: [""],
  //         due_date: [""],
  //         status: [""],
  //         comments: [""],
  //         owner: [""]
  //     })
  //   )
  // }

  // removeMetric(index: any){
  //   this.riskMetricCreateForm.removeAt(index);
  // }

  // saveMetric(){
  //   let postdata;
  //   if(this.riskMetric.risk_response == 'Mitigate'){
  //     postdata = this.riskMetricCreateForm.value;
  //     postdata.forEach((element: any) => {
  //       element.due_date = this.datepipe.transform(element.due_date, 'yyyy-MM-dd');
  //     });
  //   }
  //   if(this.riskMetric.risk_response == 'Accept'){
  //     postdata = this.riskMetricAcceptCreateForm.value
  //   }
  //   if(this.riskMetric.risk_response == 'Transfer'){
  //     postdata = this.riskMetricTranferCreateForm.value
  //   }
  //   this.spinner.show();
  //   let queryParam = `?risk_id=${this.riskMetric.risk_id}&risk_response=${this.riskMetric.risk_response}&project_id=${this.projectId}`
  //   this.projectService.setRiskResponse(queryParam, postdata).subscribe((res: any) => {
  //     this.spinner.hide();
  //     this.toastrService.success('Risk updated successfully')
  //   })

  // }

  //   getRiskLogs() {
  //     if(this.isFirstLoad){
  //       this.projectService.getDomainList().subscribe((getRiskLogs: any) => {
  //         this.isFirstLoad = false;
  //         this.patchCloneData=[];
  //         let domainLIst  = getRiskLogs['records'];
  //         domainLIst.forEach((element:any) => {
  //          let domainData = {prefix:element.id,risk_domain_display_name:element.display_name,risk_domain_name:element.name,risk_domain_uuid:element.uuid,risk_sub_domain:[{risk:[]}]

  //          }
  //          this.patchCloneData.push(domainData)
  //         });
  //         this.risklogs = this.patchCloneData;
  //         this.risklogsnew.push({"risk_domain_uuid":"aaaa","risk_domain_display_name":"All Domains"})
  //         this.risklogs.forEach(element => {
  //           this.risklogsnew.push(element)

  //         });
  //         this.filterExistingRiskLogs(this.risklogs, this.masterRiskLogs);

  //         this.slaveRiskLogs = this.lodashService.Clonedata(this.risklogs);

  //         this.spinner.hide();
  //       },err=>this.spinner.hide())

  //     this.projectService.getOpportunityRiskData('delivery_risk_management',this.projectId).subscribe((getRiskLogs: any) => {
  //       this.spinner.hide();
  //           getRiskLogs['records'].push(this.likelyHood);

  //       this.patchCloneData = this.lodashService.Clonedata(getRiskLogs['records']?.find((findData: any) => findData.field_name == "delivery_risk")?.field_values);
  //       this.risklogs = getRiskLogs['records']?.find((findData: any) => findData.field_name == "delivery_risk")?.field_values;

  //       this.filterExistingRiskLogs(this.risklogs, this.masterRiskLogs);

  //       this.slaveRiskLogs = this.lodashService.Clonedata(this.risklogs);
  //       this.slaveRiskLogs.forEach((element:any) => {
  //         element.risk_sub_domain.forEach((element:any) => {
  //           let filteredRisk=element.risk.filter((e:any) => this.projectRiskIds.indexOf(e.risk_id)==-1 )
  //           element.risk = filteredRisk;

  //         });

  //       });
  //       console.log(this.slaveRiskLogs)
  //         },err=>this.spinner.hide())

  //   }

  //   }

  //   getRiskLogDropDownValues(){

  // this.projectService.getRiskImpact().subscribe((response:any) =>{
  //   this.riskFormInputValues.riskImpactList = response.risk_impact;

  // },err=>this.spinner.hide())

  // this.projectService.getRiskLikelyhood().subscribe((response:any) =>{
  //   this.riskFormInputValues.likelyHoodOptions = response.risk_likelihood;

  // },err=>this.spinner.hide())

  // this.projectService.getRiskOwners().subscribe((response:any) =>{
  //   this.riskFormInputValues.deliveryOwnersList = response.risk_owners;

  // },err=>this.spinner.hide())

  // this.getMappedRisk();

  //   }

  //   getMappedRisk(){
  //     this.projectService.getProjectMappedRisk(this.projectId).subscribe((res:any) =>{
  //        this.riskDomains = []
  //        this.projectRiskIds=[];
  //       let riskSubDomains: any[]=[];
  //         if (res.project_risks)
  //       {
  //         this.projectRisks = res.project_risks;
  //         this.projectRisks.forEach((element:any) => {
  //           this.projectRiskIds.push(element.risk.id);

  //         });

  //         res.project_risks.forEach((element:any) => {
  //           if (this.riskDomains.filter(e => e.uuid === element.risk_domain.uuid).length == 0) {
  //             this.riskDomains.push(element.risk_domain)
  //           }
  //           if (riskSubDomains.filter(e => e.uuid === element.risk_sub_domain.uuid).length == 0) {
  //             riskSubDomains.push(element.risk_sub_domain)
  //           }
  //         });

  //         this.riskDomains.forEach((domains:any) => {
  //           domains.risk_sub_domain= [];

  //           riskSubDomains.forEach((sudomains:any) => {
  //             if(sudomains.risk_domain==domains.uuid){
  //               sudomains.risk = [];
  //               domains.risk_sub_domain.push(sudomains);
  //             }
  //           });

  //         });
  //         this.riskDomains.forEach((element:any) => {
  //           element.risk_domain_uuid = element.uuid;
  //           element.risk_sub_domain.forEach((subdomains:any) => {
  //             subdomains.risk_sub_domain_uuid=subdomains.uuid;
  //             res.project_risks.forEach((risk:any) => {
  //               risk.risk_uuid=risk.risk.uuid;
  //               risk.risk_domain_uuid = element.uuid;
  //               risk.risk_sub_domain_uuid=subdomains.uuid;

  //               if(risk.risk_sub_domain.uuid==subdomains.uuid){
  //                 risk.risk_display_name = risk.risk.display_name;
  //                 risk.prefix = risk.risk.prefix;

  //                 subdomains.risk.push(risk);
  //               }

  //             });
  //           });
  //         });

  //       }

  //       this.masterRiskLogs = this.riskDomains;//riskDomains.map((okrData: any) => {okrData.risk_domain});
  //       this.masterRiskLogs = this.sortRecordsList(this.masterRiskLogs, 'prefix');

  //       this.allRisks = [];
  //       for(let item in this.masterRiskLogs){
  //         for(let child in this.masterRiskLogs[item]['risk_sub_domain']){
  //           let arr = this.masterRiskLogs[item]['risk_sub_domain'][child]['risk'];
  //           this.masterRiskLogs[item]['risk_sub_domain'][child]['risk'] =arr;
  //            arr = arr.map((o:any)=>{
  //             let obj={...o};
  //             obj['hasError'] = !o?.status || !o?.risk_owner || !(o?.due_date);
  //             return obj;
  //           })
  //           this.allRisks = this.allRisks.concat(arr);
  //         }

  //         this.setupRiskFormData();

  //       }
  //       this.getRiskLogs();

  //     },err=>this.spinner.hide())
  //   }

  //   getRiskStatusList() {
  //     this.projectService.getRiskStatus().subscribe((status: any) => {
  //       this.statusList = status;
  //       this.riskFormInputValues.statusList = this.statusList
  //       console.log("status", this.statusList);
  //     })
  //   }

  getProjectid() {
    this.route.parent?.params.subscribe((res: any) => {
      this.projectId = atob(res.id);
    });
  }
  // displayFn(data: any): string {
  //   if (data && data?.name) {
  //     return data && data?.name ? data?.name : data?.mail;
  //   } else if (data && data?.label) {
  //     return data && data?.label ? data?.label : data?.action_complete_label;
  //   } else
  //     return data && data?.full_name ? data?.full_name : data?.mail;
  // }
  // patchRiskLog() {
  //   this.projectService.getprojectdetailsOverview(this.projectId).subscribe((res: any) => {
  //     this.opportunityData = res.opportunity;
  //     this.projectWorkType=res.work_type;

  //   }, error => {
  //     this.spinner.hide();
  //     throw error;
  //   })
  // }
  closeDialog() {
    this.dialog.closeAll();
  }

  // removeRiskLogs(childData: any) {
  //   if(childData?.uuid){
  //     this.allRisks =this.allRisks.filter((o:any)=> o?.uuid != childData?.uuid)
  //   }else{
  //     this.allRisks =this.allRisks.filter((o:any)=> o?.risk_uuid != childData?.risk_uuid)
  //   }
  //   this.masterRiskLogs.filter((goal: any, goalindex: number) => {
  //     if (goal.risk_domain_uuid == childData.risk_domain_uuid) {
  //       if (goal.risk_sub_domain.length != 0) {
  //         goal.risk_sub_domain.forEach((keyResultParent: any, keyResultParentindex: number) => {
  //           let childKeyResultIndex = keyResultParent.risk.findIndex((child: any) => child.risk_uuid == childData.risk_uuid)
  //           if (childKeyResultIndex !== -1) {
  //             keyResultParent.risk.splice(childKeyResultIndex, 1);
  //           }
  //           if (keyResultParent.risk.length == 0) {
  //             goal.risk_sub_domain.splice(keyResultParentindex, 1);
  //           }
  //           if (goal.risk_sub_domain.length == 0) {
  //             this.masterRiskLogs.splice(goalindex, 1);
  //           }
  //         })
  //       } else {
  //         this.masterRiskLogs.splice(goalindex, 1);
  //       }
  //     }
  //   })

  //   //if (!childData.goal_is_custom) {
  //   this.patchRecommendedRiskLog(childData);
  //   //}
  //   this.setupRiskFormData();

  // }
  // patchRecommendedRiskLog(childData: any) {
  //   console.log("childData", childData);

  //   if (this.slaveRiskLogs.length !== 0 || this.slaveRiskLogs) {
  //     let goalIndex = this.slaveRiskLogs?.findIndex((goalIndex: any) => goalIndex.risk_domain_uuid == childData.risk_domain_uuid);
  //     if (goalIndex !== -1) {
  //       for (var goalKeyResult of this.slaveRiskLogs) {
  //         if (goalKeyResult) {
  //           if (goalKeyResult.risk_domain_uuid == childData.risk_domain_uuid) {
  //             let parenKeyResultIndex = goalKeyResult.risk_sub_domain.findIndex((parentKeyResultsIndex: any) => parentKeyResultsIndex.risk_sub_domain_uuid == childData.risk_sub_domain_uuid);
  //             if (parenKeyResultIndex !== -1) {
  //               goalKeyResult.risk_sub_domain[parenKeyResultIndex].risk.push(childData);
  //               this.sortRecordsList(goalKeyResult.risk_sub_domain[parenKeyResultIndex].risk, 'prefix')
  //               break;
  //             } else {
  //               if (this.patchCloneData.length !== 0) {
  //                 for (var patchGoalKeyResult of this.patchCloneData) {
  //                   if (patchGoalKeyResult) {
  //                     if (patchGoalKeyResult.risk_domain_uuid == childData.risk_domain_uuid) {
  //                       let patchParentIndex = patchGoalKeyResult.risk_sub_domain.findIndex((patchParentKeyResults: any) => patchParentKeyResults.risk_sub_domain_uuid == childData.risk_sub_domain_uuid);
  //                       if (patchParentIndex !== -1) {
  //                         for (var patchParent of patchGoalKeyResult.risk_sub_domain) {
  //                           let patchParentclone = this.lodashService.Clonedata(patchParent);
  //                           if (patchParent.risk_sub_domain_uuid == childData.risk_sub_domain_uuid) {
  //                             patchParentclone = patchParent;
  //                             patchParentclone.risk = [];

  //                             for (var patchLocalArray of this.slaveRiskLogs) {
  //                               if (patchLocalArray.risk_domain_uuid == childData.risk_domain_uuid) {
  //                                 patchLocalArray.risk_sub_domain.push(patchParentclone);
  //                                 patchParentclone.risk.push(childData);
  //                                 this.sortRecordsList(patchLocalArray.risk_sub_domain, 'prefix')
  //                                 break;
  //                               }
  //                             }
  //                             break;
  //                           }
  //                         }
  //                       }
  //                       break;
  //                     }
  //                   }
  //                 }
  //               }
  //               break;
  //             }
  //           }
  //         }
  //       }

  //     } else {
  //       if (this.patchCloneData.length !== 0) {
  //         for (var patchNewGoal of this.patchCloneData) {
  //           if (patchNewGoal) {
  //             if (patchNewGoal.risk_domain_uuid == childData.risk_domain_uuid) {
  //               let patchNewGoalParentIndex = patchNewGoal.risk_sub_domain.findIndex((patchNewGoalParentKeyResults: any) => patchNewGoalParentKeyResults.risk_sub_domain_uuid == childData.risk_sub_domain_uuid);
  //               if (patchNewGoalParentIndex !== -1) {
  //                 let patchNewGoalclone = this.lodashService.Clonedata(patchNewGoal);
  //                 let patchNewGoalParent = patchNewGoal.risk_sub_domain.filter((patchNewGoalparentdata: any) => patchNewGoalparentdata.risk_sub_domain_uuid == childData.risk_sub_domain_uuid);
  //                 if (patchNewGoal.risk_domain_uuid == childData.risk_domain_uuid) {
  //                   patchNewGoalclone.risk_sub_domain = patchNewGoalParent;
  //                   patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk = [];
  //                   patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk.push(childData);
  //                   let patchNewChildIndex = patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk.findIndex((patchNewChildIndex: any) => patchNewChildIndex.risk_uuid == childData.risk_uuid);
  //                   patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk = this.lodashService.sortUniqueBy(patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk, patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk[patchNewChildIndex].risk_uuid)
  //                   this.slaveRiskLogs.push(patchNewGoalclone);
  //                   this.sortRecordsList(this.slaveRiskLogs, 'prefix')
  //                   break;
  //                 }
  //                 // else{
  //                 //   patchNewGoalclone.risk_sub_domain = patchNewGoalParent;
  //                 //   patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk = [];
  //                 //   patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk.push(childData);
  //                 //   patchNewGoalclone.risk_sub_domain.push(childData);
  //                 //   patchNewGoalclone.risk_sub_domain[patchNewGoalParentIndex].risk.push(childData);
  //                 //   //goalKeyResult.risk_sub_domain[parenKeyResultIndex].risk = this.lodashService.orderBy(goalKeyResult.risk_sub_domain[parenKeyResultIndex].risk, "key_title", "asc");
  //                 // }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // removeRightPaneRecommendedRiskLog(childData: any) {
  //   this.addColumnRiskLog(childData);
  //   if (this.slaveRiskLogs.length !== 0) {
  //     try {
  //       let goalIndex = this.slaveRiskLogs.findIndex((goalIndex: any) => goalIndex.risk_domain_uuid == childData.risk_domain_uuid);
  //       this.slaveRiskLogs.filter((goal: any) => {
  //         if (goal.risk_domain_uuid == childData.risk_domain_uuid) {
  //           goal.risk_sub_domain.forEach((parent: any, parentindex: number) => {
  //             if (parent.risk.length != 0 || null) {
  //               if (parent.risk_sub_domain_uuid == childData.risk_sub_domain_uuid) {
  //                 for (let j = 0; j < parent.risk.length; j++) {
  //                   let childKeyResultIndex = parent.risk.findIndex((child: any) => child.risk_uuid == childData.risk_uuid)
  //                   parent.risk.splice(childKeyResultIndex, 1);
  //                   if (parent.risk.length == 0 && goal.risk_sub_domain.length == 0) {
  //                     this.slaveRiskLogs.splice(goalIndex, 1);
  //                   }
  //                   if (parent.risk.length == 0) {
  //                     goal.risk_sub_domain.splice(parentindex, 1);
  //                     if (goal.risk_sub_domain.length == 0) {
  //                       this.slaveRiskLogs.splice(goalIndex, 1);
  //                     }
  //                     let parentIndex = goal.risk_sub_domain.findIndex((sliceParentIndex: any) => sliceParentIndex.risk_sub_domain_uuid == childData.risk_sub_domain_uuid);
  //                     if (parentIndex !== -1) {
  //                       goal.risk_sub_domain.splice(parentIndex, 1);
  //                     }
  //                   }
  //                   break;
  //                 }
  //               } else {
  //                 if (parent.risk.length == 0 && goal.risk_sub_domain.length == 0) {
  //                   this.slaveRiskLogs.splice(goalIndex, 1);
  //                 }
  //               }
  //             }
  //           });
  //         }
  //       })
  //     } catch (error) {
  //       if (error) throw error;
  //     }
  //   }
  // }

  // addColumnRiskLog(childData: any) {
  //   let slaveKeyResultdata = this.lodashService.Clonedata(this.slaveRiskLogs);
  //   // below will find goal parent using goal id which will return respective goal parent
  //   let keyresultsdata = { ...slaveKeyResultdata.find((i: any) => i.risk_domain_uuid == childData.risk_domain_uuid) };
  //   // below will find goals child data using key result unique id which will return respective goal parents child data
  //   let keyresultchilddata = keyresultsdata.risk_sub_domain.filter((i: any) => {
  //     if (i.risk_sub_domain_uuid == childData.risk_sub_domain_uuid) {
  //       return i;
  //     }
  //   });
  //   // below will check whether goal unique id is equal to key result goal unique id if equal it will return true else false
  //   const isExist = this.masterRiskLogs.some(e => e.risk_domain_uuid == keyresultsdata.risk_domain_uuid);
  //   // below will pass child data to parent
  //   keyresultsdata.risk_sub_domain = keyresultchilddata;
  //   // below will find subchild data of parent using key result id which will return respective goal parent subchild
  //   let subchild = keyresultsdata.risk_sub_domain[0].risk.filter((el: any) => {
  //     if (el.risk_uuid == childData.risk_uuid) {
  //       return el;
  //     }
  //   });

  //   // below will push to key arr based on condition

  //   let subchildata = subchild[0];
  //   if (!isExist) {
  //     let keyclonedata = this.lodashService.Clonedata(keyresultsdata);
  //     this.masterRiskLogs.push(keyclonedata);
  //     if (keyresultsdata.risk_sub_domain != null) {
  //       this.masterRiskLogs.filter((filterResponse: any) => {
  //         if (filterResponse.risk_domain_uuid == subchildata.risk_domain_uuid) {
  //           if (filterResponse.risk_sub_domain.some((e: any) => e.risk_sub_domain_uuid == subchildata.risk_sub_domain_uuid)) {
  //             let parentindex = filterResponse.risk_sub_domain.findIndex((i: any) => i.risk_sub_domain_uuid == subchildata.risk_sub_domain_uuid);
  //             filterResponse.risk_sub_domain[parentindex].risk = subchild;
  //           }
  //         }
  //       })
  //     }
  //   } else {

  //     this.masterRiskLogs.filter((filterResponse: any) => {
  //       if (filterResponse.risk_domain_uuid == subchildata.risk_domain_uuid) {
  //         if (filterResponse.risk_sub_domain.some((e: any) => e.risk_sub_domain_uuid == subchildata.risk_sub_domain_uuid)) {
  //           let parentindex = filterResponse.risk_sub_domain.findIndex((i: any) => i.risk_sub_domain_uuid == subchildata.risk_sub_domain_uuid);
  //           filterResponse.risk_sub_domain[parentindex].risk.push(subchild[0]);
  //         } else {
  //           filterResponse.risk_sub_domain.push(keyresultchilddata[0]);
  //           let parentindex = filterResponse.risk_sub_domain.findIndex((i: any) => i.risk_sub_domain_uuid == subchildata.risk_sub_domain_uuid);
  //           filterResponse.risk_sub_domain[parentindex].risk = subchild;

  //         }
  //       }
  //     })
  //   }
  //   this.allRisks=[];
  //   this.sortRecordsList(this.masterRiskLogs, 'prefix');
  //   for (let risk in this.masterRiskLogs) {
  //     this.masterRiskLogs[risk]['risk_sub_domain'] = this.sortRecordsList(this.masterRiskLogs[risk]['risk_sub_domain'], 'prefix')
  //     for (let risk_Sub_domain in this.masterRiskLogs[risk]['risk_sub_domain']) {
  //       this.masterRiskLogs[risk]['risk_sub_domain'][risk_Sub_domain]['risk'] = this.sortRecordsList(this.masterRiskLogs[risk]['risk_sub_domain'][risk_Sub_domain]['risk'], 'prefix')
  //       let arr = this.masterRiskLogs[risk]['risk_sub_domain'][risk_Sub_domain]['risk'];
  //       arr = arr.map((o:any)=>{
  //         let obj={...o};
  //         obj['hasError'] = !o?.status || !o?.risk_owner || !(o?.due_date);
  //         return obj;
  //       })
  //       this.allRisks = this.allRisks.concat(arr);
  //     }
  //   }
  //   this.setupRiskFormData();
  // }
  // setupRiskFormData(){
  //   console.log("Setup risk form called *******************************************************************************"+this.allRisks)
  //   console.log(this.allRisks)

  //   this.riskSetupFormArray = this.fb.group({
  //     riskSetupList:this.fb.array([])
  //   })
  //   this.allRisks.forEach((element:any) => {
  //     this.addRiskSetupData(this.riskSetupFormArray,element)
  //   });
  // }

  //   addRiskSetupData(riskSetupFormArray:any,element:any) {
  //    let arr =riskSetupFormArray.controls["riskSetupList"] as FormArray
  //    let owner ={email: element?.risk_owner?.email,first_name: element?.risk_owner?.first_name,
  //    full_name: element?.risk_owner?.full_name,
  //    id: element?.risk_owner?.id,
  //    last_name: element?.risk_owner?.last_name,
  //    username: element?.risk_owner?.username,
  //   mail:element?.risk_owner?.email}
  //     const riskSetupForm = this.fb.group({
  //       impact: [element?.risk_impact?.id, Validators.required],
  //       likelihood: [element?.risk_likelihood?.id, Validators.required],
  //       owner:[owner,Validators.required],
  //       duedate:[element?.due_date,Validators.required],
  //       description:[element?.impact_description,Validators.required],
  //       phase:[element?.risk_phase,Validators.required],
  //       response:[element?.risk_response,Validators.required],
  //       status:['NA',Validators.required],
  //       mitigation:[{ value: element?.action_plan&&element?.action_plan?.length>0?element?.action_plan[0]?.risk_mitigation?.action_tasks+'':'Mitigation Plan', disabled: true },Validators.required],
  //       transferTo:[element?.risk_transfer],
  //       mitigationList:this.fb.array([])
  //      });
  //      if(element?.action_plan&&element?.action_plan?.length>0){
  //       console.log(element?.action_plan)
  //       element?.action_plan.forEach((element:any) => {
  //         const mitigationData = this.fb.group({
  //           id: [element?.risk_mitigation?.id],
  //           mitigationtask: [{value:element?.risk_mitigation?.action_tasks, disabled: true }],
  //           duedate:[element?.planned_end_date],
  //           risk:[element?.risk_mitigation?.risk],
  //           isSelected:[element?.is_active]
  //           });
  //           (riskSetupForm.controls["mitigationList"] as FormArray).push(mitigationData);

  //          });
  //      }

  //     arr.push(riskSetupForm);
  //   }

  //    get getriskDetailFormArray(){
  //     return this.riskDetailFormArray.controls["riskDetailList"] as FormArray

  //    }
  //   addRiskDetailFormData(data:any,riskId:any){

  //     let arr =this.riskDetailFormArray.controls["riskDetailList"] as FormArray
  //     const riskDetailForm = this.fb.group({
  //       id: [data?.id, Validators.required],
  //       tasks: [data?.risk_mitigation?.action_tasks, Validators.required],
  //       planed_end_date: [data?.planned_end_date, Validators.required],
  //       planed_end_date_passed: [this.isDatePassed(data?.planned_end_date)],

  //       status:[data?.status,Validators.required],
  //       actual_end_date:[data?.actual_end_date,Validators.required],
  //       actual_end_date_passed:[this.isDatePassed(data?.actual_end_date)],

  //       remarks:[data?.remarks,Validators.required],
  //       project_risks:[data?.project_risks,Validators.required],
  //       risk_mitigation:[data?.risk_mitigation,Validators.required],
  //       riskId:[riskId,Validators.required]

  //     });

  //      arr.push(riskDetailForm)

  //   }

  //   isDatePassed(datestr:any){
  // try{
  //   return Date.parse(datestr)-Date.parse(new Date().toISOString())<0;
  // }catch(e){
  //   return false;
  // }
  //   }
  //   updateRiskDetails(){
  //     if(this.riskDetailFormArray.valid){
  //       let payload:any[]=[];
  //       let arr =this.riskDetailFormArray.controls["riskDetailList"] as FormArray
  //       let riskId:any;

  //    for (let index = 0; index < arr.controls.length; index++) {
  //     const element = arr.controls[index];
  //     let formatteEndDate=formatDate(element.get('actual_end_date')?.value, 'yyyy-MM-dd', 'en-US') || formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
  //     let formattePlannedDate=formatDate(element.get('planed_end_date')?.value, 'yyyy-MM-dd', 'en-US') || formatDate(new Date(), 'yyyy-MM-dd', 'en-US')

  //    let data =  {"id":element.get('id')?.value,"planned_end_date": formattePlannedDate, "status": element.get('status')?.value,
  //       "actual_end_date": formatteEndDate, "remarks": element.get('remarks')?.value,"project_risks": element.get('project_risks')?.value.id,"risk_mitigation": element.get('risk_mitigation')?.value?.id,"riskId":element.get('riskId')?.value
  //   };
  //    riskId = element.get('riskId')?.value;
  //        payload.push(data);

  //    }
  //    this.spinner.show();
  //    this.projectService.updateProjectRiskMitigations(riskId,payload).subscribe((getRiskLogs: any) => {
  //      this.spinner.hide();
  //      this.dialog.closeAll();
  //      console.log(this.risklogs)

  // },err=>{
  //      this.spinner.hide()
  //      })

  //    console.log(payload)

  //     }else{
  //       this.toastrService.error("Please fill all required feilds")
  //     }

  //   }

  //   createFormDataPayload(data?: any) {
  //     if(this.activeTab == this.staticText?.delivery.risk_log.setup){
  //       let formData: any[] = []
  //       if (data && data.length>0) {
  //         for (var formdata of data) {
  //           let obj={}
  //           if (formdata?.risk_uuid && formdata?.impact_description !== obj
  //             && formdata?.risk_owner !== obj && formdata?.risk_mitigation !== obj && formdata?.due_date !== obj && formdata?.status?.slug !== obj) {
  //             var selecterMeasurement: any = {
  //               uuid: formdata?.risk_uuid,
  //               // risk_owner: {
  //               //   first_name: formdata?.risk_owner?.first_name || '',
  //               //   last_name: formdata?.risk_owner?.last_name || '',
  //               //   mail: formdata?.risk_owner?.email || ''
  //               // },
  //               risk_owner: {
  //                 first_name: formdata?.risk_owner?.first_name || '',
  //                 last_name: formdata?.risk_owner?.last_name || '',
  //                 mail: formdata?.risk_owner?.email ||formdata?.risk_owner?.mail|| ''
  //               },
  //               // director: {
  //               //   first_name: formdata?.director?.first_name || '',
  //               //   last_name: formdata?.director?.last_name || '',
  //               //   mail: formdata?.director?.email || 'jeevithpriya.ravichandran@impigertech.com'
  //               // },
  //               due_date: formatDate(formdata?.due_date, 'yyyy-MM-dd', 'en-US') || formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
  //               status: formdata?.status?.slug || '',
  //               impact_description: formdata?.impact_description || '',
  //               risk_mitigation: formdata?.risk_mitigation || '',
  //               risk_impact: formdata?.risk_impact?.id || '',
  //               risk_likelihood:formdata?.risk_likelihood || '',
  //               risk_phase: formdata?.risk_phase || '',
  //             }
  //             console.log("selecterMeasurement", selecterMeasurement);

  //             formData.push(selecterMeasurement);
  //             this.lodashService.sortUniqueBy(formData, data.measurement_criteria_uuid);
  //             this.riskFormArray = this.riskFormArray.filter((o: any) => o.uuid != formdata?.risk_uuid)
  //           }
  //         }
  //       }
  //     }else{
  //       this.updateData = data;
  //       console.log(this.updateData);

  //     }
  //   }

  //   requestPayloadToApi(data?:any){
  //     this.spinner.show();
  //     this.projectService.saveRiskLog(data).subscribe((saveRiskLog: any) => {
  //       if (saveRiskLog.status == 201 || 200) {
  //         this.spinner.hide();
  //         this.toastrService.success('Risk Log Created SuccessFully');
  //         this.getMappedRisk();

  //       }
  //     }, err => {
  //       this.toastrService.error('Please fill valid data');
  //     })
  //     console.log("Request Payload:"+data);

  //   }

  //   saveRiskLog() {
  //     let valid =true;
  //     for(let i in  this.allRisks){
  //       let ele:any = this.allRisks[i];
  //       if( !ele?.risk_owner || !ele?.due_date|| isNaN(ele?.risk_likelihood)|| !ele?.risk_phase|| !ele?.risk_mitigation || !ele?.risk_impact|| !ele?.impact_description){
  //         this.toastrService.error('Please fill all the fields');
  //         valid=false;
  //         break;
  //       }else if( parseFloat(ele?.risk_likelihood)>1 || parseFloat(ele?.risk_likelihood)<0){
  //         this.toastrService.error('Please enter valid Risk likelyhood value');
  //         valid=false;
  //         break;
  //       }
  //     }
  //     if(valid){
  //       const payload: any = {
  //         project_id: parseInt(this.projectId),
  //         risks_data: []
  //       };

  //       (this.masterRiskLogs || []).forEach((riskDomain: any) => {
  //         const uuid1: any = {
  //           uuid: riskDomain.risk_domain_uuid,
  //           risk_subdomain: []
  //         };
  //         (riskDomain.risk_sub_domain || []).forEach((riskSubDomain: any) => {
  //           const uuid2: any = {
  //             uuid: riskSubDomain.risk_sub_domain_uuid,
  //             risk: []
  //           };
  //           (riskSubDomain.risk || []).forEach((risk: any) => {
  //             let foundItem = this.riskFormArray.find((o: any) => o?.uuid === risk?.risk_uuid)
  //             if (foundItem) {
  //               uuid2.risk.push(foundItem);
  //             } else {
  //               risk = this.allRisks.find((o:any)=>o?.risk_uuid === risk?.risk_uuid)
  //               const uuid3: any = {
  //                 uuid: risk?.risk_uuid,
  //                 risk_owner: {
  //                   first_name: risk?.risk_owner?.first_name || '',
  //                   last_name: risk?.risk_owner?.last_name || '',
  //                   mail:  risk?.risk_owner?.mail || risk?.risk_owner?.email||''
  //                 },
  //                 due_date: risk?.due_date ? formatDate(risk?.due_date, 'yyyy-MM-dd', 'en-US') || formatDate(new Date, 'yyyy-MM-dd', 'en-US') : formatDate(new Date, 'yyyy-MM-dd', 'en-US'),
  //                 status: risk?.status?.slug || 'project_risk_open',
  //                 risk_mitigation: risk?.risk_mitigation || '',
  //                 impact_description: risk?.impact_description || '',
  //                 risk_impact: risk?.risk_impact?.id || '',
  //                 risk_likelihood:risk?.risk_likelihood,
  //                 risk_phase: risk?.risk_phase || '',
  //               };
  //               uuid2.risk.push(uuid3);
  //             }
  //           });
  //           uuid1.risk_subdomain.push(uuid2);
  //         });
  //         payload.risks_data.push(uuid1);

  //       });
  //       payload['project'] = this.projectId;
  //       this.spinner.show();
  //       this.projectService.saveRiskLog(payload).subscribe((saveRiskLog: any) => {
  //         if (saveRiskLog.status == 201 || 200) {
  //           this.spinner.hide();
  //           this.toastrService.success('Risk Log Created SuccessFully');
  //           this.projectService.getprojectDataById(this.projectId).subscribe(((res)=>{
  //             this.patchRiskLog();
  //           }));
  //         }
  //       }, err => {
  //         this.toastrService.error('Please fill valid data');
  //       })
  //     }
  //   }
  //   updateRiskLog(risk:any){

  //     console.log(risk);
  //     if(!risk?.end_date ||!risk?.update_status?.slug || (risk?.update_status?.slug == 'project_risk_transfer' && (!risk?.new_owner?.mail && !risk?.new_owner?.email)) ||!risk?.remarks){
  //       this.toastrService.error('Please fill all the fields');
  //       return;
  //     }else{
  //       let payload:any ={
  //         "actual_close_date": risk?.end_date ? formatDate(risk?.end_date, 'yyyy-MM-dd', 'en-US'):risk?.end_date,
  //         "status": risk?.update_status?.slug,
  //         "remarks": risk?.remarks,
  //         "project_risks": risk?.id,
  //         "project":this.projectId,
  //         "owner":risk?.update_status?.slug == 'project_risk_transfer'?(risk?.new_owner?.mail || risk?.new_owner?.email) || '':''
  //       }
  //       this.spinner.show();
  //       this.projectService.updateRiskHistory(payload).subscribe((saveRiskLog: any) => {
  //         if (saveRiskLog.status == 201 || 200) {
  //           this.spinner.hide();
  //           this.toastrService.success('Risk details updated SuccessFully');
  //           this.updateData = {};
  //           this.projectService.getprojectDataById(this.projectId).subscribe(((res)=>{
  //             this.patchRiskLog();
  //           }));
  //         }
  //       })
  //     }
  //   }

  //   filterExistingRiskLogs(keyResultArray: any[], patchArray: any[]) {
  //     if (keyResultArray && patchArray) {
  //       patchArray?.forEach((patchGoalArray: any) => {
  //         patchGoalArray?.risk_sub_domain?.forEach((patchParentKeyResultArray: any) => {
  //           patchParentKeyResultArray?.risk?.forEach((patchChildKeyResultArray: any) => {
  //             let findGoal = keyResultArray.find((findGoalRes: any) => findGoalRes?.risk_domain_uuid == patchGoalArray?.risk_domain_uuid);
  //             let GoalIndex = keyResultArray.findIndex((categoryIndx: any) => categoryIndx?.risk_domain_uuid == patchGoalArray?.risk_domain_uuid);
  //             if (findGoal) {
  //               let parentKeyIndex = findGoal?.risk_sub_domain?.findIndex((subIndx: any) => subIndx?.risk_sub_domain_uuid == patchParentKeyResultArray?.risk_sub_domain_uuid);
  //               findGoal?.risk_sub_domain?.forEach((findSubCategory: any) => {
  //                 if (findSubCategory.risk_sub_domain_uuid == patchParentKeyResultArray?.risk_sub_domain_uuid) {
  //                   let childKeyResultIndex = findSubCategory?.risk?.findIndex((childKeyResultIndx: any) => childKeyResultIndx.risk_uuid == patchChildKeyResultArray.risk_uuid);
  //                   findSubCategory?.risk?.splice(childKeyResultIndex, 1);
  //                   if (findSubCategory?.risk?.length == 0 && findGoal?.risk_sub_domain?.length == 0) {
  //                     keyResultArray?.splice(GoalIndex, 1);
  //                   }
  //                   if (findSubCategory?.risk?.length == 0) {
  //                     findGoal?.risk_sub_domain.splice(parentKeyIndex, 1);
  //                     if (findGoal?.risk_sub_domain?.length == 0) {
  //                       keyResultArray?.splice(GoalIndex, 1);
  //                     }
  //                     let parentIndex = findGoal?.risk_sub_domain?.findIndex((subIndx: any) => subIndx?.risk_sub_domain_uuid == patchParentKeyResultArray?.risk_sub_domain_uuid);
  //                     if (parentIndex !== -1) {
  //                       findGoal?.risk_sub_domain.splice(parentKeyIndex, 1);
  //                     }
  //                   }
  //                 } else {
  //                   if (findSubCategory?.risk?.length == 0 && findGoal?.risk_sub_domain?.length == 0) {
  //                     keyResultArray?.splice(GoalIndex, 1);
  //                   }
  //                 }
  //               });
  //             }

  //           })
  //         })
  //       })
  //     }
  //     return keyResultArray;
  //   }
  // sortRecordsList(arr: any = [], key: any) {
  //   arr.sort((a: any, b: any) => {
  //     if (a[key] === b[key]) {
  //       return 0;
  //     } else if (a[key] === null) {
  //       return 1;
  //     } else if (b[key] === null) {
  //       return -1;
  //     } else if (a[key] < b[key]) {
  //       return -1;
  //     } else {
  //       return 1;
  //     }
  //   });
  //   return arr;
  // }
  // getHeatMap(){
  //   this.spinner.show();
  //   this.projectService.getProjectRiskHeatMap(this.projectId).subscribe((data:any)=>{
  //     this.spinner.hide()
  //   let originalResponse = data?.project_risks;
  //     originalResponse?.forEach((el:any) => {
  //         el.risk_domain.risk_sub_domain.forEach((elsub:any) => {
  //           if(!elsub.risk){
  //             elsub.risk=[];
  //           }
  //           else if(!elsub.risk[0]){
  //             elsub.risk=[elsub.risk];

  //           }
  //         });

  //     });
  //     originalResponse?.forEach((el:any) => {
  //       el.risk_domain.risk_sub_domain.forEach((elsub:any) => {
  //            if(elsub.risk&&elsub.risk.length>0){
  //           elsub.risk.forEach((elrisk:any) => {
  //             if(elrisk.action_plan&&elrisk.action_plan.length>0){
  //               elrisk.action_plan.forEach((element:any) => {
  //                 if(this.isDatePassed(element?.planned_end_date)){
  //                   elrisk.isPlannedDatePassed = true;
  //                 }
  //               });
  //             }
  //           });

  //         }

  //       });

  //   });
  //    this.riskHeatmapArr = originalResponse;

  //     },err=>this.spinner.hide())
  // }
  // changeTab(evt:any){
  //   this.activeTab = evt.tab?.textLabel;
  //   if(this.activeTab == this.staticText?.delivery.risk_log.risk_radar){
  //     this.getRiskRadarData();

  //   }

  //   if(this.activeTab == this.staticText?.delivery.risk_log.risk_heatmap){
  //     this.getHeatMap();

  //   }
  // }

  // setPieChart() {
  //   let call = this;
  //   let pieChartOptions1:any = {
  //     chart: {
  //         type: 'pie',
  //         plotShadow: false,
  //         height:300,
  //         options3d: {
  //             enabled: true,
  //             alpha: 45,
  //             beta: 0
  //         },

  //         renderTo: 'chart',
  //         margin: 0,
  //         width: 500,
  //         maxWidth:900
  //     },
  //     title: {
  //         text: undefined
  //     },
  //     xAxis: {
  //       minPadding: 0,
  //       maxPadding: 0
  //     },
  //     credits: {
  //         enabled: false
  //     },
  //     plotOptions: {
  //       pie: {
  //           allowPointSelect: true,
  //           cursor: 'pointer',
  //           depth: 35,
  //           dataLabels: {
  //               enabled: false,
  //               padding: 0,
  //               verticalAlign: 'middle'
  //           },
  //           showInLegend: true,
  //           size: 200,
  //           point: {
  //             events: {
  //               legendItemClick: function(e:any): any {
  //                 if (e.target.visible && call?.filteredRisks?.name == e.target?.name) {
  //                   call.filteredRisks = [];
  //                   call.showStatusRightPane = false;
  //                 }
  //               }
  //             }
  //           }
  //       }
  //     },
  //     series: [{
  //       name: 'Risks',
  //       colorByPoint: true,
  //       data: this.riskHeatMapData,
  //       groupPadding: 0,
  //       events: {
  //         click(e: any) {
  //           call.showRiskModal(e?.point)
  //         }
  //       },
  //   }],
  //     legend: {
  //         enabled: true,
  //         align: 'center',
  //         verticalAlign: 'bottom',
  //         layout: 'horizontal'
  //     }
  //   }
  //   console.log(this.riskHeatMapData);

  //   this.pieChart = new Chart(pieChartOptions1);
  //   //this.pieChart.addSeries(this.riskHeatMapData, true, true)
  //   // chartOptions1.series[0].levels[0].dataLabels.formatter = function () {
  //   //   return "<p class='pl-1'>" + this.point.name + "</p><p class='pl-1 point-values'><span>" + this.point.value + "</span> " + (this.point.value > 1 ? ' Risks' : ' Risk') + "</p>"
  //   // }
  // }
  // getRandomcolors() {
  //   const colors: any[] = ["#FFA800", "#1A85D6", "#07B797", "#738199", "#B58848", "#706FD3", "#F3819A", "#00F000", "#52E6CB", "#232459", "#EB55D7", "#738199", "#B58848", "#706FD3", "#F3821A", "#00F210", "#72E8CB", "#235359", "#EB32D7"];
  //   let colorIndex = Math.floor(Math.random() * colors.length);
  //   return colors[colorIndex];
  // }
  // showRiskModal(data:any){
  //   console.log(data);

  //   this.showStatusRightPane = true;
  //   this.filteredRisks = {risks:data?.risks,name:data.name};
  //  // this.dialog.open(this.updateRiskModal,{panelClass:"mw-650"});
  // }
  // showRiskDetails(addTracker:any,evt:Event,riskId:any,risk:any){
  //   if(!risk.action_plan||risk.action_plan.length==0){
  //     return
  //   }

  //   let arr:any[] =[]
  //   this.spinner.show();
  //   this.projectService.getProjectRiskMitigations(riskId).subscribe((getRiskLogs: any) => {
  //     this.spinner.hide();
  //     evt.stopPropagation();
  //     this.getriskDetailFormArray.clear();
  //     if(getRiskLogs&&getRiskLogs.length>0){
  //       arr = getRiskLogs.filter((data:any)=>{
  //            return data?.is_active;
  //       });
  //       arr.forEach(element => {
  //         this.addRiskDetailFormData(element,riskId)

  //       });
  //       this.dialog.open(addTracker, {
  //         disableClose: true,
  //         maxWidth:"59vw",
  //         maxHeight:"70vh"
  //       });
  //     }else{
  //       this.toastrService.error("This risk has no mitigations")
  //     }

  //   },err=>{
  //     this.spinner.hide()
  //    evt.stopPropagation();
  //    this.getriskDetailFormArray.clear();
  //    this.toastrService.error("There was an error while fetching risk mitigations.")

  //   })

  // }
}

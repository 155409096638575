<div class="modal-dialog m-0" style="max-width: 100%">
  <div class="modal-content" style="border: none">
    <app-modal-header-common
      [title]="mode == 'add' ? 'Add Open Req' : 'Edit Open Req'"
    >
    </app-modal-header-common>
    <div
      class="modal-body p-0 p-3 incident-tracker"
      style="overflow-y: auto; max-height: 50vh"
    >
      <form [formGroup]="openReqForm">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Job ID</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="job_id"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Job Title</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="job_title"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Job Description</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="job_description"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Job Location</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="job_location"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="pickerStartDate"
                readonly
                fullWidth
                status="basic"
                (click)="pickerStartDate.open()"
                formControlName="start_date"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerStartDate">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerStartDate> </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>End Date</mat-label>
              <input
                matInput
                [matDatepicker]="pickerEndDate"
                readonly
                fullWidth
                status="basic"
                (click)="pickerEndDate.open()"
                formControlName="end_date"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerEndDate">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerEndDate> </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Number of Needed</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="number_of_needed"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Reporting Manager</mat-label>
              <mat-select formControlName="reporting_manager">
                <mat-option *ngFor="let option of userList" [value]="option.id"
                  >{{ option.first_name }} {{ option.last_name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let option of statusOptions"
                  [value]="option.id"
                  >{{ option.display_name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6" *ngIf="statusOptionMap[openReqForm['controls']['status']?.value] == 'Fulfilled'">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Fulfilled By</mat-label>
              <mat-select formControlName="fulfilled_by">
                <mat-option
                  *ngFor="let option of vendorList"
                  [value]="option.id"
                  >{{ option.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="d-flex justify-content-between">
          <button
            class="mr-2 ml-2"
            mat-flat-button
            color="primary"
            *ngIf="mode == 'edit'"
            (click)="editOpenReq()"
          >
            Update
          </button>
          <button
            class="mr-2 ml-2"
            mat-flat-button
            color="primary"
            *ngIf="mode == 'add'"
            (click)="addOpenReq()"
          >
            Add
          </button>
          <button (click)="closeDialog()" mat-flat-button color="secondary">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { WorkForceService } from 'src/app/shared/services/work-force.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'src/app/shared/services/message.service';
import { EditOpenReqComponent } from './edit-open-req/edit-open-req.component';
import { PeopleService } from 'src/app/shared/services/people.service';

@Component({
  selector: 'app-open-requirment',
  templateUrl: './open-requirment.component.html',
  styleUrls: ['./open-requirment.component.scss'],
  encapsulation: ViewEncapsulation.Emulated


})
export class ProjectOpenRequirmentComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar.open_requirements;
  projectId: any
  endVal: number = 20;
  startVal: number = 0;
  CurrentPage: any = 0;
  EndValue = 20;
  startValue: any = 0;
  CurrentPageOther: any = 0;
  EndValueOther = 20;
  startValueOther: any = 0;
  activeTab = 0;
  applicationMasterList: any[] = [];
  applicationMasterListAll: any[] = [];
  applicationMasterListOther: any[] = [];
  applicationMasterListAllOther: any[] = [];
  applicationMasterRecordCount: any;
  applicationMasterRecordCountOther: any;

  sortRecords: any = 20;
  sortRecordsOther: any = 20;

  selctedLetterIndex: number | any = -1;
  selectedRecords: any[] = [];

  itemsPerPage: any;
  user: any;
  //projectID: any;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private workForceService: WorkForceService,
    private peopleService: PeopleService,
    public fb: FormBuilder,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.messageService.setGnews('Project - Open Staffing Reqs')
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    // this.SortData();
    this.getprojectId();
  }
  getApplicationMAster() {
    this.spinner.show();
    this.workForceService.getOpenRequirmentMasterData(this.projectId, this.startValue, this.EndValue).subscribe((res: any) => {
      this.applicationMasterList = res.records;
      this.applicationMasterRecordCount = res.count;
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
    })
  }

  getProjectApplicationMAster(isFromSearch?: any) {
    this.spinner.show();
    this.workForceService.getProjectOpenRequirmentMasterData(this.projectId, this.startValueOther, this.EndValueOther).subscribe((res: any) => {
      this.applicationMasterListOther = res.records;
      this.applicationMasterRecordCountOther = res.count;
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
    })
  }
  //   getProjectApplicationCount(){
  //     this.workForceService.getProjectOpenRequirmentMasterCount(this.projectId).subscribe((workForceTableList: any) => { 
  //       this.applicationMasterRecordCountOther = workForceTableList;
  //     })
  //   }
  //  getCount(){
  //     this.workForceService.getOpenRequirmentMasterCount(this.projectId).subscribe((workForceTableList: any) => { 
  //       this.applicationMasterRecordCount = workForceTableList;
  //     })
  //   }

  changeTab(event: any) {
    this.activeTab = event?.index;
    this.CurrentPage = 0;
    this.EndValue = 20;
    this.startValue = 0;

    this.CurrentPageOther = 0;
    this.EndValueOther = 20;
    this.startValueOther = 0;
    if (this.activeTab == 1) {
      if (this.applicationMasterList?.length == 0) {
        this.getApplicationMAster();
      }
      // this.getCount();
    }
    if (this.activeTab == 0) {
      if (this.applicationMasterListOther?.length == 0) {
        this.getProjectApplicationMAster();
      }
      // this.getProjectApplicationCount();
    }
  }

  getprojectId() {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectId = atob(params.id);
      this.getApplicationMAster();
      this.getProjectApplicationMAster();
    });
  }


  onChekedChange(event: any, tableRecords: any) {
    if (event.target.checked) {
      this.selectedRecords.push(tableRecords?.id);
    }
    else {
      const index = this.selectedRecords.indexOf(tableRecords?.id, 0);
      if (index > -1) {
        this.selectedRecords.splice(index, 1);
      }
    }
  }

  mapToProject() {
    let payload = { "openrequirements": this.selectedRecords }
    this.workForceService.updateProjectOpenRequirmentMaster(this.projectId, payload).subscribe((workForceTableList: any) => {
      this.toastr.success("Mapped Successfully.")
    }, (error: any) => {
      this.spinner.hide();
    })
  }

  getRecords(isFromProjectApplicaton?: boolean) {
    if (this.CurrentPage > 0) {
      this.startValue = (parseInt(this.CurrentPage) - 1) * (parseInt(this.sortRecords));
    } else {
      this.startValue = 0
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    this.getApplicationMAster();
  }

  getRecordsOtherTab() {
    if (this.CurrentPageOther > 0) {
      this.startValueOther = (parseInt(this.CurrentPageOther) - 1) * (parseInt(this.sortRecordsOther));
    } else {
      this.startValueOther = 0
    }
    this.EndValueOther = parseInt(this.startValueOther) + parseInt(this.sortRecordsOther);
    this.getProjectApplicationMAster();
  }

  isChecked(tableRecords?: any) {
    const index = this.selectedRecords.indexOf(tableRecords?.id, 0);
    if (index > -1) {
      return true
    } else {
      return false;
    }
  }

  addOpenReqRecord() {
    const dr = this.dialog.open(EditOpenReqComponent, {
      width: '500px',
      data: { projectID: this.projectId, mode: "add" }
    });
    dr.afterClosed().subscribe((res) => {
      console.log(res);
      this.getRecordsOtherTab();
    });
  }

  editOpenReqRecord(openReqRecord: any) {
    const dr = this.dialog.open(EditOpenReqComponent, {
      width: '500px',
      data: { projectID: this.projectId, mode: "edit", openReqRecord: openReqRecord }
    });
    dr.afterClosed().subscribe((res) => {
      console.log(res);
      this.getRecordsOtherTab();
    });
  }

  deleteOpenReqRecord(openReqRecord: any) {
    this.peopleService.deleteOpenReq(openReqRecord.id).subscribe((res: any) => {
      this.toastr.success('Open Req deleted successfully');
      this.getRecordsOtherTab();
    });
  }
}

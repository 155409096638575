import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinancialService } from '../../../../../shared/services/financial.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-f-edit-spend',
  templateUrl: './edit-spend.component.html',
  styleUrls: ['./edit-spend.component.scss']
})
export class EditSpendComponent implements OnInit {
  mode = 'add';
  projectID: any;
  spendForm: FormGroup;
  costType: any = [];
  costHead: any = [];
  spendType: any = [];
  spendTypeMap: any = [];
  budgets: any = [];
  spendRecord: any;
  projectDetails: any;
  ouGdp: any = [];


  constructor(
    private formBuilder: FormBuilder,
    private financialService: FinancialService,
    public dialogRef: MatDialogRef<EditSpendComponent>,
    public toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mode = data.mode;
    this.projectID = data.projectID;
    this.spendRecord = data.budgetRecord;
  }

  ngOnInit(): void {
    this.spendForm = this.formBuilder.group({
      budget: [this.spendRecord?.budget?.id || '', Validators.required],
      payee_name: [this.spendRecord?.payee_name || '', Validators.required],
      invoice_ref: [this.spendRecord?.invoice_number || '', Validators.required],
      billing_date: [this.spendRecord?.billing_date || '', Validators.required],
      payment_date: [this.spendRecord?.payment_date || '', Validators.required],
      payment_mode: [this.spendRecord?.payment_mode || '', Validators.required],
      cost_type: [this.spendRecord?.cost_type || '', Validators.required],
      cost_head: [this.spendRecord?.cost_head || '', Validators.required],
      ou_gdp: [this.spendRecord?.ou_gdp.id || ""],
      type: [{ value: this.spendRecord?.type.id || '' }, Validators.required],
      spend: [this.spendRecord?.spend || ''],
      type_description: [{ value: this.spendRecord?.type_description || '', disabled: true }],
    });
    this.spendForm.get('type')?.valueChanges.subscribe(value => {
      this.checkTypeOther();
    });
    let projectDetails = localStorage.getItem("selectedProject");
    this.projectDetails = JSON.parse(projectDetails || '{}');
    this.getMetadata();
  }

  checkTypeOther() {
    if (this.spendTypeMap[this.spendForm['controls']['type']?.value] !== 'Other') {
      this.spendForm.get('type_description')?.disable();
    } else {
      this.spendForm.get('type_description')?.enable();
    }
  }

  getMetadata() {
    this.financialService.getCostPoolTaxanomy().subscribe((res: any) => {
      this.costHead = res.cost_head;
      this.costType = res.cost_type;
    });
    this.financialService.getSpendTypes().subscribe((res: any) => {
      this.spendType = res;
      this.spendType.reduce((acc: any, curr: any) => {
        this.spendTypeMap[curr.id.toString()] = curr.name;
        return acc;
      });
    });
    this.financialService.getOuGdpList().subscribe((res: any) => {
      this.ouGdp = res;
    });
    this.financialService.getFinanceBudget(this.projectID).subscribe((res: any) => {
      this.budgets = res;
    });
  }

  createAddEditPayload() {
    return {
      budget_id: this.spendForm.value.budget,
      billing_date: new Date(this.spendForm.value.billing_date).toISOString().split('T')[0],
      payment_date: new Date(this.spendForm.value.payment_date).toISOString().split('T')[0],
      ppm_id: this.projectDetails.ppm_id,
      cost_head: this.spendForm.value.cost_head,
      cost_type: this.spendForm.value.cost_type,
      type_id: this.spendForm.value.type,
      spend: this.spendForm.value.spend,
      payee_name: this.spendForm.value.payee_name,
      ou_gdp_id: this.spendForm.value.ou_gdp,
      payment_mode: this.spendForm.value.payment_mode,
      invoice_number: this.spendForm.value.invoice_ref,
      type_description: this.spendForm.value.type_description,
      project_id: this.projectID
    }
  }

  addSpend() {
    if (this.spendForm.valid) {
      // let data = this.budgetForm.value;
      let data = this.createAddEditPayload()
      this.financialService.addFinanceSpend(data).subscribe((res: any) => {
        this.toastService.success('Budget added successfully');
        this.dialogRef.close("success");
      });
    } else {
      console.log(this.spendForm)
      this.toastService.error('Please fill all the required fields');
    }
  }

  editSpend() {
    if (this.spendForm.valid) {
      let data = this.createAddEditPayload()
      this.financialService.editFinanceSpend(this.spendRecord.id, data).subscribe((res: any) => {
        this.toastService.success('Budget edited successfully');
        this.dialogRef.close();
      });
    } else {
      console.log(this.spendForm)
      this.toastService.error('Please fill all the required fields');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

<div class="row field-adjust" id="custom-height">
  <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="row w-100 m-0">
  <div class="col-md-auto p-0 px-0" style="width: 100%">
    <div class="p-0">
      <div class="row p-0 m-0">
        <div class="p-0">
          <div class="">
            <mat-tab-group
              mat-align-tabs="start"
              class="head"
              (selectedTabChange)="changeTab($event)"
            >
              <mat-tab
                label="{{
                  staticText?.delivery.open_requirment.mapped_open_requirment
                }} ({{ applicationMasterRecordCountOther }})"
              >
                <div *ngIf="activeTab == 0">
                  <div class="open-req-toolbar">
                    <button
                      mat-button
                      class="bg-primary text-white"
                      mat-button
                      mat-flat-button
                      (click)="addOpenReqRecord()"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="material-icons-filled "
                        class="cursor-pointer fs-5"
                        >add
                      </mat-icon>
                      New Requirement
                    </button>
                  </div>
                  <div class="custom-table fixed-table-header">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            *ngFor="
                              let header of staticText?.delivery.open_requirment
                                .table_header_mapped_open_requirment
                            "
                          >
                            <span class="d-flex justify-content-center">{{
                              header
                            }}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let tableRecords of applicationMasterListOther;
                            index as tableIndex
                          "
                        >
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.job_id || "-" }}
                            </span>
                          </td>
                          <td
                            *ngxPermissionsOnly="[
                              'delivery.change_workforcedashboard'
                            ]"
                          >
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.job_title || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.job_description || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.job_location || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.start_date || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.end_date || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.number_of_needed || "-" }}
                            </span>
                          </td>
                          <td>
                            <span
                              class="d-flex justify-content-center text-center fs-13"
                              *ngIf="tableRecords?.reporting_manager"
                            >
                              {{ tableRecords?.reporting_manager?.first_name }}
                              {{ tableRecords?.reporting_manager?.last_name }}
                            </span>
                            <span
                              class="d-flex justify-content-center text-center fs-13"
                              *ngIf="!tableRecords?.reporting_manager"
                            >
                              -
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.status?.name || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.fulfilled_by?.name || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{
                                tableRecords?.added_from_project_central
                                  ? "Project Central"
                                  : "Mapped From Master" || "-"
                              }}
                            </span>
                          </td>
                          <td class="align-content-center">
                            <span class="d-flex justify-content-center fs-13">
                              <div
                                class="d-flex justify-content-around align-items-center"
                              >
                                <img
                                  class="pointer mr-1"
                                  *ngIf="
                                    tableRecords.added_from_project_central
                                  "
                                  src="./assets/images/pages/workforce/edit.svg"
                                  alt="edit"
                                  (click)="editOpenReqRecord(tableRecords)"
                                />
                                <img
                                  class="pointer"
                                  *ngIf="
                                    tableRecords.added_from_project_central
                                  "
                                  src="./assets/images/comment-delete.svg"
                                  alt="delete"
                                  (click)="deleteOpenReqRecord(tableRecords)"
                                />
                              </div>
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="!applicationMasterListOther?.length">
                          <td colspan="5" class="noRecords mt-2 pt-4">
                            <span class="mt-4">
                              Please map open staffing reqs for this project
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between"
                    *ngIf="applicationMasterRecordCountOther > 0"
                  >
                    <div class="p-1">
                      <div
                        class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
                      >
                        <span class="p-1">Displaying</span>
                        <select
                          class="form-select form-select-sm table-sort-filter"
                          aria-label="Default select example"
                          [(ngModel)]="sortRecordsOther"
                          (ngModelChange)="getRecordsOtherTab(true)"
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                        </select>
                        <span class="p-1">{{
                          applicationMasterRecordCountOther
                        }}</span>
                        <span class="p-1">records</span>
                      </div>
                    </div>
                    <div class="p-1">
                      <ngb-pagination
                        [pageSize]="sortRecords"
                        [collectionSize]="applicationMasterRecordCountOther"
                        [maxSize]="2"
                        [rotate]="true"
                        [(page)]="CurrentPageOther"
                        [boundaryLinks]="true"
                        (pageChange)="getRecordsOtherTab(true)"
                      >
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
                <!--Workforce ends-->
              </mat-tab>
              <mat-tab
                label="{{
                  staticText?.delivery.open_requirment.open_requirment
                }} ({{ applicationMasterRecordCount }})"
              >
                <div *ngIf="activeTab == 1">
                  <div class="custom-table fixed-table-header">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            *ngFor="
                              let header of staticText?.delivery.open_requirment
                                .table_header_open_requirment
                            "
                            scope="col"
                          >
                            <span class="d-flex justify-content-center">{{
                              header
                            }}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        class="cursor-pointer"
                        *ngIf="applicationMasterList?.length"
                      >
                        <tr
                          *ngFor="
                            let tableRecords of applicationMasterList;
                            index as tableIndex
                          "
                        >
                          <td scope="row">
                            <span class="d-flex justify-content-center fs-13">
                              {{ tableRecords?.job_id || "-" }}
                            </span>
                          </td>
                          <td
                            *ngxPermissionsOnly="[
                              'delivery.change_workforcedashboard'
                            ]"
                          >
                            <span class="d-flex justify-content-center text-center fs-13">
                              {{ tableRecords?.job_title || "-" }}
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-center fs-13">
                              {{ tableRecords?.number_of_needed || "-" }}
                            </span>
                          </td>

                          <td>
                            <span class="d-flex justify-content-center fs-13">
                              {{ tableRecords?.created_date || "-" }}
                            </span>
                          </td>
                          <td>
                            <div
                              class="d-flex justify-content-around align-items-center"
                            >
                              <!-- <img src="./assets/images/pages/workforce/edit.svg" alt="edit"
                                                                *ngxPermissionsOnly="['delivery.add_workforcedashboard']"
                                                                (click)="editWorkForceById(tableRecords?.id)"> -->
                              <input
                                type="checkbox"
                                class="mx-2"
                                [checked]="isChecked(tableRecords)"
                                [disabled]="user?.is_vendor"
                                (change)="onChekedChange($event, tableRecords)"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tr *ngIf="!applicationMasterList?.length">
                        <td colspan="5" class="noRecords mt-2 pt-4">
                          <span class="mt-4">{{
                            staticText.common.no_records_found
                          }}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between"
                    *ngIf="applicationMasterRecordCount > 0"
                  >
                    <div class="p-1">
                      <div
                        class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
                      >
                        <span class="p-1">Displaying</span>
                        <select
                          class="form-select form-select-sm table-sort-filter"
                          aria-label="Default select example"
                          [(ngModel)]="sortRecords"
                          (ngModelChange)="getRecords()"
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                        </select>
                        <span class="p-1">{{
                          applicationMasterRecordCount
                        }}</span>
                        <span class="p-1">records</span>
                      </div>
                    </div>
                    <div class="p-1">
                      <ngb-pagination
                        [pageSize]="sortRecords"
                        [collectionSize]="applicationMasterRecordCount"
                        [maxSize]="2"
                        [rotate]="true"
                        [(page)]="CurrentPage"
                        [boundaryLinks]="true"
                        (pageChange)="getRecords()"
                      >
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
                <!--Workforce ends-->
              </mat-tab>
              <mat-tab
                label="Insights"
              >
              <app-power-bi-module page="project_central_insights"></app-power-bi-module>
              </mat-tab>
            </mat-tab-group>
            <div
              class="modal-footer"
              *ngIf="activeTab == 1 && !user?.is_vendor"
            >
              <button
                (click)="mapToProject()"
                mat-flat-button
                mat-button
                class="bg-primary text-white text-capitalize float-right ml-2 cursor-pointer"
                [disabled]="selectedRecords.length == 0"
              >
                {{ staticText.buttons.save_btn }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex">
  <div class="img-container w-100">
    <img class="img-align" src="./assets/images/bg3.png" />
    <div
      class="centered fw-bold"
      matTooltip="{{ projectdetail?.project_name }}"
      matTooltipClass="example-tooltip"
    >
      {{ projectdetail?.project_name }}
    </div>
  </div>
</div>

<div class="mat-left-content">
  <p class="d-flex justify-content-center fw-bold mt-3">
    {{ projectdetail?.status?.label }}
  </p>
  <hr />
  <div id="setBox">
    <mat-stepper
      class="mat-nav-list"
      orientation="vertical"
      #stepper
      (selectionChange)="setStepperData(null)"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>

      <mat-step label="view">
        <ng-template matStepLabel>{{
          staticText?.delivery.left_bar.project_central
        }}</ng-template>
        <ng-template matStepContent>
          <ul class="nav-list nodots">
            <li
              class="nav-list-columns"
              routerLinkActive="active"
              routerLink="overview"
              (click)="setStepperData('overview')"
              *ngxPermissionsOnly="['delivery.view_projectmapping']"
            >
              {{ staticText?.delivery.left_bar.overview }}
            </li>
            <li
              class="nav-list-columns"
              routerLinkActive="active"
              routerLink="okrlog"
              (click)="setStepperData('okrlog')"
              *ngxPermissionsOnly="['delivery.view_projectkeyresults']"
            >
              {{ staticText?.delivery.left_bar.okr }}
            </li>
            <li>
              <b>Project Charter</b>
              <ul class="nodots">
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="scope"
                  (click)="setStepperData('scope')"
                  *ngxPermissionsOnly="['delivery.view_workforcedashboard']"
                >
                  {{ staticText?.delivery.left_bar.scope }}
                </li>
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="application-master"
                  (click)="setStepperData('application-master')"
                  *ngxPermissionsOnly="['delivery.view_workforcedashboard']"
                >
                  {{ staticText?.delivery.left_bar.applications }}
                </li>
              </ul>
            </li>
            <li>
              <b>Project Performance</b>
              <ul class="nodots">
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="scorecard"
                  (click)="setStepperData('scorecard')"
                  *ngxPermissionsOnly="['delivery.view_projectmetric']"
                >
                  <!-- {{staticText?.delivery.left_bar.score_setup}}</li> -->
                  Performance Dashboard
                </li>
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="terms"
                  (click)="setStepperData('terms')"
                >
                  {{ staticText?.delivery.left_bar.terms }}
                </li>
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="release"
                  (click)="setStepperData('release')"
                  *ngxPermissionsOnly="['delivery.view_projectrelease']"
                >
                  {{ staticText?.delivery.left_bar.release_tracker }}
                </li>
              </ul>
            </li>
            <li>
              <b>People</b>
              <ul class="nodots">
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="resource"
                  (click)="setStepperData('resource')"
                  *ngxPermissionsOnly="['delivery.view_workforcedashboard']"
                >
                  {{ staticText?.delivery.left_bar.workforce }}
                </li>
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="open-requirment"
                  (click)="setStepperData('open-requirment')"
                  *ngxPermissionsOnly="['delivery.view_workforcedashboard']"
                >
                  {{ staticText?.delivery.left_bar.open_requirements }}
                </li>
                <li
                  class="nav-list-columns"
                  routerLinkActive="active"
                  routerLink="training"
                  (click)="setStepperData('training')"
                  *ngxPermissionsOnly="['delivery.view_projectrisks']"
                >
                  {{ staticText?.delivery.left_bar.training }}
                </li>
              </ul>
            </li>
            <li
              class="nav-list-columns"
              routerLinkActive="active"
              routerLink="financials"
              (click)="setStepperData('financials')"
              *ngxPermissionsOnly="['delivery.view_projectmetric']"
            >
              {{staticText?.delivery.left_bar.financials}}
            </li>

            <!-- <li class="nav-list-columns" routerLinkActive="active" routerLink="change-request"
                            (click)="setStepperData('change-request')"
                            *ngxPermissionsOnly="['delivery.view_projectchangerequest']">
                            {{staticText?.delivery.left_bar.change_request}}
                        </li> -->

            <li
              class="nav-list-columns"
              routerLinkActive="active"
              routerLink="risklog"
              (click)="setStepperData('risklog')"
              *ngxPermissionsOnly="['delivery.view_projectrisks']"
            >
              {{ staticText?.delivery.left_bar.risk }}
            </li>
            <li
              class="nav-list-columns"
              routerLinkActive="active"
              routerLink="assessment"
              (click)="setStepperData('assessment')"
              *ngxPermissionsOnly="['delivery.view_projectrisks']"
            >
              {{ staticText?.delivery.left_bar.assessment_audit }}
            </li>

            <!--/toe/{{opportunityId}}/add-terms-->
            <!-- <li class="nav-list-columns" routerLinkActive="active" routerLink="toe" (click)="setStepperData('toe')" *ngxPermissionsOnly="['delivery.view_projectrisks']">
                            {{staticText?.delivery.left_bar.toe}}
                        </li> -->

            <!-- <li class="nav-list-columns" routerLinkActive="active" routerLink="credentials"
                            (click)="setStepperData('credentials')"
                            *ngxPermissionsOnly="['delivery.view_datacredentials']">
                            {{staticText?.delivery.data_credentials.title}}
                        </li> -->
          </ul>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>

  <!-- <div class="mt-4">
        <mat-card class="mat-size pt-2 cursor-pointer" *ngIf="showIdea" (click)="tips = true; showIdea = false">
            <img style="height: 24px; position: relative;
            width: 51px; right: 13px; bottom: -2px;" src="./assets/images/lightbulb 1.svg">
        </mat-card>
    </div>
    <div *ngIf="tips" style="width: 100%;">
        <app-tips type="Tips" tipType="setupTip" (closetips)="tips = false; showIdea = true">
        </app-tips>
    </div> -->
</div>

import { Component, OnInit, Input } from "@angular/core";
import { ProjectService } from "src/app/shared/services/project.service";
import { ProgramService } from "src/app/shared/services/program.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { ViewEncapsulation } from "@angular/core";
import { Observable } from "rxjs";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { DateAdapter } from "@angular/material/core";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { forEach } from "lodash";
import { MessageService } from "src/app/shared/services/message.service";
import Highcharts from "highcharts";
import { FinancialService } from "src/app/shared/services/financial.service";
import moment from "moment";
import { PulsesDialogComponent } from "../pulses-dialog/pulses-dialog.component";
import { CreatePulseDialogComponent } from "../../kepler/hubble/create-pulse-dialog/create-pulse-dialog.component";
@Component({
  selector: "app-deliveryoverview",
  templateUrl: "./deliveryoverview.component.html",
  styleUrls: ["./deliveryoverview.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [DatePipe],
})
export class DeliveryoverviewComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptionsPeople: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    // legend: {
    //   align: "right",
    //   verticalAlign: "top",
    //   layout: "vertical",
    //   x: 0,
    //   y: 50,
    // },
    plotOptions: {
      pie: {
        dataLabels: {
          distance: 5,
          enabled: true,
        },
        showInLegend: false,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "pie",
        data: [
          { name: "Goal1", y: 75 },
          { name: "Goal2", y: 40 },
          { name: "Goal3", y: 10 },
          { name: "Goal4", y: 90 },
        ],
      },
    ],
  };
  projectDetailForm: FormGroup | any;
  staticText: any = (textConfiguration as any).default;
  projectId: any;
  isEditMode: boolean = false;
  projectdetail: any;
  opportunity_data: any;
  ownerdetails: any;
  details: any;
  opportunityFilteredOptions: any;
  vendorFilteredOptions: any;
  projectForm: FormGroup | any;
  selectedOpportunity: any = "";
  statusList: any = [];
  files: any = [];
  existingFileList = [];
  //accordionItems = ['People Insights','Change Requests','Subscribed OKRs','Risk Radar','Applications selected'];
  accordionItems = ["Subscribed OKRs", "Risk Radar", "Applications selected"];
  form!: FormGroup;
  programDetail: any;
  editEnabled = false;
  breadcrumb: any;
  scopeBrief: any = "This is description";
  ownerList: any;
  executionList: any;
  allSOWCOntractList: any;
  projectContractsList: any;
  ppm_id: any;
  user: any;
  okrData: any = [];
  riskData: any = {};
  deliveryData: any;
  projectDetail: any;
  peopleData: any;
  releaseMap = {
    releases_due_15_to_30_days: "15 to 30 days",
    releases_due_30_to_45_days: "30 to 45 days",
    releases_due_45_to_60_days: "40 to 60 days",
    releases_due_next_15_days: "Next 15 days",
    releases_due_today_to_30_days: "30 days",
  };
  pulsesList: any;
  positivePulses: any = 0;
  negativePulses: any = 0;
  projectPulseInfo: any;

  /* @Input() selected_id: any; */

  constructor(
    private financialService: FinancialService,
    private projectservice: ProjectService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private messageService: MessageService,
    private program: ProgramService,
    private fb: FormBuilder,
    private breadCrumService: BreadcrumbService,
    private date: DatePipe,
    private toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB");
  }

  ngOnInit(): void {
    this.messageService.setGnews("Project Central - Overview");
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    localStorage.removeItem("rightbar");
    this.createForm();

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    });

    this.programDetail = JSON.parse(localStorage.getItem("program") || "{}");
    this.getprojectId();
    this.initialisePulseInfo();
    this.getAllSOWCOntractList();
    this.getPulse();
  }

  initialisePulseInfo() {
    this.projectPulseInfo = {
      portfolio: {
        id: this.programDetail.portfolio_id,
        name: this.programDetail.portfolio_name,
      },
      sub_portfolio: {
        id: this.programDetail.subportfolio_id,
        name: this.programDetail.subportfolio_name,
      },
      program: {
        id: this.programDetail.program_id,
        name: this.programDetail.program_name,
      },
      project: {
        id: this.projectDetail.id,
        project_name: this.projectDetail.project_name,
      },
    };
  }

  getPulseCounts() {
    this.positivePulses = 0;
    this.negativePulses = 0;
    this.pulsesList.forEach((pulse: any) => {
      if (pulse.is_positive) {
        this.positivePulses++;
      } else {
        this.negativePulses++;
      }
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  getPerformanceImpact(pulse: any) {
    let perfImpact = "";
    pulse.performance_impact.forEach((impact: any) => {
      perfImpact += impact.display_name + ", ";
    });
    perfImpact = perfImpact.trimEnd();
    if (perfImpact.endsWith(",")) {
      perfImpact = perfImpact.slice(0, -1);
    }
    return perfImpact;
  }

  getPulse() {
    this.financialService
      .getProjectPulse(this.projectId)
      .subscribe((res: any) => {
        this.pulsesList = res;
        this.pulsesList.forEach((pulse: any) => {
          pulse["color"] = this.getGMapRandomColor();
          pulse["impact"] = this.getPerformanceImpact(pulse);
        });
        this.getPulseCounts();
      });
  }

  createForm() {
    this.form = this.fb.group({ vendorcontracts: this.fb.array([]) });
    this.projectForm = this.fb.group({
      sowValue: ["", Validators.required],
      beginDate: ["", Validators.required],
      endDate: ["", Validators.required],
      selectedFile: ["", Validators.required],

      selectedStatus: [""],
    });
  }

  showPulses() {
    const dialog = this.dialog.open(PulsesDialogComponent, {
      width: "80vw",
      data: {
        pulsesList: this.pulsesList,
        projectId: this.projectId,
      },
    });
    dialog.afterClosed().subscribe(() => {
      this.getPulse();
    });
  }

  showCreatePulseDialog() {
    const dialog = this.dialog.open(CreatePulseDialogComponent, {
      width: "500px",
      data: {
        projectPulseInfo: this.projectPulseInfo,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.getPulse();
      }
    });
  }

  createProjectDetailForm() {
    this.projectDetailForm = this.fb.group({
      owner: [this.projectDetail?.owner?.id || "", Validators.required],
      sponsor: [this.projectDetail?.sponsor?.id || "", Validators.required],
      startDate: [this.projectDetail?.begin_date || "", Validators.required],
      endDate: [
        this.projectDetail?.expected_end_date || "",
        Validators.required,
      ],
      executionType: [
        this.projectDetail?.execution_type?.id || "",
        Validators.required,
      ],
    });
    this.getMetaData();
  }

  getMetaData() {
    this.financialService.getUserList().subscribe((res: any) => {
      this.ownerList = res;
    });

    this.financialService.getExecutionTypeList().subscribe((res: any) => {
      this.executionList = res;
    });
  }

  getprojectId() {
    this.spinner.show();
    this.activatedRoute.parent?.params.subscribe(
      (params) => {
        this.spinner.hide();
        this.projectId = atob(params.id);
        // console.log('getparmasid', this.projectId);
        this.getprojectdetails();
        // this.getSowContractFiles();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getprojectdetails(force = false) {
    this.spinner.show();
    this.projectservice
      .getprojectdetailsOverview(this.projectId, force)
      .subscribe(
        (response: any) => {
          let breadCrumbFirst: any = [this.breadcrumb[0]];
          let breadCrumbLast: any = [
            this.breadcrumb[this.breadcrumb.length - 1],
          ];
          let breadCrumb = [
            {
              label: this.programDetail.portfolio_name,
              url: "/delivery/projects",
              queryParams: { portfolio: this.programDetail.portfolio_id },
            },
            {
              label: this.programDetail.subportfolio_name,
              url: "/delivery/projects",
              queryParams: { subportfolio: this.programDetail.subportfolio_id },
            },
            {
              label: this.programDetail.program_name,
              url: "/delivery/projects",
              queryParams: { program: this.programDetail.program_id },
            },
          ];
          breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast);
          this.breadCrumService.setBreadcrumb(breadCrumbFirst);
          this.projectdetail = response;
          this.projectDetail = response;
          this.ppm_id = this.projectdetail.ppm_id;
          this.projectContractsList = this.projectdetail.projectcontracts;
          localStorage.setItem(
            "projectContract",
            JSON.stringify(this.projectContractsList)
          );

          // console.log(this.projectdetail.vendorcontracts)
          // this.patchFormArray(this.projectdetail?.vendorcontracts)
          this.okrData = this.getOkrCardData(response.okr_overview);
          this.riskData = this.getRiskCardData(response.risks_overview);
          this.deliveryData = this.getDeliveryCardData(
            response.delivery_overview
          );
          this.peopleData = this.getPeopleCardData(response.people_overview);
          this.getProjectStatus();
          this.createProjectDetailForm();
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          throw error;
        }
      );
  }

  getPeopleCardData(data: any) {
    const chartData = Object.keys(data.workforce_data).map((key) => ({
      name: key,
      y: data.workforce_data[key],
    }));
    const chartOptions: Highcharts.Options = {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          dataLabels: {
            distance: 5,
            enabled: true,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          type: "pie",
          data: chartData,
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return {
      chartData: chartOptions,
      attrition_rate: data.attrition_rate,
      open_requirements: data.open_requirements,
      aging_open_requirements: data.aging_open_requirements,
      employees_with_lwd_next_month: data.employees_with_lwd_next_month,
    };
  }

  getDeliveryCardData(data: any) {
    const upcomingReleasesArray = Object.entries(data.upcoming_releases);
    return {
      upcoming_releases: upcomingReleasesArray,
      recent_releases: data.recent_releases,
      completed_releases_within_due_date:
        data.completed_releases_within_due_date,
    };
  }

  getOkrCardData(data: any) {
    return data.map((okr: any) => {
      return {
        ...okr,
        goals: okr.goals.map((goal: any) => {
          const percentage =
            (goal.no_of_mos_within_target_value / goal.total_no_of_mos) * 100;
          return {
            ...goal,
            percentage: parseFloat(percentage.toFixed(2)),
          };
        }),
      };
    });
  }

  getRiskCardData(data: any) {
    const chartData = Object.keys(data.domain_risks).map((key) => ({
      name: key,
      y: data.domain_risks[key],
    }));

    const chartOptions: Highcharts.Options = {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          dataLabels: {
            distance: 5,
            enabled: true,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          type: "pie",
          data: chartData,
        },
      ],
      credits: {
        enabled: false,
      },
    };

    return {
      chartOptions: chartOptions,
      no_of_project_risks_with_mitigation:
        data.no_of_project_risks_with_mitigation,
    };
  }

  patchFormArray(vendorcontracts: any[]) {
    vendorcontracts?.forEach((element) => {
      this.patchValueForVendor(element);
    });
  }

  patchValueForVendor(element?: any): FormGroup {
    let vendorForm = this.fb.group({
      id: [element?.id, Validators.required],
      name: [
        {
          value: element?.vendor?.name ? element?.vendor?.name : "",
          disabled: true,
        },
        Validators.required,
      ],
      sowdate: [
        element?.sow_start_date ? element?.sow_start_date : "",
        Validators.required,
      ],
      tenure: [element?.tenure ? element?.tenure : "", Validators.required],
      value: [
        element?.sow_value ? element?.sow_value : "",
        Validators.required,
      ],
      fileName: [element?.document_name ? element?.document_name : ""],
      file: [""],
      edit: [false],
    });
    const vendors = this.form.get("vendorcontracts") as FormArray;
    vendors.push(vendorForm);
    return vendorForm;
  }

  //   saveProjectDetails(){
  //     // if(this.projectdetail?.status?.slug != this.projectForm.value.selectedStatus?.slug){
  //     //   this.updateProjectStatus();
  //     // }
  //     if(this.form.invalid)
  //       return;

  //   //    this.uploadFileToServer();

  //   let payload: any[] =[];
  //     this.spinner.show();
  //     const vendors = this.form.get('vendorcontracts') as FormArray

  //     vendors?.controls.forEach(element => {
  //       let data = {id:element?.get('id')?.value,
  //       tenure:element?.get('tenure')?.value,
  //       tenure_type:"m",
  //       sow_start_date:this.date.transform(element?.get('sowdate')?.value,'yyyy-MM-dd'),
  //       sow_value:element?.get('value')?.value
  //     }
  //     payload.push(data)

  // });

  //     this.projectservice.updateProjectVendorDetails(payload).subscribe((response: any) => {
  //       this.spinner.hide()
  //       if(response){
  //         this.toastr.success("Data saved successfully");
  //       }
  //     },err=>this.spinner.hide());
  //   }
  getOpportunities() {}
  getVendors() {
    this.program
      .getOpportunityFormData("opportunity_empanelled_vendors")
      .subscribe(
        (res: any) => {
          this.vendorFilteredOptions = res.records[0].field_values;
        },
        (error) => {
          throw error;
        }
      );
  }
  getProjectStatus() {
    this.spinner.show();
    this.projectservice.getProjectStatus(this.projectId).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.statusList = res;
        let found = this.statusList.find(
          (e: any) => e.slug == this.projectForm.value.selectedStatus?.slug
        );
        if (!found) {
          this.statusList.push(this.projectForm.value.selectedStatus);
        }
      },
      (error) => {
        throw error;
      }
    );
  }

  updateProjectStatus() {
    this.spinner.show();
    let payload = {
      project_id: this.projectId,
      status: this.projectForm.value.selectedStatus?.slug,
    };
    this.projectservice.updateProjectStatus(payload).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res) {
          this.toastr.success("Status updated successfully");
          this.projectservice.setStatusValue(
            this.projectForm.value.selectedStatus
          );
        }
        this.getProjectStatus();
      },
      (error) => {
        this.projectservice.setStatusValue(this.projectdetail.status);
        this.projectForm
          .get("selectedStatus")
          .setValue(this.projectdetail.status);
        this.getProjectStatus();
        throw error;
      }
    );
  }
  downloadFile(e: any) {
    var payload = {
      file_name: e,
    };
    this.spinner.show();
    this.projectservice.downloadFile(payload, this?.projectId).subscribe(
      (res: any) => {
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute("download", e);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastr.success("File downloaded successfully");
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }
  getShortName(fullName: any) {
    return fullName
      ?.split(" ")
      .slice(0, 2)
      .map((n: any) => n[0])
      .join("");
  }
  getProgramName(program: any[]) {
    let programName = "";
    program?.forEach((element) => {
      programName = programName.concat(element?.name + " \n");
    });
    return programName.substring(0, programName.length - 1);
  }
  getProgramManagerName(program: any[]) {
    let programName = "";
    program?.forEach((element) => {
      programName = programName.concat(element?.manager + ",");
    });
    return programName.substring(0, programName.length - 1);
  }
  onSelectFile(evt: any, index: any) {
    const vendors = this.form.get("vendorcontracts") as FormArray;
    vendors?.controls[index]
      ?.get("fileName")
      ?.setValue(evt?.target.files[0].name);
    vendors?.controls[index]?.get("file")?.setValue(evt?.target.files[0]);

    this.files[0] = evt?.target.files[0];
    this.projectForm.get("selectedFile").setValue(evt?.target.files[0].name);
    this.uploadFileToServer(vendors?.controls[index]?.get("id")?.value);
  }
  enableEdit(index: any) {
    const vendors = this.form.get("vendorcontracts") as FormArray;
    if (vendors?.controls[index]?.get("edit")?.value) {
      vendors?.controls[index]?.get("edit")?.setValue(false);
    } else {
      vendors?.controls[index]?.get("edit")?.setValue(true);
    }
  }
  get isEditEnabled() {
    let editenabled = false;
    const vendors = this.form.get("vendorcontracts") as FormArray;
    vendors.controls.forEach((element) => {
      if (element?.get("edit")?.value) {
        editenabled = true;
      }
    });
    return editenabled;
  }
  deleteFile(index: any, i: any) {
    const vendors = this.form.get("vendorcontracts") as FormArray;
    vendors?.controls[i]?.get("fileName")?.setValue("");
    vendors?.controls[i]?.get("file")?.setValue("");
  }
  deleteServerFile(file: any) {
    this.spinner.show();
    let payload = { file_name: file.file };
    this.projectservice
      .deleteSowContractFile(this.projectId, payload)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response) {
            this.toastr.success("Contract Deleted Susccesfully.");
            this.projectForm.get("selectedFile").setValue("");

            this.getprojectdetails();
            this.getSowContractFiles();
          }
        },
        (err) => this.spinner.hide()
      );
  }

  uploadFileToServer(id?: any) {
    if (this.files.length > 0) {
      this.files.forEach((element: any) => {
        this.spinner.show();
        let formData = new FormData();
        formData.append("file", element);
        formData.append("file_name", element.name);
        this.projectservice.uploadVendorContract(formData, id).subscribe(
          (response: any) => {
            this.spinner.hide();
            if (response) {
              this.files = [];
              //   this.getprojectdetails();
              // this.getSowContractFiles();
            }
          },
          (err) => this.spinner.hide()
        );
      });
    }
  }

  getSowContractFiles() {
    this.spinner.show();
    this.projectservice.getProjectSowContract(this.projectId).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.existingFileList = response;
        this.existingFileList.forEach((element: any) => {
          this.projectForm.get("selectedFile").setValue(element?.file);
        });
      },
      (err) => this.spinner.hide()
    );
  }

  getTagObject() {
    return {
      entity_id: this.projectId,
      entity_type: "project",
    };
  }

  async getAllSOWCOntractList() {
    await this.projectservice.getAllSOWContractList().subscribe((res: any) => {
      this.allSOWCOntractList = res;
    });
  }

  mapSOWContract() {
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      maxWidth: "50vw",
      height: "80vh",
    });
    this.allSOWCOntractList?.records?.forEach((element: any) => {
      element.selected =
        this.projectContractsList.filter(
          (e: any) => e.contract.id == element.id
        ).length > 0
          ? true
          : false;
    });
    dialog.componentInstance.type = {
      name: "map_sow_to_project",
      section: "map_sow_to_project",
      contract_List: this.allSOWCOntractList?.records,
    };
    dialog.afterClosed().subscribe((val) => {
      if (val) {
        let selected = val?.filter((e: any) => e.selected == true);
        let mapIds: any = [];
        let filteredResource: any = [];
        selected.forEach((element: any) => {
          filteredResource = [];
          element?.resourseList?.forEach((element: any) => {
            if (element.selected) {
              filteredResource.push(element?.id);
            }
          });
          mapIds.push({
            id: element.id,
            contract_name: element.contract_name,
            resources: filteredResource,
          });
        });
        this.mapContractMasterDetails(mapIds);
        //  this.teamCreateForm.controls[i].value.respondents.value = selected;
      }
    });
  }

  cancel() {
    this.dialog.closeAll();
  }

  mapContractMasterDetails(mapIds: any) {
    let payload = {
      ppm_id: this.ppm_id,
      contracts: mapIds,
    };
    this.spinner.show();
    this.projectservice.updateProjectVendorDetails(payload).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response) {
          this.projectContractsList = [];
          this.projectContractsList = response.records;
          localStorage.setItem(
            "projectContract",
            JSON.stringify(this.projectContractsList)
          );
          this.toastr.success("Data saved successfully");
        }
      },
      (err) => this.spinner.hide()
    );
  }

  editProjectDetailVisibility(value: boolean) {
    this.isEditMode = value;
  }

  createAddEditPayload() {
    return {
      owner_id: this.projectDetailForm.value.owner,
      sponsor_id: this.projectDetailForm.value.sponsor,
      execution_type_id: this.projectDetailForm.value.executionType,
      begin_date: new Date(this.projectDetailForm.value.startDate)
        .toISOString()
        .split("T")[0],
      expected_end_date: new Date(this.projectDetailForm.value.endDate)
        .toISOString()
        .split("T")[0],
    };
  }
  editProjectDetails() {
    if (this.projectDetailForm.valid) {
      let data = this.createAddEditPayload();
      this.financialService
        .editProjectDetail(this.projectDetail.id, data)
        .subscribe((res: any) => {
          this.getprojectdetails(true);
          this.toastr.success("Project Details edited successfully");
          this.isEditMode = false;
        });
    }
  }
}

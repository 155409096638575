import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RightPanelService {
  currentMonth: any;
  currentYear: number;
  constructor(
    private http: HttpClient,
    private transferState: TransferState
  ) {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth() + 1;;
    this.currentYear = currentDate.getFullYear();
   }

  getAttritionTrendData(id: any) {   
    const detailKey = makeStateKey(`delivery/attrition-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse) {
       return this.http.get(`delivery/attrition-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
  }
  getKRRTrendData(id: any) { 
    const detailKey = makeStateKey(`delivery/krr-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse) {
       return this.http.get(`delivery/krr-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
  }
  getResourceTrendData(id: any){
    const detailKey = makeStateKey(`delivery/resource-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse) {
       return this.http.get(`delivery/resource-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
  }
  getSpendTrendData(id:any){
    const detailKey = makeStateKey(`delivery/spend-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse) {
       return this.http.get(`delivery/spend-monthwise-count/?project=${id}&month=${this.currentMonth}&year=${this.currentYear}`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
  }
  getStaffingPhyramidData(id:any){
    const detailKey = makeStateKey(`delivery/workforce-pyramid-map/?project=${id}`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse) {
       return this.http.get(`delivery/workforce-pyramid-map/?project=${id}`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
  }
  getRiskRadar(id:any){
    const detailKey = makeStateKey(`delivery/project-mapped-risks/?project_id=${id}`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse) {
       return this.http.get(`delivery/project-mapped-risks/?project_id=${id}`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
  }
  getApplicationSelected(id:any){
    const detailKey = makeStateKey(`delivery/get-project-application-master/${id}/`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.post(`delivery/get-project-application-master/${id}/`, '').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }
  getChangeRequestData(id:any){
    const detailKey = makeStateKey(`delivery/project/${id}/changerequest-values/`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse) {
       return this.http.get(`delivery/project/${id}/changerequest-values/`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
  }
  getSubscribeOKRData(id:any){
     return this.http.get(`delivery/getgoals/list/?project_id=${id}`)
  }
}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-spend-tracker',
  templateUrl: './spend-tracker.component.html',
  styleUrls: ['./spend-tracker.component.scss']
})
export class SpendTrackerComponent implements OnInit {
  projectID:any;
  project_details:any;
  spendDataList:any = [];
  spendTrackerForm: FormArray | any;
  program_details:any;
  currentYear:any;
  
  month = [
    {name:'Jan',month:1},
    {name:'Feb',month:2},
    {name:'Mar',month:3},
    {name:'Apr',month:4},
    {name:'May',month:5},
    {name:'Jun',month:6},
    {name:'Jul',month:7},
    {name:'Aug',month:8},
    {name:'Sep',month:9},
    {name:'Oct',month:10},
    {name:'Nov',month:11},
    {name:'Dec',month:12},
  ]

  user:any;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private messageService: MessageService
  ) { 
    this.spendTrackerForm = this.formBuilder.array([]);
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
  }

  ngOnInit(): void {
    this.messageService.setGnews('Project central - spend tracker')
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.getprojectId();
   
  }
  getprojectId() {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectID = atob(params.id);
    });
    this.program_details = JSON.parse(localStorage.getItem('program')|| '{}');
    this.projectService.getprojectdetailsOverview(this.projectID).subscribe((response: any) => {
      this.project_details = response;
      this.getSpendTracker();
    });
    
  }
  
  getSpendTracker(){
    this.spinner.show();
    this.projectService.getSpendTracker(this.project_details.project_id).subscribe((res: any) => {
      this.month.forEach((element:any) => {
        let spend:any = res.find((x:any) => x.month == element.month);
        this.addSpendTracker(spend,element);
      });
    });
  }
  addSpendTracker(spend:any,month:any){
    var data = {
      id:spend?.id ? spend?.id :null,
      project_id:this.project_details.project_id,
      project_name:  this.project_details.project_name ? this.project_details.project_name :'',
      program_name: this.program_details.program_name ? this.program_details.program_name : '',
      portfolio:  this.program_details.portfolio_name ? this.program_details.portfolio_name : '',
      month:month?.month ? month?.month : '',
      month_name:month?.name ? month?.name : '',
      year:this.currentYear,
      invoice_type:spend?.invoice_type ? spend?.invoice_type : '',
      actual_invoiced:spend?.actual_invoiced ? spend?.actual_invoiced : 0,
      field_disable:true
    }
    this.spendTrackerForm.push(
      this.formBuilder.control(data));
  }
 
  getYTD(month:any){
    let ytd:number = 0
    this.spendTrackerForm.value.forEach((element:any) => {
      if(element.month <= month){
        ytd = ytd + parseFloat(element.actual_invoiced);
      }
    });
    return ytd ? ytd : 0;
  }

  submitSpendTracker(spend:any,index:any){
    this.spinner.show();
    this.projectService.updateSpendTracker([spend]).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success('Data updated successfully.');
      this.spendTrackerForm.controls[index].value.field_disable = true;
    });
  }
  enableField(index:any){
    this.spendTrackerForm.controls[index].value.field_disable = false;
    this.spendTrackerForm.value.forEach((element:any,i:any) => {
      if(i != index){
        this.spendTrackerForm.controls[i].value.field_disable = true;
      }
    });
  }
}

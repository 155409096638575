import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxPermissionsService } from 'ngx-permissions';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-data-credientials',
  templateUrl: './data-credientials.component.html',
  styleUrls: ['./data-credientials.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class DataCredientialsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.data_credentials.title;
  actionmode: string = '';
  projectId: any;
  dataCredientials: any;
  selected_id = 0;
  page: number = 1;
  endVal: number = 10;
  startVal: number = 0;
  totalCount: any;
  itemsPerPage: number = 10;
  deleteID: any;
  password: any;

  show = true;
  constructor(private toastr: ToastrService, public dialog: MatDialog, 
    private spinner: NgxSpinnerService, private projectService: ProjectService, 
    private permissionsService: NgxPermissionsService,
    private activatedRoute: ActivatedRoute) { this.actionmode = 'list'; }


  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    this.getprojectId();
    this.password = 'password';
  }


  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = false;
    } else {
      this.password = 'password';
      this.show = true;
    }
  }

  getShortName(fullName: any) {
    return fullName.split(' ').map((n: any) => n[0]).join('');
  }

 
  getName(name: any) {
    return name.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }


  getprojectId() {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectId = atob(params.id);
      console.log('getparmasid', this.projectId);
    });
    this.getdataCredientials();
  }

  getdataCredientials() {
    this.spinner.show();
    this.projectService.datacredientials(this.projectId).subscribe((response: any) => {
      if (response) {
        console.log('credientials data', response);
        this.dataCredientials = response;
        this.totalCount = this.dataCredientials?.length;
      }
      else {
        this.dataCredientials = '';
        this.totalCount = '';
      }
      this.spinner.hide();
    },err=>this.spinner.hide())
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getdataCredientials();
  }

  editCredientials(id: any) {
    this.actionmode = 'edit';
    this.selected_id = id;
  }

  /*  deleteCredientials(id: any) {
     this.spinner.show();
     this.projectService.deleteCredientials(id).subscribe((response: any) => {
       if (response == true) {
         this.toastr.success('Delete successfully');
         this.getdataCredientials();
         this.spinner.hide();
       }
     })
   } */

  deleteCredientials(id: any, content: any) {
    this.deleteID = id;
    this.dialog.open(content)
  }

  deleteDialog() {
    this.spinner.show();
    this.projectService.deleteCredientials(this.deleteID).subscribe((response: any) => {
      if (response == true) {
        this.toastr.success('Delete successfully');
        this.dialog.closeAll();
        this.getdataCredientials();
        this.spinner.hide();

      }
    }, error => {
      this.dialog.closeAll();
      this.spinner.hide();
      this.toastr.error("Error");
      throw error;
    });
  }



  closeDialog() {
    this.dialog.closeAll();
  }


  closeaddform(e: any) {
    console.log(e);
  }

  changeCount(e: any) {
    this.actionmode = '';
  }

  addform() {
    this.selected_id = 0;
    this.actionmode = 'add';
  }
  callRefereshParentData(e: any) {
    this.actionmode = '';
    this.getdataCredientials();
  }

}

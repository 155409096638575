import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-assessment-audit',
  templateUrl: './assessment-audit.component.html',
  styleUrls: ['./assessment-audit.component.scss']
})
export class AssessmentAuditComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  projectID: any;
  activeTab: number = 0;
  program_details: any;
  project_details: any;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getprojectId();
  }

  getprojectId() {
    console.log(this.activatedRoute);
    this.activatedRoute.parent?.params.subscribe((params: any) => {
      this.projectID = atob(params.id);
    });

  }

  changeTab(tab: any) {
    this.activeTab = tab.index;
  }

}

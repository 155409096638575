import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FinancialService } from "../../../../../shared/services/financial.service";
import { ToastrService } from "ngx-toastr";
import { TransferState } from "@angular/platform-browser";

@Component({
  selector: "app-f-edit-budget",
  templateUrl: "./edit-budget.component.html",
  styleUrls: ["./edit-budget.component.scss"],
})
export class EditBudgetComponent implements OnInit {
  mode = "add";
  projectID: any;
  budgetForm: FormGroup;
  costType: any = [];
  costHead: any = [];
  ouGdp: any = [];
  userList: any = [];
  budgetRecord: any;
  projectDetails: any;

  constructor(
    private formBuilder: FormBuilder,
    private financialService: FinancialService,
    public dialogRef: MatDialogRef<EditBudgetComponent>,
    public toastService: ToastrService,
    private transferState: TransferState,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mode = data.mode;
    this.projectID = data.projectID;
    this.budgetRecord = data.budgetRecord;
  }

  ngOnInit(): void {
    this.budgetForm = this.formBuilder.group({
      name: [this.budgetRecord?.name || "", Validators.required],
      cost_type: [this.budgetRecord?.cost_type || "", Validators.required],
      cost_head: [this.budgetRecord?.cost_head || "", Validators.required],
      valid_from: [this.budgetRecord?.valid_from || "", Validators.required],
      valid_to: [this.budgetRecord?.valid_to || "", Validators.required],
      amount: [this.budgetRecord?.amount || "", Validators.required],
      owner: [this.budgetRecord?.owner.id || "", Validators.required],
      ou_gdp: [this.budgetRecord?.ou_gdp.id || ""],
      budget_approver: [
        this.budgetRecord?.approver.id || "",
        Validators.required,
      ],
    });
    let projectDetails = localStorage.getItem("selectedProject");
    this.projectDetails = JSON.parse(projectDetails || "{}");
    this.getMetadata();
  }

  getMetadata() {
    this.financialService.getCostPoolTaxanomy().subscribe((res: any) => {
      this.costHead = res.cost_head;
      this.costType = res.cost_type;
    });
    this.financialService.getOuGdpList().subscribe((res: any) => {
      this.ouGdp = res;
    });
    this.financialService.getUserList().subscribe((res: any) => {
      this.userList = res;
    });
  }

  createAddEditPayload() {
    return {
      approver_id: this.budgetForm.value.budget_approver,
      consumed_amount: 0,
      ou_gdp_id: this.budgetForm.value.ou_gdp,
      owner_id: this.budgetForm.value.owner,
      ppm_id: this.projectDetails.ppm_id,
      valid_from: new Date(this.budgetForm.value.valid_from)
        .toISOString()
        .split("T")[0],
      valid_to: new Date(this.budgetForm.value.valid_to)
        .toISOString()
        .split("T")[0],
      cost_head: this.budgetForm.value.cost_head,
      cost_type: this.budgetForm.value.cost_type,
      amount: this.budgetForm.value.amount,
      project_id: this.projectID,
      name: this.budgetForm.value.name,
    };
  }

  addBudget() {
    if (this.budgetForm.valid) {
      // let data = this.budgetForm.value;
      let data = this.createAddEditPayload();
      this.financialService.addFinanceBudget(data).subscribe((res: any) => {
        this.toastService.success("Budget added successfully");
        this.dialogRef.close();
      });
    } else {
      console.log(this.budgetForm);
      this.toastService.error("Please fill all the required fields");
    }
  }

  editBudget() {
    if (this.budgetForm.valid) {
      let data = this.createAddEditPayload();
      this.financialService
        .editFinanceBudget(this.budgetRecord.id, data)
        .subscribe((res: any) => {
          this.toastService.success("Budget edited successfully");
          this.dialogRef.close();
        });
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}

<div class="w-100 bg-lavendar" [ngClass]="{ 'mt-80': !iframeEmbedded }">
  <app-header></app-header>
</div>
<div class="p-3">
  <div class="px-0" [ngClass]="{ 'pb-4': !iframeEmbedded }">
    <app-bread-crumb></app-bread-crumb>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <div
        class="single-left-pane"
        [ngStyle]="{ height: !iframeEmbedded ? 'inherit' : '100%' }"
      >
        <app-left-bar></app-left-bar>
      </div>
    </div>
    <div
      [ngClass]="{ 'col-sm-10': iframeEmbedded, 'col-sm-7': !iframeEmbedded }"
    >
      <div
        class="single-center-pane"
        [ngStyle]="{ height: !iframeEmbedded ? 'inherit' : '95vh' }"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="col-sm-3" *ngIf="!iframeEmbedded">
      <div class="single-right-pane">
        <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
        <h6>Project Attributes</h6>
        <div class="accordion" id="accordionRightPanel">
          <div
            *ngFor="let item of accordionItems; index as i"
            class="accordion-item"
          >
            <h2 class="accordion-header" [id]="'headingOneRight' + i">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                attr.data-bs-target="#collapseOneRight{{ i }}"
                aria-expanded="true"
                aria-controls="collapseOneRight{{ i }}"
                style="font-size: 13px"
              >
                {{ item }}
              </button>
            </h2>
            <div
              [id]="'collapseOneRight' + i"
              class="accordion-collapse collapse"
              [aria-labelledby]="'headingOneRight' + i"
              data-bs-parent="#accordionRightPanel"
            >
              <app-right-bar
                [projectId]="projectId"
                [item]="item"
                [type]="'right'"
              ></app-right-bar>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                style="font-size: 13px"
              >
                Curated from Web
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body p-2">
                <app-gnews
                  [pagename]="''"
                  [display_header]="'false'"
                ></app-gnews>
              </div>
            </div>
          </div>
        </div>
        <br />
        <app-individual-scorecard></app-individual-scorecard>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<div class="row field-adjust" id="custom-height">
    <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="row" >
    <div class="col-md-12">
        <div class="custom-height">
            <div class="custom-table">
                <table class="table table-bordered">
                    <thead style="position: sticky; z-index: 1">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col" class="w-100">Change Request Description</th>
                            <!-- <th class="text-center col-2" scope="col">Action</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr FormArray="projectChangeRequestForm" *ngFor="let cr of projectChangeRequestForm.controls; let i = index">
                            <td>{{i+1}}</td>
                            <td [id]="'crDiv_'+i">
                                <div class="d-flex justify-content-between">
                                    <div style="width:100%">
                                        <div class="wprr">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label class="label-design"></mat-label>
                                                <input type="text" matInput fullWidth status="basic" 
                                                [(ngModel)]="cr.value.change_request_description" [ngModelOptions]="{standalone: true}" name="change_request_description">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div>
                                        <mat-icon class="collapsed rotate-minus-90deg" (click)="toggleEvent($event)" data-bs-toggle="collapse" attr.data-bs-target="#collapseOne{{i}}" aria-expanded="true" aria-controls="collapseOne" style="cursor: pointer;">arrow_back_ios</mat-icon>
                                    </div>
                                </div>
                                <div [id]="'collapseOne'+i" class="accordion-collapse collapse" [aria-labelledby]="'goalDiv_'+i" data-bs-parent="#accordionExample">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="background: #0bb797;">Type</th>
                                                <th scope="col" style="background: #0bb797;">Status</th>
                                                <th scope="col" style="background: #0bb797;">Eff Date</th>
                                                <th scope="col" style="background: #0bb797;">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="align-middle">
                                                    <div style="width:120px">
                                                        <mat-form-field class="example-full-width w-100" style="width:120px"  appearance="outline">
                                                            <mat-label class="label-design"></mat-label>
                                                            <input type="text" matInput fullWidth status="basic" 
                                                            [(ngModel)]="cr.value.cr_type" [ngModelOptions]="{standalone: true}" name="cr_type">
                                                        </mat-form-field>
                                                    </div>
                                                </td>
                                                <td class="align-middle">
                                                    <div style="width:120px">
                                                        <mat-form-field class="example-full-width w-100" style="width:120px"  appearance="outline">
                                                            <mat-label class="label-design"></mat-label>
                                                            <input type="text" matInput fullWidth status="basic" 
                                                            [(ngModel)]="cr.value.status" [ngModelOptions]="{standalone: true}" name="status">
                                                        </mat-form-field>
                                                    </div>
                                                </td>
                                                <td class="align-middle">
                                                    <div style="width:120px">
                                                        <mat-form-field appearance="outline" class="w-100 py-0 px-0">
                                                            <mat-label class="label-design"></mat-label>
                                                            <input matInput [matDatepicker]="picker"
                                                                readonly
                                                                [(ngModel)]="cr.value.cr_effective_date" [ngModelOptions]="{standalone: true}"
                                                                fullWidth status="basic" (click)="picker.open()" name="cr_effective_date">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker>
                                                            </mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </td>
                                                <td class="align-middle">
                                                    <div style="width:120px">
                                                        <mat-form-field class="example-full-width w-100" style="width:120px"  appearance="outline">
                                                            <mat-label class="label-design"></mat-label>
                                                            <input type="number" matInput fullWidth status="basic" 
                                                            [(ngModel)]="cr.value.cr_value" [ngModelOptions]="{standalone: true}" name="cr_value">
                                                        </mat-form-field>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                           <!-- <td class="align-middle">
                                <div style="display: flex; align-items: center; justify-content: space-around;">
                                    <span class="cursor-pointer" (click)="removeCR(i)">
                                        <span class="cursor-pointer">
                                            <img src="./assets/images/comment-delete.svg"
                                                style="width:16px;">
                                        </span>
                                    </span>
                                    <span class="cursor-pointer" (click)="addCR(i)"
                                    *ngIf="projectChangeRequestForm?.length - 1 === i">
                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                            class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                        </mat-icon>
                                    </span>
                                </div>
                            </td> -->
                        </tr>
                        <tr *ngIf="projectChangeRequestForm?.controls?.length == 0">
                            <td class="empty-txt" colspan="3">{{staticText.common.no_records_found}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

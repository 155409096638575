<div class="row field-adjust" id="custom-height">
  <app-view-page-header
    [headerName]="'Assessments & Audits'"
  ></app-view-page-header>
</div>
<div class="row w-100 m-0">
  <div class="col-md-auto p-0 px-0" style="width: 100%">
    <!--Tabs Started -->
    <mat-tab-group mat-align-tabs="start" class="head tabs" (selectedTabChange)="changeTab($event)">
      <mat-tab label="{{ staticText.delivery.assessment_audit.assessments }}">
        <div *ngIf="projectID">
          <app-assessments projectID="{{ projectID }}"></app-assessments>
        </div>
      </mat-tab>
      <mat-tab label="{{ staticText.delivery.assessment_audit.audits }}">
        <div *ngIf="projectID">
          <app-audits projectID="{{ projectID }}"></app-audits>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
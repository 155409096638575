
<div class="row field-adjust" id="custom-height">
    <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="mb-4"  >
    <app-filter-card [pagetype]="'application'" ></app-filter-card>
</div>
<div class="row w-100 m-0">
    <div class="col-md-auto p-0 px-0" style="width:100%">
        <div class="container p-0">
            <div class="row p-0 m-0 ">
                <div class="p-0 ">
                    <div class="">
                        <mat-tab-group mat-align-tabs="start" class="head" (selectedTabChange)="changeTab($event)">
                            <mat-tab label="{{staticText?.delivery.applications.project_application}} ({{projectApplicationMasterList?.application_count}})" >
                                <div *ngIf="activeTab == 0">
                                        <div class="custom-table fixed-table-header">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th *ngFor="let header of staticText?.delivery.applications.table_header_project" 
                                                            scope="col"><span class="d-flex justify-content-center">{{header}}</span></th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody class="cursor-pointer">
                                                    <tr *ngFor="let tableRecords of projectApplicationMasterList?.data;index as tableIndex">
                                                        <td scope="row">
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.name || '-'}}
                                                            </span>
                                                        </td>
                                                         <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.disaster_recovery_tier  || '-' | titlecase}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.support_group || '-'}}
                                                            </span>
                                                        </td>
                                                       <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.business_owner || '-'}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.business_area || '-'}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tr *ngIf="!projectApplicationMasterList?.data?.length">
                                                    <td colspan="6" class="noRecords text-center mt-2">
                                                        <span class="mt-2">Please add Applications from the Application Master </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div style="overflow: auto;position: relative;">
                                            <div class="d-flex  align-items-center justify-content-between" *ngIf="projectApplicationMasterList?.application_count > 0">
                                                <div class="p-1">
                                                    <div
                                                        class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark">
                                                        <span class="p-1">Displaying</span>
                                                        <select class="form-select form-select-sm table-sort-filter"
                                                            aria-label="Default select example" [(ngModel)]="sortRecords"
                                                            (change)="getRecords(0)">
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="150">150</option>
                                                            <option value="200">200</option>
                                                        </select>
                                                        <span class="p-1">{{projectApplicationMasterList?.application_count}}</span>
                                                        <span class="p-1">records</span>
                                                    </div>
                                                </div>
                                                <div class="p-1" >
                                                    <ngb-pagination [pageSize]="sortRecords" [collectionSize]="projectApplicationMasterList?.application_count" [maxSize]="2"
                                                        [rotate]="true" [(page)]="CurrentPage" [boundaryLinks]="true"
                                                        (pageChange)="getRecords(0);">
                                                    </ngb-pagination>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Workforce ends-->
                                    <!-- </div> -->
                                </div>
                            </mat-tab>
                            <mat-tab label="{{staticText?.delivery.applications.application_master}} ({{applicationMasterList?.count}})" >
                                <div *ngIf="activeTab == 1">
                                        <div class="custom-table fixed-table-header" >
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th *ngFor="let header of staticText?.delivery.applications.table_header"
                                                            scope="col"><span class="d-flex justify-content-center">{{header}}</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody class="cursor-pointer">
                                                    <tr *ngFor="let tableRecords of applicationMasterList?.data;index as tableIndex">
                                                        <td scope="row">
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.name || '-'}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.disaster_recovery_tier  || '-' | titlecase}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.support_group || '-'}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.business_owner || '-'}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="d-flex justify-content-center fs-13" >
                                                                {{tableRecords?.business_area || '-'}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex justify-content-around align-items-center mt-2">
                                                                    <input type="checkbox" class="mx-2" [checked]="isChecked(tableRecords)" [disabled]="user?.is_vendor" (change)="onChekedChange($event,tableRecords)">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tr *ngIf="!applicationMasterList?.data?.length">
                                                    <td colspan="6" class="noRecords text-center mt-2">
                                                        <span class="mt-2">{{staticText.common.no_records_found}} </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="d-flex  align-items-center justify-content-between" *ngIf="applicationMasterList?.count > 0">
                                            <div class="p-1">
                                                <div
                                                    class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark">
                                                    <span class="p-1">Displaying</span>
                                                    <select class="form-select form-select-sm table-sort-filter"
                                                        aria-label="Default select example" [(ngModel)]="sortRecords"
                                                        (ngModelChange)="getRecords(1)">
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="150">150</option>
                                                        <option value="200">200</option>
                                                    </select>
                                                    <span class="p-1">{{applicationMasterList?.count}}</span>
                                                    <span class="p-1">records</span>
                                                </div>
                                            </div>
                                            <div class="p-1" >
                                                <ngb-pagination [pageSize]="sortRecords" [collectionSize]="applicationMasterList?.count" [maxSize]="2"
                                                    [rotate]="true" [(page)]="CurrentPage" [boundaryLinks]="true"
                                                    (pageChange)="getRecords(1);">
                                                </ngb-pagination>
                                            </div>
                                        </div>
                                        <!--Workforce ends-->
                                    <!-- </div> -->
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                        <div class="modal-footer" *ngIf="applicationMasterList?.count > 0 && activeTab == 1 && !user?.is_vendor">
                            <button (click)="mapToProject()" mat-button mat-flat-button class="bg-primary text-white text-capitalize float-right ml-2 cursor-pointer" 
                                [disabled]="selectedRecords.length==0">
                                {{staticText.buttons.save_btn}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { WorkForceService } from "src/app/shared/services/work-force.service";
import { MatDialog } from "@angular/material/dialog";
import { FilterService } from "src/app/shared/services/filter.service";
import { MatTabGroup } from "@angular/material/tabs";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-delivery-resources",
  templateUrl: "./delivery-resources.component.html",
  styleUrls: ["./delivery-resources.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DeliveryResourcesComponent implements OnInit {
  @ViewChild("tabGroup", { static: true }) tabGroup: MatTabGroup;
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery.left_bar.workforce;
  projectId: any;
  activeTab: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  allKRRList: any;
  allResourceList: any;
  allAttritionList: any;
  firstTimeLoad: boolean = true;

  filterObj: any;
  filterString: any = "";
  editingResourceId: any;
  workForceResourceForm: FormGroup | any;
  user: any;
  projectTraining: any;
  trainingList: any;
  contractTypes: any;

  constructor(
    public dialog: MatDialog,
    private filterService: FilterService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private workForceService: WorkForceService,
    public fb: FormBuilder,
    private toastrService: ToastrService,
    private messageService: MessageService
  ) {
    this.workForceResourceForm = this.fb.group({
      resource_id: [""],
      resource_name: [""],
      job_category: [""],
      job_title: [""],
      company_name: [""],
      classification: [""],
      country: [""],
      krr_classification: [""],
      krr_risk_mitigation_plan: [""],
      work_permit: [""],
      work_permit_expiry_date: [""],
      work_permit_renewal_plan: [""],
      wp_contingency_plan: [""],
      no_of_resource_having_exception_production_access: ["No"],
      no_of_resource_having_exception_generic_id: ["No"],
      no_of_resource_having_exception_pii_access: [""],
      no_of_resource_having_exception_pci_access: [""],
      no_of_resource_having_exception_phi_access: [""],
      no_of_resource_having_exception_cross_border_data_access: [""],
      sow_model: [""],
      approved_by_dl_manager: [""],
      resignation_date: [""],
      dl_notified_date: [""],
      lwd: [""],
      notice_period: [""],
      replacement_status: [""],
      replacement_start_date: [""],
      replacement_name: [""],
      kt_plan: [""],
      selected_trainings: this.fb.array([]),
      is_existed: [false],
    });
  }

  ngOnInit(): void {
    this.messageService.setGnews("Project central - Workforce");
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    // this.SortData();
    this.getprojectId();
    this.getContractTypes();

    this.filterService.filterSubject.subscribe((res: any) => {
      this.CurrentPage = 0;
      this.EndValue = 20;
      this.startValue == 0;
      this.firstTimeLoad = true;

      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if (this.filterObj[element].array.length > 0) {
          awl = true;
          return;
        }
      });
      if (Object.keys(this.filterObj).length > 0) {
        this.getList();
      } else {
        this.getList();
      }
    });
  }

  getContractTypes() {
    this.workForceService.getContractTypes().subscribe((res: any) => {
      this.contractTypes = res;
    });
  }

  getprojectId() {
    this.activatedRoute.parent?.params.subscribe((params) => {
      console.log(params);
      this.projectId = atob(params.id);
      this.getProjectTraining();
    });
  }

  getProjectTraining() {
    this.projectTraining = [];
    this.workForceService
      .getProjectTrainings(this.projectId)
      .subscribe((res: any) => {
        if (res) {
          this.projectTraining = res;
        }
      });
  }

  getList() {
    this.getAllResourceList();
    this.getAllAttritionList();
    this.getKRRList();
  }

  getAllResourceList() {
    this.spinner.show();
    this.allResourceList = [];
    let payload = this.getPayload();
    this.workForceService
      .getProjectResourceListNew(this.startValue, this.EndValue, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          let contractList = JSON.parse(
            localStorage.getItem("projectContract") || ""
          );
          this.allResourceList = res;
          this.allResourceList.data.forEach((element: any, index: any) => {
            this.allResourceList.data[index].contractList = contractList.filter(
              (item: any) => item.contract.vendor.name === element.company_name
            );
          });
        }
      });
  }

  getAllAttritionList() {
    this.spinner.show();
    this.allAttritionList = [];
    let payload = this.getPayload();
    this.workForceService
      .getProjectAttritionListNew(this.startValue, this.EndValue, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.allAttritionList = res;
        }
      });
  }

  getKRRList() {
    this.spinner.show();
    this.allKRRList = [];
    let payload = this.getPayload();
    this.workForceService
      .geProjectKKRListNew(this.startValue, this.EndValue, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.allKRRList = res;
        }
      });
  }

  getPayload() {
    let payload: any = {};
    if (this.filterObj?.workforceMonth?.array?.length > 0) {
      let months: any = [];
      this.filterObj?.workforceMonth.array.forEach((element: any) => {
        months.push(element.id);
      });
      payload = {
        month: months,
      };
    } else if (this.filterObj?.workforceJobCategory?.array?.length > 0) {
      let job_category: any = [];
      this.filterObj?.workforceJobCategory.array.forEach((element: any) => {
        job_category.push(element.id);
      });
      payload = {
        job_category: job_category,
      };
    } else if (this.filterObj?.vendor?.array?.length > 0) {
      let vendor: any = [];
      this.filterObj?.vendor.array.forEach((element: any) => {
        vendor.push(element.id);
      });
      payload = {
        vendor: vendor,
      };
    } else if (this.filterObj?.workforceClassification?.array?.length > 0) {
      let classification: any = [];
      this.filterObj?.workforceClassification.array.forEach((element: any) => {
        classification.push(element.id);
      });
      payload = {
        classification: classification,
      };
    } else if (this.filterObj?.workforceCountry?.array?.length > 0) {
      let country: any = [];
      this.filterObj?.workforceCountry.array.forEach((element: any) => {
        country.push(element.id);
      });
      payload = {
        country: country,
      };
    }
    payload.project = [this.projectId];
    return payload;
  }

  changeTab(event: any) {
    this.activeTab = event.index;
    this.CurrentPage = 0;
    this.EndValue = 20;
    this.startValue == 0;
    if (this.activeTab == 0 && this.allResourceList?.count == 0) {
      this.getAllResourceList();
    } else if (this.activeTab == 1 && this.allKRRList?.count == 0) {
      this.getKRRList();
    } else if (this.activeTab == 2 && this.allAttritionList?.count == 0) {
      this.getAllAttritionList();
    }
  }

  getRecords(isFromAttrition?: boolean) {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if (!this.firstTimeLoad) {
      if (this.activeTab == 0) {
        this.getAllResourceList();
      } else if (this.activeTab == 1) {
        this.getKRRList();
      } else if (this.activeTab == 2) {
        this.getAllAttritionList();
      }
    }
    this.firstTimeLoad = false;
  }

  editWorkForceDetails(id: any, type: any, modal: any) {
    this.editingResourceId = id;
    let workForceDetails = "";
    this.spinner.show();
    if (type == "attrition") {
      this.workForceService
        .getWorkForceAttritionDataById(id)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.patchWorkForceValues(res, modal);
        });
    } else if (type == "resource") {
      this.workForceService
        .getWorkForceResourceDataById(id, this.projectId)
        .subscribe((res: any) => {
          this.patchWorkForceValues(res, modal, type);
          this.spinner.hide();
        });
    } else if (type == "krr") {
      this.workForceService
        .getWorkForceKrrDataById(id)
        .subscribe((res: any) => {
          this.patchWorkForceValues(res, modal);
          this.spinner.hide();
        });
    }
  }

  patchWorkForceValues(res: any, modal: any, type: any = "") {
    this.workForceResourceForm.patchValue(res);
    if (type == "resource") {
      this.workForceResourceForm.controls.selected_trainings.controls = [];
      res.selected_trainings.forEach((element: any) => {
        this.workForceResourceForm.controls.selected_trainings.controls.push(
          this.fb.group({
            id: new FormControl(element.id),
            resource_id: new FormControl(res.id, [Validators.required]),
            training_id: new FormControl(element.training_id, [
              Validators.required,
            ]),
            status: new FormControl(element.status, [Validators.required]),
          })
        );
      });
    }
    this.dialog.open(modal, {
      height: "70vh",
    });
  }
  updateWorkForceDetails() {
    this.spinner.show();
    let data = this.workForceResourceForm.value;
    let form: any = this.workForceResourceForm;
    let arr: any = [];
    form?.controls?.selected_trainings.controls.forEach((element: any) => {
      arr.push(element.value);
    });
    data.selected_trainings = arr;
    this.workForceService
      .updateWorkForceDetailsById(data, this.editingResourceId)
      .subscribe((res: any) => {
        if (data.is_existed) {
          const exitPayload = {
            resignation_date: new Date(data.lwd).toISOString().split("T")[0],
          };
          this.workForceService
            .updatedExitStatus(exitPayload, this.editingResourceId)
            .subscribe((res: any) => {
              this.spinner.hide();
              this.toastrService.success("Resource updated successfully.");
              this.dialog.closeAll();
            });
        } else {
          this.spinner.hide();
          this.toastrService.success("Resource updated successfully.");
          this.dialog.closeAll();
        }
        this.getList();
      });
  }
  cancel() {
    this.dialog.closeAll();
  }

  loadResourceContentType(event: any, index: any, resource: any) {
    let payload = {
      contract_type: event,
      contract_id: null,
      contract_name: null,
    };
    this.patchResourceContract(resource.id, payload);
  }

  loadResourceContract(event: any, index: any, resource: any) {
    let payload = {
      contract_type: "SOW",
      contract_id: event.contract.id,
      contract_name: event.contract.contract_name,
    };
    this.allResourceList.data[index].contract_name =
      event.contract.contract_name;
    this.patchResourceContract(resource.id, payload);
  }

  patchResourceContract(id: any, payload: any) {
    this.spinner.show();
    this.workForceService
      .updateResourceContract(id, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
      });
  }

  addTrainingItems() {
    this.workForceResourceForm.controls.selected_trainings.controls.push(
      this.fb.group({
        id: new FormControl(""),
        resource_id: new FormControl(this.editingResourceId, [
          Validators.required,
        ]),
        training_id: new FormControl("", [Validators.required]),
        status: new FormControl("", [Validators.required]),
      })
    );
  }

  getTrainingName(trainingId: any) {
    if (trainingId) {
      return this.projectTraining.find((item: any) => item.id === trainingId)
        .training_name;
    }
  }

  getTrainingList(index: any) {
    if (this.projectTraining) {
      let filteringArray: any = [];
      this.workForceResourceForm
        .get("selected_trainings")
        .controls.forEach((element: any) => {
          if (element.value.training_id) {
            filteringArray.push(element.value.training_id);
          }
        });
      var filteredArray =
        filteringArray.length > 0
          ? this.projectTraining.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl == array_el.id;
                }).length == 0
              );
            })
          : this.projectTraining;

      filteredArray =
        typeof this.workForceResourceForm.get("selected_trainings").controls[
          index
        ].value.training_id.value === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.training_name
                  .toLowerCase()
                  .indexOf(
                    this.workForceResourceForm
                      .get("selected_trainings")
                      .controls[index].value.training_id.value.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  searchTraining(index: any) {
    return this.getTrainingList(index);
  }

  onInputChanged(value: string): void {
    console.log(value);
  }

  onOptionSelectionChanged(event: any) {
    const selectedOption = event.option.value;
  }

  removeTraining(index: any) {
    if (
      this.workForceResourceForm.get("selected_trainings").controls[index].value
        .id
    ) {
      this.spinner.show();
      let id =
        this.workForceResourceForm.get("selected_trainings").controls[index]
          .value.id;
      this.workForceService.deleteResourceTraining(id).subscribe((res: any) => {
        this.spinner.hide();
      });
    }
    this.workForceResourceForm.get("selected_trainings").removeAt(index);
  }
}

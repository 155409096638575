import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FinancialService } from 'src/app/shared/services/financial.service';
import { PeopleService } from 'src/app/shared/services/people.service';

@Component({
  selector: 'app-edit-open-req',
  templateUrl: './edit-open-req.component.html',
  styleUrls: ['./edit-open-req.component.scss']
})
export class EditOpenReqComponent implements OnInit {

  mode = 'add';
  projectID: any;
  openReqForm: FormGroup;
  statusOptions: any = [];
  vendorList: any = [];
  openReqRecord: any;
  projectDetails: any;
  userList: any = [];
  statusOptionMap: any = {};


  constructor(
    private formBuilder: FormBuilder,
    private peopleService: PeopleService,
    public dialogRef: MatDialogRef<EditOpenReqComponent>,
    public toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mode = data.mode;
    this.projectID = data.projectID;
    this.openReqRecord = data.openReqRecord;
  }

  ngOnInit(): void {
    this.openReqForm = this.formBuilder.group({
      "job_id": [this.openReqRecord?.job_id || '', Validators.required],
      "job_title": [this.openReqRecord?.job_title || '', Validators.required],
      "job_description": [this.openReqRecord?.job_description || '', Validators.required],
      "job_location": [this.openReqRecord?.job_location || '', Validators.required],
      "start_date": [this.openReqRecord?.start_date || '', Validators.required],
      "end_date": [this.openReqRecord?.end_date || '', Validators.required],
      "number_of_needed": [this.openReqRecord?.number_of_needed || '', Validators.required],
      "reporting_manager": [this.openReqRecord?.reporting_manager?.id || '', Validators.required],
      "status": [this.openReqRecord?.status?.id || '', Validators.required],
      "fulfilled_by": [this.openReqRecord?.fulfilled_by?.id || ''],
    });
    let projectDetails = localStorage.getItem("selectedProject");
    this.projectDetails = JSON.parse(projectDetails || '{}');
    this.getMetadata();
  }

  getMetadata() {
    this.peopleService.getUserList().subscribe((res: any) => {
      this.userList = res;
    });
    this.peopleService.getVendorList().subscribe((res: any) => {
      this.vendorList = res;
    });
    this.peopleService.getOpenReqStatusOptions().subscribe((res: any) => {
      this.statusOptions = res;
      res.forEach((element: any) => {
        this.statusOptionMap[element.id] = element.name;
      });
    });
  }

  createAddEditPayload() {
    return {
      "job_id": this.openReqForm.value.job_id,
      "job_title": this.openReqForm.value.job_title,
      "job_description": this.openReqForm.value.job_description,
      "job_location": this.openReqForm.value.job_location,
      "start_date": new Date(this.openReqForm.value.start_date).toISOString().split('T')[0],
      "end_date": new Date(this.openReqForm.value.end_date).toISOString().split('T')[0],
      "number_of_needed": this.openReqForm.value.number_of_needed,
      "reporting_manager": this.openReqForm.value.reporting_manager,
      "status": this.openReqForm.value.status,
      "fulfilled_by": this.statusOptionMap[this.openReqForm.value.status] == 'Fulfilled' ? this.openReqForm.value.fulfilled_by : null,
      "added_from_project_central": true
    }
  }

  addOpenReq() {
    if (this.openReqForm.valid) {
      let data = this.createAddEditPayload()
      this.peopleService.addOpenReq(this.projectID, data).subscribe((res: any) => {
        this.toastService.success('Open Req added successfully');
        this.dialogRef.close("success");
      });
    } else {
      this.toastService.error('Please fill all the required fields');
    }
  }

  editOpenReq() {
    if (this.openReqForm.valid) {
      let data = this.createAddEditPayload()
      this.peopleService.editOpenReq(this.openReqRecord.id, data).subscribe((res: any) => {
        this.toastService.success('Open Req edited successfully');
        this.dialogRef.close("success");
      });
    } else {
      console.log(this.openReqForm)
      this.toastService.error('Please fill all the required fields');
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterService } from 'src/app/shared/services/filter.service';
import { FinancialService } from 'src/app/shared/services/financial.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { EditSpendComponent } from './edit-spend/edit-spend.component';

@Component({
  selector: 'app-f-spend',
  templateUrl: './spend.component.html',
  styleUrls: ['./spend.component.scss']
})
export class SpendComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  startValue: any = 0;
  @Input() projectID: any;
  spendRecords: any = [];
  private unsubscribe$ = new Subject<void>();
  filterValue: any = '';


  constructor(
    private financalService: FinancialService,
    private filterService: FilterService,
    public dialog: MatDialog,
    public toastService: ToastrService) { }

  ngOnInit(): void {
    this.getSpendRecords();
    this.subscribeFilters();
  }

  subscribeFilters() {
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.filterValue = res;
      this.getSpendRecords();
    });
  }

  getSpendRecords() {
    let month_id = '';
    if (this.filterValue.financialsSpendMonth?.array.length && this.filterValue.financialsSpendMonth?.input?.id) {
      month_id = this.filterValue.financialsSpendMonth.input.id;
    }
    this.financalService.getFinanceSpend(this.projectID, month_id).subscribe((response: any) => {
      this.spendRecords = response;
    });
  }

  addSpendRecord() {
    const dr = this.dialog.open(EditSpendComponent, {
      width: '500px',
      data: { projectID: this.projectID, mode: "add" }
    });
    dr.afterClosed().subscribe((res) => {
      console.log(res);
      this.getSpendRecords();
    });
  }

  editSpendRecord(budgetRecord: any) {
    const dr = this.dialog.open(EditSpendComponent, {
      width: '500px',
      data: { projectID: this.projectID, mode: "edit", budgetRecord: budgetRecord }
    });
    dr.afterClosed().subscribe((res) => {
      console.log(res);
      this.getSpendRecords();
    });
  }

  deleteSpendRecord(spendRecord: any) {
    this.financalService.deleteFinanceSpend(spendRecord.id).subscribe((response: any) => {
      this.getSpendRecords();
      this.toastService.success('Spend Deleted Successfully');
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

    <div class="accordion-body p-2">
                <!--People Insights -->
                <div *ngIf = "item == 'People Insights'">
                    <mat-card class="p-0 mb-2" *ngIf="attritionData?.trend">
                        <mat-card-content class="p-1 card-color-pi">
                            <mat-card-subtitle class="mb-1 card-title-pi">Attrition</mat-card-subtitle>
                            <mat-card-subtitle class="mb-0 fs-10 trend-color-pi">Trend</mat-card-subtitle>
                            <div class="pl-3">
                                <!-- <div  [chart]="attritionData.trend"  ></div> -->
                                <highcharts-chart  style="display: flex;" [Highcharts]="Highcharts" [options]="attritionData.trend" [callbackFunction]="chartCallback"  [oneToOne]=true></highcharts-chart>
                            </div>
                        </mat-card-content>
                        <mat-card-content class="pb-2">
                            <mat-card-subtitle class="mb-0 fs-10 pl-1 pb-1 trend-color-pi text-center" *ngIf="attritionData?.vendors?.length > 0">{{staticText.common.top_3_vendor}}</mat-card-subtitle>
                            <div *ngFor="let vendor of attritionData?.vendors;" class="pl-2 pr-2" >
                                <div class="d-flex justify-content-between w-100"> 
                                    <span class="fs-12 ">{{vendor?.name}}</span>
                                    <b class="fs-12 count-span">{{vendor?.count}}</b>
                                </div>
                            </div>
                            <div *ngIf="attritionData?.vendors?.length == 0" class="text-center">
                                <span class="fs-12">{{staticText.common.no_records_found}}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="p-0 mb-2" *ngIf="krrData?.trend">
                        <mat-card-content class="p-1 card-color-pi">
                            <mat-card-subtitle class="mb-1 card-title-pi">KRR</mat-card-subtitle>
                            <mat-card-subtitle class="mb-0 fs-10 trend-color-pi">Trend</mat-card-subtitle>
                            <div class="pl-3">
                                <!-- <div  [chart]="krrData.trend" class="w-100"></div> -->
                                <highcharts-chart  style="display: flex;" [Highcharts]="Highcharts" [options]="krrData.trend" [callbackFunction]="chartCallback"  [oneToOne]=true></highcharts-chart>
                            
                            </div>
                        </mat-card-content>
                        <mat-card-content class="pb-2"> 
                            <mat-card-subtitle class="mb-0 fs-10 trend-color-pi pl-1 pb-1 text-center">{{staticText.common.top_3_vendor}}</mat-card-subtitle>
                            <div *ngFor="let vendor of krrData?.vendors;" class="pl-2 pr-2" >
                                <div class="d-flex justify-content-between w-100" > 
                                    <span class="fs-12">{{vendor?.name}}</span>
                                    <b class="fs-12 count-span">{{vendor?.count}}</b>
                                </div>
                            </div>
                            <div *ngIf="krrData?.vendors?.length == 0" class="text-center">
                                <span class="fs-12">{{staticText.common.no_records_found}}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="p-0 mb-2" *ngIf="resourceData?.trend">
                        <mat-card-content class="p-1 card-color-pi">
                            <mat-card-subtitle class="mb-1 card-title-pi">Resource</mat-card-subtitle>
                            <mat-card-subtitle class="mb-0 fs-10 trend-color-pi">Trend</mat-card-subtitle>
                            <div class="pl-3">
                                <!-- <div  [chart]="resourceData.trend" class="w-100"></div> -->
                                <highcharts-chart  style="display: flex;" [Highcharts]="Highcharts" [options]="resourceData.trend" [callbackFunction]="chartCallback"  [oneToOne]=true></highcharts-chart>
                            </div>
                        </mat-card-content>
                        <mat-card-content class="pb-2">
                            <mat-card-subtitle class="mb-0 fs-10 trend-color-pi pl-1 pb-1 text-center">{{staticText.common.top_3_vendor}}</mat-card-subtitle>
                            <div *ngFor="let vendor of resourceData?.vendors;" class="pl-2 pr-2" >
                                <div class="d-flex justify-content-between w-100" > 
                                    <span class="fs-12">{{vendor?.name}}</span>
                                    <b class="fs-12 count-span">{{vendor?.count}}</b>
                                </div>
                            </div>
                            <div *ngIf="resourceData?.vendors?.length == 0" class="text-center">
                                <span class="fs-12">{{staticText.common.no_records_found}}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="p-0 mb-2" *ngIf="spendData?.trend">
                        <mat-card-content class="p-1 card-color-pi">
                            <mat-card-subtitle class="mb-1 card-title-pi">Spend</mat-card-subtitle>
                            <mat-card-subtitle class="mb-0 fs-10 trend-color">Trend</mat-card-subtitle>
                            <div class="pl-3 w-100">
                                <!-- <div  [chart]="spendData.trend" class="w-100"></div> -->
                                <highcharts-chart  style="display: flex;" [Highcharts]="Highcharts" [options]="spendData.trend" [callbackFunction]="chartCallback"  [oneToOne]=true></highcharts-chart>
                            </div>
                        </mat-card-content>
                        <mat-card-content class="pb-2">
                            <mat-card-subtitle class="mb-0 fs-10 trend-color-pi pl-1 pb-1 text-center">{{staticText.common.top_3_vendor}}</mat-card-subtitle>
                            <div *ngFor="let vendor of spendData?.vendors;" class="pl-2 pr-2" >
                                <div class="d-flex justify-content-between w-100" > 
                                    <span class="fs-12">{{vendor?.name}}</span>
                                    <b class="fs-12 count-span">{{vendor?.count}}</b>
                                </div>
                            </div>
                            <div *ngIf="spendData?.vendors?.length == 0" class="text-center">
                                <span class="fs-12">{{staticText.common.no_records_found}}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="p-0 mb-2" *ngIf="pyramidChart">
                        <mat-card-content class="p-1 card-color-pi">
                            <mat-card-subtitle class="mb-1 card-title-pi">Staffing Pyramid </mat-card-subtitle>
                        </mat-card-content>
                        <div>
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="pyramidChart"
                                [oneToOne]="true"
                                style="width: 100%; height: 200px; display: block;"
                                >
                            </highcharts-chart>
                        </div>
                    </mat-card>
                </div>
                <!--People Insights End-->
                <!--Change Requests-->
                <!-- <div *ngIf="item == 'Change Requests'">
                    <mat-card class="pt-0 mt-2 pb-0 pr-2 pl-2" *ngFor="let cr of changeRequest;"  style="border-left: 6px solid #01356d;">
                        <mat-card-content class="pl-0 pr-0">
                                <div class="d-flex justify-content-between w-100" > 
                                    <b class="fs-12 mt-2">{{cr?.change_request_description}}</b>
                                    <b class="fs-12 mt-1 count-span">{{cr?.cr_value}}</b>
                                </div>
                        </mat-card-content>
                    </mat-card>  
                    <div *ngIf="!changeRequest" class="text-center">
                        <span class="fs-12">{{staticText.common.no_records_found}}</span>
                    </div> 
                </div> -->
                <!--Change Requests End-->
                <!--Subscribed OKRs-->
                <div *ngIf="item == 'Subscribed OKRs'">
                    <app-okr-listing [OKRList]="OKRData"></app-okr-listing>
                    <!-- <mat-card class="p-0 mb-2"  *ngFor="let OKR of OKRData;">
                        <mat-card-content class="p-1 card-color mb-0">
                            <mat-card-subtitle class="mb-1 okr-card-title">{{OKR?.goal_name}}</mat-card-subtitle>
                        </mat-card-content>
                        <mat-card-content class="pb-2 pt-1">
                            <div class="d-flex justify-content-between w-100 text-center" *ngIf="OKR?.metrics?.length > 0"> 
                                <div class="w-100">
                                    <span class="fs-12 trend-color-pi">Reporting Month</span>
                                </div>
                                <div class="w-100">
                                    <span class="fs-12 trend-color-pi">Current Value</span>
                                </div>
                                <div class="w-100">
                                    <span class="fs-12 trend-color-pi">Delivered till date</span>
                                </div>
                            </div>
                            <div  class="pl-2 pr-2" *ngFor="let metric of OKR?.metrics;">
                                <div class="d-flex justify-content-start mt-1 mb-1"> 
                                    <b class="fs-12 text-center">{{metric.reporting_month}}</b>
                                </div> 
                                <div class="d-flex justify-content-between w-100 text-center"> 
                                    <div class="w-100">
                                        <b class="fs-12 count-span">{{metric.month_short}}</b>
                                    </div>
                                    <div class="w-100">
                                        <b class="fs-12 count-span">{{metric.project_target}}</b>
                                    </div>
                                    <div class="w-100">
                                        <b class="fs-12 count-span">{{metric.delivered_till_date}}</b>
                                    </div>
                                </div>
                                <hr class="mt-2 mb-2"/>
                            </div>
                            <div *ngIf="!OKR?.metrics.length" class="text-center">
                                <span class="fs-12">No metric found</span>
                            </div>
                        </mat-card-content>
                    </mat-card> -->
                    <div *ngIf="!OKRData?.length" class="text-center">
                        <span class="fs-12">{{staticText.common.no_records_found}}</span>
                    </div> 
                </div>
                <!--Subscribed OKRs End-->
                <!--Applications selected-->
                <div *ngIf="item == 'Applications selected'">
                    <div *ngFor="let app of applicationSelectedData" [ngStyle]="{'background': app.color}"  class="impacted-okr-style">
                        <div class="cursor-pointer" >{{app?.name}}</div>
                    </div>


                    <!-- <mat-card class="pt-0 mt-2 pb-0 pr-2 pl-2" *ngFor="let app of applicationSelectedData;"  style="border-left: 6px solid #01356d;">
                        <mat-card-content class="pl-0 pr-0">
                                <div class="d-flex justify-content-between w-100" > 
                                    <b class="fs-12 mt-1 pt-1 pb-1">{{app?.name}}</b>
                                </div>
                        </mat-card-content>
                    </mat-card>   -->
                    <div *ngIf="applicationSelectedData?.length == 0" class="text-center">
                        <span class="fs-12">{{staticText.common.no_records_found}}</span>
                    </div>
                </div>
                <!--Applications selected End-->
                <div *ngIf="item == 'Risk Radar'">
                    <div class="comingSoon text-center">
                        <img src="assets/images/analytics_bg.png" style="width: 100%; height: calc(100vh - 50vh); object-fit: cover; ">
                        <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
                    </div>
                </div>
        </div>
  
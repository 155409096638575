@Pipe({
  name: 'CheckNextMetric'
})
export class CheckNextMetricPipe {
  transform(childKRs: any, childIndex: number, mosIndex: number, metricIndex: number) {
    let childData = childKRs[childIndex];
    let mIndex = childData?.measure_of_success[mosIndex]['metrics'].findIndex((e: any, index: number) => index > metricIndex);
    if (mIndex == -1) {
      let nextIndex = childData?.measure_of_success.findIndex((e: any, index: number) => index > mosIndex && e.metrics?.length > 0);
      if (nextIndex > -1) {
        return { "isPresent": true, krData: { ...childData, "mos": childData?.measure_of_success[nextIndex], "mindex": nextIndex, "krindex": childIndex, "metricIndex": 0 } };
      } else if (childKRs?.length > (childIndex + 1)) {
        let data = { "isPresent": false, krData: {} };
        for (let i = childIndex + 1; i < childKRs?.length; i++) {
          let foundIndex = childKRs[i]?.measure_of_success.findIndex((e: any) => e.metrics?.length > 0);
          if (foundIndex > -1) {
            data.isPresent = true;
            data.krData = { ...childKRs[i], "mos": childKRs[i]?.measure_of_success[foundIndex], "mindex": foundIndex, "krindex": i, "metricIndex": 0 };
            break;
          }
        }
        return data;
      }
      return { "isPresent": false, krData: {} };
    }
    return { "isPresent": true, krData: { ...childData, "mos": childData?.measure_of_success[mosIndex], "mindex": mosIndex, "krindex": childIndex, "metricIndex": mIndex } };
  }
}
@Pipe({
  name: 'CheckPrevMetric'
})
export class CheckPrevMetricPipe {
  transform(childKRs: any, childIndex: number, mosIndex: number, metricIndex: number) {
    let childData = childKRs[childIndex];
    if (metricIndex == 0) {
      if (mosIndex > 0) {
        let nextIndex = findLastIndex(childKRs[childIndex]?.measure_of_success, (e: any, index: number) => index < mosIndex && e.metrics?.length > 0);
        return { "isPresent": true, krData: { ...childData, "mos": childData?.measure_of_success[nextIndex], "mindex": nextIndex, "krindex": childIndex, "metricIndex": childKRs[childIndex]?.measure_of_success[nextIndex]['metrics']?.length - 1 } };
      }
      if ((childIndex - 1) >= 0) {
        let data = { "isPresent": false, krData: {} };
        for (let i = childIndex - 1; i >= 0; i--) {
          const foundIndex = findLastIndex(childKRs[i]?.measure_of_success, (e: any) => e.metrics?.length > 0);
          if (foundIndex > -1) {
            data.isPresent = true;
            data.krData = { ...childKRs[i], "mos": childKRs[i]?.measure_of_success[foundIndex], "mindex": foundIndex, "krindex": i, "metricIndex": childKRs[i]?.measure_of_success[foundIndex]['metrics']?.length - 1 };
            break;
          }
        }
        return data;
      }
      return { "isPresent": false, krData: {} };
    } else
      return { "isPresent": true, krData: { ...childData, "mos": childData?.measure_of_success[mosIndex], "mindex": mosIndex, "krindex": childIndex, "metricIndex": metricIndex - 1 } };
  }
}

import { Component, OnInit, ViewEncapsulation, ViewChild, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators,FormArray } from '@angular/forms';
import { LodashService } from 'src/app/shared/services/lodash.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';


declare var require: any;
import * as Highcharts from "highcharts";
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
require("highcharts/modules/networkgraph")(Highcharts);
import { Chart } from 'angular-highcharts';
import { cloneDeep, findLastIndex, forEach } from 'lodash';
import { months } from 'moment';
var BreakException = {};
@Component({
  selector: 'app-project-okr-log',
  templateUrl: './project-okr-log.component.html',
  styleUrls: ['./project-okr-log.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [CheckNextMetricPipe, CheckPrevMetricPipe]
})
export class ProjectOkrLogComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar.okr;
  @ViewChild('updateOKR') updateOKRModal: any;
  historyModal: any;
  selectedKR = '';
  treeMapChart1: any;
  okrHeatMapData: any = []
  krForm!: FormGroup;
  activeTab = this.staticText?.delivery.okr_log.setup;

  projectOkrLogs: any[] = [];
  patchCloneData: any[] = [];
  masterOkrLogs: any[] = [];
  masterKRs: any[] = [];
  masterKRsSample: any[] = [];

  masterKRsNew: any[] = [];


  selectedMasterKRs: any[] = [];

  measureofSuccessData: any = [];
  projectId: any;
  showKeyResultPane: boolean = false;
  metricPage = 1;
  krData: any = {};

  okrChildFormArray: any[] = [];
  allKeyResults = [];
  activeChildTab = '';
  selectedKeyResult: any = {};
  selectedKRs: any = [];
  selectedMosData: any = {};
  selectedUpdateChildIndex: number = 0;
  selectedSetupChildIndex: number = 0;
  opportunityID: any = '';
  selectedKRObj: any = {};
  monthList:any = [];
  months:any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  okrHistory: any = [];
  chartOptions: { [key: string]: any } = {};
  chartData: { [key: string]: any } = {};

  singleMasterRec: any;
  okrGoalMetricList:any = [];
  selectedokrGoalMetricList:any = [];
  public context;

  okrArray: any;
  okrArrayHeatMap: any;
  selectedOkr: any;
  goalMetric: any;

  okrMetricCreateForm: FormGroup | any;
  user: any;

  okrMetricGroup: any;
  selectedPeriod: any;
  selectedMetric: any;
  selectedMetricObj:any
  matricMonthForm: FormArray | any;
  constructor(
    private projectService: ProjectService,
    private lodashService: LodashService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private nextMetric: CheckNextMetricPipe,
    private prevMetric: CheckPrevMetricPipe,
    private changeDetectorRef: ChangeDetectorRef) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.getAllMandatoryData();
    
  }

  getAllMandatoryData() {
    this.spinner.show();
    this.getProjectid();
    this.getOkr();
    // this.patchOkr();
    this.createForm();
  }
  getProjectid() {
    this.route.parent?.params.subscribe((res: any) => {
      this.projectId = atob(res.id)
    })
  }
  // patchOkr() {
  //   this.projectService.getprojectdetailsById(this.projectId).subscribe((res: any) => {
  //     this.opportunityID = res?.opportunity?.id;
  //     this.getOkr();
  //     // this.getOkrLogs();      
  //     //this.setHeatMapChart();       
  //   }, error => {
  //     this.spinner.hide();
  //     throw error;
  //   })
  // }

  getOkr() {
    this.spinner.show();
    this.projectService.getProjectKrData(this.projectId).subscribe((getOkr: any) => {
      getOkr.okr_hierarchy.forEach((objective: any) => {
        objective.level = "objective";
        objective.name = objective.title;
        if (objective.key_results.length > 0) {
          objective.children = objective.key_results;
          objective.children.forEach((kr: any) => {
            kr.level = "kr";
            kr.name = kr.title;
            kr.objective_id = objective.id;
            if (kr.goals.length > 0) {
              kr.children = kr.goals;
              kr.children.forEach((goals: any) => {
                goals.level = "goal";
                goals.name = goals.goal_name;
                goals.kr_id = kr.id;
                goals.objective_id = objective.id;

                if (getOkr.selected_goal_ids.findIndex((e: any) => e.goal_id == goals.id) > -1) {
                  goals.selected = true;
                } else {
                  goals.selected = false;
                }
              })

              kr.selected = false;

              if (kr.children.filter((e: any) => e.selected === true).length == kr.children.length) {
                kr.selected = true;
                kr.selection_type = 'all';
              } else if (kr.children.filter((e: any) => e.selected === true).length > 0) {
                kr.selected = true;
                kr.selection_type = 'intermediate';
              } else {
                kr.selected = false;
                kr.selection_type = 'none';
              }
            }
          });

          objective.selected = false;

          if (objective.children.filter((e: any) => e.selection_type === 'all').length == objective.children.length) {
            objective.selected = true;
            objective.selection_type = 'all';
          } else if (objective.children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || objective.children.filter((e: any) => e.selection_type === 'all').length > 0) {
            objective.selected = true;
            objective.selection_type = 'intermediate';
          } else {
            objective.selected = false;
            objective.selection_type = 'none';
          }
        }
      });
      this.okrArray = getOkr.okr_hierarchy;
      this.okrArrayHeatMap = cloneDeep(this.okrArray);
      this.selectedOkr = getOkr.selected_goal_ids;
      this.spinner.hide();
    })
  }

  collapse(item: any) {
    if (item.level == 'objective') {
      this.okrArray.forEach((element: any) => {
        if (element.id != item.id) element.expanded = false;
      });
    } else if (item.level == 'kr') {
      let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);

      if (this.okrArray[krIndex].children.length > 0) {
        this.okrArray[krIndex].children.forEach((element: any) => {
          if (element.id != item.id) element.expanded = false;
        });
      }
    }
  }

  selectGoals(item: any) {
    if (item.level == 'objective') {
      this.okrArray.forEach((obj: any) => {
        if (obj.id == item.id) {
          obj.selected = item.selected;
          obj.selection_type = item.selected ? 'all' : 'none';

          obj.children.forEach((kr: any) => {
            kr.selected = item.selected;
            kr.selection_type = item.selected ? 'all' : 'none';

            kr.children.forEach((goal: any) => {
              goal.selected = item.selected;
              goal.selection_type = item.selected ? 'all' : 'none';
            });
          });
        }
      });
    } else if (item.level == 'kr') {
      let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);

      if (this.okrArray[krIndex].children.length > 0) {
        this.okrArray[krIndex].children.forEach((kr: any) => {
          if (kr.id == item.id) {
            kr.selected = item.selected;
            kr.selection_type = item.selected ? 'all' : 'none';

            kr.children.forEach((goal: any) => {
              goal.selected = item.selected;
              goal.selection_type = item.selected ? 'all' : 'none';
            });
          }
        });

        if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length == this.okrArray[krIndex].children.length) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'all';
        } else if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length > 0) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'intermediate';
        } else {
          this.okrArray[krIndex].selected = false;
          this.okrArray[krIndex].selection_type = 'none';
        }
      }
    } else if (item.level == 'goal') {
      let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);
      let goalIndex = this.okrArray[krIndex].children.findIndex((e: any) => e.id == item.kr_id);

      if (this.okrArray[krIndex].children[goalIndex].children.length > 0) {
        this.okrArray[krIndex].children[goalIndex].children.forEach((goal: any) => {
          if (goal.id == item.id) {
            goal.selected = item.selected;
            goal.selection_type = item.selected ? 'all' : 'none';
          }
        });


        if (this.okrArray[krIndex].children[goalIndex].children.filter((e: any) => e.selected === true).length == this.okrArray[krIndex].children[goalIndex].children.length) {
          this.okrArray[krIndex].children[goalIndex].selected = true;
          this.okrArray[krIndex].children[goalIndex].selection_type = 'all';
        } else if (this.okrArray[krIndex].children[goalIndex].children.filter((e: any) => e.selected === true).length > 0) {
          this.okrArray[krIndex].children[goalIndex].selected = true;
          this.okrArray[krIndex].children[goalIndex].selection_type = 'intermediate';
        } else {
          this.okrArray[krIndex].children[goalIndex].selected = false;
          this.okrArray[krIndex].children[goalIndex].selection_type = 'none';
        }

        if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length == this.okrArray[krIndex].children.length) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'all';
        } else if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length > 0) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'intermediate';
        } else {
          this.okrArray[krIndex].selected = false;
          this.okrArray[krIndex].selection_type = 'none';
        }
      }
    }
  }

  saveSelection() {
    let goalArray: any = [];

    this.okrArray.forEach((objective: any) => {
      objective?.children?.forEach((kr: any) => {
        kr?.children?.forEach((goal: any) => {
          if (goal.selected) {
            goalArray.push(goal.id)
          }
        });

      });
    });

    let postdata = {
      project_id: this.projectId,
      goal_ids: goalArray
    }

    this.spinner.show();
    delete this.okrArrayHeatMap;
    this.projectService.selectProjectGoals(postdata).subscribe((getOkr: any) => {
      this.okrArrayHeatMap = [];
      this.toastrService.success("Goals mapped successfully");
      this.okrArrayHeatMap = cloneDeep(this.okrArray);
      this.changeDetectorRef.detectChanges();
      this.spinner.hide();
    })
  }

  // getOkrLogs() {
  //   this.spinner.show();
  //   this.projectService.getProjectKeyResultsData().subscribe((getOkr:any) => {
  //     this.masterKRsNew = this.sortKeyResults(getOkr['records'],'key_result_title'); 

  //     this.spinner.hide();      


  //   })
  //   this.projectService.getOpportunityKeyResultsData('opportunity_key_results&opportunity_id='+this.opportunityID+'&project_id='+this.projectId).subscribe((getOkr: any) => {

  //     this.masterOkrLogs = [];
  //     this.patchCloneData = this.lodashService.Clonedata(getOkr['records'][0]['field_values']);
  //     this.projectOkrLogs = getOkr['records'][0]['field_values'];
  //     this.filterExistingOkrLogs(this.projectOkrLogs, this.masterOkrLogs);
  //     this.masterOkrLogs = this.projectOkrLogs;
  //     this.allKeyResults = [];
  //     this.okrHeatMapData=[];
  //     this.masterKRs=[];
  //     this.masterKRsSample=[];
  //     this.masterKRsNew = [];
  //     for(let item in this.masterOkrLogs){
  //       this.masterOkrLogs[item]['key_results'] =this.sortKeyResults(this.masterOkrLogs[item]['key_results'],'key_title');
  //       for(let child in this.masterOkrLogs[item]['key_results']){
  //         let arr = this.sortKeyResults(this.masterOkrLogs[item]['key_results'][child]['child_key_results'],'key_title')
  //         arr = arr.map((o:any)=>{
  //           let obj={...o}
  //           obj['frequency'] = o?.frequency?.id;
  //           obj['unit'] = o?.unit?.id;
  //           obj['project_measure_of_success'] = o?.project_measure_of_success; 
  //           obj['hasError'] = !o?.frequency?.id || !o?.unit?.id;         
  //           return obj;
  //         })
  //         this.masterOkrLogs[item]['key_results'][child]['child_key_results'] =arr;
  //         this.allKeyResults = this.allKeyResults.concat(arr);
  //         this.okrHeatMapData.push({ name:this.masterOkrLogs[item]['key_results'][child]?.key_result_title,id:child,value:arr.length })
  //       }  
  //       this.masterKRs = this.masterKRs.concat(this.masterOkrLogs[item]['key_results']);  
  //       this.masterKRsSample = this.masterKRsSample.concat(this.masterOkrLogs[item]['key_results']);                 

  //     }
  //     this.activeChildTab = this.masterKRs?.[0]?.key_result_uuid;
  //     console.log(this.allKeyResults,this.okrHeatMapData,this.masterOkrLogs);
  //     this.getMappedKeyResult();
  //     this.spinner.hide();      
  //   })
  // }

  createForm() {
    // this.krForm = this.formBuilder.group({
    //   actual_value:["",Validators.required],
    //   month:["",Validators.required],
    //   comments:['',Validators.required]
    // });


    this.okrMetricCreateForm = this.formBuilder.array([]);
  }

  getFilteredArray(array: any) {
    return array != undefined ? array.filter((e: any) => e.selected == true) : []
  }

  // filterExistingOkrLogs(keyResultArray: any[], patchArray: any[]) {
  //   if (keyResultArray && patchArray) {
  //     patchArray?.forEach((patchGoalArray: any) => {
  //       patchGoalArray?.key_results?.forEach((patchParentKeyResultArray: any) => {
  //         patchParentKeyResultArray?.child_key_results?.forEach((patchChildKeyResultArray: any) => {
  //           let findGoal = keyResultArray.find((findGoalRes: any) => findGoalRes?.goal_uuid == patchGoalArray?.goal_uuid);
  //           let GoalIndex = keyResultArray.findIndex((categoryIndx: any) => categoryIndx?.goal_uuid == patchGoalArray?.goal_uuid);
  //           if (findGoal) {
  //             let parentKeyIndex = findGoal?.key_results?.findIndex((subIndx: any) => subIndx?.key_result_uuid == patchParentKeyResultArray?.key_result_uuid);
  //             findGoal?.key_results?.forEach((findSubCategory: any) => {
  //               if (findSubCategory.key_result_uuid == patchParentKeyResultArray?.key_result_uuid) {
  //                 let childKeyResultIndex = findSubCategory?.child_key_results?.findIndex((childKeyResultIndx: any) => childKeyResultIndx.key_result_uuid == patchChildKeyResultArray.key_result_uuid);
  //                 findSubCategory?.child_key_results?.splice(childKeyResultIndex, 1);
  //                 if (findSubCategory?.child_key_results?.length == 0 && findGoal?.key_results?.length == 0) {
  //                   keyResultArray?.splice(GoalIndex, 1);
  //                 }
  //                 if (findSubCategory?.child_key_results?.length == 0) {
  //                   findGoal?.key_results.splice(parentKeyIndex, 1);
  //                   if (findGoal?.key_results?.length == 0) {
  //                     keyResultArray?.splice(GoalIndex, 1);
  //                   }
  //                   let parentIndex = findGoal?.key_results?.findIndex((subIndx: any) => subIndx?.key_result_uuid == patchParentKeyResultArray?.key_result_uuid);
  //                   if (parentIndex !== -1) {
  //                     findGoal?.key_results.splice(parentKeyIndex, 1);
  //                   }
  //                 }
  //               } else {
  //                 if (findSubCategory?.child_key_results?.length == 0 && findGoal?.key_results?.length == 0) {
  //                   keyResultArray?.splice(GoalIndex, 1);
  //                 }
  //               }
  //             });
  //           }

  //         })
  //       })
  //     })
  //   }
  //   return keyResultArray;
  // }
  // sortKeyResults(arr:any=[],key:any){
  //   arr.sort((a:any, b:any)=> {
  //     if(a[key] === b[key]){
  //       return 0;
  //     }else if (a[key] === null) {
  //       return 1;
  //     }else if (b[key] === null) {
  //       return -1;
  //     }else if (a[key] < b[key]) {
  //       return -1;
  //     }else{
  //       return 1;
  //     }
  //   });
  //   return arr;
  // }
  // openMeasureofSuccessModal(mos: any,  modal: any) {
  //   this.selectedMosData = mos;
  //   this.dialog.open(modal, {panelClass:"mw-650"});
  // }
  // changeTab(evt:any){
  //   this.activeTab = evt.tab?.textLabel;
  //   if(this.activeTab == this.staticText?.delivery.okr_log.progress_update){
  //     this.spinner.show();
  //     this.selectedKRs =[];
  //     this.projectService.getMappedProjectKeyresults(this.projectId).subscribe((res: any) => {
  //       this.spinner.hide();
  //       this.selectedKRs = res;
  //       this.activeChildTab = this.selectedKRs?.[this.selectedUpdateChildIndex]?.key_result?.uuid;
  //     })
  //   }else{
  //     this.activeChildTab = this.masterKRs?.[this.selectedSetupChildIndex]?.key_result_uuid;
  //   }
  // }
  // changeChildTab(index:number,isSetup:boolean){
  //   if(isSetup){
  //     this.activeChildTab = this.masterKRs[index]?.key_result_uuid;
  //     this.selectedSetupChildIndex = index;
  //   }else{
  //     this.activeChildTab = this.selectedKRs[index]?.key_result?.uuid;
  //     this.selectedUpdateChildIndex = index;
  //   }
  // }
  // onClickMoS(item:any, mos:any,mindex:number,krindex:number,modal?:any){
  //   this.showKeyResultPane = true;
  //   this.metricPage=1;
  //   this.krData = {...item,"mos":mos,"mindex":mindex,"krindex":krindex,"metricIndex":0};
  //   this.krForm.patchValue({
  //     'actual_value': mos?.metrics?.[0]?.metric_actual?.actual_value,
  //     'month': mos?.metrics?.[0]?.metric_actual?.month,
  //     'comments': mos?.metrics?.[0]?.metric_actual?.comments,
  //   })
  //   if(modal){
  //     this.selectedMosData = mos;
  //     this.dialog.open(modal, {panelClass:"mw-650"}); 
  //   }
  // }
  // openMeasureofSuccessModalEdit(mos: any,  modal: any) {
  //   this.selectedMosData = mos;
  //   this.dialog.open(modal, {panelClass:"mw-650"});
  // }

 

  onClickGoal(obj: any, kr: any, goal: any, modal: any) {
    this.selectedMetricObj ="";
    this.selectedPeriod = "";
    this.selectedMetric = "";
    if (this.user?.is_vendor) return;
    // this.okrMetricGroup = [];
    // for(let i = 1; i < 13; i++){
    //   this.okrMetricGroup.push({
    //     groupDate: new Date().getFullYear() + '-' + ((""+i).length < 2 ? ("0" + i) : i) + "-01" ,
    //     group: []
    //   })
    // }
    
    this.goalMetric = {
      objective: obj.name,
      kr: kr.name,
      goal: goal.name,
      goal_id: goal.id,
      matrics:goal.instancemetrics
    }

    
     this.spinner.show();
    this.okrMetricCreateForm.clear()
    this.projectService.getGoalMetrics(this.projectId, goal.id).subscribe((res: any) => {
      this.okrGoalMetricList = res;
      this.spinner.hide();
      

      // this.selectedPeriod = "";
      // if (res.length > 0) {
      //   res.forEach((item: any) => {
      //     item.date = item.reporting_year + '-' + (("" + item.reporting_month).length < 2 ? "0" + item.reporting_month : item.reporting_month) + '-01'
      //     item.convertedDate = new Date(item.reporting_year + '-' + ((item.reporting_month).length < 2 ? "0" + item.reporting_month : item.reporting_month) + '-01')

      //     let indx = this.okrMetricGroup.findIndex((e: any) => e.groupDate == item.date);

      //     if (indx > -1) {
      //       this.okrMetricGroup[indx].group.push(item)
      //     } else {
      //       this.okrMetricGroup.push({
      //         groupDate: item.date,
      //         group: [item]
      //       })
      //     }

        });
    //  }
      
    //})
    this.dialog.open(modal, {
      width: '40vw',
    });
  }

  
  editColum(i:any){
    this.monthList[i].edit = true;
  }

  selectedMatricEvent(e:any){
    this.selectedMetricObj = this.goalMetric?.matrics.filter((item: any) => item.id == e.value)[0];
    this.selectedokrGoalMetricList = this.okrGoalMetricList.filter((item:any) => item.id == e.value)[0];
    this.loadMonthDetails();
  }

  selectedMatricYear(e:any){
    this.loadMonthDetails();
  }

  loadMonthDetails(){
    if(this.selectedPeriod){
      this.monthList = [];
      let monthObj:any = [];
    this.months.forEach((m:any,index:any)=>{
      monthObj.push({month:m,month_id:index+1,value:0,edit:false})
    })
    this.monthList = monthObj;
      let data:any = [];
      let metricYearDetails = this.selectedokrGoalMetricList?.months_data?.filter((item:any)=> item.reporting_year == this.selectedPeriod)[0];
      metricYearDetails.details.forEach((val:any)=>{
        this.monthList.forEach((m:any)=>{
          if(val.reporting_month == m.month_id){
            m.value = val?.current_value
          }
        })
      });
    }
  }

  matricTarVal(){
    // this.monthList[i].edit = false;
    let payload:any=[];
  this.monthList.forEach((m:any,index:any)=>{
    payload.push({
        id: this.selectedMetricObj?.id,
        reporting_month: m.month_id,
        reporting_year: this.selectedPeriod,
        target_value: this.selectedMetricObj?.target_value,
        mos_name: this.selectedMetricObj?.instancemetrics__name,
        mos: this.selectedMetricObj?.instancemetrics,
        current_value: m.value,
        comments: '',
      })
    })
    this.saveMetric(payload);
  }
  getData() {
    let dt = this.okrMetricGroup.filter((e: any) => e.groupDate == this.selectedPeriod)[0];
    this.okrMetricCreateForm.clear();

    if(dt.group.length > 0){
      dt.group.forEach((element: any) => {
        this.okrMetricCreateForm.push(
          this.formBuilder.group(
            {
              id: [element.id ? element.id : ''],
              reporting_month: [element.reporting_month],
              reporting_year: [element.reporting_year],
              target_value: [element.target_value],
              mos_name: [element.mos_name],
              mos: [element.mos],
              current_value: [element.current_value],
              comments: [element.comments]
            })
        )
      });
    }else{
      let indx = this.okrMetricGroup.findIndex((e: any) => e.groupDate == new Date().getFullYear() + '-' + ((""+ (new Date().getMonth() + 1)).length < 2 ? ("0" + (new Date().getMonth() + 1)) : new Date().getMonth() + 1) + '-01' )
      let month = this.selectedPeriod.substring(5,7);
      let year = this.selectedPeriod.substring(0,4)
      this.okrMetricGroup[indx].group.forEach((element: any) => {
        this.okrMetricCreateForm.push(
          this.formBuilder.group({
            id: [''],
            reporting_month: [month],
            reporting_year: [year],
            target_value: [element.target_value],
            mos_name: [element.mos_name],
            mos: [element.mos],
            current_value: [''],
            comments: ['']
          }) 
        )
      });
    }

    
  }

  addMetricItems() {
    this.okrMetricCreateForm.push(
      this.formBuilder.group(
        {
          metric: [""],
          project_target: [""],
          reporting_month: [""],
          comments: [""],
          value: [""],
          delivered_till_date: [""]
        })
    )
  }

  removeMetric(index: any) {
    this.okrMetricCreateForm.removeAt(index);
  }

  saveMetric(matric:any) {
    let postdata = {
      project_id: this.projectId,
      goal_id: this.goalMetric.goal_id,
      goalmetrics: matric
    }
    this.spinner.show();
    this.projectService.setGoalMetrics(postdata).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success('OKR updated successfully')
    })

  }

  getControlByName(groupIndex: number, controlName: string): FormControl {
    const fg = this.okrMetricCreateForm.at(groupIndex) as FormGroup;
    return fg.get(controlName) as FormControl;
  }


  // onClickChild(isNext:boolean){    
  //   let data:any ={}
  //   if(isNext)
  //     data = this.nextMetric.transform(this.selectedKRs[this.selectedUpdateChildIndex]?.key_result?.child_key_results, this.krData?.krindex,this.krData?.mindex,this.krData?.metricIndex);
  //   else
  //     data = this.prevMetric.transform(this.selectedKRs[this.selectedUpdateChildIndex]?.key_result?.child_key_results, this.krData?.krindex,this.krData?.mindex,this.krData?.metricIndex);
  //   this.showKeyResultPane = true;
  //   this.metricPage = parseFloat(data?.krData?.metricIndex)+1;
  //   this.krData = data?.krData;
  //   this.krForm.patchValue({
  //     'actual_value': this.krData?.mos?.metrics?.[data?.krData?.metricIndex]?.metric_actual?.actual_value,
  //     'month': this.krData?.mos?.metrics?.[data?.krData?.metricIndex]?.metric_actual?.month,
  //     'comments': this.krData?.mos?.metrics?.[data?.krData?.metricIndex]?.metric_actual?.commends,
  //   });    
  // }




  // showOKRModal(evt:any){
  //   console.log(evt.point.index);
  //   let data = this.okrHeatMapData.find((o:any)=>o.id ==  evt.point.index);
  //   console.log(data);

  //   this.dialog.open(this.updateOKRModal,{panelClass:"mw-650"});
  // }  
  // openModal(kr:any,child_kr:any,mos:any,isChartClicked:any,modal:any){
  //   this.selectedKRObj = {...mos, objective:kr?.title, key_result:child_kr?.title,isChartClicked};
  //   this.spinner.show();
  //   this.projectService.getKeyresultsHistory(this.projectId, mos?.id).subscribe((res)=>{
  //     this.spinner.hide();
  //     if(res){
  //       this.okrHistory = res;
  //       let months = [
  //         "Jan",
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep",
  //         "Oct",
  //         "Nov",
  //         "Dec"
  //       ];

  //       for(let i=0;i<this.okrHistory.length;i++){
  //         let year = this.okrHistory[i]?.[0]?.year;
  //         let metricName = this.okrHistory[i]?.[0]?.metric_details?.name;
  //         for(let ind in months){
  //           let index = this.okrHistory[i].findIndex((e:any)=>e.month == months[ind]);
  //           if(index == -1){
  //             this.okrHistory[i].push({month:months[ind],actual_value:null,year:year,metricName:metricName});
  //           }
  //         }
  //         this.okrHistory[i] = this.okrHistory[i].sort((a:any, b:any) =>{
  //           return months.indexOf(a.month) - months.indexOf(b.month);
  //         });
  //         let categories = this.okrHistory[i]?.map((e:any)=>e?.month +"-"+ e?.year);
  //         let data:any =[];
  //         for(let j=0;j<this.okrHistory[i]?.length;j++){
  //           data.push([parseFloat(this.okrHistory[i][j]?.actual_value)]);
  //         }
  //         this.chartOptions[i] = {
  //           colors: ['#232459'],
  //           chart: {
  //             type: "column",
  //             height:300,
  //             minWidth:300,
  //             width:400,
  //           },
  //           title: {
  //             text: this.okrHistory[i]?.metric_details?.name
  //           },
  //           xAxis: {
  //             categories: categories,
  //             title: {
  //               text: null
  //             },              
  //             minPadding: 0,
  //             maxPadding: 0
  //           },
  //           yAxis: {
  //             min: 0,
  //             title: {
  //               text: ""
  //             },
  //             labels: {
  //               overflow: "justify"
  //             }
  //           },
  //           plotOptions: {
  //             bar: {
  //               dataLabels: {
  //                 enabled: true
  //               },
  //               groupPadding:0
  //             }
  //           },
  //           legend: {
  //             layout: "vertical",
  //             align: "right",
  //             verticalAlign: "top",
  //             floating: true,
  //             borderWidth: 1,
  //             backgroundColor:
  //                "#FFFFFF",
  //             shadow: true
  //           },
  //           credits: {
  //             enabled: false
  //           },
  //           series: [
  //             {
  //               name: 'Actual Value',
  //               data: data,                
  //               pointWidth: 25
  //             },
  //           ]
  //         };          
  //         this.chartData['barChartData'+i]= new Chart(this.chartOptions[i])
  //       }
  //       this.historyModal = this.dialog.open(modal,{panelClass:"mw-650"})
  //     }else{
  //       this.toastrService.error("Unable to fetch data...please try again later...") 
  //     }
  //   },err=>{
  //     this.toastrService.error("No Data found!") 
  //   })
  // }
  closeDialog() {
    this.dialog.closeAll();
  }
  // mapKeyResults(krindex:number){

  //   let selectedKRs:any = this.masterKRs[krindex]['child_key_results'].filter((e:any)=> e.is_linked_key_result);
  //   let selectedKRIds = selectedKRs.map((e:any)=>e.id);

  //   let payload={
  //     "project": this.projectId,
  //     "key_result": this.masterKRs[krindex]?.id,
  //     "child_key_result":selectedKRIds
  //   }



  //   this.spinner.show();
  //   this.projectService.mapProjectKeyresults(payload).subscribe((res: any) => {
  //     this.spinner.hide();
  //     if(res){
  //       this.toastrService.success("Data saved successfully");
  //     }
  //   })
  // }

  //   setHeatMapChart() {
  //     let call = this;
  //     let chartOptions1: any = {
  //       title: {
  //         text: ''
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //       series: [{
  //         type: "treemap",
  //         levels: [
  //           {
  //             level: 1,
  //             dataLabels: {
  //               enabled: true,
  //               useHTML: true,
  //               inside: true,
  //             }
  //           }
  //         ],
  //         events: {
  //           click(e: any) {
  //             call.showOKRModal(e)
  //           }
  //         },
  //         layoutAlgorithm: 'squarified',
  //         data: [...this.okrHeatMapData]
  //       }]
  //     };
  //     chartOptions1.series[0].levels[0].dataLabels.formatter = function () {
  //       return "<p class='pl-1'>" + this.point.name + "</p><p class='pl-1 point-values'><span>" + this.point.value + "</span> " + (this.point.value > 1 ? ' Key Results' : '  Key Result') + "</p>"
  //     }
  //     this.treeMapChart1 = new Chart(chartOptions1);

  //   }
  //   updateKeyResultMoS(metric:any){    
  //     let payload:any={
  //       "metric": metric?.id,
  //       "actual_value" : this.krForm.value.actual_value,
  //       "month": this.krForm.value.month,
  //       "comments": this.krForm.value.comments,
  //       "project": Number(this.projectId),
  //       "is_active": true,
  //       "key_result_mos": this.krData?.mos?.id
  //     };
  //     this.spinner.show();
  //     this.projectService.updateMetricsValue(payload).subscribe((res: any) => {
  //       this.spinner.hide();
  //       if(res){
  //         this.toastrService.success("Metric updated successfully!")
  //         let metricIndex = this.metricPage-1;
  //         this.selectedKRs[this.selectedUpdateChildIndex]['key_result']['child_key_results'][this.krData?.krindex]['measure_of_success'][this.krData?.mindex]['metrics'][metricIndex]['metric_actual']['actual_value'] = this.krForm.value.actual_value;
  //         this.selectedKRs[this.selectedUpdateChildIndex]['key_result']['child_key_results'][this.krData?.krindex]['measure_of_success'][this.krData?.mindex]['metrics'][metricIndex]['metric_actual']['month'] = this.krForm.value.month;
  //         this.selectedKRs[this.selectedUpdateChildIndex]['key_result']['child_key_results'][this.krData?.krindex]['measure_of_success'][this.krData?.mindex]['metrics'][metricIndex]['metric_actual']['commends'] = this.krForm.value.comments;
  //         this.krData['mos']['metrics'][metricIndex]['metric_actual']['actual_value'] = this.krForm.value.actual_value;
  //         this.krData['mos']['metrics'][metricIndex]['metric_actual']['month'] = this.krForm.value.month;
  //         this.krData['mos']['metrics'][metricIndex]['metric_actual']['comments'] = this.krForm.value.comments;
  //         if(this.metricPage+1 <= this.krData?.mos?.metrics?.length ){
  //           setTimeout(() => {
  //             this.metricPage+=1;
  //             this.krForm.patchValue({
  //               'actual_value': this.krData.mos?.metrics?.[this.metricPage-1]?.metric_actual?.actual_value,
  //               'month': this.krData.mos?.metrics?.[this.metricPage-1]?.metric_actual?.month,
  //               'comments': this.krData.mos?.metrics?.[this.metricPage-1]?.metric_actual?.commends,
  //             });            
  //           }, 0);
  //         }
  //       }
  //     })
  //     this.dialog.closeAll();
  //   }
  //   onChangePage(evt:any){
  //     this.metricPage = evt; 
  //     this.krForm.patchValue({
  //       'actual_value': this.krData.mos?.metrics?.[this.metricPage-1]?.metric_actual?.actual_value,
  //       'month': this.krData.mos?.metrics?.[this.metricPage-1]?.metric_actual?.month,
  //       'comments': this.krData.mos?.metrics?.[this.metricPage-1]?.metric_actual?.commends,
  //     });
  //   }
  //   onKeyResultAdded(evt:any){
  //    let payload={
  //     "project": this.projectId,
  //     "key_result": evt.treeArray.id,
  //     "child_key_result":[evt.subchild.id]
  //   }
  //   this.singleMasterRec = payload;
  //   this.spinner.show();
  //     this.projectService.mapProjectKeyresults(payload).subscribe((res: any) => {
  //       this.spinner.hide();
  //       if(res){
  //         this.toastrService.success("Data saved successfully");
  //         this.getMappedKeyResult();
  //       }
  //     })

  //   }

  // getMappedKeyResult(){
  //   this.spinner.show();
  //   this.selectedKRs =[];
  //   this.projectService.getMappedProjectKeyresults(this.projectId).subscribe((res: any) => {
  //     this.spinner.hide();
  //     this.selectedKRs = res;
  //     this.markMappedKrs();


  //   })
  // }

  // markMappedKrs(){
  //   if(this.singleMasterRec){
  //     let keyResultIndx = this.masterKRs.findIndex((e: any) => e.id === this.singleMasterRec.key_result);
  //     let childKeyResultIndex = this.masterKRs[this.masterKRs.findIndex((e: any) => e.id === this.singleMasterRec.key_result)].child_key_results.findIndex((e1: any) => e1.id === this.singleMasterRec.child_key_result[0])
  //     this.masterKRs[keyResultIndx].child_key_results[childKeyResultIndex].is_linked_key_result = true;
  //     delete this.singleMasterRec;
  //   }

  //   this.masterKRs.forEach((element:any) => {
  //     element?.child_key_results?.forEach((elementone:any) => {
  //       //elementone.isMapped = this.checkIfKRIsMapped(elementone?.id)

  //       // let val=this.getMetricActual(elementone?.id);
  //       // if(val){
  //       //   element.child_key_results = val;
  //       //   element.child_key_results.forEach((elementtwo:any) => {
  //       //     elementtwo.isMapped = this.checkIfKRIsMapped(elementtwo?.id)

  //       //   });

  //       // }
  //        // elementone.isMapped = this.checkIfKRIsMapped(elementone?.id)



  //     });
  //   });



  //   let krString = JSON.stringify(this.masterKRs);

  //   this.masterKRsSample = JSON.parse(krString);
  //   console.log("-----------------------------------------------------");

  //   console.log(this.masterKRsSample);
  //   console.log("-----------------------------------------------------");

  // }

  // checkIfKRIsMapped(id:any){
  //   let isMapped=false;
  //   this.selectedKRs.forEach((element:any) => {
  //    if(element?.child_key_result.indexOf(id)>-1){
  //     isMapped= true;
  //    }
  // });
  //  return isMapped;
  // }
  // getMetricActual(id:any):any{
  //   let returnValue:any;
  //   this.selectedKRs.forEach((element:any) => {
  //    if(element?.child_key_result.indexOf(id)>-1){
  //     returnValue =element?.key_result?.child_key_results
  //    }
  // });
  // return returnValue;
  // }

  getTrucatedName(name: string) {
    if (!name) {
      return "NA";
    } else {
      if (name.length > 50) {
        return name.substring(0, 49) + "..."
      }
      else {
        return name;
      }

    }
  }

  // deleteProjectOkr(data: any){
  //  this.spinner.show();
  //   this.projectService.deleteProjectOkr(this.projectId, data.key_result_id, data.child_key_result_id).subscribe((res: any) => {
  //     this.spinner.hide();
  //     if(res){  
  //       let keyResultIndx = this.masterKRs.findIndex((e: any) => e.id === data.key_result_id);
  //       let childKeyResultIndex = this.masterKRs[this.masterKRs.findIndex((e: any) => e.id === data.key_result_id)].child_key_results.findIndex((e1: any) => e1.id === data.child_key_result_id)
  //       this.masterKRs[keyResultIndx].child_key_results[childKeyResultIndex].is_linked_key_result = false;
  //       this.getMappedKeyResult();
  //     }
  //   })
  // }
}

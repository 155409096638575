import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DeliveryFunctionalityRoutingModule } from "./delivery-functionality-routing.module";
import { IndexComponent } from "./index/index.component";
import { LeftBarComponent } from "./left-bar/left-bar.component";
import { RightBarComponent } from "./right-bar/right-bar.component";
import { SharedModule } from "src/app/shared/shared.module";
import { DeliveryoverviewComponent } from "./deliveryoverview/deliveryoverview.component";
import { DeliveryResourcesComponent } from "./delivery-resources/delivery-resources.component";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { ScorecardComponent } from "./scorecard/scorecard.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { DataCredientialsComponent } from "./data-credientials/data-credientials.component";
import { AddcredientialsComponent } from "./data-credientials/addcredientials/addcredientials.component";
import { MatMenuModule } from "@angular/material/menu";
import { MaterialmodulesModule } from "src/app/shared/materialmodules.module";
import { MatCardModule } from "@angular/material/card";
import { NgCircleProgressModule } from "ng-circle-progress";
import { PipeModule } from "src/app/shared/pipes/pipe.module";
import {
  CheckNextMetricPipe,
  CheckPrevMetricPipe,
  ProjectOkrLogComponent,
} from "./project-okr-log/project-okr-log.component";
import { CommentBoxOkrComponent } from "./project-okr-log/comment-box-okr/comment-box-okr.component";
import { RiskLogComponent } from "./risk-log/risk-log.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProjectReleaseManagementComponent } from "./project-release-management/project-release-management.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { ChangeRequestComponent } from "./change-request/change-request.component";
import { MatRadioModule } from "@angular/material/radio";
import { ChartModule } from "angular-highcharts";
import { HighchartsChartModule } from "highcharts-angular";
import { ProjectTrainingComponent } from "./project-training/project-training.component";
import {
  CheckNextToEPipe,
  CheckPrevToEPipe,
  ToeMilestonesComponent,
} from "./toe-milestones/toe-milestones.component";
import { ApplicationMasterComponent } from "./application-master/application-master.component";
import { ProjectOpenRequirmentComponent } from "./open-requirment/open-requirment.component";
import { ViewPageHeaderComponent } from "./view-page-header/view-page-header.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SpendTrackerComponent } from "./spend-tracker/spend-tracker.component";
import { FinancialsComponent } from "./financials/financials.component";
import { DeliveryScopeComponent } from "./deliveryscope/deliveryscope.component";
import { DeliveryToeComponent } from "./delivery-toe/delivery-toe.component";
import { BudgetComponent } from "./financials/budget/budget.component";
import { SpendComponent } from "./financials/spend/spend.component";
import { InsightsComponent } from "./financials/insights/insights.component";
import { EditBudgetComponent } from "./financials/budget/edit-budget/edit-budget.component";
import { EditSpendComponent } from "./financials/spend/edit-spend/edit-spend.component";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { EditOpenReqComponent } from "./open-requirment/edit-open-req/edit-open-req.component";
import { AssessmentAuditComponent } from './assessment-audit/assessment-audit.component';
import { AssessmentsComponent } from './assessment-audit/assessments/assessments.component';
import { AuditsComponent } from './assessment-audit/audits/audits.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PulsesDialogComponent } from './pulses-dialog/pulses-dialog.component';


@NgModule({
  declarations: [
    IndexComponent,
    LeftBarComponent,
    RightBarComponent,
    DeliveryoverviewComponent,
    DeliveryResourcesComponent,
    ScorecardComponent,
    DataCredientialsComponent,
    AddcredientialsComponent,
    ProjectOkrLogComponent,
    CommentBoxOkrComponent,
    RiskLogComponent,
    ProjectReleaseManagementComponent,
    ApplicationMasterComponent,
    ProjectOpenRequirmentComponent,
    ChangeRequestComponent,
    ProjectTrainingComponent,
    ToeMilestonesComponent,
    CheckNextMetricPipe,
    CheckPrevMetricPipe,
    CheckNextToEPipe,
    CheckPrevToEPipe,
    ViewPageHeaderComponent,
    SpendTrackerComponent,
    FinancialsComponent,
    DeliveryScopeComponent,
    DeliveryToeComponent,
    BudgetComponent,
    SpendComponent,
    InsightsComponent,
    EditBudgetComponent,
    EditSpendComponent,
    EditOpenReqComponent,
    AssessmentAuditComponent,
    AssessmentsComponent,
    AuditsComponent,
    PulsesDialogComponent,
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatChipsModule,
    SharedModule,
    DeliveryFunctionalityRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatProgressBarModule,
    MatMenuModule,
    MaterialmodulesModule,
    MatCardModule,
    MatIconModule,
    NgxPermissionsModule,
    NgbModule,
    PipeModule.forRoot(),
    MatRadioModule,
    ChartModule,
    HighchartsChartModule,
    DragDropModule,
    PowerBIEmbedModule,
    NgCircleProgressModule.forRoot(/*{
      "radius": 60,
      "space": -10,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 10,
      "outerStrokeColor": "#4882c2",
      "outerStrokeGradientStopColor": "#53a9ff",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 10,
      "title": "UI",
      "animateTitle": false,
      "animationDuration": 1000,
      "showUnits": false,
      "showBackground": false,
      "clockwise": false,
      "startFromZero": false,
      "lazy": true
    }*/),
  ],
})
export class DeliveryFunctionalityModule {}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Chart } from 'angular-highcharts';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  projectId:any;
  cards:any;
  iframeEmbedded: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) { }

  //accordionItems = ['People Insights','Change Requests','Subscribed OKRs','Risk Radar','Applications selected'];
  accordionItems = ['Subscribed OKRs','Risk Radar','Applications selected'];
  ngOnInit(): void {
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != 'object' ? true : false;
    })

    localStorage.removeItem('rightbar')
    this.getprojectId();

   
  }
  getprojectId() {
    this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.id);
     });
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { WorkForceService } from 'src/app/shared/services/work-force.service';
import { MatDialog } from '@angular/material/dialog';
import { FilterService } from 'src/app/shared/services/filter.service';

@Component({
  selector: 'app-application-master',
  templateUrl: './application-master.component.html',
  styleUrls: ['./application-master.component.scss'],
  encapsulation: ViewEncapsulation.Emulated


})
export class ApplicationMasterComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar.applications;
  projectId: any
  CurrentPage: any = 0;
  EndValue=10;
  startValue:any= 0;
  activeTab = 0;
  applicationMasterList:any;
  projectApplicationMasterList:any;
  sortRecords: any = 10;
  selectedRecords:any[]=[];
  applicationFilter = this.fb.group({
    name: [''],
    tier:['']
  });
  itemsPerPage: any;
  filterObj:any;
  filterString:any='';
  firstTime:any = true;
  user: any;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private workForceService:WorkForceService,
    public fb: FormBuilder,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    // this.SortData();
    this.getprojectId();

    this.filterService.filterSubject.subscribe((res: any) => {
      this.CurrentPage=0;
      this.EndValue=20;
      this.startValue== 0;

      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element].array.length > 0){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        this.getList();
      }else{
        this.getList();
      }
    })


  //   this.filterService.filterSubject.subscribe((res: any) => {
  //     this.filterObj = res;
  //     if(this.filterObj?.applicationTier || this.filterObj?.applicationBusinessArea || this.filterObj?.applicationBusinessOwner || this.filterObj?.applicationSupportGroup){
  //       this.filterString =`tier=${this.filterObj?.applicationTier}&business_area=${this.filterObj?.applicationBusinessArea}&business_owner=${this.filterObj?.applicationBusinessOwner}&support_group=${this.filterObj?.applicationSupportGroup}`; 
  //     }else{
  //       this.filterString = '';
  //     }
  //     if(this.projectId){
  //       this.CurrentPage=0;
  //       this.EndValue=10;
  //       this.startValue== 0;
  //       this.getApplicationMAster();
  //       this.getProjectApplicationMAster();
  //     }
  // })
   
  }

    getprojectId() {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectId = atob(params.id);
    });
  }

  getList(){
    this.getProjectApplicationMAster();
    this.getApplicationMAster();
  }


  getProjectApplicationMAster(isFromSearch?:any){
    this.spinner.show();
    this.projectApplicationMasterList = [];
    let payload = this.getPayload();
    this.workForceService.getProjectApplicationMasterDataNew(this.projectId, this.startValue, this.EndValue, payload).subscribe((workForceTableList: any) => {  
      this.projectApplicationMasterList = workForceTableList;
        this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
    })
  }

  getApplicationMAster(){
    this.spinner.show();
    this.applicationMasterList=[]
    let payload = this.getPayload();
    this.workForceService.getApplicationMasterDataNew(this.projectId, this.startValue, this.EndValue, payload).subscribe((workForceTableList: any) => {  
        this.applicationMasterList = workForceTableList;
        this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
    })
  }

  getPayload(){
    let payload: any = {}
    if(this.filterObj?.applicationTier?.array?.length > 0){
      let tier: any =  [];
      this.filterObj?.applicationTier.array.forEach((element: any) => {
        tier.push(element.id)
      });
      payload = {
        tier: tier
      }
    }
    else if(this.filterObj?.applicationSupportGroup?.array?.length > 0){
      let support_group: any =  [];
      this.filterObj?.applicationSupportGroup.array.forEach((element: any) => {
        support_group.push(element.id)
      });
      payload = {
        support_group: support_group
      }
    }
    else if(this.filterObj?.applicationBusinessOwner?.array?.length > 0){
      let business_owner: any =  [];
      this.filterObj?.applicationBusinessOwner.array.forEach((element: any) => {
        business_owner.push(element.id)
      });
      payload = {
        business_owner: business_owner
      }
    }else if(this.filterObj?.applicationBusinessArea?.array?.length > 0){
      let business_area: any =  [];
      this.filterObj?.applicationBusinessArea.array.forEach((element: any) => {
        business_area.push(element.id)
      });
      payload = {
        business_area: business_area
      }
    }
    return payload
  }

  changeTab(event:any){
    this.activeTab = event.index;
    this.CurrentPage=0;
    this.EndValue=10;
    this.startValue= 0;
     this.clearForm();
     if(this.activeTab == 0 && this.projectApplicationMasterList?.data?.length == 0){
      this.getProjectApplicationMAster();
    }else if(this.activeTab == 1 && this.applicationMasterList?.data?.length == 0){
      this.getApplicationMAster();
    }
  }

  clearForm(){
    this.applicationFilter.patchValue({name:"",tier:""})
    this.applicationFilter.updateValueAndValidity(); 
  }
  


  onChekedChange(event:any,tableRecords:any){
    if(event.target.checked){
      this.selectedRecords.push(tableRecords?.id);
    }
    else{
      const index = this.selectedRecords.indexOf(tableRecords?.id, 0);
    if (index > -1) {
      this.selectedRecords.splice(index, 1);
    }
  }

  }


  mapToProject(){
   let payload = { "applicationmaster":this.selectedRecords}
   this.workForceService.updateProjectApplicationMaster(this.projectId, payload).subscribe((workForceTableList: any) => {  
      this.toastr.success("Mapped Successfully.")
    }, (error: any) => {
      this.spinner.hide();
    })
  }
  
  getRecords(tab:any) {
    if (this.CurrentPage > 0) {
      this.startValue = (parseInt(this.CurrentPage) - 1) * (parseInt(this.sortRecords));
    } else {
      this.startValue = 0
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if(tab == 1){
       this.getApplicationMAster();
    }else if(tab == 0){
      if(this.firstTime == false){
       this.getProjectApplicationMAster();
      }
    }
    this.firstTime = false;
  }
   isChecked(tableRecords?:any){
    const index = this.selectedRecords.indexOf(tableRecords?.id, 0);
    if (index > -1) {
      return true
    }else{
      return false;
    }
  }
}

<div id="overlay" class="overlay d-none1 addform">
    <div class="right-modal w-25 p-0 ">
        <div class="col-md-12 p-3 pb-0 d-flex justify-content-between" (click)="closeaddform($event)">
            <h5 class="text-brick ml-2 fw-bold">{{title}}</h5>

            <button type="button" class="close float-right px-3 pb-3" aria-label="Close" (click)="closeaddform($event)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <hr class="mt-0">
        <form class="col-md-10 ml-3" [formGroup]="credientialsForm" (ngSubmit)="doFormSubmit($event)">

            <div class="form-group row">
                <div class="col-md-8">
                    <input type="hidden" class="form-control" id="id" name="id" formControlName="id">
                </div>
            </div>
            <div class="form-group row">
                <label class="p-0 fw-bold ">{{staticText?.delivery?.data_credentials.src_type}}</label>
                <select class="form-control form-field" formControlName="source_type">
                    <option value="0" disabled>{{staticText?.delivery?.delivery_metrics.select_option}} </option>
                    <option *ngFor="let source of sourceType" value="{{source.id}}">
                        {{source.name}}
                    </option>
                </select>
            </div>
            <div class="form-group row">
                <label for="input3" class="p-0 fw-bold ">{{staticText?.delivery?.data_credentials.src_name}}</label>
                <input type="text" class="form-control" id="title" name="title" formControlName="source_name"
                    placeholder="Enter the Name">
            </div>
            <div class="form-group row">
                <label for="input3" class="p-0 fw-bold ">{{staticText?.delivery?.data_credentials.src_url}}</label>
                <input type="text" class="form-control" id="title" name="title" formControlName="source_url"
                    placeholder="Enter the API URL">
            </div>
            <div class="form-group row">
                <label for="input3" class="p-0 fw-bold ">{{staticText?.auth?.username}}</label>
                <input type="text" class="form-control" id="title" name="title" formControlName="username"
                    placeholder="Enter the Name">
            </div>
            <div class="form-group row">
                <label for="input3" class="p-0 fw-bold ">{{staticText?.auth?.password}}</label>
                <div class="p-0" (click)="onClick()">
                    <input [type]="password" class="form-control position-absolute" id="title" name="title"
                        formControlName="password" placeholder="Enter the password">
                    <span class="far fa-eye form-control-feedback show-password position-relative" *ngIf="!show"></span>
                    <span class="fas fa-eye-slash form-control-feedback show-password position-relative"
                        *ngIf="show"></span>
                </div>
            </div>
            <div class="form-group row">
                <label for="input3" class="p-0 fw-bold ">{{staticText?.delivery?.data_credentials.api_token}}</label>
                <input type="text" class="form-control" id="title" name="title" formControlName="api_token"
                    placeholder="Enter the type...">
            </div>
            <div class="form-group row">
                <label for="input3" class="p-0 fw-bold ">{{staticText?.delivery?.data_credentials.data_type}}</label>
                <input type="text" class="form-control" id="title" name="title" formControlName="data_type"
                    placeholder="Enter the name...">
            </div>
            <div class="form-group row" *ngIf="title == 'Add Credentials'">
                <button type="submit" class="btn btn-primary px-4 mt-2">{{staticText?.delivery?.data_credentials.add_credentials}}</button>
            </div>
            <div class="form-group row" *ngIf="title == 'Edit Credentials'">
                <button type="submit" class="btn btn-primary px-4 mt-2">{{staticText?.delivery?.data_credentials.update_credentials}}</button>
            </div>
        </form>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FinancialService } from 'src/app/shared/services/financial.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { EditBudgetComponent } from './edit-budget/edit-budget.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-f-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() projectID: any;
  startValue: any = 0;
  budgetRecords: any = {
    data: [{
      "cost_type": "Test",
      "cost_head": "Trest",
      "valid_from": "Today",
      "valid_to": "Next Month",
      "amount": "$1000",
      "owner": "Sidharth",
      "ou_gdp": "2.5",
      "budget_approver": "John",
      "action": "edit"
    }, {
      "cost_type": "Test",
      "cost_head": "Trest",
      "valid_from": "Today",
      "valid_to": "Next Month",
      "amount": "$1000",
      "owner": "Sidharth",
      "ou_gdp": "2.5",
      "budget_approver": "John",
      "action": "edit"
    }, {
      "cost_type": "Test",
      "cost_head": "Trest",
      "valid_from": "Today",
      "valid_to": "Next Month",
      "amount": "$1000",
      "owner": "Sidharth",
      "ou_gdp": "2.5",
      "budget_approver": "John",
      "action": "edit"
    }, {
      "cost_type": "Test",
      "cost_head": "Trest",
      "valid_from": "Today",
      "valid_to": "Next Month",
      "amount": "$1000",
      "owner": "Sidharth",
      "ou_gdp": "2.5",
      "budget_approver": "John",
      "action": "edit"
    }, {
      "cost_type": "Test",
      "cost_head": "Trest",
      "valid_from": "Today",
      "valid_to": "Next Month",
      "amount": "$1000",
      "owner": "Sidharth",
      "ou_gdp": "2.5",
      "budget_approver": "John",
      "action": "edit"
    }, {
      "cost_type": "Test",
      "cost_head": "Trest",
      "valid_from": "Today",
      "valid_to": "Next Month",
      "amount": "$1000",
      "owner": "Sidharth",
      "ou_gdp": "2.5",
      "budget_approver": "John",
      "action": "edit"
    }, {
      "cost_type": "Test",
      "cost_head": "Trest",
      "valid_from": "Today",
      "valid_to": "Next Month",
      "amount": "$1000",
      "owner": "Sidharth",
      "ou_gdp": "2.5",
      "budget_approver": "John",
      "action": "edit"
    }]
  };

  constructor(private financalService: FinancialService, public dialog: MatDialog, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.getBudgetRecords();
  }

  getBudgetRecords() {
    this.financalService.getFinanceBudget(this.projectID).subscribe((response: any) => {
      this.budgetRecords = response;
    });
  }

  addBudgetRecord() {
    const dr = this.dialog.open(EditBudgetComponent, {
      width: '500px',
      data: { projectID: this.projectID, mode: "add" }
    });
    dr.afterClosed().subscribe((res) => {
      console.log(res);
      this.getBudgetRecords();
    });
  }

  editBudgetRecord(budgetRecord: any) {
    const dr = this.dialog.open(EditBudgetComponent, {
      width: '500px',
      data: { projectID: this.projectID, mode: "edit", budgetRecord: budgetRecord }
    });
    dr.afterClosed().subscribe((res) => {
      console.log(res);
      this.getBudgetRecords();
    });
  }

  deleteBudgetRecord(budgetRecord: any) {
    this.financalService.deleteFinanceBudget(budgetRecord.id).subscribe((response: any) => {
      this.getBudgetRecords();
      this.toastService.success('Budget Deleted Successfully');
    });
  }

}

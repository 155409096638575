<div class="spend-toolbar">
  <div style="width: 50%">
    <app-filter-card [pagetype]="'financials-spend'"></app-filter-card>
  </div>
  <div>
    <button
      mat-button
      class="bg-primary text-white"
      mat-button
      mat-flat-button
      (click)="addSpendRecord()"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="material-icons-filled "
        class="cursor-pointer fs-5"
        >add
      </mat-icon>
      New Spend
    </button>
  </div>
</div>
<div class="custom-table fixed-table-header">
  <table class="table table-bordered" style="min-height: 80px">
    <thead>
      <tr>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">#</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.billing_date
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.payment_date
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.payment_mode
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.invoice_ref
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.spend
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.payee_name
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.budget
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.cost_type
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.cost_head
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.type
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.spend_table.action
          }}</span>
        </th>
      </tr>
    </thead>
    <tbody class="cursor-pointer" *ngIf="spendRecords?.length">
      <tr *ngFor="let tableRecords of spendRecords; index as tableIndex">
        <td scope="row">
          <span class="d-flex fs-13">
            {{ startValue + tableIndex + 1 }}
          </span>
        </td>
        <td>
          <span class="d-flex color-blue fs-13">
            {{ tableRecords?.billing_date || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.payment_date || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.payment_mode || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.invoice_number || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.spend | currency : "USD" : "symbol" || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.payee_name }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.budget?.name }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.budget?.cost_type }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.budget?.cost_head }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{
              tableRecords?.type?.name == "Other"
                ? tableRecords?.type_description
                : tableRecords?.type?.name
            }}
          </span>
        </td>
        <td>
          <div class="d-flex justify-content-around align-items-center">
            <img
              src="./assets/images/pages/workforce/edit.svg"
              alt="edit"
              (click)="editSpendRecord(tableRecords)"
            />
            <img
              src="./assets/images/comment-delete.svg"
              alt="delete"
              (click)="deleteSpendRecord(tableRecords)"
            />
          </div>
        </td>
      </tr>
    </tbody>
    <ng-container *ngIf="!spendRecords?.length">
      <div class="noRecords d-flex justify-content-center text-center">
        <span>
          {{ staticText.common.no_records_found }}
        </span>
      </div>
    </ng-container>
  </table>
</div>
<div
  class="d-flex align-items-center justify-content-between"
  *ngIf="spendRecords?.count > 0"
>
  <div class="p-1">
    <div
      class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
    >
      <span class="p-1">Displaying</span>
      <select
        class="form-select form-select-sm table-sort-filter"
        aria-label="Default select example"
        [(ngModel)]="sortRecords"
        (ngModelChange)="getRecords()"
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="150">150</option>
        <option value="200">200</option>
      </select>
      <span class="p-1">{{ spendRecords?.count }}</span>
      <span class="p-1">records</span>
    </div>
  </div>
  <div class="p-1">
    <ngb-pagination
      [pageSize]="sortRecords"
      [collectionSize]="spendRecords?.count"
      [maxSize]="2"
      [rotate]="true"
      [(page)]="CurrentPage"
      [boundaryLinks]="true"
      (pageChange)="getRecords()"
    >
    </ngb-pagination>
  </div>
</div>

<div class="row field-adjust" id="custom-height">
  <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="d-flex align-items-center">
  <mat-icon class="text-warning cursor-pointer mb-2 fs-5"> star</mat-icon>
  <span> - Recommended </span>
</div>
<section class="projectOkr">
  <div class="row row-cols-2 m-0">
    <div class="px-0 col-12">
      <!-- (selectedTabChange)="changeTab($event)" -->
      <mat-tab-group mat-align-tabs="start" class="mt-3 head">
        <mat-tab label="{{ staticText?.delivery.okr_log.setup }}">
          <app-loading *ngIf="!okrArray"></app-loading>
          <div style="height: calc(100vh - 395px); overflow: scroll">
            <app-selection-tree-listing
              [(items)]="okrArray"
              selectedOkr="selectedOkr"
              [tree]="'project'"
              [context]="context"
            ></app-selection-tree-listing>
          </div>
          <div class="footer-right" *ngIf="!user?.is_vendor">
            <button
              mat-button
              mat-flat-button
              class="bg-primary text-white w-100 border my-2"
              (click)="saveSelection()"
            >
              Save
            </button>
          </div>
        </mat-tab>
        <mat-tab label="{{ staticText?.okr_heatmap?.title }}">
          <div class="p-1">
            <div class="table" style="margin-bottom: 0px !important">
              <div class="table-row">
                <div
                  class="table-cell cursor-pointer fw-bold"
                  style="
                    max-width: 150px;
                    min-width: 150px;
                    width: 150px;
                    font-size: 14px;
                    position: sticky;
                    left: 0;
                    z-index: 1;
                    height: 0;
                  "
                >
                  <div
                    class="domain"
                    style="
                      background-color: #0bb797;
                      border-radius: 10px;
                      height: 100%;
                    "
                  >
                    Objectives
                  </div>
                </div>
                <div class="table-cell table-nest">
                  <div class="table-row" style="position: relative">
                    <div
                      class="table-cell cursor-pointer"
                      style="
                        max-width: 150px;
                        min-width: 150px;
                        font-size: 14px;
                        position: sticky;
                        left: 150px;
                        z-index: 1;
                        height: 0;
                      "
                    >
                      <div
                        class="subdomain"
                        style="
                          background-color: #0bb797;
                          border-radius: 10px;
                          padding: 8px;
                          height: 100%;
                        "
                      >
                        Key Results
                      </div>
                    </div>
                    <div
                      class="table-cell"
                      style="
                        max-width: 150px;
                        min-width: 150px;
                        position: relative;
                        padding: 5px;
                        cursor: pointer;
                      "
                    >
                      <span
                        style="
                          position: absolute;
                          font-size: 14px;
                          text-align: center;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          width: 90%;
                          height: 100%;
                          padding: 7px 0;
                        "
                      >
                        <div
                          style="
                            background-color: #0bb797;
                            color: white;
                            border-radius: 10px;
                            padding: 8px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          Goals
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="table" style="margin-bottom: 0">
                            <div class="table-row" style="position: sticky; top: 0px; z-index: 3; background: white;">
                                <div class="table-cell cursor-pointer domain fw-bold"
                                    style="max-width: 150px;min-width: 150px;width:150px;text-align: left;font-size:14px; background: white; position: sticky; top: 0;left:0; z-index: 3;">
                                    <div style="background-color: #0bb797; border-radius: 10px;"> Objective </div>

                                </div>
                                <div class="table-cell table-nest">
                                    <div class="table-row"
                                        style="position: relative; width: 100%; position: sticky; top: 0; left: 150px; z-index: 3;">
                                        <div class="table-cell cursor-pointer subdomain"
                                            style="max-width: 150px;min-width: 150px;text-align: left;font-size:14px; background-color: white; position: sticky; top: 0;left:150px; z-index: 3;">
                                            <div style="background-color: #0bb797; border-radius: 10px; padding: 8px;">
                                                Keyresult </div>
                                        </div>
                                        <div class="table-cell"
                                            style="width: 150px; position: relative; padding: 5px; cursor: pointer; background-color: white; color: white; position: sticky; top: 0; left: 0; z-index: 2;">
                                            <span style="position: sticky;font-size: 14px;text-align: left;
                                                left: 50%; transform: translate(-50%, -50%); width: 90%; ">
                                                <div
                                                    style="background-color: #0bb797; border-radius: 10px; padding: 8px;">
                                                    Goal </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
            <div
              class="okrCenterList"
              style="overflow: scroll; height: calc(100vh - 425px)"
            >
              <div class="table" style="width: 60%">
                <div
                  class="table-row"
                  *ngFor="
                    let treearray of getFilteredArray(okrArrayHeatMap);
                    let subindex = index
                  "
                >
                  <div
                    class="table-cell cursor-pointer fw-bold"
                    style="
                      max-width: 150px;
                      min-width: 150px;
                      font-size: 14px;
                      position: sticky;
                      left: 0;
                      z-index: 1;
                      height: 0;
                    "
                  >
                    <div
                      class="domain"
                      style="border-radius: 10px; height: 100%"
                    >
                      {{ treearray?.title || treearray?.name | titlecase }}
                    </div>
                  </div>
                  <div
                    class="table-cell table-nest"
                    *ngIf="treearray?.children"
                  >
                    <div
                      class="table-row"
                      style="position: relative"
                      *ngFor="
                        let subParent of getFilteredArray(treearray?.children);
                        let subsubindex = index
                      "
                    >
                      <div
                        class="table-cell cursor-pointer"
                        id="subdomain_{{ subsubindex }}"
                        style="
                          max-width: 150px;
                          min-width: 150px;
                          font-size: 14px;
                          position: sticky;
                          left: 150px;
                          z-index: 1;
                          height: 0;
                        "
                      >
                        <div
                          class="subdomain"
                          style="
                            border-radius: 10px;
                            padding: 8px;
                            height: 100%;
                          "
                          [matTooltip]="
                            subParent?.title || subParent?.name | titlecase
                          "
                          matTooltipPosition="right"
                        >
                          {{ getTrucatedName(subParent?.title) | titlecase }}
                        </div>
                      </div>

                      <div
                        class="table-cell"
                        style="
                          max-width: 150px;
                          min-width: 150px;
                          position: relative;
                          cursor: pointer;
                        "
                        *ngFor="
                          let goal of getFilteredArray(subParent?.children);
                          let goalsubindex = index
                        "
                        (click)="
                          onClickGoal(treearray, subParent, goal, okrLogPop)
                        "
                      >
                        <span
                          id="goalsubdomain_{{ subsubindex }}_{{
                            goalsubindex
                          }}"
                          class="cursor-pointer"
                          style="
                            position: relative;
                            font-size: 14px;
                            text-align: center;
                            height: 0;
                          "
                        >
                          <div
                            [ngStyle]="{
                              backgroundColor:
                                selected == goal.id ? '#86da64' : '#c2bec7'
                            }"
                            [matTooltip]="goal?.goal_name | titlecase"
                            matTooltipPosition="right"
                            style="
                              border-radius: 10px;
                              padding: 8px;
                              height: 100%;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            {{ getTrucatedName(goal?.goal_name) | titlecase }}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>

<ng-template #okrLogPop>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'OKR Update'">
      </app-modal-header-common>
      <div class="modal-body p-0">
        <div class="saw">
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec obj">Objective: </span>

            <p class="mb-0">{{ goalMetric.objective }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec krq">Keyresult: </span>

            <p class="mb-0">{{ goalMetric.kr }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol">Goal: </span>

            <p class="mb-0">{{ goalMetric.goal }}</p>
          </div>
          <!--Goal Metrics-->
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol" style="background: #0f7b68"
              >MOS:
            </span>
            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select
                [(ngModel)]="selectedMetric"
                (selectionChange)="selectedMatricEvent($event)"
              >
                <mat-option>Select MOS</mat-option>
                <mat-option
                *ngFor="let mos of goalMetric?.matrics"
                  value="{{ mos.id }}"
                  
                  >{{ mos?.instancemetrics__name}}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="d-flex mb-2" style="align-items: center" *ngIf="selectedMetricObj">
            <span class="sjec gol" style="background: #0f7b68"
              >Year:
            </span>

            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select
                [(ngModel)]="selectedPeriod" (selectionChange)="selectedMatricYear($event)"
              >
                <mat-option>Select Period</mat-option>
                <mat-option value="2024">2024</mat-option>
                <mat-option value="2023">2023</mat-option>
                <mat-option value="2022">2022</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- <form
          [formGroup]="okrMetricCreateForm"
          style="width: 100%"
          *ngIf="okrMetricCreateForm.controls.length > 0"
        > -->
          <div *ngIf="selectedMetricObj && selectedPeriod" class="custom-table table-responsive p-4 pt-0" style="height: auto">
            <div class="d-flex justify-content-between">
              <span>Target Value : {{selectedMetricObj?.target_value}} </span>
              <button type="submit" class="btn btn-primary ml-2 mb-2" (click)="matricTarVal()">Submit</button>
            </div>
              <table class="table table-bordered">
                <thead style="position: sticky; top: 0; z-index: 1">
                  <tr>
                    <th class="fixed-header" scope="col">Month</th>
                    <th class="fixed-header" scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of monthList;let i = index;">
                    <td>{{m.month}}</td>
                    <td style="padding: 5px;" >
                      <div class="d-flex justify-content-center" *ngIf="m.edit">
                        <input type="text"  [(ngModel)]="m.value" class="form-control" placeholder="Enter Number" style="font-size: 12px;width: 100px;">
                        <!-- <button type="submit" class="btn btn-primary ml-2" (click)="matricTarVal(i)">Submit</button> -->
                      </div>
                      <div class="d-flex justify-content-between" *ngIf="!m.edit">
                        <div></div>
                        <span>{{m?.value || 0}}</span>
                        <i class="fa fa-edit" (click)="editColum(i)"></i>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr
                    *ngFor="let m of okrMetricCreateForm.controls; let i = index"
                    [formGroup]="m"
                  >
                    <td class="align-middle" style="width: 25%">
                      <p class="m-0" style="font-size: 12px; font-weight: 500">
                        {{
                          okrMetricCreateForm.controls[i].get("mos_name").value
                        }}
                      </p>
                      <p class="m-0" style="font-size: 12px; font-weight: 500">
                        <span style="font-weight: 300"> Target Value: </span
                        >{{
                          okrMetricCreateForm.controls[i].get("target_value")
                            .value
                        }}
                      </p>
                    </td>
                    <td class="align-middle" style="width: 25%">
                      <mat-form-field
                        appearance="outline"
                        style="width: 100px; text-align: center"
                      >
                        <mat-label class="label-design"> </mat-label>
                        <input
                          matInput
                          class="fw-500"
                          type="number"
                          formControlName="current_value"
                          fullWidth
                          name="current_value"
                        />
                      </mat-form-field>
                    </td>
                    <td class="align-middle" style="width: 50%">
                      <mat-form-field
                        appearance="outline"
                        class="py-1"
                        style="width: 100%; max-width: 100%"
                      >
                        <mat-label class="label-design"> </mat-label>
                        <input
                          matInput
                          class="fw-500"
                          formControlName="comments"
                          fullWidth
                          name="comments"
                        />
                      </mat-form-field>
                    </td>
                  </tr> -->
                </tbody>
              </table>
          </div>
          <!-- <div>
            <button
              mat-button
              mat-flat-button
              class="bg-primary text-white text-capitalize float-right mr-2 mt-2 mb-2"
              (click)="saveMetric()"
            >
              Save
            </button>
          </div>
        </form> -->
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #updateOKR>
    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <div class="border-top"></div>
            <div class="modal-header ">
                <h2 mat-dialog-title class="fw-bold mb-0"> {{staticText?.delivery.risk_log.update_okr}}</h2>
            </div>
            <div class="modal-body d-flex"> 
                <div class="d-flex">
                <table class="table table-responsive fs-14 okr-tbl" >
                    <thead>
                        <th>Key Result</th>
                        <th>Actual Value</th>
                        <th>Progress(%)</th>
                        <th>Remarks</th>
                        <th>Actual End Date</th>
                        <th>History</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let child of allKeyResults">
                            <td>{{child.key_result_title}}</td>
                            <td class="input-fld num"><div><input type="number"/></div></td>
                            <td class="input-fld num"><div><input type="number"/></div></td>
                            <td class="input-fld"><div><input type="text"/></div></td>
                            <td class="date-fld"><div>
                                <mat-form-field appearance="outline" class="w-100 p-0">
                                  <input matInput [matDatepicker]="picker" readonly
                                      fullWidth status="basic" (click)="picker.open()">
                                  <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                                  <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                </div>
                            </td>
                            <td class="mw-60" (click)="selectedKR=child.key_result_title;openModal(viewHistory)">
                                <div>
                                    <img src="./assets/images/statistics-new.svg"/>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>            
            </div> 
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="cancel" (click)="closeDialog()">{{staticText?.buttons.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="saveOkr text-white text-capitalize float-right add-release"
                           >{{staticText?.buttons.save_btn}} </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->
<!-- <ng-template #viewHistory>
    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <div class="border-top"></div>
            <div class="modal-header ">
                <h2 mat-dialog-title class="fw-bold mb-0"> {{staticText?.delivery.okr_log.okr_history}}</h2><br>
            </div>
            <div class="modal-body"> 
                <div>                    
                    <h4 class="objective-title">{{selectedKRObj?.objective}}</h4>
                    <h5>{{selectedKRObj?.key_result}}</h5>
                    <h6 class="mos-title">{{selectedKRObj?.goal_name}}</h6>
                </div>
                <div class="d-flex row" *ngIf="!selectedKRObj?.isChartClicked">
                    <table class="table table-responsive fs-14 w-auto m-2 border col-6 p-0" *ngFor="let tdata of okrHistory">
                        <tr>
                            <td [attr.colspan]="4" class="risk-title">{{tdata?.[0]?.metricName || tdata?.[0]?.metric_details?.name}}</td>
                        </tr>
                        <tr>
                            <td class="theading">Date</td>
                            <td class="theading">Month</td>
                            <td class="theading">Value</td>
                            <td class="theading">Remarks</td>
                        </tr>
                        <tr *ngFor="let historyData of tdata" [hidden]="historyData?.actual_value ==null">
                            <td>{{historyData?.last_updated_on | date:'dd/MM/yyyy'}}</td>
                            <td class="input-fld">{{historyData?.month}}</td>
                            <td class="input-fld">{{historyData?.actual_value}}</td>
                            <td >{{historyData?.comments}}</td>
                        </tr>
                        <tr *ngIf="!okrHistory || okrHistory?.length==0">
                          <td [attr.colspan]="4">No data found.</td>
                        </tr>
                    </table>
                </div>  
                <div class="row" *ngIf="selectedKRObj?.isChartClicked">
                    <div class="col-6" *ngFor="let tdata of okrHistory;let i=index;let first=first">
                            <p class="fw-bold py-2 ml-4" >{{tdata?.[0]?.metricName || tdata?.[0]?.metric_details?.name}}</p>
                                <div class="col-12" [chart]="chartData['barChartData'+i]"></div>
                    </div>
                    <div *ngIf="!okrHistory || okrHistory.length==0" class="no-dta"> {{staticText.common.no_records_found}}</div>
                </div>          
            </div> 
            <div class="modal-footer">
                <div class="row">
                    <div>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" (click)="historyModal.close()">{{staticText?.buttons.close_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->
<!-- <ng-template #MeasureofSuccessModal>
    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <div class="border-top"></div>
            <div class="modal-header ">
                <h2 mat-dialog-title class="fw-bold mb-0"> Measure of Success Details</h2>
            </div>
            <div class="modal-body d-flex"> 
                <div class="d-flex">
                <table class="table table-responsive fs-14 w-auto" >
                    <tr>
                        <td [attr.colspan]="6" class="risk-title">
                            <b>Goal Name:</b>&nbsp;{{selectedMosData?.goal_name}}<br>
                            <b>Goal Description:</b>&nbsp;{{selectedMosData?.goal_description}}<br>
                        </td>
                    </tr>
                    <tr>
                        <td class="theading">Metric Name</td>
                        <td class="theading">Metric Description</td>
                        <td class="theading">MoS Type</td>
                        <td class="theading">Target Value</td>
                        <td class="theading">Frequency</td>
                        <td class="theading">Remarks</td>
                    </tr>
                    <tr *ngFor="let metric of selectedMosData?.metrics">
                        <td>{{metric?.name}}</td>
                        <td>{{metric?.description}}</td>
                        <td>{{metric?.mos_type_name}}</td>
                        <td *ngIf="metric?.mos_type_name=='Need Improvement'">{{metric?.from_value}}-{{metric?.to_value}}</td>
                        <td *ngIf="metric?.mos_type_name=='LTB'">{{metric?.min_value}}</td>
                        <td *ngIf="metric?.mos_type_name=='HTB'">{{metric?.max_value}}</td>
                        <td *ngIf="metric?.mos_type_name=='Binary(Adherence)'">{{metric?.binary_value?'Yes':'No'}}</td>
                        <td>{{metric?.frequency}}</td>
                        <td>{{metric?.remarks}}</td>
                    </tr>
                    <tr *ngIf="!selectedMosData?.metrics || selectedMosData?.metrics?.length==0">
                      <td [attr.colspan]="6">{{staticText.common.no_records_found}}</td>
                    </tr>
                </table>
            </div>            
            </div> 
            <div class="modal-footer">
                <div class="row">
                    <div>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" mat-dialog-close>{{staticText?.buttons.close_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->

<!-- <ng-template #MeasureofSuccessModalEdit>

    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <div class="border-top"></div>
            <div class="modal-header ">
                <h2 mat-dialog-title class="fw-bold mb-0"> Measure of Success Update</h2>
            </div>
            <div class="modal-body d-flex"> 
                <div class="row">
                  <div class="col-12">
                   
                    <mat-card *ngFor="let metricDetails of krData?.mos?.metrics | paginate: { itemsPerPage: 1, currentPage: metricPage, totalItems: krData?.mos?.metrics?.length } ; let e = index" class="m-1 p-1 d-grid">
                        <div class="row col-12 m-0 p-0">
                            <div class="p-1 col-12">
                                <div><b>Metric Name</b></div>
                                <div>{{metricDetails?.name}}</div>
                            </div>
                            <div class="p-1 col-6">
                                <div><b>MoS Type</b></div>
                                <div>{{metricDetails?.mos_type_name}}</div>
                            </div>
                            <div class="p-1 col-6">
                                <div><b>Target Value</b></div>
                                <div *ngIf="metricDetails?.mos_type_name=='Need Improvement'">{{metricDetails?.from_value}}-{{metricDetails?.to_value}}</div>
                                <div *ngIf="metricDetails?.mos_type_name=='LTB'">{{metricDetails?.min_value}}</div>
                                <div *ngIf="metricDetails?.mos_type_name=='HTB'">{{metricDetails?.max_value}}</div>
                                <div *ngIf="metricDetails?.mos_type_name=='Binary(Adherence)'">{{metricDetails?.binary_value?'Yes':'No'}}</div>
                            </div>
                        </div>

                        <div [formGroup]="krForm" class="row col-12 field-adjust p-2">
                            <div>
                                <mat-form-field appearance="outline" class="col-4 pl-0">
                                    <mat-label>Actual Value</mat-label>
                                    <input matInput [type]="metricDetails?.mos_type_name=='Binary(Adherence)'?'text':'number'" placeholder="Actual Value" formControlName="actual_value">
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-4 pl-0">
                                    <mat-label>Month</mat-label>
                                    <mat-select class="select-box w-100" formControlName="month">
                                        <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-4 pl-0">
                                    <mat-label>Comments</mat-label>
                                    <input type="text" matInput fullWidth status="basic" placeholder="Comments"
                                        formControlName="comments">
                                </mat-form-field>
                            </div>
                            
                        </div>
                        <mat-card-actions class="border-top p-2 mt-2" align="end" >
                            <button class="saveOkr text-white text-capitalize float-right ml-2" mat-raised-button
                                (click)="updateKeyResultMoS(metricDetails)" [disabled]="krForm.invalid">
                                {{staticText.buttons.save_btn}}
                            </button>
                            <button mat-button class="cancel" mat-dialog-close (click)="krData={};showKeyResultPane=false;">
                                {{staticText.buttons.cancel_btn}}
                            </button>
                        </mat-card-actions>
                       
                    </mat-card>
                  </div>  
               
                
            </div>  
                    
            </div> 
        </div>
    </div>
</ng-template> -->

import { Component, Input, OnInit } from '@angular/core';
import { IncidentTrackerService } from '../../../../shared/services/incident-tracker.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent implements OnInit {
  @Input() projectID: any;
  auditInstances: any;
  auditInstanceTitleList: any = [];

  constructor(
    private incidentTrackerService: IncidentTrackerService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAuditInstances();
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  prepareInstancesList() {
    this.auditInstances.forEach((category: any) => {
      category.instances.forEach((instance: any) => {
        this.auditInstanceTitleList.push({
          value: instance.template.id,
          display_name: instance.template.title,
        });
      });
    });
  }

  openInstance(res: any) {
    console.log(res);
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      width: "80vw",
      data: {
        instance: res.id,
      },
    });
    dialog.componentInstance.type = { name: "instancepop" };
    dialog.afterClosed().subscribe((data: any) => {
      if (!!data) {
        window.location.href = data.replace("embedded=true", "");
      }
    });
  }

  getAuditInstances() {
    this.incidentTrackerService.getAuditInstancesByProjectId(this.projectID).subscribe((resp: any) => {
      this.auditInstances = resp;
      this.prepareInstancesList();
      this.auditInstances.forEach((category: any) => {
        category.instances.forEach((element: any) => {
          (element.color = this.getGMapRandomColor()),
            (element.last_updated_on_dt = new Date(element.last_updated_on)),
            (element.owners = []),
            element?.user?.forEach((e: any) => {
              element.owners.push({
                user: e,
              });
            });
        });
      });
    });
  }

}

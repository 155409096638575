import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { WorkForceService } from 'src/app/shared/services/work-force.service';
import { MatDialog } from '@angular/material/dialog';
import { FilterService } from 'src/app/shared/services/filter.service';
import { MatTabGroup } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/shared/services/project.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-delivery-toe',
  templateUrl: './delivery-toe.component.html',
  styleUrls: ['./delivery-toe.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class DeliveryToeComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery.left_bar.terms;
  projectId: any;
  toggletacc = new Map<string, boolean>(); 
  termsArray: any;
  selectedTerms: any;
  context: any;
  user: any;
  frequencyList:any = [];
  userList:any;
  constructor(
    public dialog: MatDialog,
    private filterService: FilterService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private workForceService:WorkForceService,
    public fb: FormBuilder,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private messageService: MessageService
  ) { 
    this.context = {
      componentParent: this
    }
  }
  
  ngOnInit(): void {
    this.messageService.setGnews('Project central - toe')
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.getAllMandatoryData();
    this.getFreqList();
    this.getUserList();
  }

  getAllMandatoryData() {
    this.getOpportunityId();
    
  }
  getOpportunityId() {
    this.route.parent?.params.subscribe((res: any) => {
      this.projectId = atob(res.id)

      this.getProjectTerms();
    });
  }
  toggleAcc(id: any) {
    if (this.toggletacc.get(id)) {
      this.toggletacc.set(id, false);

    } else {
      this.toggletacc.set(id, true);

    }

  }

  getProjectTerms(){
    this.projectService.getProjectTerms(this.projectId).subscribe((res:any) => {
      let selectedTerms = res.selected_term_ids;

      this.gettoe(selectedTerms);
    })
  }

  gettoe(selected: any){
    this.spinner.show();
    this.projectService.getProjectToeData(this.projectId).subscribe((getTerm:any) => {
      getTerm.selected_term_ids = selected;
      getTerm.terms_hierarchy.forEach((category: any) => {
        category.level = "category";
        if(category.subcategories.length > 0){
          category.children = category.subcategories;
          category.children.forEach((sub: any) => {
            sub.level = "subcategory";
            sub.category_id = category.id;
            if(sub.terms.length > 0){
              sub.children = sub.terms;
              sub.children.forEach((term: any) => {
                term.level = "term";
                term.name = term.title;
                term.project_id = this.projectId;
                term.instancemetrics = term?.instancemetrics;
                term.subcategory_id = sub.id;
                term.subcategory = sub?.display_name;
                term.category_id = category.id;
                term.category = category?.display_name;
                getTerm.selected_term_ids = getTerm?.selected_term_ids?.length > 0 ? getTerm.selected_term_ids : []
                getTerm.selected_term_ids.forEach((element:any) => {
                  if(element.term_id == term.id){
                    term.projectterm_id = element?.id,
                    term.comments = element?.delivey_obligation[0]?.comments ? element?.delivey_obligation[0]?.comments : '';
                    term.context = element?.delivey_obligation[0]?.context ? element?.delivey_obligation[0]?.context : '';
                    term.frequency = element?.delivey_obligation[0]?.frequency ? element?.delivey_obligation[0]?.frequency : '';
                    term.due_date = element?.delivey_obligation[0]?.due_date ? element?.delivey_obligation[0]?.due_date : '';
                  }
                });
                if(getTerm.selected_term_ids.findIndex((e: any) => e.term_id == term.id) > -1){
                  term.selected = true;
                }else{
                  term.selected = false;
                }
              })

              if(sub.children.filter((e: any) => e.selected === true).length == sub.children.length){
                sub.selected = true;
                sub.selection_type = 'all';
              }else if(sub.children.filter((e: any) => e.selected === true).length > 0){
                sub.selected = true;
                sub.selection_type = 'intermediate';
              }else{
                sub.selected = false;
                sub.selection_type = 'none';
              }
            }
          });

          if(category.children.filter((e: any) => e.selection_type === 'all').length == category.children.length){
            category.selected = true;
            category.selection_type = 'all';
          }else if(category.children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || category.children.filter((e: any) => e.selection_type === 'all').length > 0){
            category.selected = true;
            category.selection_type = 'intermediate';
          }else{
            category.selected = false;
            category.selection_type = 'none';
          }
        }
      });
      this.termsArray = getTerm.terms_hierarchy;
      this.selectedTerms = getTerm.selected_term_ids;
      this.spinner.hide();      
    })
  }

  collapse(item: any){
    if(item.level == 'category'){
        this.termsArray.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }else if(item.level == 'subcategory'){
        let termIndex = this.termsArray.findIndex((e: any) => e.id == item.category_id);

        if(this.termsArray[termIndex].children.length > 0){
          this.termsArray[termIndex].children.forEach((element: any) => {
            if(element.id != item.id) element.expanded = false;
          });
        }
      }
    }

    selectGoals(item: any){
      if(item.level == 'category'){
        this.termsArray.forEach((cat: any) => {
          if(cat.id == item.id) {
            cat.selected = item.selected;
            cat.selection_type = item.selected ? 'all' : 'none';

            cat.children.forEach((sub: any) => {
              sub.selected = item.selected;
              sub.selection_type = item.selected ? 'all' : 'none';

              sub.children.forEach((term: any) => {
                term.selected = item.selected;
                term.selection_type = item.selected ? 'all' : 'none';
              });
            });
          }
        });
      }else if(item.level == 'subcategory'){
        let subIndex = this.termsArray.findIndex((e: any) => e.id == item.category_id);

        if(this.termsArray[subIndex].children.length > 0){
          this.termsArray[subIndex].children.forEach((sub: any) => {
            if(sub.id == item.id) {
              sub.selected = item.selected;
              sub.selection_type = item.selected ? 'all' : 'none';

              sub.children.forEach((term: any) => {
                term.selected = item.selected;
                term.selection_type = item.selected ? 'all' : 'none';
              });
            }
          });

          if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length == this.termsArray[subIndex].children.length){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'all';
          }else if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length > 0){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'intermediate';
          }else{
            this.termsArray[subIndex].selected = false;
            this.termsArray[subIndex].selection_type = 'none';
          }
        }    
      }else if(item.level == 'term'){
        let subIndex = this.termsArray.findIndex((e: any) => e.id == item.category_id);
        let termIndex = this.termsArray[subIndex].children.findIndex((e: any) => e.id == item.subcategory_id);

        if(this.termsArray[subIndex].children[termIndex].children.length > 0){
          this.termsArray[subIndex].children[termIndex].children.forEach((term: any) => {
            if(term.id == item.id) {
              term.selected = item.selected;
              term.selection_type = item.selected ? 'all' : 'none';
              term.comments = item?.comments ? item?.comments : ''
              term.context = item?.context ? item?.context : '';
              term.frequency = item?.frequency ? item?.frequency : '';
              term.due_date = item?.due_date ? item?.due_date : '';
            
            }
          });
          

          if(this.termsArray[subIndex].children[termIndex].children.filter((e: any) => e.selected === true).length == this.termsArray[subIndex].children[termIndex].children.length){
            this.termsArray[subIndex].children[termIndex].selected = true;
            this.termsArray[subIndex].children[termIndex].selection_type = 'all';
          }else if(this.termsArray[subIndex].children[termIndex].children.filter((e: any) => e.selected === true).length > 0){
            this.termsArray[subIndex].children[termIndex].selected = true;
            this.termsArray[subIndex].children[termIndex].selection_type = 'intermediate';
          }else{
            this.termsArray[subIndex].children[termIndex].selected = false;
            this.termsArray[subIndex].children[termIndex].selection_type = 'none';
          }

          if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length == this.termsArray[subIndex].children.length){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'all';
          }else if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length > 0){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'intermediate';
          }else{
            this.termsArray[subIndex].selected = false;
            this.termsArray[subIndex].selection_type = 'none';
          }
        }
      }
    }

  saveSelection(){
    let tArray: any = [];

    this.termsArray.forEach((category: any) => {
      category?.children?.forEach((sub: any) => {
        sub?.children?.forEach((term: any) => {
          if(term.selected){
            tArray.push({term_id:term?.id,
                        comments:"",
                        context:term?.context ? term?.context : '',
                        frequency:term?.frequency ? term?.frequency : '',
                        due_date:term?.due_date ? term?.due_date : '',
                         });
          }
        });
        
      });
    });

    let postdata = {
      project_id: this.projectId,
      terms: tArray
    }

    this.projectService.selectProjectToe(postdata).subscribe((getTerms:any) => {
      this.toastrService.success('Terms updated successfully.');
    })
  }

  reload(){
    this.ngOnInit();
  }
   getFreqList(){
     this.projectService.getBaseValue('term_frequency').subscribe((res: any) => {
       this.frequencyList = res;
     })
   }
   getUserList(){
    this.projectService.getUser().subscribe((res: any) => {
      this.userList = res;
    })
   }

}

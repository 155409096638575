@Pipe({
  name: 'CheckNextToE'
})
export class CheckNextToEPipe {
  transform(subCategories:any,subIndex:number,toeIndex:number) {
    let categoryData = subCategories[subIndex];
    let nextIndex = categoryData?.toe.findIndex((e:any,index:number)=> index>toeIndex);
    if(nextIndex>-1){ 
      return {"isPresent":true,catData:{...categoryData,"toe":categoryData?.toe[nextIndex],"toeIndex":nextIndex,"categoryIndex":subIndex}};
    }else if(subCategories?.length > (subIndex+1)){
      let data ={"isPresent":false,catData:{}};
      let foundIndex = subCategories.findIndex((e:any,index:number)=>e.toe?.length>0 && index>subIndex);
      data.isPresent =true;
      data.catData = {...subCategories[foundIndex],"toe":subCategories[foundIndex]?.toe[0],"toeIndex":0,"categoryIndex":foundIndex};
      return data;
    }
    return {"isPresent":false,catData:{}};
  }
}
@Pipe({
  name: 'CheckPrevToE'
})
export class CheckPrevToEPipe {
  transform(subCategories:any,subIndex:number,toeIndex:number) {
    let categoryData = subCategories[subIndex];
    if(toeIndex==0){
      let nextIndex = findLastIndex(categoryData?.toe,(e:any,index:number)=> index<toeIndex);
      if(nextIndex>-1){ 
        return {"isPresent":true,catData:{...categoryData,"toe":categoryData?.toe[nextIndex],"toeIndex":nextIndex,"categoryIndex":subIndex}};
      }else if((subIndex-1)>=0){
        let data ={"isPresent":false,catData:{}};
        let foundIndex = findLastIndex(subCategories,(e:any,index:number)=>e.toe?.length>0 && index<subIndex);
        data.isPresent =true;
        data.catData = {...subCategories[foundIndex],"toe":subCategories[foundIndex]?.toe[0],"toeIndex":0,"categoryIndex":foundIndex};
        return data;
      }
      return {"isPresent":false,catData:{}};
    }else
    return {"isPresent":true,catData:{...categoryData,"toe":categoryData?.toe[toeIndex-1],"toeIndex":toeIndex-1,"categoryIndex":subIndex}};
  }
}

import { Component, OnInit, ViewEncapsulation,ViewChild, Pipe } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/shared/services/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DatePipe } from '@angular/common';
import { findLastIndex } from 'lodash';

@Component({
  selector: 'app-toe-milestones',
  templateUrl: './toe-milestones.component.html',
  styleUrls: ['./toe-milestones.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers:[DatePipe,CheckNextToEPipe,CheckPrevToEPipe]
})
export class ToeMilestonesComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  toeForm!:FormGroup|any;
  activeTab=this.staticText?.delivery.toe.setup;

  projectToEData: any[] = [];
  allProjectToeData: any[] = [];

  selectedFile:any={};
  projectId: any;
  showToEPane: boolean = false;
  toePage=1;
  subCategoryData:any={};
  
  activeChildTab='';
  selectedToEs:any=[];
  selectedToEsId:any[]=[];
  selectedToeData:any={};
  selectedToeList:any[]=[];
  selectedUpdateChildIndex:number = 0;
  selectedSetupChildIndex:number = 0;
  teamList:any = [];
  monthList =['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  toggletacc = new Map<string, boolean>(); 

  constructor(
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public dialog: MatDialog, private date:DatePipe,
    private nextToe:CheckNextToEPipe,
    private prevToe:CheckPrevToEPipe,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getAllMandatoryData();
  }

  getAllMandatoryData() {
    this.spinner.show();
    this.getTeamsList();
    this.getProjectid();
    this.getToE();
    this.createForm();
    this.getSelectedToes();
      
  }
  getProjectid() {
    this.route.parent?.params.subscribe((res: any) => {
      this.projectId = atob(res.id)
    })
  }
  getToE() {
    this.spinner.show();
    this.projectService.getToEData(this.projectId).subscribe((res: any) => {
      this.spinner.hide();
      this.projectToEData = res;
      this.filterToeData();
    });    
    this.projectService.getToECategory().subscribe((res: any) => {
      this.spinner.hide();
      this.projectToEData = res['records'];
    });  

    
  }
  
  toggleAcc(id:any){
    if(this.toggletacc.get(id)){
      this.toggletacc.set(id, false);
 }else{
      this.toggletacc.set(id, true);
     }
   
  }
  createForm() {    
    this.toeForm = this.formBuilder.group({
      metrics:this.formBuilder.array([]),
      toe_status:['',Validators.required],
      toe_status_month:['',Validators.required],
    });
  }
  createMetricItem(metric:any,toeID:number,metricId:number){
    return this.formBuilder.group({
      actual_value :[metric.actual_value || '',Validators.required],
      month :[metric.month || '',Validators.required],
      toe:[toeID],
      metrics:[metricId],
      project:[this.projectId]
    })
  }
  openToEModal(mos: any,  modal: any) {
    this.selectedToeData = mos;
    this.dialog.open(modal, {panelClass:"mw-650"});
  }

  openToeUpdate(toe:any,modal:any){
    this.selectedToeData = toe;
    this.dialog.open(modal, {panelClass:"mw-650"});
  }
  changeTab(evt:any){
    this.activeTab = evt.tab?.textLabel;
    if(this.activeTab == this.staticText?.delivery.toe.progress_update){
      this.spinner.show();
      this.projectService.getToEData(this.projectId).subscribe((res: any) => {
        this.spinner.hide();
        this.allProjectToeData = res;
        }); 
    }else{
      this.activeChildTab = this.projectToEData?.[this.selectedSetupChildIndex]?.name;
    }
  }
  getSelectedToes(showSpinner?:any){
    if(showSpinner){
    this.spinner.show();
  }
    this.selectedToEs =[];
    this.selectedToEsId=[];
    this.projectService.getMappedProjectToEs(this.projectId).subscribe((res: any) => {
      if(showSpinner){
      this.spinner.hide();}
      this.selectedToEs = res;
      this.selectedToEs.forEach((element:any) => {
        element.term_sub_category.forEach((subCat:any) => {
          subCat.toe.forEach((toe:any) => {
               this.selectedToEsId.push(toe.id)


          });
        });
      });
      this.activeChildTab = this.selectedToEs?.[this.selectedUpdateChildIndex]?.name;
      this.filterToeData();
    })
  }

  filterToeData(){
    console.log(this.selectedToEs)
    console.log('-----------------------------------------------------------------------------------')
    console.log(this.projectToEData)

    if(this.selectedToEs&&this.selectedToEs.length>0&&this.projectToEData){
      this.projectToEData.forEach((element:any) => {
        element.term_sub_category.forEach((subCat:any) => {
let filteredToe = subCat.toe.filter((e:any)=>this.selectedToEsId.indexOf(e.id)==-1)
      subCat.toe = filteredToe;

        });
      });

    }
  }
  changeChildTab(index:number,isSetup:boolean){
    if(isSetup){
      this.activeChildTab = this.projectToEData[index]?.name;
      this.selectedSetupChildIndex = index;
    }else{
      this.activeChildTab = this.selectedToEs[index]?.name;
      this.selectedUpdateChildIndex = index;
      if(this.selectedToEs[index]?.term_sub_category?.[0]?.toe?.length>0){
        this.onClickToE(this.selectedToEs[index]?.term_sub_category?.[0],this.selectedToEs[index]?.term_sub_category?.[0]?.toe?.[0],0,0);
      }else{
        this.showToEPane = false;
        this.subCategoryData ={};
      }
    }
  }
  
  onClickChild(isNext:boolean){
    let data:any ={};
    if(isNext)
      data = this.nextToe.transform(this.selectedToEs[this.selectedUpdateChildIndex]?.term_sub_category, this.subCategoryData?.categoryIndex,this.subCategoryData?.toeIndex);
    else  
      data = this.prevToe.transform(this.selectedToEs[this.selectedUpdateChildIndex]?.term_sub_category, this.subCategoryData?.categoryIndex,this.subCategoryData?.toeIndex);
    this.showToEPane = true;
    this.toePage=1;
    this.subCategoryData = data?.catData;
    this.onClickToE(this.selectedToEs[this.selectedUpdateChildIndex]?.term_sub_category?.[this.subCategoryData?.categoryIndex],this.selectedToEs[this.selectedUpdateChildIndex]?.term_sub_category?.[this.subCategoryData?.categoryIndex]?.toe?.[this.toePage-1],this.toePage-1,this.subCategoryData?.categoryIndex);
  }
  onClickToE(item:any, toe:any={},toeIndex:number,categoryIndex:number,modal?:any){
    this.showToEPane = true;
    this.toePage=1;
    Object.keys(this.toeForm.controls).forEach((name) => {
       this.toeForm.removeControl(name);
    });
    this.createForm();
    toe['metrics'] = toe?.metrics?.map((e:any)=>{
      let obj={...e};
      if(e?.mos_type_name=='Need Improvement'){
        obj['target_value']= e?.from_value+"-"+e?.to_value;
      }else if(e?.mos_type_name=='LTB'){
        obj['target_value']= e?.min_value;
      }else if(e?.mos_type_name=='HTB'){
        obj['target_value']= e?.max_value;
      }else if(e?.mos_type_name=='Binary(Adherence)'){
        obj['target_value']= e?.binary_value?'Yes':'No';
      }
      return obj;
    })
     this.subCategoryData = {...item,"toe_data":item?.toe,"toe":toe,"toeIndex":toeIndex,"categoryIndex":categoryIndex};
    
     console.log(this.subCategoryData);
     for(let i in toe?.metrics){
      let items = this.toeForm.get("metrics") as FormArray;
      items.push(this.createMetricItem(toe?.metrics[i],toe?.id,toe?.metrics?.[i]?.id));
     };
     
     this.toeForm.get("metrics").updateValueAndValidity();
    //  if(toe?.physical_verification){
    //   this.toeForm.addControl('physical_verification_verified_by',new FormControl(toe?.evidence?.physical_verification_verified_by?.email||'',Validators.required));
    //   this.toeForm.addControl('physical_verification_date',new FormControl(toe?.evidence?.physical_verification_date||'',Validators.required));
    //   this.toeForm.addControl('physical_verification_month',new FormControl(toe?.evidence?.physical_verification_month||'',Validators.required));
    //  }
    //  if(toe?.self_declaration){
    //   this.toeForm.addControl('self_declaration_verified_by',new FormControl(toe?.evidence?.self_declaration_verified_by?.email||'',Validators.required));
    //   this.toeForm.addControl('self_declaration_date',new FormControl(toe?.evidence?.self_declaration_date||'',Validators.required));
    //   this.toeForm.addControl('self_declaration_month',new FormControl(toe?.evidence?.self_declaration_month||'',Validators.required));
    //  }
    //  if(toe?.document_proof){
    //   this.toeForm.addControl('document_proof_verified_by',new FormControl(toe?.evidence?.document_proof_verified_by?.email||'',Validators.required));
    //   this.toeForm.addControl('document_proof_date',new FormControl(toe?.evidence?.document_proof_date||'',Validators.required));
    //   this.toeForm.addControl('document_proof_month',new FormControl(toe?.evidence?.document_proof_month||'',Validators.required));
    //  }
    try{
      toe?.evidence?.forEach((element:any) => {
        this.toeForm.addControl(element?.name+'_verified_by',new FormControl(toe?.toe_evidence[element?.name+'_verified_by']?.email||'',Validators.required));
        this.toeForm.addControl(element?.name+'_month',new FormControl(toe?.toe_evidence[element?.name+'_month']||'',Validators.required));
        this.toeForm.addControl(element?.name+'_date',new FormControl(toe?.toe_evidence[element?.name+'_date']||'',Validators.required));      
       this.selectedFile[element?.name+'_file']={};
       this.selectedFile[element?.name+'_file']['name'] = toe?.toe_evidence[element?.name+'_file'] || null;
     });
    }catch(e){

    }
   
     toe?.toe_compliance?.forEach((element:any) => {
        this.toeForm.addControl(element?.compliance_name+'_status',new FormControl(element?.compliance_status||'',Validators.required));
        this.toeForm.addControl(element?.compliance_name+'_month',new FormControl(element?.compliance_month||'',Validators.required));
     });
    //  if(toe?.compliance?.SOX?.is_active =='true'){
    //    let soxValue = toe?.toe_compliance.find((o:any)=> o?.compliance_name == 'SOX');
    //     this.toeForm.addControl('soxCompStatus',new FormControl(soxValue?.compliance_status||'',Validators.required));
    //     this.toeForm.addControl('soxCompMonth',new FormControl(soxValue?.compliance_month||'',Validators.required));
    //  }
    //  if(toe?.compliance?.GDPR?.is_active =='true'){
    //   let gdprValue = toe?.toe_compliance.find((o:any)=> o?.compliance_name == 'GDPR');
    //     this.toeForm.addControl('gdprCompStatus',new FormControl(gdprValue?.compliance_status||'',Validators.required));
    //     this.toeForm.addControl('gdprCompMonth',new FormControl(gdprValue?.compliance_month||'',Validators.required));
    //  }
     this.toeForm.get("toe_status").setValue(toe?.toe_status?.status);
     this.toeForm.get("toe_status_month").setValue(toe?.toe_status?.month);
     this.toeForm.updateValueAndValidity();
    //  this.selectedFile['self_declaration_file']={};
    //  this.selectedFile['self_declaration_file']['name'] = toe?.evidence?.self_declaration_file || null;
    //  this.selectedFile['physical_verification_file'] ={};
    //  this.selectedFile['physical_verification_file']['name'] = toe?.evidence?.physical_verification_file || null;
    //  this.selectedFile['document_proof_file'] ={};
    //  this.selectedFile['document_proof_file']['name'] = toe?.evidence?.document_proof_file || null;
    if(modal){
      this.openToeUpdate(toe,modal)
    }


  }
  mapToE(cIndex:number){
    let selectedToEs:any = this.projectToEData[cIndex]['term_sub_category'];
    let selectedSubCategoryIds = selectedToEs.map((e:any)=>{
      let mappedIDs = (e['toe'].filter((o:any)=> o.is_linked_toe)?? []).map((obj:any)=>obj.id);
      return {id:e.id,toe_ids:mappedIDs,is_linked_sub_category:e.is_linked_sub_category};
    });
    selectedSubCategoryIds = selectedSubCategoryIds.filter((e:any)=> e.is_linked_sub_category||e.toe_ids.length>0);
    selectedSubCategoryIds.forEach((e:any)=>delete e.is_linked_sub_category);
    let payload={
      "project": this.projectId,
      "category":this.projectToEData[cIndex]?.id,
      "sub_categories":selectedSubCategoryIds??[]
    }
    this.spinner.show();
    this.projectService.mapProjectToE(payload).subscribe((res: any) => {
      this.spinner.hide();
      if(res){
        this.toastrService.success("Data saved successfully");
      }
    })
  }
  
  onChangePage(evt:any){
    this.toePage = evt; 
    this.onClickToE(this.selectedToEs[this.selectedUpdateChildIndex]?.term_sub_category?.[this.subCategoryData?.categoryIndex],this.selectedToEs[this.selectedUpdateChildIndex]?.term_sub_category?.[this.subCategoryData?.categoryIndex]?.toe?.[this.toePage-1],this.toePage-1,this.subCategoryData?.categoryIndex);
  }
  onSelectSubCategory(evt:any,index:number){
    if(evt.target.checked){
      this.projectToEData[this.selectedSetupChildIndex]?.term_sub_category?.[index]?.toe?.map((e:any)=>e.is_linked_toe =true);      
    }
  }
  
  onSelectFile(evt:any,key:string){
    this.selectedFile[key] = evt?.target.files[0];    
  }  
  deleteFile(key:string) {
    this.selectedFile[key] ='';
  }
  updateToEData(toe:any){
    if(this.toeForm.invalid){
      this.toastrService.error("Please fill all the required fields");
      return;
    }else if( (toe?.self_declaration && !this.selectedFile['self_declaration_file']) || (toe?.physical_verification && !this.selectedFile['physical_verification_file']) || (toe?.document_proof && !this.selectedFile['document_proof_file'])){
      this.toastrService.error("Please upload the documents");
      return;
    }else{
      // let self_declaration_verified_by = this.teamList.find((o:any)=>o.mail == this.toeForm.value?.self_declaration_verified_by);
      // let physical_verification_verified_by = this.teamList.find((o:any)=>o.mail == this.toeForm.value?.physical_verification_verified_by);
      // let document_proof_verified_by = this.teamList.find((o:any)=>o.mail == this.toeForm.value?.document_proof_verified_by);
      let payload:any = {
        "toe_status":{
          "project": Number(this.projectId),
          "toe": toe?.id,
          "toe_status": this.toeForm.value?.toe_status,
          "toe_status_month": this.toeForm.value?.toe_status_month
        },
        "toe_metric": this.toeForm.get("metrics").getRawValue(),
        "toe": toe?.id,
        "project":Number(this.projectId),
        // "self_declaration_verified_by":self_declaration_verified_by,
        // "physical_verification_verified_by": physical_verification_verified_by,
        // "document_proof_verified_by": document_proof_verified_by,
        // "self_declaration_date": this.date.transform(this.toeForm.value?.self_declaration_date,"yyyy-MM-dd"),
        // "physical_verification_date": this.date.transform(this.toeForm.value?.physical_verification_date,"yyyy-MM-dd"),
        // "document_proof_date": this.date.transform(this.toeForm.value?.document_proof_date,"yyyy-MM-dd"),
        // "self_declaration_month": this.toeForm.value?.self_declaration_month,
        // "physical_verification_month": this.toeForm.value?.physical_verification_month,
        // "document_proof_month": this.toeForm.value?.document_proof_month,
        // "self_declaration_file": this.selectedFile['self_declaration_file']?.name,
        // "physical_verification_file": this.selectedFile['physical_verification_file']?.name,
        // "document_proof_file": this.selectedFile['document_proof_file']?.name
      }
      let toe_compliance:any = [];
      this.subCategoryData?.toe?.compliance.forEach((element:any) => {
        toe_compliance.push({"toe": toe?.id, 
        "project":Number(this.projectId),
        "compliance_name": element?.display_name, 
        "compliance_status": this.toeForm.get(element?.name+"_status").value, 
        "compliance_month": this.toeForm.get(element?.name+"_month").value
        });
      });
      
      let deleted_file:any = {}
      let formData = new FormData();
      this.subCategoryData?.toe?.evidence.forEach((element:any) => {
        let verifiedDetails = this.teamList.find((o:any)=> o.mail == this.toeForm.value?.[element.name+"_verified_by"]);
        payload[element.name+"_verified_by"] = verifiedDetails;
        payload[element.name+"_date"] = this.date.transform(this.toeForm.value?.[element.name+"_date"],"yyyy-MM-dd");
        payload[element.name+"_month"] = this.toeForm.value?.[element.name+"_month"];
        payload[element.name+"_file"] = this.selectedFile[element.name+"_file"]?.name;
        if(toe?.toe_evidence?.[element.name+"_file"] != this.selectedFile[element.name+"_file"]?.name || !this.selectedFile[element.name+"_file"] || !toe?.toe_evidence?.[element.name+"_file"]){
          if(this.selectedFile[element.name+"_file"]?.name)
            formData.append(element.name+"_file",this.selectedFile[element.name+"_file"]);
          if(toe?.toe_evidence?.[element.name+"_file"])
            deleted_file[element.name+"_file"] = toe?.toe_evidence?.[element.name+"_file"];
        }
      });
      payload['toe_compliance'] = toe_compliance;
      formData.append('toe_data',JSON.stringify(payload));
      // if(this.subCategoryData?.toe?.compliance?.SOX?.is_active =='true'){      
      //   toe_compliance.push({"toe": toe?.id, 
      //     "project":this.projectId,
      //     "compliance_name": "SOX", 
      //     "compliance_status": this.toeForm.get("soxCompStatus").value, 
      //     "compliance_month": this.toeForm.get("soxCompMonth").value
      //     });
      // }
      // if(this.subCategoryData?.toe?.compliance?.GDPR?.is_active =='true'){      
      //   toe_compliance.push({"toe": toe?.id, 
      //     "project":this.projectId,
      //     "compliance_name": "GDPR", 
      //     "compliance_status": this.toeForm.get("gdprCompStatus").value, 
      //     "compliance_month": this.toeForm.get("gdprCompMonth").value
      //     });
      // }
      // if(toe?.evidence?.self_declaration_file != this.selectedFile['self_declaration_file']?.name){
      //   formData.append('self_declaration_file',this.selectedFile['self_declaration_file']);
      //   deleted_file['self_declaration_file'] = toe?.evidence?.self_declaration_file;
      // }
      // if(toe?.evidence?.physical_verification_file != this.selectedFile['physical_verification_file']?.name){
      //   formData.append('physical_verification_file',this.selectedFile['physical_verification_file']);
      //   deleted_file['physical_verification_file'] = toe?.evidence?.physical_verification_file;
      // }
      // if(toe?.evidence?.document_proof_file != this.selectedFile['document_proof_file']?.name){
      //   formData.append('document_proof_file',this.selectedFile['document_proof_file']);
      //   deleted_file['document_proof_file'] = toe?.evidence?.document_proof_file;
      // }
      if(Object.keys(deleted_file)?.length>0){
        formData.append('deleted_file',JSON.stringify(deleted_file));
      }
      this.spinner.show();
      this.projectService.updateProjectToE(formData,toe?.uuid).subscribe((res: any) => {        
        if(res){
          this.toastrService.success("Data saved successfully");          
          this.projectService.getMappedProjectToEs(this.projectId).subscribe((res: any) => {
            this.spinner.hide();
            this.selectedToEs = res;
            this.dialog.closeAll();
            this.spinner.show();
            this.projectService.getToEData(this.projectId).subscribe((res: any) => {
              this.spinner.hide();
              this.allProjectToeData = res;
              }); 
          })
        }else{
          this.spinner.hide();
        }
      })
    }
  }
  
  getTeamsList() {
    this.projectService.getOpportunityData('opportunity_teams').subscribe((getTeamList: any) => {
      this.teamList = getTeamList['records'].find((i: any) => i.field_name == "opportunity_team").field_values;      
    })
  }
 
  onKeyResultAdded(evt:any){

 let payload= {"project":this.projectId,
 "category":2,
 "sub_categories":[{"id":evt?.subchild?.id,"toe_ids":[evt?.child?.id]}]}

   this.spinner.show();
   this.projectService.mapProjectToE(payload).subscribe((res: any) => {
     this.spinner.hide();
     if(res){
       this.toastrService.success("Data saved successfully");
       this.getSelectedToes();
      // this.getToE();

     }
   })
 
 
   }
}

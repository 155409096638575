import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ChangeRequestComponent } from './change-request/change-request.component';
import { DataCredientialsComponent } from './data-credientials/data-credientials.component';
import { DeliveryResourcesComponent } from './delivery-resources/delivery-resources.component';
import { DeliveryoverviewComponent } from './deliveryoverview/deliveryoverview.component';
import { IndexComponent } from './index/index.component';
import { ProjectOkrLogComponent } from './project-okr-log/project-okr-log.component';
import { ProjectReleaseManagementComponent } from './project-release-management/project-release-management.component';
import { ProjectTrainingComponent } from './project-training/project-training.component';

import { RiskLogComponent } from './risk-log/risk-log.component';
import { ScorecardComponent } from './scorecard/scorecard.component';
import { ToeMilestonesComponent } from './toe-milestones/toe-milestones.component';
import { ApplicationMasterComponent } from './application-master/application-master.component';
import { ProjectOpenRequirmentComponent } from './open-requirment/open-requirment.component';
import { FinancialsComponent } from './financials/financials.component';
import { DeliveryScopeComponent } from './deliveryscope/deliveryscope.component';
import { DeliveryToeComponent } from './delivery-toe/delivery-toe.component';
import { AssessmentAuditComponent } from './assessment-audit/assessment-audit.component';



const routes: Routes = [
  /* 
    {
      path: 'credentials',
      component: DataCredientialsComponent,
    }, */
  {
    path: ':id',
    component: IndexComponent,
    data: { breadcrumb: null },
    resolve: { project: ProjectService },
    children: [

      {
        path: 'overview', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: DeliveryoverviewComponent,
      },
      {
        path: 'scope', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: DeliveryScopeComponent,
      },
      {
        path: 'terms', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: DeliveryToeComponent,
      },
      {
        path: 'resource', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: DeliveryResourcesComponent,
      },

      {
        path: 'scorecard', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: ScorecardComponent,
      },
      {
        path: 'change-request', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: ChangeRequestComponent
      },
      {
        path: 'credentials', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: DataCredientialsComponent,
      },
      {
        path: 'okrlog', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: ProjectOkrLogComponent,
      },
      {
        path: 'risklog', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: RiskLogComponent,
      },
      {
        path: 'release', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: ProjectReleaseManagementComponent,
      },
      {
        path: 'training', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: ProjectTrainingComponent,
      },
      {
        path: 'application-master', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: ApplicationMasterComponent
      },
      {
        path: 'open-requirment', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: ProjectOpenRequirmentComponent

      },
      {
        path: 'toe',
        component: ToeMilestonesComponent,
      },
      {
        path: 'financials', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: FinancialsComponent,
      },
      {
        path: 'assessment', data: { breadcrumb: (data: any) => `${data?.project?.project_name}` }, resolve: { project: ProjectService },
        component: AssessmentAuditComponent,
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryFunctionalityRoutingModule { }

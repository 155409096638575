<div class="row field-adjust" id="custom-height">
  <div *ngIf="!projectdetail" style="height: calc(100vh - 200px)">
    <app-loading></app-loading>
  </div>
  <div class="col-md-12" *ngIf="projectdetail">
    <div class="row">
      <div *ngIf="isRedirectFrom" class="w-auto p-0">
        <h3 class="screen-name" (click)="clickPrevious()">
          <mat-icon
            id="chevron_left"
            aria-hidden="false"
            aria-label="material-icons-filled "
            class="cursor-pointer"
            style="font-size: 35px"
            >chevron_left
          </mat-icon>
        </h3>
      </div>
      <div class="title-holder">
        <div class="d-flex align-items-center gap-2">
          <p id="title" class="fw-bold fs-24 mb-0">
            {{ staticText?.delivery?.overview?.overview }}
          </p>
          <span
            (click)="showPulses()"
            *ngIf="positivePulses > 0"
            class="positive-pulse"
          ></span>
          <span
            (click)="showPulses()"
            *ngIf="negativePulses > 0"
            class="negative-pulse"
          ></span>
          <button
            mat-button
            (click)="showCreatePulseDialog()"
            class="bg-primary text-white ml-auto"
            mat-button
            mat-flat-button
          >
            <mat-icon
              aria-hidden="false"
              aria-label="material-icons-filled "
              class="cursor-pointer fs-5"
              >add
            </mat-icon>
            Add Pulse
          </button>
        </div>

        <p class="m-0 mb-1" style="font-size: 13px">
          {{ projectdetail?.project_id }} |
          {{ programDetail?.portfolio_name }} |
          {{ programDetail?.subportfolio_name }} |
          {{ programDetail?.program_name }} | {{ programDetail?.manager }}
        </p>
        <app-tag
          *ngIf="projectdetail"
          [dataObj]="getTagObject()"
          [tagsIds]="projectdetail?.tag_ids"
        ></app-tag>
      </div>
      <!-- <div class="col-md-3 button-pos" *ngIf="!!stepperTitles.buttonName && !disableFields">
                <button id='+parameter' mat-button class="float-end danger fs-16" mat-flat-button
                    (click)="addPopup()">
                    <mat-icon id="add" aria-hidden="false" aria-label="material-icons-filled"
                        class="cursor-pointer fs-5">add
                    </mat-icon>
                    {{stepperTitles.buttonName}}
                </button>
            </div> -->
    </div>
    <hr />
    <div class="scroll px-4">
      <div class="row">
        <div class="shadow bg-white rounded col-12">
          <div class="row">
            <div class="col-md-10 ps-0">
              <p
                class="pt-2 fw-500 fs-12 sleft-border setup-border"
                style="color: #ffffff"
              >
                <span class="px-3">Project Details</span>
              </p>
            </div>
            <div class="col-md-2">
              <section class="float-end pt-2" *ngIf="!isEditMode">
                <mat-icon
                  class="edit-icon pointer-event"
                  (click)="editProjectDetailVisibility(true)"
                  >edit</mat-icon
                >
              </section>
            </div>
          </div>
          <form [formGroup]="projectDetailForm">
            <div class="row">
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0">Project</p>
                <p class="text-black fw-bold">
                  {{ projectDetail?.project_name || "-" }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0">Portfolio</p>
                <p class="text-black">
                  {{ programDetail?.portfolio_name || "-" }}
                </p>
              </div>
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0">Sub-Portfolio</p>
                <p class="text-black">
                  {{ programDetail?.subportfolio_name || "-" }}
                </p>
              </div>
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0">Program</p>
                <p class="text-black">
                  {{ programDetail?.program_name || "-" }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <p class="fs-14 title-color fw-normal m-0">
                  Scope Brief Description
                </p>
                <p class="text-black">
                  {{ scopeBrief || "-" }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0" *ngIf="!isEditMode">
                  Owner
                </p>
                <p class="text-black" *ngIf="!isEditMode">
                  {{ projectDetail?.owner?.first_name || "-" }}
                  {{ projectDetail?.owner?.last_name || " " }}
                </p>
                <mat-form-field
                  class="col-12 px-2"
                  appearance="outline"
                  *ngIf="isEditMode"
                >
                  <mat-label>Owner</mat-label>
                  <mat-select formControlName="owner">
                    <mat-option
                      *ngFor="let option of ownerList"
                      [value]="option.id"
                      >{{ option.first_name }}
                      {{ option.last_name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0" *ngIf="!isEditMode">
                  Sponsor
                </p>
                <p class="text-black" *ngIf="!isEditMode">
                  {{ projectDetail?.sponsor?.first_name || "-" }}
                  {{ projectDetail?.sponsor?.last_name || "" }}
                </p>
                <mat-form-field
                  class="col-12 px-2"
                  appearance="outline"
                  *ngIf="isEditMode"
                >
                  <mat-label>Sponsor</mat-label>
                  <mat-select formControlName="sponsor">
                    <mat-option
                      *ngFor="let option of ownerList"
                      [value]="option.id"
                      >{{ option.first_name }}
                      {{ option.last_name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0" *ngIf="!isEditMode">
                  Start Date
                </p>
                <p class="text-black" *ngIf="!isEditMode">
                  {{ projectDetail?.begin_date || "-" }}
                </p>
                <mat-form-field
                  class="col-12 px-2"
                  appearance="outline"
                  *ngIf="isEditMode"
                >
                  <mat-label>Start Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerValidFrom"
                    readonly
                    fullWidth
                    status="basic"
                    (click)="pickerValidFrom.open()"
                    formControlName="startDate"
                  />
                  <mat-datepicker-toggle matSuffix [for]="pickerValidFrom">
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerValidFrom> </mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0" *ngIf="!isEditMode">
                  End Date
                </p>
                <p class="text-black" *ngIf="!isEditMode">
                  {{ projectDetail?.expected_end_date || "-" }}
                </p>
                <mat-form-field
                  class="col-12 px-2"
                  appearance="outline"
                  *ngIf="isEditMode"
                >
                  <mat-label>End Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerValidFrom"
                    readonly
                    fullWidth
                    status="basic"
                    (click)="pickerValidFrom.open()"
                    formControlName="endDate"
                  />
                  <mat-datepicker-toggle matSuffix [for]="pickerValidFrom">
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerValidFrom> </mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0" *ngIf="!isEditMode">
                  Execution Type
                </p>
                <p class="text-black" *ngIf="!isEditMode">
                  {{
                    projectDetail?.execution_type
                      ? projectDetail?.execution_type?.display_name
                      : "-"
                  }}
                </p>
                <mat-form-field
                  class="col-12 px-2"
                  appearance="outline"
                  *ngIf="isEditMode"
                >
                  <mat-label>Execution Type</mat-label>
                  <mat-select formControlName="executionType">
                    <mat-option
                      *ngFor="let option of executionList"
                      [value]="option.id"
                      >{{ option.display_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="fs-14 title-color fw-normal m-0">Workforce Mix</p>
                <div class="d-flex">
                  <p class="text-black margin-right w-25">
                    OU: {{ projectDetail?.workforce.ou }} %
                  </p>
                  <p class="text-black margin-right w-25">
                    GDP: {{ projectDetail?.workforce.gdp }} %
                  </p>
                  <p class="text-black w-25">
                    Contractor: {{ projectDetail?.workforce.contractor }} %
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0">Total Budget</p>
                <p class="text-black">
                  {{
                    projectDetail?.financials.total_budget || "-"
                      | currency : "USD" : "symbol"
                  }}
                </p>
              </div>
              <div class="col-md-4">
                <p class="fs-14 title-color fw-normal m-0">Total Consumed</p>

                <p class="text-black">
                  {{
                    projectDetail?.financials.total_consumed || "-"
                      | currency : "USD" : "symbol"
                  }}
                </p>
              </div>
            </div>
            <div class="custom-buttons-display" *ngIf="isEditMode">
              <button
                mat-button
                class="bg-light custom-form-btn"
                mat-button
                mat-flat-button
                (click)="editProjectDetailVisibility(false)"
              >
                Cancel
              </button>
              <button
                mat-button
                class="bg-primary custom-cancel text-white"
                mat-button
                mat-flat-button
                (click)="editProjectDetails()"
              >
                Update
              </button>
            </div>
          </form>
        </div>

        <div class="mt-5 d-flex justify-content-between">
          <div>
            <p id="title" class="fw-bold fs-20">Related Contracts and SOWs</p>
          </div>
          <div *ngIf="!user.is_vendor">
            <button
              mat-button
              (click)="mapSOWContract(mapSow)"
              class="bg-primary text-white"
              mat-button
              mat-flat-button
            >
              <mat-icon
                aria-hidden="false"
                aria-label="material-icons-filled "
                class="cursor-pointer fs-5"
                >add
              </mat-icon>
              Map SOW
            </button>
          </div>
        </div>

        <!-- <form [formGroup]="form">
                        <div class="custom-table fixed-table-header" >
                            <table class="table table-bordered" style="min-height:100px ;" >
                                <thead>
                                    <tr>
                                        <th><span class="d-flex justify-content-center">OU/GDP</span></th>
                                        <th><span class="d-flex justify-content-center">Start Date</span></th>
                                        <th><span class="d-flex justify-content-center">Tenure</span></th>
                                        <th><span class="d-flex justify-content-center">Value</span></th>  
                                        <th><span class="d-flex justify-content-center fw-bold color-blue fs-13">Contract Upload  </span></th>   
                                        <th><span class="d-flex justify-content-center">Actions</span></th>     
                                    </tr>
                                </thead>
                                <tbody class="cursor-pointer" >
                                <tr formArrayName="vendorcontracts" *ngFor="let vendor of form.get('vendorcontracts').controls; index as i">
                                        <td scope="row" class="align-middle">
                                            <span *ngIf="!vendor.get('edit').value" class="d-flex justify-content-center fs-13" >
                                            {{vendor.get('name').value}}
                                                </span>

                                            <span *ngIf="vendor.get('edit').value" class="d-flex justify-content-center fs-13" [formGroupName]="i">
                                                <mat-form-field class="w-50" appearance="outline"  style="height: 40px;">
                                                    <mat-label>OU/GDP</mat-label>
                                                    <input type="text" matInput status="basic"
                                                        formControlName="name">
                                                </mat-form-field>
                                            </span> 
                                        </td>
                                        <td class="align-middle">
                                            <span *ngIf="!vendor.get('edit').value" class="d-flex justify-content-center fs-13" >
                                                {{vendor.get('sowdate').value||"Not Available"}}
                                                </span>
                                            <span *ngIf="vendor.get('edit').value" class="d-flex justify-content-center fs-13" [formGroupName]="i">

                                                <mat-form-field appearance="outline" class="w-100" style="width:140px !important;height: 40px;">
                                                    <mat-label >Start Date</mat-label>
                                                    <input matInput [matDatepicker]="picker"
                                                        formControlName="sowdate" 
                                                        fullWidth status="basic" (click)="picker.open()">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                                </span>
                                        </td>
                                        <td class="align-middle">
                                            <span *ngIf="!vendor.get('edit').value" class="d-flex justify-content-center fs-13" >
                                                {{vendor.get('tenure').value||"Not Available"}}
                                                </span>
                                            <span *ngIf="vendor.get('edit').value" class="fs-13" [formGroupName]="i">
                                                <mat-form-field class="w-100 " appearance="outline" style="width:100px !important;height: 40px;">
                                                    <mat-label>Tenure</mat-label>
                                                    <input type="text" matInput status="basic"
                                                        formControlName="tenure">
                                                </mat-form-field>
                                                
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <span *ngIf="!vendor.get('edit').value" class="d-flex justify-content-center fs-13" >
                                                {{vendor.get('tenure').value||"Not Available"}}
                                                </span>
                                            <span *ngIf="vendor.get('edit').value" class="d-flex justify-content-center fs-13" [formGroupName]="i">
                                                <mat-form-field class="w-100" appearance="outline" style="width:100px !important;height: 40px;">
                                                    <mat-label>Value</mat-label>
                                                    <input type="text" matInput status="basic"
                                                        formControlName="value">
                                                </mat-form-field>
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <span *ngIf="vendor.get('fileName').value" class="d-flex justify-content-center fs-13" >
                                                    <img src="./assets/images/docs.png" height="40" *ngIf="vendor.get('fileName').value.includes('.doc') || data?.name?.includes('.docx') ">                                                                                    
                                                    <img src="./assets/images/pdf-icon-new.svg" height="40" *ngIf="vendor.get('fileName').value.includes('.pdf') ">
                                                    <img src="./assets/images/powerpoint.png" height="40" *ngIf="vendor.get('fileName').value.includes('.pptx') || vendor.get('fileName').value.includes('.ppt')">
                                                    <p class="w-100 cursor-pointer" style="    margin-right: 5px;
                                                    margin-left: 5px;
                                                    margin-top: 10px;" >
                                                        {{vendor.get('fileName').value}}</p>
                                                    <img src="./assets/images/delete-icon-new.svg" class="cursor-pointer" height="40"
                                                        (click)="deleteFile(e,i)" >
                                                </span>

                                            <span *ngIf="!vendor.get('fileName').value" class="d-flex justify-content-center fw-bold color-blue fs-13" [formGroupName]="i">
                                                <div class="p-2 mt-1 upload justify-content-center">
                                                    
                                                        <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                        (click)="fileInput.click()">
                                                        <span class="ms-2  secondary" style="margin-top: -6px;">Upload </span>
                                                    </div>
                                                    <input hidden #fileInput  type="file" id="file" accept=".pdf,.ppt, .docx,.doc,.pptx,.xls,.xlsx" (change)="onSelectFile($event,i)">
                                                </div>  
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <span class="">
                                                <button class="d-flex justify-content-center" mat-icon-button matTooltip="Click to Edit"
                                                    class="iconbutton" color="primary" (click)="enableEdit(i)">
                                                    <mat-icon aria-label="Edit">edit</mat-icon>
                                                </button>
                                            </span>
                                        </td>
                                    
                                    
                                    
                                    </tr>
                                    <tr *ngIf="form.get('vendorcontracts')?.controls?.length == 0" >
                                        <td colspan="6" class="empty-txt pt-4">Sorry, we couldn't find any matching contract at the moment</td>
                                    </tr>

                                </tbody>
                            
                            </table>
                        </div>
                    </form> -->
        <div class="custom-table fixed-table-header">
          <table class="table table-bordered" style="min-height: 100px">
            <thead>
              <tr>
                <th><span class="d-flex justify-content-center">#</span></th>
                <th>
                  <span class="d-flex justify-content-center">Contract ID</span>
                </th>
                <th>
                  <span class="d-flex justify-content-center"
                    >Contract Name</span
                  >
                </th>
                <th>
                  <span class="d-flex justify-content-center">OU/GDP</span>
                </th>
                <th>
                  <span class="d-flex justify-content-center">Start Date</span>
                </th>
                <th>
                  <span class="d-flex justify-content-center">End Date</span>
                </th>
                <th>
                  <span class="d-flex justify-content-center"
                    >Contract Value
                  </span>
                </th>
                <th>
                  <span class="d-flex justify-content-center"
                    >Contract Value Tenure</span
                  >
                </th>
                <th>
                  <span class="d-flex justify-content-center"
                    >Contract Attachment</span
                  >
                </th>
              </tr>
            </thead>
            <tbody class="cursor-pointer">
              <ng-container
                *ngFor="let contract of projectContractsList; index as i"
              >
                <tr>
                  <td class="text-center">{{ i + 1 }}</td>
                  <td>{{ contract?.contract?.id }}</td>
                  <td>{{ contract?.contract?.contract_name }}</td>
                  <td>
                    {{
                      contract?.vendor?.name
                        ? contract?.vendor?.name
                        : contract?.contract?.vendor?.name
                    }}
                  </td>
                  <td>{{ contract?.contract?.start_date }}</td>
                  <td>{{ contract?.contract?.end_date }}</td>
                  <td>{{ contract?.contract?.contract_value }}</td>
                  <td>{{ contract?.contract?.tenure }}</td>
                  <td>
                    <a
                      (click)="
                        downloadFile(contract?.contract?.contract_attachment)
                      "
                      style="color: blue"
                      >{{ contract?.contract?.contract_attachment }}</a
                    >
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tr *ngIf="projectContractsList.length == 0">
              <td colspan="10" class="empty-txt pt-4">
                Sorry, we couldn't find any matching contract at the moment
              </td>
            </tr>
          </table>
        </div>
        <div class="row mt-4">
          <div class="d-flex flex-row">
            <div class="w-50 shadow bg-white rounded cards" *ngIf="okrData">
              <div class="card-headers">
                <p
                  class="pt-1 fw-500 fs-12 sleft-border tag-border setup-border"
                  style="color: #ffffff"
                >
                  <span class="px-3">OKR</span>
                </p>
              </div>
              <div class="w-100 cardBody">
                <div class="okr-div" *ngFor="let data of okrData">
                  <div style="margin-bottom: 2px">
                    <p class="fs-14 title-color m-0" style="font-weight: 700">
                      {{ data.name ? data.name : "-" }}
                    </p>
                  </div>
                  <div
                    class="okr-div"
                    *ngFor="let item of data.goals; let indx = index"
                  >
                    <p class="fs-14 fw-normal m-0">
                      {{ item.name ? item.name : "-" }}
                    </p>
                    <div class="okr-card">
                      <div class="progress-bar">
                        <div
                          [ngStyle]="{
                            height: '100%',
                            'background-color': '#003770',
                            width: item.percentage + '%',
                            'border-radius': '15px'
                          }"
                        ></div>
                      </div>
                      <p class="fs-14 data fw-normal m-0">
                        {{ item.percentage }}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-50 shadow bg-white rounded cards" *ngIf="riskData">
              <div class="card-headers">
                <p
                  class="pt-1 fw-500 fs-12 sleft-border tag-border setup-border"
                  style="color: #ffffff"
                >
                  <span class="px-3">Risk</span>
                </p>
              </div>
              <div class="w-100 cardBody">
                <div class="detail">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="riskData.chartOptions"
                    style="width: 100%; height: 150px; display: block"
                  >
                  </highcharts-chart>
                </div>
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    No. of risk passed due date :
                    {{ riskData.no_of_project_risks_with_mitigation }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div
              class="w-50 shadow bg-white rounded cards"
              *ngIf="deliveryData"
            >
              <div class="card-headers">
                <p
                  class="pt-1 fw-500 fs-12 sleft-border tag-border setup-border"
                  style="color: #ffffff"
                >
                  <span class="px-3">Delivery</span>
                </p>
              </div>
              <div class="w-100 cardBody">
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    Total of recent releases :
                    {{ deliveryData.recent_releases }}
                  </p>
                </div>
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    Completed within due date :
                    {{ deliveryData.completed_releases_within_due_date }}
                  </p>
                </div>
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    Upcoming releases :
                  </p>
                </div>
                <div class="detail">
                  <div class="tags">
                    <div
                      class="chip-div"
                      *ngIf="deliveryData.upcoming_releases.length > 0"
                    >
                      <div
                        class="chips"
                        *ngFor="let chip of deliveryData.upcoming_releases"
                      >
                        <span class="chip-head">{{
                          releaseMap[chip[0]] ? releaseMap[chip[0]] : chip[0]
                        }}</span>
                        <span class="chip-body">{{ chip[1] }}</span>
                      </div>
                    </div>
                    <div
                      class="chip-div"
                      *ngIf="deliveryData.upcoming_releases.length === 0"
                    >
                      <div class="chips">
                        <span class="chip-head">No Data</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-50 shadow bg-white rounded cards" *ngIf="peopleData">
              <div class="card-headers">
                <p
                  class="pt-1 fw-500 fs-12 sleft-border tag-border setup-border"
                  style="color: #ffffff"
                >
                  <span class="px-3">People</span>
                </p>
              </div>
              <div class="w-100 cardBody">
                <div class="detail">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="peopleData.chartData"
                    style="width: 100%; height: 150px; display: block"
                  >
                  </highcharts-chart>
                </div>
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    Attrition Rate :
                    {{ peopleData.attrition_rate }}
                  </p>
                </div>
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    Job title with upcoming requirements :
                  </p>
                </div>

                <div class="detail">
                  <div
                    class="chip-div"
                    *ngIf="
                      peopleData.employees_with_lwd_next_month.length === 0
                    "
                  >
                    <div class="chips">
                      <span class="chip-head">No Data</span>
                    </div>
                  </div>
                  <div
                    class="chip-div"
                    *ngIf="peopleData.employees_with_lwd_next_month.length > 0"
                  >
                    <div
                      class="chips"
                      *ngFor="
                        let chip of peopleData.employees_with_lwd_next_month
                      "
                    >
                      <span class="chip-head">{{ chip.job_title }}</span>
                      <span class="chip-body">{{ chip.total }}</span>
                    </div>
                  </div>
                </div>
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    Total open request :
                    {{ peopleData.open_requirements }}
                  </p>
                </div>
                <div class="detail">
                  <p class="fs-14 header title-color fw-normal m-0 w-100">
                    Open request that has Aging :
                  </p>
                </div>
                <div class="detail">
                  <div
                    class="chip-div"
                    *ngIf="peopleData.aging_open_requirements.length === 0"
                  >
                    <div class="chips">
                      <span class="chip-head">No Data</span>
                    </div>
                  </div>
                  <div
                    class="chip-div"
                    *ngIf="peopleData.aging_open_requirements.length > 0"
                  >
                    <div
                      class="chips"
                      *ngFor="let chip of peopleData.aging_open_requirements"
                    >
                      <span class="chip-head">{{ chip.job_title }}</span>
                      <span class="chip-body">{{ chip.open_positions }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="my-3 text-right px-4" *ngIf="isEditEnabled">
            
            <button type="button" class="btn btn-primary button-save"  [disabled]="form.invalid " (click)="saveProjectDetails()"
            >{{staticText?.buttons?.save_btn}}</button>
        </div> -->
    </div>
  </div>
</div>

<div class="row field-adjust" id="custom-height">
  <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="d-flex align-items-center">
  <mat-icon class="text-warning cursor-pointer mb-2 fs-5"> star</mat-icon>
  <span> - Recommended </span>
</div>
<section class="riskLog">
  <!-- <div class="row row-cols-2 m-0">
        <div [ngClass]="activeTab == staticText?.delivery.risk_log.setup||activeTab == staticText?.delivery.risk_log.risk_radar? 'col-12':'col-12'"> -->
  <!-- <mat-card class="centerPane min-card-height"> -->
  <!-- (selectedTabChange)="changeTab($event)" -->
  <mat-tab-group mat-align-tabs="start" class="mt-3 head">
    <mat-tab label="{{ staticText?.delivery.risk_log.setup }}">
      <app-loading *ngIf="!riskArray"></app-loading>
      <div style="height: calc(100vh - 395px); overflow: scroll">
        <app-selection-tree-listing
          [(items)]="riskArray"
          selectedOkr="selectedRisks"
          [disabled]="false"
          [tree]="'project'"
          [context]="context"
        ></app-selection-tree-listing>
      </div>
      <div class="footer-right" *ngIf="!user?.is_vendor">
        <button
          mat-button
          mat-flat-button
          class="bg-primary text-white w-100 border mb-2"
          color="primary"
          (click)="saveSelection()"
        >
          Save
        </button>
      </div>
      <!-- <div class="riskCenterList  mt-2" style="overflow: scroll; max-height: 600px;min-height: 600px;">
                            <div class="container-fluid p-0">
                                <div class="p-1 rightTreeCard" *ngFor="let tree of slaveRiskLogs | filter:searchText;index as treeindex">
                                    <div class="d-flex flex-column">
                                        <app-tree [treearray]="tree" [treearraycopy]="allRisks" [projectWorkType]="projectWorkType" [treeindex]="treeindex" [treecardtype]="'formtree'" [addicon]="true" [parentTagName]="'RiskRecommendRightPaneParent'" [formShow]="false" [subParentTagname]="'RiskRecommendRightPaneSubparent'"
                                            [treeAddPermission]="'delivery.add_projectmapping'" (addkeyresult)="removeRightPaneRecommendedRiskLog($event)">
                                        </app-tree>
                                        <div class="text-center" *ngIf="!slaveRiskLogs?.length && !searchText">
                                            <p class="my-2">{{staticText.common.no_records_found}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="slaveRiskLogs?.length == 0">
                                <div class="col-12 mx-auto">
                                    <img src="./assets/images/no-record.png" class="no-records" height="100%" width="100%">
                                </div>
                                <div class="col-8 mx-auto">
                                    <p class="text-center fw-bold text-muted">
                                        {{staticText.common.no_records_found}}
                                    </p>
                                </div>
                            </div>
                        </div> -->
    </mat-tab>
    <mat-tab label="{{ staticText?.delivery.risk_log.risk_heatmap }}">
      <app-risklog-heatmap
        [riskArrayHeatMap]="riskArrayHeatMap"
        [projectId]="projectId"
      ></app-risklog-heatmap>
    </mat-tab>

    <!-- <mat-tab label="{{staticText?.delivery.risk_log?.risk_radar}}">
            <div class="d-flex justify-content-between" style="min-height: 540px;">
                <div class="pie d-flex justifiy-content-center" style="margin-top:30px;"  [chart]="stock"></div>



                <div class="col-5 " *ngIf="showStatusRightPane">
                    <mat-card class="rightPane p-0">
                        <div class="d-flex border-bottom">
                            <span class="bubble-right pt-2 pb-2"></span>
                            <div class="pe-1 ps-1 w-100">
                                <div class="d-flex justify-content-between align-items-center topBorders">
                                    <div class="p-3">
                                        <p class="fw-bold fs-18 text-black mb-0">
                                            {{staticText?.risk_convergence.right_pane_heading}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-card-content class="riskRightList">
                            <div class="container-fluid pe-1 ps-1">
                                <div class="d-flex flex-column align-items-center mt-3">
                                    <div class="d-flex w-100 p-2">Status:&nbsp; <b>{{filteredRisks?.name}}</b></div>
                                    <div class="listing-card" *ngFor="let riskItem of filteredRisks?.risks">
                                        <div class="d-flexalign-items-center p-2">
                                            <h5 class="mb-0 fs-16 fw-normal twoline-sentence">
                                                {{riskItem?.risk_name}}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions class="border-top p-2 m-2" align="end">
                            <button class="saveRisk text-white text-capitalize float-right" mat-raised-button
                                (click)="showStatusRightPane=false">
                                {{staticText.buttons.close_btn}}
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </mat-tab> -->
  </mat-tab-group>
  <!-- </mat-card> -->
  <!-- </div> -->
  <!-- <div class="col-4" *ngIf="activeTab == staticText?.delivery.risk_log.setup">
            <mat-card class="rightPane p-0 min-card-height">
                <div class="d-flex border-bottom">
                    <span class="bubble-right pt-2 pb-2"></span>
                    <div class="pe-1 ps-1 w-100">
                        <div class="d-flex justify-content-between align-items-center topBorders">
                            <div class="p-3">
                                <p class="fw-bold fs-18 text-black mb-0">{{staticText?.delivery.risk_log.project_risk}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <mat-card-content class="riskRightList">
                    <div class="container-fluid pe-1 ps-1">

                        <app-tree [treecardtype]="'formtreenew'" [showChildOnly]="true" [treearray]="allRisks" [riskSetupFormArray]="riskSetupFormArray" [parentTagName]="'RiskCenterPaneParent'" [removeicon]="true" [formShow]="true" [formArray]="riskFormInputValues" [subParentTagname]="'RiskCenterPaneSubParent'"
                            [formTagName]="'RiskCenterPaneForm'" [RiskLogform]="true" (formData)="createFormDataPayload($event)" (formDatanew)="requestPayloadToApi($event)" (addkeyresult)="removeRiskLogs($event)" isCentrePane="true" [projectId]="projectId"
                            [treeRemovePermission]="'delivery.delete_projectmapping'">
                        </app-tree>


                    </div>
                </mat-card-content>
            </mat-card>
        </div> -->
  <div
    class="col-4"
    *ngIf="activeTab == staticText?.delivery.risk_log.risk_radar"
  >
    <mat-card class="rightPane p-0 min-card-height">
      <div class="d-flex border-bottom">
        <span class="bubble-right pt-2 pb-2"></span>
        <div class="pe-1 ps-1 w-100">
          <div
            class="d-flex justify-content-between align-items-center topBorders"
          >
            <div class="p-3">
              <p class="fw-bold fs-18 text-black mb-0">
                {{ staticText?.delivery.risk_log.risk_domains }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <mat-card-content class="riskRightList">
        <div class="container-fluid pe-1 ps-1">
          <div [formGroup]="riskDomainForm">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 95%; margin-left: 10px"
            >
              <mat-label>Risk Domains</mat-label>
              <mat-select
                formControlName="riskDomain"
                placeholder="Risk Domains"
                required="true"
              >
                <mat-option
                  *ngFor="let iitem of risklogsnew"
                  [value]="iitem.risk_domain_uuid"
                  (onSelectionChange)="
                    selectdomainforriskradar(
                      iitem.risk_domain_uuid,
                      iitem?.risk_domain_name,
                      $event
                    )
                  "
                  >{{ iitem?.risk_domain_display_name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <div class="okr-subParent-container pe-2 ps-2 pt-2 cursor-pointer ng-star-inserted">
                            <div class="card p-0 shadow-none sub-parentcontext" (click)="selectdomainforriskradar(1)" [ngClass]="selectedRiskDomain==1?'domain_selected':'domain_unselected'">
                                <div class="card-body p-2 pl-0" style="padding-left: 0px !important;">
                                    <h5 class="subParent-Heading fs-16 threeline-sentence d-flex align-items-center">
                                        <span class="bubble-left pt-2 pb-2 ng-star-inserted" style="background: green;"> 1 </span>
                                        <span mattooltipclass="example-tooltip" class="mat-tooltip-trigger fw-400 pl-1">
                                            All Domains </span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let domain of risklogs;index as i" class="okr-subParent-container pe-2 ps-2 pt-2 cursor-pointer ng-star-inserted">
                            <div class="card p-0 shadow-none sub-parentcontext" (click)="selectdomainforriskradar(i+2,domain?.risk_domain_name)" [ngClass]="selectedRiskDomain==i+2?'domain_selected':'domain_unselected'">
                                <div class="card-body p-2 pl-0" style="padding-left: 0px !important;">
                                    <h5 class="subParent-Heading fs-16 threeline-sentence d-flex align-items-center">
                                        <span class="bubble-left pt-2 pb-2 ng-star-inserted" style="background: green;"> {{i+2}} </span>
                                        <span mattooltipclass="example-tooltip" class="mat-tooltip-trigger fw-400 pl-1">
                                            {{domain?.risk_domain_name}} </span>
                                    </h5>
                                </div>
                            </div>
                        </div> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- </div> -->
</section>

<!-- <ng-template #addTracker>
    <div class="field-adjust">
        <div class="modal-dialog action-tracker-popup mb-0">
            <div class="modal-content b-none tableModelStyle">
                <div class="modal-header d-flex justify-content-between align-items-center pt-0 pb-1">
                    <h2 mat-dialog-title class="mb-0 fw-bold color-Port-Gore fs-18">
                        {{staticText?.action_tracker?.title}}</h2>
                    <img class="cursor-pointer" src="./assets/images/pages/workforce/close.svg" alt="closeModal"
                        (click)="closeDialog()">
                </div>
                <div class="modal-body d-flex flex-column justify-content-between pb-0">
                    <form novalidate class="p-0">
                        <mat-dialog-content style="max-height: 40vh;overflow: scroll;">

                            <div class="" [formGroup]="riskSetupFormArray">
                                <div class="form-group required">
                                    <div class="row col-9 px-0 details-bg-1" hidden>
                                        <div class="col-2" style="margin-left: 30px;">
                                            <label for="benefits"
                                                class="labelstyle">{{staticText?.action_tracker?.basic_details}}</label>

                                        </div>

                                        <div class="required mb-0 col-2" style="margin-left: 30px;">
                                            <label for="benefits"
                                                class="labelstyle">{{staticText?.action_tracker?.basic_details}}</label>

                                        </div>

                                        <div class="col-2" style="margin-left: 30px;">
                                            <label for="benefits"
                                                class="labelstyle">{{staticText?.action_tracker?.basic_details}}</label>


                                        </div>
                                        <div class="required mb-0 col-2" style="margin-left: 30px;">
                                            <label for="benefits"
                                                class="labelstyle">{{staticText?.action_tracker?.basic_details}}</label>

                                        </div>
                                        <div class="required mb-0 col-2" style="margin-left: 30px;">
                                            <label for="benefits"
                                                class="labelstyle">{{staticText?.action_tracker?.basic_details}}</label>

                                        </div>
                                    </div>
                                    <div formArrayName="getriskDetailFormArray">
                                        <div class="row px-0"
                                            [ngClass]="{'details-bg-safe':fform.get('planed_end_date_passed').value===false,'details-bg-alert':fform.get('planed_end_date_passed').value===true}"
                                            *ngFor="let fform of getriskDetailFormArray?.controls; let i = index"
                                            [formGroup]="fform">
                                            <mat-form-field class="w-33" id="actionplanformfield" appearance="outline">
                                                <mat-label id="actionplanformfieldlable">
                                                    Action Plan/Task
                                                </mat-label>
                                                <input id="actionplanformfieldinput" type="text" formControlName="tasks"
                                                    matInput [name]="'action_task-'+i"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Action Plan/Task"
                                                    disabled />
                                            </mat-form-field>


                                            <mat-form-field id="planneddateformfield" class="w-33" appearance="outline"
                                                style="max-width: 125%;">
                                                <mat-label id="planneddateformfieldlable">Planned End Date</mat-label>
                                                <input id="planneddateformfieldinput" matInput
                                                    formControlName="planed_end_date" [matDatepicker]="picker"
                                                    [name]="'due_date-'+i" [ngModelOptions]="{standalone: true}"
                                                    dateFormat="yyyy/MM/dd" (click)="picker.open()">
                                                <mat-datepicker-toggle id="planneddateformfieldtoggle" matSuffix
                                                    [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker id="planneddateformfieldpicker" #picker>
                                                </mat-datepicker>
                                            </mat-form-field>

                                            <mat-form-field id="statusformfield" class="w-33" appearance="outline">
                                                <mat-label id="statusformfieldlable">Status</mat-label>
                                                <mat-select id="statusformfieldselect" formControlName="status"
                                                    placeholder="Status" required="true">
                                                    <mat-option id="statusformfieldoption_{{item?.display_name}}"
                                                        *ngFor="let iitem of statusListNew"
                                                        [value]="iitem.id">{{iitem?.display_name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>


                                            <mat-form-field id="actualdateformfield" class="w-33" appearance="outline"
                                                style="max-width: 125%;">
                                                <mat-label id="actualdateformfieldlable">Actual End Date</mat-label>
                                                <input id="actualdateformfieldinput" matInput
                                                    formControlName="actual_end_date" [matDatepicker]="apicker"
                                                    [name]="'actual_date-'+i" [ngModelOptions]="{standalone: true}"
                                                    dateFormat="yyyy/MM/dd" (click)="apicker.open()">
                                                <mat-datepicker-toggle id="actualdateformfieldtoggle" matSuffix
                                                    [for]="apicker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker id="actualdateformfieldpicker" #apicker>
                                                </mat-datepicker>
                                            </mat-form-field>

                                            <mat-form-field id="remarkformfield" class="w-33" appearance="outline">
                                                <mat-label id="remarkformfieldlable">
                                                    Remarks
                                                </mat-label>
                                                <input id="remarkformfieldinput" type="text" formControlName="remarks"
                                                    matInput [name]="'action_remarks-'+i"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Remarks" />
                                            </mat-form-field>



                                        </div>
                                    </div>
                                </div>


                            </div>
                        </mat-dialog-content>

                        <div class="float-right">
                            <button mat-button (click)="closeDialog()" class="my-3 px-4 p-0 border border-default me-3">
                                <span class="m-0">
                                    {{staticText?.buttons?.cancel_btn}}
                                </span>
                            </button>
                            <button mat-button class="my-3 me-3 btn-bg text-white px-4 p-0"
                                (click)="updateRiskDetails()">
                                <span class="m-0">
                                    {{staticText?.buttons?.save_btn}}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->

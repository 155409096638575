<div style="overflow-x: hidden">
    <div class="tab-holder mt-3">
      <div class="accordion" id="accordionPanel">
        <div
          *ngFor="let category of auditInstances; let i = index"
          class="accordion-item"
        >
          <h2 class="accordion-header" id="heading{{ i }}">
            <button
              class="accordion-button {{
                i === 0 ? '' : 'collapsed'
              }}"
              type="button"
              data-bs-toggle="collapse"
              attr.data-bs-target="#collapse{{ i }}"
              aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
              aria-controls="collapse{{ i }}"
              style="font-size: 13px; font-weight: 700"
            >
              {{ category.type }}
            </button>
          </h2>
          <div
            id="collapse{{ i }}"
            class="accordion-collapse collapse {{
              i === 0 ? 'show' : ''
            }}"
            aria-labelledby="heading{{ i }}"
            data-bs-parent="#accordionPanel"
          >
            <div class="accordion-body p-2">
              <div class="flex-container-1">
                <div
                  *ngIf="
                    category.instances &&
                    category.instances?.length <= 0
                  "
                  class="trn-no"
                >
                  No records found.
                </div>
                <app-loading
                  style="width: 100%; min-height: 200px"
                  *ngIf="!category.instances"
                ></app-loading>
                <div
                  class="flex-item p-1"
                  *ngFor="let x of category.instances"
                >
                  <div
                    class="trn-x"
                    [ngStyle]="{ background: x.color }"
                    style="height: 200px"
                    (click)="openInstance(x)"
                  >
                    <h6 class="mb-0 trn-nm">
                      {{ x.template.title }}
                    </h6>
                    <div
                      class="mb-0 mt-2 trn-ph"
                      *ngIf="x.created_by"
                    >
                      <div class="d-flex align-items-center">
                        <mat-icon class="small-icon mr-1"
                          >perm_identity</mat-icon
                        >
                        <span>Owner</span>
                      </div>
                      <span style="display: flex; font-weight: 700">
                        {{
                          x.created_by.first_name +
                            " " +
                            x.created_by.last_name
                        }}
                      </span>
                    </div>
                    <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                      Created On
                      <span style="display: flex; font-weight: 700">
                        {{ x.start_date | date : "dd MMM, yyyy" }}
                      </span>
                    </p>
                    <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                      Due By
                      <span style="display: flex; font-weight: 700">
                        {{ x.end_date | date : "dd MMM, yyyy" }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="auditInstances?.length === 0" class="text-center">
        No records found
      </div>
    </div>
  </div>
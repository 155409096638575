<div class="row field-adjust" id="custom-height">
    <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="row p-0 data ml-3">
    <div class="row mr-1 pl-3">
        <div class="credientias pl-4 pr-0">
            <div class="sub-title pt-3">
                <app-bread-crumb></app-bread-crumb>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <p class="screen-title">
                        {{staticText?.delivery?.data_credentials.title}}
                    </p>
                </div>

                <div class="col-md-4">
                    <button mat-button class="float-end border primary lightSecondary project-btn p-2 ml-3"
                        (click)="addform()" mat-flat-button *ngxPermissionsOnly="['delivery.view_datacredentials']">
                        <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="cursor-pointer fs-5">
                            add
                        </mat-icon>
                        {{staticText?.delivery?.data_credentials.add_credentials}}
                    </button>
                </div>
            </div>
            <hr>
            <div class="scroll" style="max-height: 612px;min-height:612px;">
                <div>
                    <div class="mb-2 position-relative"
                        *ngFor="let data of dataCredientials | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount}  let i = index">
                        <div class="row grid jusify-content-between">
                            <div class="col-md-2 col-vendor">
                                <div class="pl-2 d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div class="mr-3 mt-2">
                                            <span class="pt-3 name position-relative"
                                                [ngStyle]="{'backgroundColor': i|getRandomColor}">{{getShortName(data.source_type.name)}}</span>
                                            <!--[ngClass]="{'titlecase-7':
                                                data.source_type.name.,
                                                'titlecase-1':
                                                data.source_type.name.includes('E'),
                                                'titlecase-3':
                                                data.source_type.name.includes('R'),
                                                'titlecase-6':
                                                data.source_type.name.includes('A'),
                                                'titlecase-5':
                                                data.source_type.name.includes('c') ,
                                                'titlecase-2':
                                                data.source_type.name.includes('k')
                                                }"-->
                                        </div>
                                        <div>
                                            <div class="d-flex mt-2">
                                                <p class="company-name">
                                                    {{data.source_type.name}}</p>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-project mt-2">
                                <div class="pl-2 d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div class="mr-2 mt-1">
                                            <i class="far fa-file-alt file-icon"></i>
                                        </div>
                                        <div>
                                            <div class="d-flex ">
                                                <p class="url mt-1">
                                                    {{data.source_name}}</p>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 mt-2">
                                <div class="pl-2 d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div>
                                            <div class="d-flex ">
                                                <p class="url mt-1" matTooltip="URL -  {{data.source_url}}"
                                                    matTooltipClass="example-tooltip">
                                                    {{data.source_url}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto col-2 col-name">
                                <div class=" d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div class="mr-2 mt-2">
                                            <span class="pt-3 name position-relative"
                                                [ngStyle]="{'backgroundColor': i|getRandomColor}">{{getName(data.username)}}</span>
                                            <!--[ngClass]="{'titlecase-7':
                                                data.username.includes('H'),
                                                'titlecase-1':
                                                data.username.includes('E'),
                                                'titlecase-3':
                                                data.username.includes('R'),
                                                'titlecase-6':
                                                data.username.includes('A'),
                                                'titlecase-5':
                                                data.username.includes('c') ,
                                                'titlecase-2':
                                                data.username.includes('k')
                                                }"-->
                                        </div>
                                        <div>
                                            <div class="d-flex mt-2">
                                                <p class="company-name" matTooltip="{{data.username}}">
                                                    {{data.username}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto col-2 p-0">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex  mt-2 justify-content-center align-items-center">
                                        <div class="form-group has-search" (click)="onClick()">
                                            <span class="far fa-eye form-control-feedback show-password"
                                                *ngIf="!show"></span>
                                            <span class="fas fa-eye-slash form-control-feedback show-password"
                                                *ngIf="show"></span>
                                            <input [type]="password" value="{{data.password}}" autocomplete="off"
                                                class="form-control" placeholder="Search">
                                        </div>
                                    </div>
                                    <div class="px-2 cursor-pointer" (click)="editCredientials(data.id)"
                                        matTooltip="Click to Edit ">
                                        <i class="fas fa-arrow-right arrow button"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="last-modified" [matTooltipClass]="'my-tooltip'"
                            matTooltip="'Last Updated by: {{data.last_updated_by}}'    'Last Updated on: {{data.last_updated_on | date}}'   ' API Token: {{data.api_token}}'   'Data Type:   {{data.data_type}}'">
                            <p> {{staticText?.common.more_info}}</p>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="dataCredientials?.length == 0">
                    <div class="col-md-5 mx-auto">
                        <img src="./assets/images/no-record.png" class="noFound" height="100%"
                            width="100%">
                    </div>
                    <div class="col-md-8 mx-auto">
                        <p class="text-center fw-bold text-muted"> {{staticText.common.no_records_found}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-addcredientials *ngIf="actionmode != '' && actionmode != 'list'" [selected_id]="selected_id"
        (closemodalwindow)=changeCount($event) (refereshParentData)=callRefereshParentData($event)>
    </app-addcredientials>

    <ng-template #delete>
        <div class="modal-dialog custom-width m-0">
            <div class="modal-content">
                <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
                <div class="modal-body">
                    <p class=" fs-5 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                </div>
                <div class="modal-footer border-0">
                    <div class="row">
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close
                                (click)="closeDialog()">{{staticText?.modal_popup?.no_btn}}</button>
                        </div>
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close (click)="deleteDialog()"
                                class="bg-primary text-white">{{staticText?.modal_popup?.yes_btn}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
import { Component, OnInit, ViewChild, Input, Output, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/shared/services/project.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MatStepper } from '@angular/material/stepper';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss']
})
export class LeftBarComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  projectId: any
  opportunityId: any='';
  stepLabelSide: any;
  lastUrlSegment: string | any;
  stepLabel: any;
  tips = true;
  showIdea = false;
  oppActive = true;
  @ViewChild('stepper') stepper: MatStepper | any;
  projectdetail: any;
  progressUpdate: any;
  convertedID='';  
  percent=0;
  colorCode='#FFC000';

  constructor(private projectservice: ProjectService,private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService, private router: Router,
    private cdRef: ChangeDetectorRef, private permissionsService: NgxPermissionsService,
  ) { this.getprojectId();
  }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);    
    this.getprojectdetails();
    }

  ngAfterViewInit() {
    this.setStepperData(null);
    
    this.projectservice.getStatusValue().subscribe((newValue) => {
      if(newValue?.slug != this.projectdetail?.status?.slug){
        this.projectdetail['status'] = newValue;
      }
    });

  }

  getprojectId() {
    this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.id);
      this.convertedID = params.id;
    });
  }


  getprojectdetails() {
    this.spinner.show();
    this.projectservice.getprojectdetailsOverview(this.projectId).subscribe((response: any) => {
      this.projectdetail = response;
      this.opportunityId = btoa(response?.opportunity?.id);
      if(this.projectdetail?.status?.slug == 'project_initiated'){  
        this.percent=50;
        this.colorCode='#1a85d6';
      }else if(this.projectdetail?.status?.slug == 'project_delivery'){  
        this.percent=100;
        this.colorCode='#07b797';
      }else{
        this.percent=10;
        this.colorCode='#dd2c54';
      }
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error
    });
  }
  getPrimaryAddress() {
    this.router.navigate([`getproposal/${btoa(this.projectId)}/proposal-evaluation`])
  }

  navigateTo(data: any) {
    this.router.navigate([`view/${btoa(this.projectId)}/${data}/`])
  }


  setStepperData(routelink: any) {
    
    if (!routelink) {
      this.lastUrlSegment = this.router.url.split('?')[0].split('/').pop();
    } else {
      this.lastUrlSegment = routelink;
    }
    const id = this.router.url.split('?')[0].split('/');
    const url = id[0];
    if (url == 'view') {
      this.stepper.selectedIndex = 1;
      this.stepper.linear = false;
    }
    if (this.lastUrlSegment == 'overview') {
      this.progressUpdate = 0;
    }
    else if (this.lastUrlSegment == 'resource') {
      this.progressUpdate = 20;
    }
    else if (this.lastUrlSegment == 'scorecard') {
      this.progressUpdate = 40;
    }
    else if (this.lastUrlSegment == 'credentials') {
      this.progressUpdate = 60;
    }
    else if (this.lastUrlSegment == 'okrlog') {
      this.progressUpdate = 80;
    }
    else if (this.lastUrlSegment == 'risklog') {
      this.progressUpdate = 90;
    }
    else if (this.lastUrlSegment == 'release') {
      this.progressUpdate = 100;
    }
    this.cdRef.detectChanges()
  }
  showTip() {
  }

}

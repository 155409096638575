<section class="projectOkr">
    <div class="row row-cols-2 m-0">
        <div class="pr-0" [ngClass]="activeTab == staticText?.delivery.toe.setup? 'col-8':'col-12'">
            <mat-card class="centerPane">
                <mat-card-subtitle class="mb-0">
                    <app-bread-crumb></app-bread-crumb>
                </mat-card-subtitle>
                <mat-tab-group mat-align-tabs="start" class="mt-3 head" (selectedTabChange)="changeTab($event)">
                    <mat-tab label="{{staticText?.delivery.toe.setup}}" >
                        <div class="p-1">
                            <div class="okrCenterList m-2" style="overflow: scroll; max-height: 585px;min-height: 585px;" >
                                <div class="p-1 rightTreeCard" *ngFor="let tree of projectToEData;index as treeindex">
                                    <div class="d-flex flex-column">
                                        <app-tree [treearray]="tree"   [treeindex]="treeindex" [treecardtype]="'formtreetoe'" [subParentTagname]="'TOECenterPaneSubParent'" [parentTagName]="'TOERecommendRightPaneParent'" [showChildOnly]="false" [addicon]="true"  [formShow]="false" 
                                [treeAddPermission]="'delivery.add_projectmapping'" (addkeyresult)="onKeyResultAdded($event)" >
                            </app-tree>
                          
                                        
                                    </div>
                                </div>


                                <!-- <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedSetupChildIndex" (selectedIndexChange)="changeChildTab($event,true)">
                                    <mat-tab *ngFor="let item of projectToEData;let cindex=index;" matTooltip="{{item?.display_name}}" label="{{item?.display_name}}" >                                        
                                        <ng-container *ngIf="activeChildTab==item?.name" >

                                 <table cellpadding="0" cellspacing="0" class="tbl-accordion">
      
                                            <tbody>
                                              <tr>
                                                <td colspan="6">
                                                  <table cellpadding="0" cellspacing="0" class="tbl-accordion-nested" *ngFor="let sub_category of item?.term_sub_category;let sindex=index">
                                                    <thead *ngIf="sub_category?.toe&&sub_category?.toe.length>0" id="thead_{{sindex}}" (click)="toggleAcc(sub_category?.id)">
                                                      <tr>
                                                        <td colspan="6" class="tbl-accordion-section">{{sub_category?.display_name}}</td>
                                                      </tr>
                                                    </thead>
                                                    <tbody *ngIf="sub_category?.toe&&sub_category?.toe.length>0&&toggletacc.get(sub_category?.id)" id="tbody_{{sindex}}">
                                                       <tr>
                                                       
    
                                                        <th>Title</th>
                                                        <th class="owner-cell">{{staticText?.delivery.toe.column_heading1}} </th> 
                                                        <th class="owner-cell">{{staticText?.delivery.toe.column_heading2}} </th> 
                                                        <th class="owner-cell">{{staticText?.delivery.toe.column_heading3}} </th> 
                                                        <th class="owner-cell">{{staticText?.delivery.toe.column_heading4}} </th>                                                           
                                                        <th class="owner-cell">{{staticText?.delivery.toe.column_heading5}} </th> 
                                          </tr>  
                                         
                                          <tr *ngFor="let toe of sub_category?.toe">
                                            <td>
                                                <div class="d-flex mt-8 mw-250 fs-14">
                                                    <input type="checkbox" class="mx-2" [(ngModel)]="toe['is_linked_toe']" [checked]="toe?.is_linked_toe" >
                                                    <div style="margin-top: -4px;">{{toe?.title}}</div>
                                                </div>
                                            </td>
                                            <td>{{toe?.description}}</td>
                                            <td>{{toe?.function?.display_name}}</td>
                                            <td>{{toe?.is_mandatory?'No':'Yes'}}</td>
                                            <td><span *ngIf="toe?.compliance?.GDPR?.is_active =='true'">GDPR</span><span *ngIf="toe?.compliance?.GDPR?.is_active =='true' && toe?.compliance?.SOX?.is_active =='true'">/</span><span *ngIf="toe?.compliance?.SOX?.is_active =='true'">SOX</span></td>
                                             <td> <mat-icon class="color-info infoicon" (click)="openToEModal(toe,ToEModal)">info</mat-icon></td>
                                        </tr>
                                
                                          
                                 </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              
                                              
                                             
                                              
                                             
                                              
                                            
                                              
                                              
                                            </tbody>
                                          </table>	 
    
    
    
    
    
    
    
                                    </ng-container>
                                                                
                                        <mat-card-actions class="border-top col-12 mt-2" align="end" >
                                            <button class="saveOkr text-white text-capitalize float-right" mat-raised-button
                                                (click)="mapToE(cindex)">
                                                {{staticText.buttons.save_btn}}
                                            </button>
                                        </mat-card-actions>
                                    </mat-tab>                                   
                                </mat-tab-group> -->
                                <div class="row" style="overflow: scroll; min-height:505px;max-height: 505px;"  *ngIf="projectToEData?.length == 0">
                                    <div class="col-12 mx-auto">
                                        <img src="../../../../../assets/images/no-record.png" class="no-records" height="100%"
                                            width="100%">
                                    </div>
                                    <div class="col-8 mx-auto">
                                        <p class="text-center fw-bold text-muted">
                                            {{staticText.common.no_records_found}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{staticText?.delivery.toe.progress_update}}">
                        <div class="p-1">
                            <div class="okrCenterList m-2" style="overflow: scroll; max-height: 505px;">
                                <div class="table">
                                    <div class="table-row" *ngFor="let treearray of allProjectToeData;let subindex=index;">
                                        <div class="table-cell cursor-pointer domain fw-bold" style="max-width: 150px;min-width: 150px;font-size:14px">
                                            {{treearray?.display_name  | titlecase}}
                                        </div>
                                        <div class="table-cell table-nest" *ngIf="treearray?.term_sub_category">
                                            <div class="table-row" style="position: relative;" *ngFor="let subParent of treearray?.term_sub_category;let subsubindex=index;">
                                                <div class="table-cell cursor-pointer subdomain" id="subdomain_{{subsubindex}}" style="max-width: 150px;min-width: 150px;font-size:14px">{{subParent?.display_name  || 'Not Available' | titlecase}}</div>

                                                <div class="table-cell"  style="max-width: 150px;min-width: 150px;position: relative;"   *ngFor="let risk of subParent?.toe;let risksubindex=index;" [ngClass]="{'medium':risk?.is_linked_toe,'cursor-pointer':risk.metrics&&risk.metrics.length>0}">

                                                    <span id="risksubdomain_{{subsubindex}}_{{risksubindex}}" class="cursor-pointer" style="position: absolute;font-size: 14px;text-align: center;" (click)="onClickToE(subParent,risk,risksubindex,subsubindex,ToEUpdateModal)"  >{{risk?.title || 'Not Available'  | titlecase}}
                                                    </span>
                                                   


                                                </div>



                                            </div>

                                        </div>


                                    </div>
                                </div> 
                                <!-- <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedUpdateChildIndex" (selectedIndexChange)="changeChildTab($event,false)">
                                    <mat-tab *ngFor="let item of selectedToEs;let krindex=index;" matTooltip="{{item?.name}}" label="{{(item?.name)}}" >
                                        <div class="d-flex justify-content-between">
                                            <ng-container *ngIf="activeChildTab==item?.name" >
                                            <table class="fs-14 mt-2 w-auto">
                                                    <thead>
                                                        <th></th> 
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngFor="let sub_category of item?.term_sub_category;let cindex1=index">
                                                            <tr>
                                                                <td>
                                                                    <div class="d-flex mt-2 mw-250 fs-14" [ngClass]="{'active-child': subCategoryData?.categoryIndex ==cindex1}" >
                                                                        <span class="material-icons-outlined fs-30"> arrow_right </span>
                                                                        <div class="pr-2 text-left">{{sub_category?.display_name}}</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngFor="let toe of sub_category?.toe;let mindex=index">
                                                                <td>
                                                                    <div class="d-flex pl-3 text-left cursor-pointer ml-60 fs-14" [ngClass]="{'active-measure': subCategoryData?.categoryIndex ==cindex1 && subCategoryData?.toeIndex ==mindex }" (click)="onClickToE(sub_category,toe,mindex,cindex1)">
                                                                        <span class="material-icons-outlined fs-30"> arrow_right </span>
                                                                        {{toe?.title}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr><td></td></tr>
                                                        </ng-container>
                                                        <tr></tr>
                                                    </tbody>
                                                </table>  
                                                <mat-card class="my-2 mx-1 p-0 w-100" *ngIf="showToEPane">
                                                    <div class="keyresult-card">
                                                        <div class="p-2 kr-title">{{subCategoryData?.toe?.title||''}}</div>
                                                        <mat-card  class="m-0 p-0 d-grid" *ngFor="let metricDetails of subCategoryData?.toe_data | paginate: { itemsPerPage: 1, currentPage: toePage, totalItems: subCategoryData?.toe_data?.length } ; let e = index">
                                                            <div [formGroup]="toeForm" class="field-adjust p-2 overflow-hidden">
                                                                <mat-card  *ngIf="subCategoryData?.toe?.metrics && subCategoryData?.toe?.metrics?.length>0" formArrayName="metrics">
                                                                    <div class="d-flex row" *ngFor="let metricDetails of subCategoryData?.toe?.metrics;let metricindex=index" [formGroupName]="metricindex">                                                                                
                                                                        <mat-form-field appearance="outline" class="col-4 pl-1">
                                                                            <mat-label>{{staticText?.delivery.toe.update_field1}}</mat-label>
                                                                            <input type="text" matInput fullWidth status="basic" placeholder="{{staticText?.delivery.toe.update_field1}}" disabled [value]="metricDetails?.name||''"/>
                                                                        </mat-form-field>
                                                                        <mat-form-field appearance="outline" class="col-2 p-0">
                                                                            <mat-label>{{staticText?.delivery.toe.update_field2}}</mat-label>
                                                                            <input type="text" matInput fullWidth status="basic" placeholder="{{staticText?.delivery.toe.update_field1}}" disabled [value]="metricDetails?.mos_type_name"/>
                                                                        </mat-form-field>
                                                                        <mat-form-field appearance="outline" class="col-2 pr-0">
                                                                            <mat-label>{{staticText?.delivery.toe.update_field3}}</mat-label>
                                                                            <input matInput type="text" placeholder="{{staticText?.delivery.toe.update_field3}}" disabled [value]="metricDetails?.target_value">
                                                                        </mat-form-field>
                                                                        <mat-form-field appearance="outline" class="col-2 pr-0">
                                                                            <mat-label>{{staticText?.delivery.toe.update_field4}}</mat-label>
                                                                            <input matInput [type]="metricDetails?.mos_type_name=='Binary(Adherence)'?'text':'number'" placeholder="{{staticText?.delivery.toe.update_field4}}" formControlName="actual_value">
                                                                        </mat-form-field>
                                                                        <mat-form-field appearance="outline" class="col-2 pr-1">
                                                                            <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                                                            <mat-select class="select-box w-100" formControlName="month">
                                                                                <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </mat-card>
                                                                <mat-card class="py-1 px-2 mt-2" *ngIf="subCategoryData?.toe?.evidence && subCategoryData?.toe?.evidence?.length>0" >                                                                             
                                                                    <div class="col px-2 fw-500">{{staticText?.delivery.toe.update_field6}}</div>
                                                                    <div *ngFor="let evidence of subCategoryData?.toe?.evidence">   
                                                                        <div class="d-flex">                                                                          
                                                                            <div class="col-3 p-0 d-flex"><div class="method-title">{{evidence?.display_name}}</div></div>
                                                                            <mat-form-field appearance="outline" class="col-2 pl-0">
                                                                                <mat-label>{{staticText?.delivery.toe.update_field7}}</mat-label>
                                                                                <mat-select class="select-box w-100" formControlName="{{evidence?.name}}_verified_by">
                                                                                    <mat-option *ngFor="let user of teamList" [value]="user?.mail">{{user?.full_name||user?.name || user?.mail}}</mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                            <mat-form-field appearance="outline" class="col-2 p-0">
                                                                                <input matInput [matDatepicker]="picker1" readonly formControlName="{{evidence?.name}}_date"
                                                                                    fullWidth status="basic" (click)="picker1.open()">
                                                                                <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
                                                                                <mat-datepicker #picker1></mat-datepicker>
                                                                                </mat-form-field>
                                                                            <div class="col-3 pr-0">
                                                                                <div class="col p-0" *ngIf="!selectedFile[evidence?.name+'_file']?.name">
                                                                                    <div class="p-1 mt-3 upload">
                                                                                        <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                                                            (click)="fileInput.click()">
                                                                                            <span class="secondary">{{staticText?.delivery.toe.update_field9}}</span>
                                                                                        </div>
                                                                                        <input hidden #fileInput [disabled]="disableFields" type="file" id="file" accept=".pdf,.ppt, .docx,.doc,.pptx,.xls,.xlsx" (change)="onSelectFile($event,evidence?.name+'_file')">
                                                                                    </div>
                                                                                    <p class="fw-light file-status text-dark fs-12 mb-1"> {{staticText?.common.doc_ext_maxsize}}</p>
                                                                                </div>
                                                                                <div class="col-md-auto p-0" *ngIf="selectedFile[evidence?.name+'_file']?.name">
                                                                                    <div>                                    
                                                                                        <span class="file d-flex">
                                                                                            <div class="d-flex w-100" >
                                                                                                <img src="./assets/images/docs.png" height="40" *ngIf="selectedFile[evidence?.name+'_file']?.name?.includes('.doc') || selectedFile[evidence?.name+'_file']?.name?.includes('.docx') ">                                                                                    
                                                                                                <img src="./assets/images/pdf-icon-new.svg" height="40" *ngIf="selectedFile[evidence?.name+'_file']?.name?.includes('.pdf') ">
                                                                                                <img src="./assets/images/powerpoint.png" height="40" *ngIf="selectedFile[evidence?.name+'_file']?.name?.includes('.pptx') || selectedFile[evidence?.name+'_file']?.name?.includes('.ppt')">
                                                                                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                                                                    {{selectedFile[evidence?.name+'_file']?.name}}</p>
                                                                                                <img src="./assets/images/delete-icon-new.svg" class="cursor-pointer" height="40"
                                                                                                    (click)="deleteFile(evidence?.name+'_file')" >
                                                                                            </div>
                                                                                        </span>
                                                                                        <br />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <mat-form-field appearance="outline" class="col-2 pr-0">
                                                                                <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                                                                <mat-select class="select-box w-100" formControlName="{{evidence?.name}}_month">
                                                                                    <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </mat-card>
                                                                <mat-card class="mt-2 py-1 px-2" *ngIf="subCategoryData?.toe?.compliance && subCategoryData?.toe?.compliance?.length>0">                                                                                                                  
                                                                    <div class="col px-2 fw-500">{{staticText?.delivery.toe.update_field13}}</div>
                                                                    <div *ngFor="let comp of subCategoryData?.toe?.compliance" class="d-flex">
                                                                        <div class="col-2 p-0 d-flex"><div class="method-title">{{comp?.display_name}}</div></div>
                                                                        <div class="d-flex align-items-center px-2">
                                                                            <mat-radio-group class=" pt-3 fs-14 pb-2 color-black-pearl" formControlName="{{comp?.name}}_status" >
                                                                                <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option1">{{staticText?.delivery.toe.radio_option1}}</mat-radio-button>
                                                                                <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option2">{{staticText?.delivery.toe.radio_option2}}</mat-radio-button>
                                                                                <mat-radio-button class="me-0 fnt14" [value]="staticText?.delivery.toe.radio_option3">{{staticText?.delivery.toe.radio_option3}}</mat-radio-button>
                                                                            </mat-radio-group>
                                                                        </div>
                                                                        <mat-form-field appearance="outline" class="col-2 pl-2 pr-0">
                                                                            <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                                                            <mat-select class="select-box w-100" formControlName="{{comp?.name}}_month">
                                                                                <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                   
                                                                </mat-card>
                                                                <mat-card class="mt-2 py-1 px-2">                                                                                                                 
                                                                    <div class="col px-2 fw-500">{{staticText?.delivery.toe.update_field14}}</div>
                                                                    <div class="d-flex">
                                                                        <div class="col-2 p-0 d-flex"><div class="method-title">{{staticText?.delivery.toe.field_label4}}</div></div>
                                                                        <div class="d-flex align-items-center px-2">
                                                                            <mat-radio-group class="pt-3 fs-14 pb-2 color-black-pearl" formControlName="toe_status" >
                                                                                <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option1">{{staticText?.delivery.toe.radio_option1}}</mat-radio-button>
                                                                                <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option2">{{staticText?.delivery.toe.radio_option2}}</mat-radio-button>
                                                                                <mat-radio-button class="me-0 fnt14" [value]="staticText?.delivery.toe.radio_option3">{{staticText?.delivery.toe.radio_option3}}</mat-radio-button>
                                                                            </mat-radio-group>
                                                                        </div>
                                                                        <mat-form-field appearance="outline" class="col-2 pl-2 pr-0">
                                                                            <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                                                            <mat-select class="select-box w-100" formControlName="toe_status_month">
                                                                                <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </mat-card>                                                              
                                                            </div>
                                                            <mat-card-actions class="border-top p-2 m-0 mt-2" align="end" >
                                                                <button class="saveOkr text-white text-capitalize float-right ml-2 cursor-pointer" mat-raised-button [disabled]="toeForm.invalid"
                                                                    (click)="updateToEData(subCategoryData?.toe)" >
                                                                    {{staticText.buttons.save_btn}}
                                                                </button>
                                                                <button mat-button class="cancel" (click)="subCategoryData={};showToEPane=false;">
                                                                    {{staticText.buttons.cancel_btn}}
                                                                </button>
                                                            </mat-card-actions>
                                                            <div class="flex col-12 mb-2" >
                                                                <ng-container *ngIf="(selectedToEs?.[selectedUpdateChildIndex]?.term_sub_category | CheckPrevToE : subCategoryData?.categoryIndex:subCategoryData?.toeIndex) as result">
                                                                    <button class="saveOkr text-white text-capitalize ml-2" mat-raised-button  [disabled]="!result?.isPresent" (click)="onClickChild(false)">
                                                                        {{staticText.buttons.prev_btn}}
                                                                    </button>
                                                                </ng-container>
                                                                <ng-container *ngIf="(selectedToEs?.[selectedUpdateChildIndex]?.term_sub_category | CheckNextToE : subCategoryData?.categoryIndex:subCategoryData?.toeIndex) as result1">
                                                                    <button class="saveOkr text-white text-capitalize float-right ml-2" mat-raised-button  [disabled]="!result1?.isPresent" (click)="onClickChild(true)">
                                                                        {{staticText.buttons.next_btn}}
                                                                    </button>
                                                                </ng-container>
                                                            </div>
                                                        </mat-card>
                                                    </div>  
                                                </mat-card>                                           
                                            </ng-container>
                                        </div>
                                    </mat-tab>                                    
                                </mat-tab-group> -->
                                <div class="row" style="overflow: scroll; max-height: 505px;" *ngIf="allProjectToeData?.length == 0">
                                    <div class="col-12 mx-auto">
                                        <img src="../../../../../assets/images/no-record.png" class="no-records" height="100%"
                                            width="100%">
                                    </div>
                                    <div class="col-8 mx-auto">
                                        <p class="text-center fw-bold text-muted">
                                            {{staticText.common.no_records_found}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="tab-content" *ngIf="activeTab=='heatmap'" >  
                                <div class="treemap" [chart]="treeMapChart1">
                                </div>
                            </div>
                            <div class="tab-content" *ngIf="activeTab=='okr_status'" >  
                                <div class="treemap" [chart]="treeMapChart1">
                                </div>
                            </div> -->
                        </div>
                    </mat-tab>
                </mat-tab-group>
                
            </mat-card>
        </div>
        <div class="col-4" *ngIf="activeTab == staticText?.delivery.toe.setup">
            <mat-card class="rightPane p-0 min-card-height">
                <div class="d-flex border-bottom">
                    <span class="bubble-right pt-2 pb-2"></span>
                    <div class="pe-1 ps-1 w-100">
                        <div class="d-flex justify-content-between align-items-center topBorders">
                            <div class="p-3">
                                <p class="fw-bold fs-18 text-black mb-0">{{staticText?.delivery.toe.selected_toe}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <mat-card-content class="riskRightList">
                    <div class="container-fluid pe-1 ps-1">
                        <div class="p-1 rightTreeCard" *ngFor="let tree of selectedToEs;index as treeindex">
                            <div class="d-flex flex-column">
                                <app-tree [treearray]="tree"   [treeindex]="treeindex" [treecardtype]="'formtreetoeselected'" [subParentTagname]="'toeRightPaneSubParent'" [parentTagName]="'toeRightPaneParentSelected'" [showChildOnly]="false" [addicon]="true"  [formShow]="false" 
                        [treeAddPermission]="'delivery.add_projectmapping'" (addkeyresult)="onKeyResultAdded($event)"  >
                    </app-tree>
                  
                                
                            </div>
                        </div>
                            


                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</section>
<ng-template #ToEModal>
    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <div class="border-top"></div>
            <app-modal-header-common [title]="'ToE Details'"> </app-modal-header-common>
            <div class="modal-body"> 
                <div>
                    <table class="table table-responsive fs-14 w-100 d-table" >
                        <tr>
                            <td [attr.colspan]="3" class="risk-title">
                                <b>{{selectedToeData?.title}}</b>
                            </td>
                        </tr>
                        <tr>
                            <td class="theading">Metric Name</td>
                            <td class="theading">MoS Type</td>
                            <td class="theading">Target Value</td>
                        </tr>
                        <tr *ngFor="let metric of selectedToeData?.metrics">
                            <td>{{metric?.name}}</td>
                            <td>{{metric?.mos_type_name}}</td>
                            <td *ngIf="metric?.mos_type_name=='Need Improvement'">{{metric?.from_value}}-{{metric?.to_value}}</td>
                            <td *ngIf="metric?.mos_type_name=='LTB'">{{metric?.min_value}}</td>
                            <td *ngIf="metric?.mos_type_name=='HTB'">{{metric?.max_value}}</td>
                            <td *ngIf="metric?.mos_type_name=='Binary(Adherence)'">{{metric?.binary_value?'Yes':'No'}}</td>                        
                        </tr>
                        <tr *ngIf="!selectedToeData?.metrics || selectedToeData?.metrics?.length==0">
                        <td [attr.colspan]="3">{{staticText.common.no_records_found}}</td>
                        </tr>
                    </table>
                </div> 
                <div class="row border-top pt-3">
                    <div class="col-4">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Applicability</div>
                        <div class="ms-4 fs-16 text-black mb-0">{{selectedToeData?.applicability |joinArrayValues:'display_name' || 'N/A'}}</div>
                    </div>
                    <div class="col-4">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Consequences</div>
                        <div class="ms-4 fs-16 text-black mb-0">
                            {{selectedToeData?.consequences|joinArrayValues:'display_name' || 'N/A'}}
                            <!-- <span *ngIf="selectedToeData?.award">Award</span>
                            <span *ngIf="selectedToeData?.discount">Discount</span>
                            <span *ngIf="selectedToeData?.penalty">Penalty</span> -->
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Compliance</div>
                        <div class="ms-4 fs-16 text-black mb-0">{{selectedToeData?.compliance|joinArrayValues:'display_name' || 'N/A'}}</div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Evidence</div>
                        <div class="ms-4 fs-16 text-black mb-0">
                            {{selectedToeData?.evidence|joinArrayValues:'display_name' || 'N/A'}}
                        </div>
                    </div>
                </div>      
                    
            </div> 
            <div class="modal-footer">
                <div class="row">
                    <div>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" mat-dialog-close>{{staticText?.buttons.close_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ToEUpdateModal>
    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <div class="border-top"></div>
            <app-modal-header-common [title]="'Update' + subCategoryData?.toe?.title"> </app-modal-header-common>
            <div class="modal-body"> 
                <div  class="m-0 p-0 d-grid" *ngFor="let metricDetails of subCategoryData?.toe_data | paginate: { itemsPerPage: 1, currentPage: toePage, totalItems: subCategoryData?.toe_data?.length } ; let e = index">
                    <div [formGroup]="toeForm" class="field-adjust p-2 overflow-hidden">
                        <mat-card  *ngIf="subCategoryData?.toe?.metrics && subCategoryData?.toe?.metrics?.length>0" formArrayName="metrics">
                            <div class="d-flex row" *ngFor="let metricDetails of subCategoryData?.toe?.metrics;let metricindex=index" [formGroupName]="metricindex">                                                                                
                                <mat-form-field appearance="outline" class="col-4 pl-1">
                                    <mat-label>{{staticText?.delivery.toe.update_field1}}</mat-label>
                                    <input type="text" matInput fullWidth status="basic" placeholder="{{staticText?.delivery.toe.update_field1}}" disabled [value]="metricDetails?.name||''"/>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-2 p-0">
                                    <mat-label>{{staticText?.delivery.toe.update_field2}}</mat-label>
                                    <input type="text" matInput fullWidth status="basic" placeholder="{{staticText?.delivery.toe.update_field1}}" disabled [value]="metricDetails?.mos_type_name"/>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-2 pr-0">
                                    <mat-label>{{staticText?.delivery.toe.update_field3}}</mat-label>
                                    <input matInput type="text" placeholder="{{staticText?.delivery.toe.update_field3}}" disabled [value]="metricDetails?.target_value">
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-2 pr-0">
                                    <mat-label>{{staticText?.delivery.toe.update_field4}}</mat-label>
                                    <input matInput [type]="metricDetails?.mos_type_name=='Binary(Adherence)'?'text':'number'" placeholder="{{staticText?.delivery.toe.update_field4}}" formControlName="actual_value">
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-2 pr-1">
                                    <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                    <mat-select class="select-box w-100" formControlName="month">
                                        <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-card>
                        <mat-card class="py-1 px-2 mt-2" *ngIf="subCategoryData?.toe?.evidence && subCategoryData?.toe?.evidence?.length>0" >                                                                             
                            <div class="col px-2 fw-500">{{staticText?.delivery.toe.update_field6}}</div>
                            <div *ngFor="let evidence of subCategoryData?.toe?.evidence">   
                                <div class="d-flex">                                                                          
                                    <div class="col-3 p-0 d-flex"><div class="method-title">{{evidence?.display_name}}</div></div>
                                    <mat-form-field appearance="outline" class="col-2 pl-0">
                                        <mat-label>{{staticText?.delivery.toe.update_field7}}</mat-label>
                                        <mat-select class="select-box w-100" formControlName="{{evidence?.name}}_verified_by">
                                            <mat-option *ngFor="let user of teamList" [value]="user?.mail">{{user?.full_name||user?.name || user?.mail}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="col-2 p-0">
                                        <input matInput [matDatepicker]="picker1" readonly formControlName="{{evidence?.name}}_date"
                                            fullWidth status="basic" (click)="picker1.open()">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                    <div class="col-3 pr-0">
                                        <div class="col p-0" *ngIf="!selectedFile[evidence?.name+'_file']?.name">
                                            <div class="p-1 mt-3 upload">
                                                <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                    (click)="fileInput.click()">
                                                    <span class="secondary">{{staticText?.delivery.toe.update_field9}}</span>
                                                </div>
                                                <input hidden #fileInput [disabled]="disableFields" type="file" id="file" accept=".pdf,.ppt, .docx,.doc,.pptx,.xls,.xlsx" (change)="onSelectFile($event,evidence?.name+'_file')">
                                            </div>
                                            <p class="fw-light file-status text-dark fs-12 mb-1"> {{staticText?.common.doc_ext_maxsize}}</p>
                                        </div>
                                        <div class="col-md-auto p-0" *ngIf="selectedFile[evidence?.name+'_file']?.name">
                                            <div>                                    
                                                <span class="file d-flex">
                                                    <div class="d-flex w-100" >
                                                        <img src="./assets/images/docs.png" height="40" *ngIf="selectedFile[evidence?.name+'_file']?.name?.includes('.doc') || selectedFile[evidence?.name+'_file']?.name?.includes('.docx') ">                                                                                    
                                                        <img src="./assets/images/pdf-icon-new.svg" height="40" *ngIf="selectedFile[evidence?.name+'_file']?.name?.includes('.pdf') ">
                                                        <img src="./assets/images/powerpoint.png" height="40" *ngIf="selectedFile[evidence?.name+'_file']?.name?.includes('.pptx') || selectedFile[evidence?.name+'_file']?.name?.includes('.ppt')">
                                                        <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                            {{selectedFile[evidence?.name+'_file']?.name}}</p>
                                                        <img src="./assets/images/delete-icon-new.svg" class="cursor-pointer" height="40"
                                                            (click)="deleteFile(evidence?.name+'_file')" >
                                                    </div>
                                                </span>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <mat-form-field appearance="outline" class="col-2 pr-0">
                                        <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                        <mat-select class="select-box w-100" formControlName="{{evidence?.name}}_month">
                                            <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            
                        </mat-card>
                        <mat-card class="mt-2 py-1 px-2" *ngIf="subCategoryData?.toe?.compliance && subCategoryData?.toe?.compliance?.length>0">                                                                                                                  
                            <div class="col px-2 fw-500">{{staticText?.delivery.toe.update_field13}}</div>
                            <div *ngFor="let comp of subCategoryData?.toe?.compliance" class="d-flex">
                                <div class="col-2 p-0 d-flex"><div class="method-title">{{comp?.display_name}}</div></div>
                                <div class="d-flex align-items-center px-2">
                                    <mat-radio-group class=" pt-3 fs-14 pb-2 color-black-pearl" formControlName="{{comp?.name}}_status" >
                                        <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option1">{{staticText?.delivery.toe.radio_option1}}</mat-radio-button>
                                        <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option2">{{staticText?.delivery.toe.radio_option2}}</mat-radio-button>
                                        <mat-radio-button class="me-0 fnt14" [value]="staticText?.delivery.toe.radio_option3">{{staticText?.delivery.toe.radio_option3}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <mat-form-field appearance="outline" class="col-2 pl-2 pr-0">
                                    <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                    <mat-select class="select-box w-100" formControlName="{{comp?.name}}_month">
                                        <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                           
                        </mat-card>
                        <mat-card class="mt-2 py-1 px-2">                                                                                                                 
                            <div class="col px-2 fw-500">{{staticText?.delivery.toe.update_field14}}</div>
                            <div class="d-flex">
                                <div class="col-2 p-0 d-flex"><div class="method-title">{{staticText?.delivery.toe.field_label4}}</div></div>
                                <div class="d-flex align-items-center px-2">
                                    <mat-radio-group class="pt-3 fs-14 pb-2 color-black-pearl" formControlName="toe_status" >
                                        <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option1">{{staticText?.delivery.toe.radio_option1}}</mat-radio-button>
                                        <mat-radio-button class="me-2 fnt14" [value]="staticText?.delivery.toe.radio_option2">{{staticText?.delivery.toe.radio_option2}}</mat-radio-button>
                                        <mat-radio-button class="me-0 fnt14" [value]="staticText?.delivery.toe.radio_option3">{{staticText?.delivery.toe.radio_option3}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <mat-form-field appearance="outline" class="col-2 pl-2 pr-0">
                                    <mat-label>{{staticText?.delivery.toe.update_field5}}</mat-label>
                                    <mat-select class="select-box w-100" formControlName="toe_status_month">
                                        <mat-option *ngFor="let month of monthList" [value]="month">{{month}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-card>                                                              
                    </div>
                    
                    
                </div>      
                    
                    
            </div> 
            <div class="modal-footer">
                    <button class="saveOkr text-white text-capitalize float-right ml-2 cursor-pointer" mat-raised-button [disabled]="toeForm.invalid"
                        (click)="updateToEData(subCategoryData?.toe)" >
                        {{staticText.buttons.save_btn}}
                    </button>
                    <button mat-button class="cancel" mat-dialog-close (click)="subCategoryData={};">
                        {{staticText.buttons.cancel_btn}}
                    </button>
            </div>
        </div>
    </div>
</ng-template>
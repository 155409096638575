import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RightPanelService } from 'src/app/shared/services/right-panel.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { Chart } from 'angular-highcharts';
import Highcharts, { chart } from "highcharts";
import HighchartsFunnel from "highcharts/modules/funnel";
import HighchartsExporting from "highcharts/modules/exporting";
import { MessageService } from 'src/app/shared/services/message.service';
const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)

HighchartsFunnel(Highcharts);
HighchartsExporting(Highcharts);

@Component({
  selector: 'app-right-bar',
  templateUrl: './right-bar.component.html',
  styleUrls: ['./right-bar.component.scss'],
})
export class RightBarComponent implements OnInit {
  @Input() projectId:any;
  @Input() type:any;
  @Input() item:any;
  staticText: any = (textConfiguration as any).default;
  attritionData:any;
  krrData:any;
  resourceData:any;
  spendData:any;
  staffingPhyramidData:any;
  pyramidChart:any
  // Highcharts = Highcharts;
  Highcharts: typeof Highcharts = Highcharts;
  changeRequest:any = [];
  applicationSelectedData:any;
  OKRData:any;
  pyramidWIdth:any;
  pyramidMargin:any;
  graphWidth:any;
  constructor(
    private rightPanelService:RightPanelService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) {
   }
  async ngOnInit() {
    // this.getAttritionTrendData();
    // this.getKRRTrendData();
    // this.getResourceTrendData();
    // this.getSpendTrendData();
    // this.getStaffingPhyramidData();
    this.getApplicationSelected();
    // this.getChangeRequestData();
    this.getSubscribeOKRData();

    if(!localStorage.getItem('rightbar') || localStorage.getItem('rightbar') != this.projectId){
      localStorage.setItem('rightbar', this.projectId);
      this.getData();
    }

    if(this.type == 'center'){
      this.pyramidWIdth = '30%';
      this.pyramidMargin = '50%';
      this.graphWidth= '';
    }else{
      this.pyramidWIdth = '65%';
      this.pyramidMargin = '35%';
      this.graphWidth=250;
    }
  }

  async getData(){
    // await this.rightPanelService.getAttritionTrendData(this.projectId).subscribe((res: any) => {
    //   this.messageService.setAttritionTrendData(res);
    // })
    // await this.rightPanelService.getKRRTrendData(this.projectId).subscribe((res: any) => {
    //   this.messageService.setKRRTrendData(res);
    // })
    // await this.rightPanelService.getResourceTrendData(this.projectId).subscribe((res: any) => {
    //   this.messageService.setResourceTrendData(res);
    // })
    // await this.rightPanelService.getSpendTrendData(this.projectId).subscribe((res: any) => {
    //   this.messageService.setSpendTrendData(res);
    // })
    // await this.rightPanelService.getStaffingPhyramidData(this.projectId).subscribe((res: any) => {
    //   this.messageService.setStaffingPhyramidData(res);
    // })
    await this.rightPanelService.getApplicationSelected(this.projectId).subscribe((res: any) => {
      this.messageService.setApplicationSelected(res);
    })
    // await this.rightPanelService.getChangeRequestData(this.projectId).subscribe((res: any) => {
    //   this.messageService.setChangeRequestData(res);
    // })
    await this.rightPanelService.getSubscribeOKRData(this.projectId).subscribe((res: any) => {
      // this.OKRData =  res.okr_hierarchy;
        this.messageService.setSubscribeOKRData(res.okr_hierarchy);
    })
  }
  async getAttritionTrendData(){
    this.messageService.getAttritionTrendData().subscribe((res: any) => {
    // await this.rightPanelService.getAttritionTrendData(this.projectId).subscribe((res: any) => {
      if(res?.Top_3_Vendor != undefined){
        const obj = res?.Top_3_Vendor
        this.attritionData ={
          trend:this.returnSparkLineChart(res?.data, 'line', res?.month),
          vendors: Object.keys(obj).map(key => ({name: key, count: obj[key]}))
        } 
      }
    })
  }

  async getKRRTrendData(){
    this.messageService.getKRRTrendData().subscribe((res: any) => {
    // await this.rightPanelService.getKRRTrendData(this.projectId).subscribe((res: any) => {
      if(res?.Top_3_Vendor != undefined){
        const obj = res?.Top_3_Vendor;
        this.krrData ={
          trend:this.returnSparkLineChart(res?.data, 'line', res?.month),
          vendors: Object.keys(obj).map(key => ({name: key, count: obj[key]}))
        } 
      }
    })
  }

  async getResourceTrendData(){
    this.messageService.getResourceTrendData().subscribe((res: any) => {
    // await this.rightPanelService.getResourceTrendData(this.projectId).subscribe((res: any) => {
      if(res?.Top_3_Vendor != undefined){
        const obj = res?.Top_3_Vendor;
        this.resourceData ={
          trend:this.returnSparkLineChart(res?.data, 'line', res?.month),
          vendors: Object.keys(obj).map(key => ({name: key, count: obj[key]}))
        } 
      }
    })
  }
  async getSpendTrendData(){
    this.messageService.getSpendTrendData().subscribe((res: any) => {
    // await this.rightPanelService.getSpendTrendData(this.projectId).subscribe((res: any) => {
      if(res?.Top_3_Vendor != undefined){
        const obj = res?.Top_3_Vendor;
        this.spendData ={
          trend:this.returnSparkLineChart(res?.data, 'line', res?.month),
          vendors: Object.keys(obj).map(key => ({name: key, count: obj[key]}))
        } 
      }
    })
  }
  staffing:any;
  async getStaffingPhyramidData(){
    this.messageService.getStaffingPhyramidData().subscribe((res: any) => {
    // await this.rightPanelService.getStaffingPhyramidData(this.projectId).subscribe((res: any) => {
      if(res?.pyramid_data != undefined){
        const obj = res?.pyramid_data;
        this.staffing = [];
        this.staffingPhyramidData = Object.keys(obj).map(key => ({name: key, count: obj[key]}));
        this.staffingPhyramidData.forEach((val:any)=>{
        this.staffing.push([val.name, val.count]);
        });
        this.pyramidChart = {
          chart: {
            type: 'pyramid'
          },
          title: {
              text: '',
          },
          plotOptions: {
              series: {
                  dataLabels: {
                      enabled: true,
                      format: '<b>{point.name}</b> ({point.y})',
                      softConnector: true
                  },
                  center: [this.pyramidMargin, '50%'],
                  width: this.pyramidWIdth
              }
          },
          legend: {
              enabled: false
          },
          series: [{
              name: '',
              data: 
                this.staffing,
          }],
          exporting: { enabled: false },
          credits: {
            enabled: false
          },
          
        };
      }
    })
      
  }

 

  async getRiskRadar(){
    await this.rightPanelService.getRiskRadar(this.projectId).subscribe((res: any) => {
      console.log(res);

    });
  }
  async getApplicationSelected(){
    this.messageService.getApplicationSelected().subscribe((res: any) => {
    // await this.rightPanelService.getApplicationSelected(this.projectId).subscribe((res: any) => {
      if(res?.data?.length > 0){
        res.data.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });
      }
      
      this.applicationSelectedData = res.data;
    });
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 30%, 75%, 100%)';
  } 

  async getChangeRequestData(){
    this.messageService.getChangeRequestData().subscribe((res: any) => {
    // await this.rightPanelService.getChangeRequestData(this.projectId).subscribe((res: any) => {
      this.changeRequest = res?.data;
    });
  }
  async getSubscribeOKRData(){
    this.messageService.getSubscribeOKRData().subscribe((res: any) => {
    // await this.rightPanelService.getSubscribeOKRData(this.projectId).subscribe((res: any) => {
       this.OKRData =res;
    });
  }

  
  returnSparkLineChart(seriesData: any, type: any, categories: any) {
    return {
      title: {
        text: ''
      },
      credits: false,
      chart: {
        backgroundColor: '#f9f9f9',
        renderTo: 'container',
       // width:this.graphWidth,
        height: 40,
         type: type,
         margin: [2, 0, 6, 0],
        style: {
          overflow: 'visible',
        },
        // skipClone: true
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0],
        gridLineWidth: 0,
        visible: false,
      },
      xAxis: {
        categories: categories,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
        gridLineWidth: 0,
        visible: false,
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          },
          dataLabels: {
            shape: 'connector',
            enabled: true,
            formatter: function(this:any) {
              return '<span style="font-size:8px;font-weight:100">'+this.x+'</span>';
            },
            y: -60,
            allowOverlap: true,
            useHTML: false
          }
        }
      },
      tooltip: {
        enabled: true,
        outside: true,
        formatter: function(this:any) {
          return '<b>'+this.x+'</b></br>'+this.y;
        }
      },
      series: [{
        color: '#F0DE36',
        data: seriesData,
        
      }],
      exporting: { enabled: false },
      
    };
  }
  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
        chart.reflow();
    },0);
  }   
}
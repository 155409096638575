import { ViewEncapsulation } from '@angular/core';
import { Component, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DeletePopupComponent } from 'src/app/shared/components/delete-popup/delete-popup.component';
import { DataService } from 'src/app/shared/services/data.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-comment-box-okr',
  templateUrl: './comment-box-okr.component.html',
  styleUrls: ['./comment-box-okr.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class CommentBoxOkrComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  commentsList: any[] = [];
  projectId: any;
  typeComment: any;
  ColorRandom: any;
  deleteCommentId: any;
  viewAllComments: boolean = false;
  @Output() viewComments = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private commentService: ProjectService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getProjectid();
    this.getCommentsList();
  }

  getProjectid() {
    this.route.parent?.params.subscribe((res: any) => {
      this.projectId = atob(res.id)
    })
  }

  getCommentsList() {
    this.spinner.show();
    this.commentService.getprojectdetailsById(this.projectId).subscribe((commentsList: any) => {
      this.commentsList = commentsList.comments;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    })
  }

  createComment() {
    this.commentService.createComment(this.projectId, { comment: this.typeComment }).subscribe((createComment: any) => {
      if (createComment.status == 201) {
        this.typeComment = '';
        this.toastrService.success('Comment Created Successfully');
        this.getCommentsList();
      }
    }, error => {
      this.toastrService.error("Can't able to Created Comment");
    })
  }

  deleteComment(deleteCommentId: number) {
    this.commentService.deleteComment(deleteCommentId).subscribe((deleteComment: any) => {
      if (deleteComment.status == 200) {
        this.getCommentsList();
        this.toastrService.success('Comment Deleted Successfully');
      }
    }, error => {
      this.toastrService.error("Can't able to Delete Comment");
    });
  }

  deletePopUp(id: number) {
    let dialogRef = this.dialog.open(DeletePopupComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteComment(id);
      }
    });
  }

  viewAllComment() {
    this.viewAllComments = true;
    this.viewComments.emit(this.viewAllComments)
  }
}

<section class="comment-box pt-3">
    <div class="d-flex flex-column">
        <div class="comment-input p-1">
            <div class="comment-Text-area">
                <textarea name="" id="" rows="4" placeholder="{{staticText?.delivery.okr_log.enter_cmnts}}..."
                    [(ngModel)]="typeComment"></textarea>
                <img src="/assets/images/send.svg" alt="send" (click)="createComment()">
            </div>
        </div>
        <div class="comments p-1">
            <div class="d-flex flex-column">
                <div class="p-1 comments-header">
                    <p class="h5 font-14 mb-0 text-capitalize fw-normal" *ngIf="commentsList.length !==0"> {{staticText?.delivery.okr_log.recent_cmnts}}
                    </p>
                </div>
                <div class="p-1 comment-list"
                    *ngFor="let comments of commentsList | slice:0:!viewAllComments ? 3 : -1;index as commentIndex">
                    <div class="row row-cols-3">
                        <div class="col-2 d-flex align-items-start">
                            <ngx-avatar [name]="comments.created_by.username" [bgColor]="commentIndex | getRandomColor" 
                                size="38" [round]="true"><!--[bgColor]="getRandomcolors(commentIndex)"-->
                            </ngx-avatar>
                        </div>
                        <div class="col-8 d-flex flex-column">
                            <div class="p-1 text-break">
                                <p class="h5 font-16 mb-0 text-capitalize fw-bold" *ngIf="(comments.created_by.first_name) || (comments.created_by.last_name)" >{{comments.created_by.first_name}} &nbsp;{{comments.created_by.last_name}} </p>
                                <p class="h5 font-16 mb-0 text-capitalize fw-bold" *ngIf="(!comments.created_by.first_name) && (!comments.created_by.last_name)" >{{comments.created_by.username}}</p>
                                <p class="font-14 mb-0 text-muted">{{comments.created_on | date:'medium'}}</p>
                            </div>
                            <div class="p-2">
                                <p class="mb-0 font-14">
                                    {{comments.comment}}
                                </p>
                            </div>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <img class="cursor-pointer mt-2" src="/assets/images/comment-delete.svg" alt="delete" *ngIf="comments?.delete"
                                width="10px" height="11px" (click)="deletePopUp(comments?.id)">
                        </div>
                    </div>
                </div>
                <div class="p-1 container-fluid" *ngIf="commentsList.length > 3 && !viewAllComments">
                    <div class="row row-cols-2">
                        <div class="col-8">
                            <div class="avatarList"
                                *ngFor="let comments of commentsList | slice:3:20;index as commentIndex"
                                [ngStyle]="{'transform': 'translate('+ 22 * commentIndex + 'px,-5px)'}">
                                <ngx-avatar [name]="comments.created_by.username" [bgColor]="commentIndex | getRandomColor"
                                    size="38" [round]="true"><!--[bgColor]="getRandomcolors(commentIndex)" -->
                                </ngx-avatar>
                            </div>
                        </div>
                        <div class="col-4">
                            <p class="h5 font-15 mb-0 text-capitalize link-color-blue cursor-pointer"
                                (click)="viewAllComment()">
                                &plus;{{commentsList.length - 3 +'others'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
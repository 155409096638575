import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { forEach } from 'lodash';
@Component({
  selector: 'app-deliveryscope',
  templateUrl: './deliveryscope.component.html',
  styleUrls: ['./deliveryscope.component.scss'],
  encapsulation:ViewEncapsulation.Emulated,
  providers:[DatePipe]
})
export class DeliveryScopeComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery.left_bar.scope;
  projectId: any;
  projectdetail: any;
  opportunity_data: any;
  ownerdetails: any;
  details: any;
  opportunityFilteredOptions: any;  
  vendorFilteredOptions: any;
  projectForm:FormGroup|any;
  selectedOpportunity:any='';
  statusList:any=[];
  files:any=[];
  existingFileList=[];
  //accordionItems = ['People Insights','Change Requests','Subscribed OKRs','Risk Radar','Applications selected'];
  accordionItems = ['Subscribed OKRs','Risk Radar','Applications selected'];
  form!: FormGroup;
  programDetail:any;
  editEnabled=false;
  breadcrumb: any;
  allSOWCOntractList: any;
  projectContractsList:any;
  ppm_id:any;

    /* @Input() selected_id: any; */

  constructor(private projectservice: ProjectService, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, public dialog: MatDialog,
    private program: ProgramService,private fb:FormBuilder, private breadCrumService: BreadcrumbService, private date:DatePipe,private toastr:ToastrService,private dateAdapter: DateAdapter<Date>) { 
      this.dateAdapter.setLocale('en-GB');
    }
    

  ngOnInit(): void {
    localStorage.removeItem('rightbar')

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })

    this.programDetail = JSON.parse(localStorage.getItem('program') || '{}');
    
    this.getprojectId();
  }

  getprojectId() {
    this.spinner.show()
    this.activatedRoute.parent?.params.subscribe(params => {
      this.spinner.hide()
      this.projectId = atob(params.id);
      // console.log('getparmasid', this.projectId);
      this.getprojectdetails();
     // this.getSowContractFiles();
    },err=>{this.spinner.hide()});
  }

  getprojectdetails() {
    console.log("test")
    this.spinner.show();
    this.projectservice.getprojectdetailsOverview(this.projectId).subscribe((response: any) => {
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: this.programDetail.portfolio_name, url: '/delivery/projects', queryParams: {portfolio: this.programDetail.portfolio_id} },
        { label: this.programDetail.subportfolio_name, url: '/delivery/projects', queryParams: {subportfolio: this.programDetail.subportfolio_id} },
        { label: this.programDetail.program_name, url: '/delivery/projects', queryParams: {program: this.programDetail.program_id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);

      this.projectdetail = response;
      this.ppm_id = this.projectdetail.ppm_id;
      this.projectContractsList = this.projectdetail.projectcontracts;
      localStorage.setItem('projectContract', JSON.stringify(this.projectContractsList));
     
      // this.getProjectStatus();
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error
    });
  }

  getProjectStatus(){    
    this.spinner.show();
      this.projectservice.getProjectStatus(this.projectId).subscribe((res: any) => {
        this.spinner.hide();
        this.statusList = res;
        let found = this.statusList.find((e:any)=> e.slug ==this.projectForm.value.selectedStatus?.slug);
        if(!found){
          this.statusList.push(this.projectForm.value.selectedStatus);
        }
      }, error => {
        throw error;
    })
  }
}
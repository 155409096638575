import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";

@Component({
  selector: "app-assessments",
  templateUrl: "./assessments.component.html",
  styleUrls: ["./assessments.component.scss"],
})
export class AssessmentsComponent implements OnInit {
  assessments: any = [];
  @Input() projectID: any;

  constructor(
    private assessmentService: AssessmentCentralService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAssessments();
  }

  getAssessments() {
    this.assessmentService
      .getAssessmentsByProject(this.projectID)
      .subscribe((response: any) => {
        this.assessments = response;
        this.assessments.forEach((category: any) => {
          category.assessments.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
      });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  openResult(res: any) {
    console.log(res);
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      width: "80vw",
      data: {
        assessment: res.id,
      },
    });
    dialog.componentInstance.type = { name: "assessmentpop" };
    dialog.afterClosed().subscribe((data: any) => {
      if (!!data) {
        window.location.href = data.replace("embedded=true", "");
      }
    });
  }
}

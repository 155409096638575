<div class="row field-adjust" id="custom-height">
    <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="row" *ngIf="goalsPresentStatus">
    <div class="col-md-12">
        <div class="custom-height">
        <form [formGroup]="projectGoalTrainingForm">
            <div class="custom-table table-responsive">
                <table class="table table-bordered">
                    <thead style="position: sticky; z-index: 1">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col" class="w-100">OKR Goal</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr FormArray="projectGoalTrainingForm" *ngFor="let goal of projectGoalTrainingForm.controls; let i = index">
                            <td>{{i+1}}</td>
                            <td class="w-100" [id]="'goalDiv_'+i" >
                                <div class="d-flex justify-content-between">
                                    <div style="width:100%">
                                        <div class="wprr">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label></mat-label>
                                                <input type="text"
                                                    placeholder="Goal" matInput
                                                    [(ngModel)]="goal.value.id" [ngModelOptions]="{standalone: true}"
                                                    [matAutocomplete]="autoGoal" (ngModelChange)="searchGoal(i)"
                                                    name="goal_name" inputmode='none'>
                                            </mat-form-field>
                                            <mat-autocomplete #autoGoal="matAutocomplete"
                                                [displayWith]="getGoalTitle.bind(this)" (optionSelected)="onselectGoal($event, i)">
                                                    <mat-option *ngFor="let item of getGoal(index, i)" [value]="item.id" style="font-size: 0.8rem; margin-left: 9px; height: unset; line-height: 1.5;">
                                                        {{item.goal_name}} <br>
                                                        <span style="font-size: 11px;font-style: italic;">
                                                            {{item.goal_description}} 
                                                        </span>
                                                    </mat-option>
                                            </mat-autocomplete>    
                                        </div>
                                        <span class="spame" style="display: inline-block">{{getGoalDesc(goal.value.id)}}</span>
                                    </div>
                                    <div>
                                        <mat-icon class="collapsed rotate-minus-90deg" (click)="toggleEvent($event)" data-bs-toggle="collapse" attr.data-bs-target="#collapseOne{{i}}" aria-expanded="true" aria-controls="collapseOne" style="cursor: pointer;">arrow_back_ios</mat-icon>
                                    </div>
                                </div>
                                <div [id]="'collapseOne'+i" class="accordion-collapse collapse" [aria-labelledby]="'goalDiv_'+i" data-bs-parent="#accordionExample">
                                    <table class="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="sub-th" >#</th>
                                                <th scope="col" class="sub-th" >Key Training</th>
                                                <!-- <th scope="col" class="sub-th" >Users</th> -->
                                                <th scope="col" class="sub-th" style="width: 120px">Due Date</th>
                                                <th class="text-center col-2 sub-th" scope="col" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let training of goal.value.trainings; let j = index">
                                                <td class="align-middle">{{j+1}}</td>
                                                <td class="align-middle">
                                                    <div>
                                                        <mat-form-field class="example-full-width w-100" appearance="outline">
                                                            <mat-label class="label-design"></mat-label>
                                                            <input type="text" matInput fullWidth status="basic" 
                                                            [(ngModel)]="training.training_name" [ngModelOptions]="{standalone: true}" name="release_name">
                                                        </mat-form-field>
                                                    </div>    
                                                </td>
                                                <!-- <td class="align-middle">
                                                    <mat-form-field class="example-chip-list" appearance="outline">
                                                        <mat-chip-list #chipList aria-label="User selection">
                                                        <mat-chip
                                                            *ngFor="let user of training?.users;let k = index"
                                                            [selectable]="selectable"
                                                            [removable]="removable && !disableFields"
                                                            (removed)="removeTeam(user, i, j)">
                                                            {{user}}
                                                            <mat-icon matChipRemove *ngIf="removable && !disableFields">cancel</mat-icon>
                                                        </mat-chip>
                                                        <input
                                                            placeholder="Users.."
                                                            *ngIf="!disableFields"
                                                            [matAutocomplete]="auto"
                                                            [matChipInputFor]="chipList"
                                                            #operationTrigger="matAutocompleteTrigger"
                                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                            [matChipInputAddOnBlur]="addOnBlur"
                                                            (ngModelChange)="searchTeam(i,j)"
                                                            (click)="operationTrigger.openPanel()"
                                                            name="users">
                                                        </mat-chip-list>
                                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTeam($event, i,j)">
                                                            <mat-option *ngFor="let team of filteredTeamListFilter(i,j)" [value]="team.name" style="font-size: 0.8rem; height: 30px;">
                                                                {{team.name}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                </td> -->
                                                <td class="align-middle">
                                                    <div>
                                                    <mat-form-field appearance="outline" class="py-0 px-0" style="width: 120px">
                                                        <mat-label class="label-design"></mat-label>
                                                        <input matInput [matDatepicker]="picker"
                                                            readonly
                                                            [(ngModel)]="training.due_date" [ngModelOptions]="{standalone: true}"
                                                            fullWidth status="basic" (click)="picker.open()" name="due_date">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker>
                                                        </mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                                </td>
                                                <td class="align-middle">
                                                    <div style="display: flex; align-items: center; justify-content: space-around;" *ngIf="!user?.is_vendor">
                                                        <span class="cursor-pointer" (click)="removeTrainingItems(i, j)"
                                                        *ngIf="!disableFields">
                                                            <span class="cursor-pointer">
                                                                <img src="./assets/images/comment-delete.svg"
                                                                    style="width:16px;">
                                                            </span>
                                                        </span>
        
                                                        <span class="cursor-pointer" (click)="addTrainingItems(i)"
                                                        *ngIf="!disableFields && goal?.value?.trainings.length - 1 === j">
                                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                            </mat-icon>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="goal?.value?.trainings?.length <= 0">
                                                <td></td>
                                                <td></td>
                                                <!-- <td></td> -->
                                                <td></td>
                                                <td>
                                                    <a class="add-btton"
                                                        (click)="addTrainingItems(i)" *ngIf="!disableFields && !user?.is_vendor">
                                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                            class="cursor-pointer fs-5">add
                                                        </mat-icon>
                                                        Add Training
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                            <td>
                                <div style="display: flex; align-items: center; justify-content: space-around;" *ngIf="!user?.is_vendor">
                                    <span class="cursor-pointer" (click)="removeGoalAllTraining(i)">
                                        <span class="cursor-pointer">
                                            <img src="./assets/images/comment-delete.svg"
                                                style="width:16px;">
                                        </span>
                                    </span>
                                    <span class="cursor-pointer" (click)="addGoalItems(index)"
                                    *ngIf="projectGoalTrainingForm?.controls?.length - 1 === i &&  i < projectGoals.length-1">
                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                            class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                        </mat-icon>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="projectGoalTrainingForm?.controls?.length <= 0">
                            <td></td>
                            <td></td>
                            <td class="w-50">
                                <a class="add-btton"
                                    (click)="addGoalItems(index)" *ngIf="!disableFields && !user?.is_vendor">
                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                        class="cursor-pointer fs-5">add
                                    </mat-icon>
                                    Add Goal
                                </a>
                            </td>
                        </tr>          
                    </tbody>
                </table>
            </div>
        </form>
        </div>
    </div>
</div>
<div class="bottom-fixed-button" *ngIf="goalsPresentStatus && !user?.is_vendor">   
    <mat-card-actions class="border-top footer-align mb-0 py-3" >
        <div class="row">
            <div class="col-md-12">
                <button id="buttons_back" mat-button mat-stroked-button color="primary" (click)="undoProcess()">
                    <span id="buttons_back_new" class="m-0 ">
                        {{staticText?.buttons.undo_btn}}
                    </span>
                </button>
                <button id="save_btn" mat-button mat-flat-button color="primary" class="bg-primary text-white float-right"
                    (click)="saveProjectGoalTraining()">
                        {{staticText?.buttons?.save_btn}}
                </button>
            </div>
        </div>
    </mat-card-actions> 
</div>                    
<div style="overflow-x: hidden">
  <div class="tab-holder mt-3">
    <div class="accordion" id="accordionPanel">
      <div
        *ngFor="let category of assessments; let i = index"
        class="accordion-item"
      >
        <h2 class="accordion-header" id="heading{{ i }}">
          <button
            class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
            type="button"
            data-bs-toggle="collapse"
            attr.data-bs-target="#collapse{{ i }}"
            aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
            aria-controls="collapse{{ i }}"
            style="font-size: 13px; font-weight: 700"
          >
            {{ category.category }}
          </button>
        </h2>
        <div
          id="collapse{{ i }}"
          class="accordion-collapse collapse {{ i === 0 ? 'show' : '' }}"
          aria-labelledby="heading{{ i }}"
          data-bs-parent="#accordionPanel"
        >
          <div class="accordion-body p-2">
            <div class="flex-container-1">
              <div
                *ngIf="
                  category.assessments && category.assessments?.length <= 0
                "
                class="trn-no"
              >
                You haven't created any assessments yet.
              </div>
              <app-loading
                style="width: 100%; min-height: 200px"
                *ngIf="!category.assessments"
              ></app-loading>
              <div class="flex-item p-1" *ngFor="let x of category.assessments">
                <div
                  class="trn-x"
                  [ngStyle]="{ background: x.color }"
                  style="height: 200px"
                  (click)="openResult(x)"
                >
                  <h6 [matTooltip]="x.name" class="mb-0 trn-nm">
                    {{ x.name }}
                  </h6>
                  <div class="mb-0 mt-2 trn-ph" *ngIf="x.created_by">
                    <div class="d-flex align-items-center">
                      <mat-icon class="small-icon mr-1">perm_identity</mat-icon>
                      <span>Owner</span>
                    </div>
                    <span style="display: flex; font-weight: 700">
                      {{
                        x.created_by.first_name + " " + x.created_by.last_name
                      }}
                    </span>
                  </div>
                  <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                    Created On
                    <span style="display: flex; font-weight: 700">
                      {{ x.start_date | date : "dd MMM, yyyy" }}
                    </span>
                  </p>
                  <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                    Due By
                    <span style="display: flex; font-weight: 700">
                      {{ x.end_date | date : "dd MMM, yyyy" }}
                    </span>
                  </p>
                  <span class="shw-status" style="bottom: 45px">{{
                    x.status
                  }}</span>
                  <div class="row tmg">
                    <div class="col-sm-9 ta">
                      <p class="mb-0 tap">
                        Updated {{ x.updated_at | timeago }}
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <app-owner
                        [editable]="false"
                        [ownerdata]="x.owners"
                        *ngIf="x?.owners?.length > 0"
                      ></app-owner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="assessments?.length === 0" class="text-center">
    No Assessments Available
  </div>
</div>

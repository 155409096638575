import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ProjectService } from 'src/app/shared/services/project.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
@Component({
  selector: 'app-project-release-management',
  templateUrl: './project-release-management.component.html',
  styleUrls: ['./project-release-management.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProjectReleaseManagementComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar?.release_tracker;
  projectID: any;
  programDetail: any;
  projectGoals: any;
  allGoalItems: any[] = [];
  projectGoalForm: FormArray | any;
  goalsPresentStatus: boolean = false;
  deletedReleaseList: any[] = [];
  isloading: boolean = false;
  user: any;
  userList: any;
  releaseStatusList: any;
  vendorList: any;

  constructor(
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private router: Router,
    public fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private date: DatePipe) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.getprojectId();
    this.createForm();
    this.getProjectReleaseByProjectId();
    this.getMetadata();
  }

  getprojectId() {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectID = atob(params.id);
    });
  }

  getProjectReleaseByProjectId() {
    this.spinner.show();
    this.projectGoals = [];
    this.isloading = true;
    this.projectService.getGoalsList(this.projectID).subscribe((res: any) => {
      this.spinner.hide();
      this.isloading = false;
      this.projectGoals = res;
      if (res.length > 0) {
        this.goalsPresentStatus = true;
        this.projectGoals.forEach((elm: any) => {
          if (elm.releases.length > 0) {
            this.projectGoalForm.push(
              this.fb.control(elm)
            )
          }
        });
      } else {
        this.callReleaseGoalEmptyPopUp();
      }
    });
  }

  callReleaseGoalEmptyPopUp() {
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = { "name": "empty_goal", "section": "release_tracker" };
    dialog.afterClosed().subscribe(data => {
      if (!!data) {
        this.router.navigate([`view/` + btoa(this.projectID) + `/okrlog`]);
      }
    })
  }

  getMetadata() {
    this.projectService.getUser().subscribe((res: any) => {
      this.userList = res;
    });
    this.projectService.getBaseValue('project_release_status').subscribe((res: any) => {
      this.releaseStatusList = res;
    });
    this.projectService.getVendorList().subscribe((res: any) => {
      this.vendorList = res;
    });
  }


  searchGoal(index: any) {
    return this.getGoal(index)
  }

  getGoal(index: any) {
    var filteredArray = this.projectGoals;
    this.projectGoalForm?.controls.forEach((val: any) => {
      filteredArray = filteredArray.filter((f: any) => f.id !== val?.value?.id);
    });
    return filteredArray;
  }

  getGoalTitle(goalId: any) {
    if (goalId) {
      return this.projectGoals.find((item: any) => item.id === goalId)?.goal_name;
    }
  }
  getGoalDesc(goalId: any) {
    if (goalId) {
      return this.projectGoals.find((item: any) => item.id === goalId)?.goal_description;
    }
  }
  onselectGoal(event: any, i: any) {
    var data = this.projectGoals[this.projectGoals.findIndex((e: any) => e.id == event.option.value)];
    this.projectGoalForm.controls[i].value = data;
    var id = document.getElementById('collapseOne' + i);
    console.log('collapseOne' + i);
    id?.classList.add('show');
  }
  removeReleaseItems(goalIndx: any, releaseIndx: any) {
    var releaseId = this.projectGoalForm.controls[goalIndx]?.value?.releases[releaseIndx]?.id;
    // if(releaseId){
    //   this.deleteReleaseFromProject(releaseId);
    // }
    this.projectGoalForm.controls[goalIndx].value.releases.splice(releaseIndx, 1)
    if (releaseId && !this.deletedReleaseList.includes(releaseId)) {
      this.deletedReleaseList.push(releaseId);
    }
  }

  // deleteReleaseFromProject(releaseId:any){
  //   this.spinner.show();
  //   this.projectService.deleteReleaseForProject(releaseId).subscribe((res: any) => {
  //   this.spinner.hide();
  //     return true;
  //   },err=>{this.spinner.hide()
  //   });
  // }

  removeGoalAllReleases(i: any) {
    this.projectGoalForm.controls[i].value.releases.forEach((releaseId: any) => {
      if (releaseId && !this.deletedReleaseList.includes(releaseId.id)) {
        this.deletedReleaseList.push(releaseId.id);
      }
    })
    this.projectGoalForm.controls.splice(i, 1);
  }

  addGoalItems(index: any) {
    var data = {
      id: null,
      goal_name: null,
      goal_description: null,
      projectgoal_id: null,
      releases: []
    }
    this.projectGoalForm.push(
      this.fb.control(data));
  }

  addReleaseItems(index: any) {
    this.projectGoalForm.controls[index].value.releases.push({
      id: null,
      release_name: null,
      due_date: null,
      owner: { id: null },
      vendor: { id: null },
      status: { id: null },
      release_notes: null
    })

  }

  saveGoal() {
    var created_releases: any = [];
    var updated_releases: any = [];
    this.projectGoalForm.controls.forEach((control: any) => {
      control.value.releases.forEach((release: any) => {
        var data = {
          "projectgoal_id": control?.value?.projectgoal_id,
          "release_name": release?.release_name,
          "due_date": this.datepipe.transform(release?.due_date, "yyyy-MM-dd"),
          "actual_date": this.datepipe.transform(release?.due_date, "yyyy-MM-dd"),
          "status_id": release?.status?.id,
          "vendor_id": release?.vendor?.id,
          "release_notes": release?.release_notes,
          "owner_id": release?.owner?.id,
          "id": release?.id
        }
        if (release.id == null) {
          created_releases.push(data);
        } else {
          updated_releases.push(data);
        }
      });
    });
    this.spinner.show();
    var payLoad = {
      "created_releases": created_releases,
      "updated_releases": updated_releases,
      "deleted_releases": this.deletedReleaseList
    }
    this.projectService.saveReleaseForProject(payLoad, this.projectID).subscribe((res: any) => {
      this.spinner.hide();
      this.toastr.success(res?.message);
      this.projectGoalForm.clear();
      this.deletedReleaseList = [];
      this.projectGoals = res?.data;
      res?.data?.forEach((elm: any) => {
        if (elm.releases.length > 0) {
          this.projectGoalForm.push(
            this.fb.control(elm)
          )
        }
      });
    }, err => {
      this.spinner.hide()
    });
  }

  undoProcess() {
    this.projectGoalForm.clear();
    this.deletedReleaseList = [];
    this.projectGoals.forEach((elm: any) => {
      if (elm.releases.length > 0) {
        this.projectGoalForm.push(
          this.fb.control(elm)
        )
      }
    });
  }

  createForm() {
    this.projectGoalForm = this.fb.array([])
  }

  toggleEvent(event: any) {
    if (event.target.ariaExpanded === "true") {
      event.srcElement.classList.remove('rotate-minus-90deg');
      event.srcElement.classList.add('rotate-90deg');
    }
    if (event.target.ariaExpanded === "false") {
      event.srcElement.classList.remove('rotate-90deg');
      event.srcElement.classList.add('rotate-minus-90deg');
    }
  }
}

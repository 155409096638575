import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ScorecardComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar.performance_dashboard;
  projectId: any;
  
  
  constructor(
    private activatedRoute: ActivatedRoute, 
       private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.getprojectId();
  }

  getprojectId() {
    this.activatedRoute?.parent?.params.subscribe(params => {
      this.projectId = atob(params.id);
    });
  }
  
}

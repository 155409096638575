<div class="modal-dialog m-0" style="max-width: 100%">
  <div class="modal-content" style="border: none">
    <app-modal-header-common
      [title]="mode == 'add' ? 'Add Budget' : 'Edit Budget'"
    >
    </app-modal-header-common>
    <div
      class="modal-body p-0 p-3 incident-tracker"
      style="overflow-y: auto; max-height: 50vh"
    >
      <form [formGroup]="budgetForm">
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Budget Name</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="name"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Cost Type</mat-label>
              <mat-select formControlName="cost_type">
                <mat-option *ngFor="let option of costType" [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Cost Head</mat-label>
              <mat-select formControlName="cost_head">
                <mat-option *ngFor="let option of costHead" [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Amount</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="amount"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Owner</mat-label>
              <mat-select formControlName="owner">
                <mat-option *ngFor="let option of userList" [value]="option.id">{{option.first_name}} {{option.last_name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>OU/GDP</mat-label>
              <mat-select formControlName="ou_gdp">
                <mat-option *ngFor="let option of ouGdp" [value]="option.id">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Budget Approver</mat-label>
              <mat-select formControlName="budget_approver">
                <mat-option *ngFor="let option of userList" [value]="option.id">{{option.first_name}} {{option.last_name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Valid From Date</mat-label>
              <input
                matInput
                [matDatepicker]="pickerValidFrom"
                readonly
                fullWidth
                status="basic"
                (click)="pickerValidFrom.open()"
                formControlName="valid_from"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerValidFrom">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerValidFrom> </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Valid To Date</mat-label>
              <input
                matInput
                [matDatepicker]="pickerValidTo"
                readonly
                fullWidth
                status="basic"
                (click)="pickerValidTo.open()"
                formControlName="valid_to"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerValidTo">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerValidTo> </mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="d-flex justify-content-between">
          <button
            class="mr-2 ml-2"
            mat-flat-button
            color="primary"
            *ngIf="mode == 'edit'"
            (click)="editBudget()"
          >
            Update
          </button>
          <button
            class="mr-2 ml-2"
            mat-flat-button
            color="primary"
            *ngIf="mode == 'add'"
            (click)="addBudget()"
          >
            Add
          </button>
          <button (click)="closeDialog()" mat-flat-button color="secondary">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

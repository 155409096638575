import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../shared/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-addcredientials',
  templateUrl: './addcredientials.component.html',
  styleUrls: ['./addcredientials.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class AddcredientialsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Output() closemodalwindow = new EventEmitter();
  @Output() messageEvent = new EventEmitter<string>();
  @Output() refereshParentData = new EventEmitter<string>();
  @Input() selected_id: any;
  credientialsForm: FormGroup | any;
  projectId: any;
  editformValues: any[] = [];
  title: any;
  tesId = 0
  sourceType: any[] = [];
  password: any;
  show = true;

  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.getprojectId();
    this.getcredientialsByIdPatch()
    this.BuildForm();
    this.getsourceType();
    console.log('selected_id works ot rnit', this.selected_id);
  }

  getprojectId() {
    this.activatedRoute?.parent?.params.subscribe(params => {
      this.projectId = atob(params.id);
      console.log('getparmasid', this.projectId);
    });
  }

  getcredientialsByIdPatch() {
    this.spinner.show();
    if (this.selected_id != 0) {
      this.title = "Edit Credentials";
      this.projectService.getcredientialsById(this.selected_id).subscribe((response: any) => {
        console.log(' getcredientials by id', response);
        this.editformValues = response[0];
        this.credientialsForm.patchValue(response[0]);
        this.credientialsForm.controls.source_type.patchValue(response[0].source_type.id);
        this.spinner.hide();
      })
    }
    else {
      this.title = "Add Credentials";
      this.spinner.hide();
    }
  }

  getsourceType() {
    this.spinner.show();
    this.projectService.getsourceTypeData().subscribe((response: any) => {
      this.sourceType = response;
      console.log('source data', this.sourceType);
      this.spinner.hide();
    })
  }

  BuildForm() {
    this.credientialsForm = this.fb.group({
      id: [''],
      source_type: Number('0',),
      source_name: [''],
      source_url: [''],
      username: [''],
      password: [''],
      api_token: [''],
      data_type: [''],
      is_active: [true],
      project: [this.projectId],
      /*  created_by: 0,
       last_updated_by: 0, */
    });
  }



  onOptionsSelected(data: any) {
    console.log('data', data)
  }



  doFormSubmit(data: any) {
    this.spinner.show();
    console.log("this.credientialsForm.value", this.credientialsForm.controls.source_type);

    if (this.selected_id == 0) {

      console.log('response.add works');


      this.projectService.addCredientials(this.credientialsForm.value).subscribe((response: any) => {
        console.log('response.mesage', response);
        this.toastr.success('Saved successfully');
        this.credientialsForm.reset();
        this.refereshParentData.emit();
        this.spinner.hide();
      })
    }
    else {
      console.log('response.edit works');

      /*  const formvalues = {
         api_token: this.credientialsForm.value.api_token,
         data_type: this.credientialsForm.value.api_token,
         id: this.credientialsForm.value.id,
         is_active: true,
         password: this.credientialsForm.value.password,
         project: this.projectId,
         source_name: this.credientialsForm.value.source_name,
         source_type: Number(this.credientialsForm.value?.source_type),
         source_url: this.credientialsForm.value.source_url,
         username: this.credientialsForm.value.username,
         created_by: 0,
         last_updated_by: 0,
       } */
      this.projectService.updateCredientials(this.credientialsForm.value).subscribe((response: any) => {
        console.log('response.mesage', response);
        this.toastr.success('Updated successfully');
        this.credientialsForm.reset();
        this.refereshParentData.emit();
        this.spinner.hide();
      })
    }
  }

  closeaddform(e: any) {
    this.closemodalwindow.emit(e);
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = false;
    } else {
      this.password = 'password';
      this.show = true;
    }
  }
}

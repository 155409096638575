<div class="row field-adjust" id="custom-height">
  <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div *ngIf="isloading" style="height: calc(100vh - 200px)">
  <app-loading></app-loading>
</div>
<div class="row" *ngIf="goalsPresentStatus">
  <div class="col-md-12">
    <div class="custom-height">
      <form [formGroup]="projectGoalForm">
        <div class="custom-table table-responsive">
          <table class="table table-bordered">
            <thead style="position: sticky; z-index: 1">
              <tr>
                <th scope="col">#</th>
                <th scope="col" class="w-100">OKR Goal</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                FormArray="projectGoalForm"
                *ngFor="let goal of projectGoalForm.controls; let i = index"
              >
                <td>{{ i + 1 }}</td>
                <td [id]="'goalDiv_' + i">
                  <div class="d-flex justify-content-between">
                    <div style="width: 100%">
                      <div class="wprr">
                        <mat-form-field
                          class="example-full-width"
                          style="width: 100%"
                          appearance="outline"
                        >
                          <mat-label></mat-label>
                          <input
                            type="text"
                            placeholder="Goal"
                            matInput
                            [(ngModel)]="goal.value.id"
                            [ngModelOptions]="{ standalone: true }"
                            [matAutocomplete]="autoGoal"
                            (ngModelChange)="searchGoal(i)"
                            name="goal_name"
                            inputmode="none"
                          />
                        </mat-form-field>
                        <mat-autocomplete
                          #autoGoal="matAutocomplete"
                          [displayWith]="getGoalTitle.bind(this)"
                          (optionSelected)="onselectGoal($event, i)"
                        >
                          <mat-option
                            *ngFor="let item of getGoal(index, i)"
                            [value]="item.id"
                            style="
                              font-size: 0.8rem;
                              margin-left: 9px;
                              height: unset;
                              line-height: 1.5;
                            "
                          >
                            {{ item.goal_name }} <br />
                            <span style="font-size: 11px; font-style: italic">
                              {{ item.goal_description }}
                            </span>
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                      <span class="spame" style="display: inline-block">{{
                        getGoalDesc(goal.value.id)
                      }}</span>
                    </div>
                    <div>
                      <mat-icon
                        class="collapsed rotate-minus-90deg"
                        (click)="toggleEvent($event)"
                        data-bs-toggle="collapse"
                        attr.data-bs-target="#collapseOne{{ i }}"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        style="cursor: pointer"
                        >arrow_back_ios</mat-icon
                      >
                    </div>
                  </div>
                  <div
                    [id]="'collapseOne' + i"
                    class="accordion-collapse collapse"
                    [aria-labelledby]="'goalDiv_' + i"
                    data-bs-parent="#accordionExample"
                  >
                    <table class="table table-bordered mt-2">
                      <thead>
                        <tr>
                          <th scope="col" class="sub-th">#</th>
                          <th scope="col" class="w-50 sub-th">Key Release</th>
                          <th scope="col" class="w-50 sub-th">Due Date</th>
                          <th scope="col" class="w-50 sub-th">Actual Date</th>
                          <th scope="col" class="w-50 sub-th">Status</th>
                          <th scope="col" class="w-50 sub-th">Vendor</th>
                          <th scope="col" class="w-50 sub-th">Owner</th>
                          <th scope="col" class="w-50 sub-th">Release Notes</th>
                          <th class="text-center col-2 sub-th" scope="col">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let release of goal.value.releases;
                            let j = index
                          "
                        >
                          <td class="align-middle">{{ j + 1 }}</td>
                          <td class="align-middle">
                            <mat-form-field
                              class="example-full-width"
                              style="width: 100%"
                              appearance="outline"
                            >
                              <mat-label class="label-design"></mat-label>
                              <input
                                type="text"
                                matInput
                                fullWidth
                                status="basic"
                                [(ngModel)]="release.release_name"
                                [ngModelOptions]="{ standalone: true }"
                                name="release_name"
                              />
                            </mat-form-field>
                          </td>
                          <td class="align-middle">
                            <div style="width: 230px">
                              <mat-form-field
                                appearance="outline"
                                class="w-100 py-0 px-0"
                              >
                                <mat-label class="label-design"></mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  readonly
                                  [(ngModel)]="release.due_date"
                                  [ngModelOptions]="{ standalone: true }"
                                  fullWidth
                                  status="basic"
                                  (click)="picker.open()"
                                  name="due_date"
                                />
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker> </mat-datepicker>
                              </mat-form-field>
                            </div>
                          </td>
                          <td class="align-middle">
                            <div style="width: 230px">
                              <mat-form-field
                                appearance="outline"
                                class="w-100 py-0 px-0"
                              >
                                <mat-label class="label-design"></mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="pickerActualDate"
                                  readonly
                                  [(ngModel)]="release.actual_date"
                                  [ngModelOptions]="{ standalone: true }"
                                  fullWidth
                                  status="basic"
                                  (click)="pickerActualDate.open()"
                                  name="actual_date"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="pickerActualDate"
                                >
                                </mat-datepicker-toggle>
                                <mat-datepicker #pickerActualDate>
                                </mat-datepicker>
                              </mat-form-field>
                            </div>
                          </td>
                          <td class="align-middle">
                            <mat-form-field
                              class="example-full-width"
                              style="width: 100%"
                              appearance="outline"
                            >
                              <mat-label class="label-design"></mat-label>
                              <mat-select
                                [(ngModel)]="release.status.id"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <mat-option
                                  *ngFor="let option of releaseStatusList"
                                  [value]="option.id"
                                  >{{ option.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td class="align-middle">
                            <mat-form-field
                              class="example-full-width"
                              style="width: 100%"
                              appearance="outline"
                            >
                              <mat-label class="label-design"></mat-label>
                              <mat-select
                                [(ngModel)]="release.vendor.id"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <mat-option
                                  *ngFor="let option of vendorList"
                                  [value]="option.id"
                                  >{{ option.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td class="align-middle">
                            <mat-form-field
                              class="example-full-width"
                              style="width: 100%"
                              appearance="outline"
                            >
                              <mat-label class="label-design"></mat-label>
                              <mat-select
                                [(ngModel)]="release.owner.id"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <mat-option
                                  *ngFor="let option of userList"
                                  [value]="option.id"
                                  >{{ option.first_name }}
                                  {{ option.last_name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td class="align-middle">
                            <mat-form-field
                              class="example-full-width"
                              style="width: 100%"
                              appearance="outline"
                            >
                              <mat-label class="label-design"></mat-label>
                              <input
                                type="text"
                                matInput
                                fullWidth
                                status="basic"
                                [(ngModel)]="release.release_notes"
                                [ngModelOptions]="{ standalone: true }"
                                name="release_notes"
                              />
                            </mat-form-field>
                          </td>
                          <td class="align-middle">
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                              "
                            >
                              <span
                                class="cursor-pointer"
                                (click)="removeReleaseItems(i, j)"
                                *ngIf="!disableFields"
                              >
                                <span class="cursor-pointer">
                                  <img
                                    src="./assets/images/comment-delete.svg"
                                    style="width: 16px"
                                  />
                                </span>
                              </span>

                              <span
                                class="cursor-pointer"
                                (click)="addReleaseItems(i)"
                                *ngIf="
                                  !disableFields &&
                                  goal?.value?.releases.length - 1 === j
                                "
                              >
                                <mat-icon
                                  aria-hidden="false"
                                  aria-label="material-icons-filled"
                                  class="cursor-pointer fs-5"
                                  style="margin-top: 10px"
                                  >add
                                </mat-icon>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="goal?.value?.releases?.length <= 0">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="w-50">
                            <a
                              class="add-btton"
                              (click)="addReleaseItems(i)"
                              *ngIf="!disableFields && !user?.is_vendor"
                            >
                              <mat-icon
                                aria-hidden="false"
                                aria-label="material-icons-filled"
                                class="cursor-pointer fs-5"
                                >add
                              </mat-icon>
                              Add Release
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                    "
                    *ngIf="!user?.is_vendor"
                  >
                    <span
                      class="cursor-pointer"
                      (click)="removeGoalAllReleases(i)"
                      *ngIf="!disableFields"
                    >
                      <span class="cursor-pointer">
                        <img
                          src="./assets/images/comment-delete.svg"
                          style="width: 16px"
                        />
                      </span>
                    </span>

                    <span
                      class="cursor-pointer"
                      (click)="addGoalItems(index)"
                      *ngIf="
                        !disableFields &&
                        projectGoalForm?.controls?.length - 1 === i &&
                        i < projectGoals.length - 1
                      "
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="material-icons-filled"
                        class="cursor-pointer fs-5"
                        style="margin-top: 10px"
                        >add
                      </mat-icon>
                    </span>
                  </div>
                </td>
              </tr>
              <tr *ngIf="projectGoalForm?.controls?.length <= 0">
                <td></td>
                <td></td>
                <td class="w-50">
                  <a
                    class="add-btton"
                    (click)="addGoalItems(index)"
                    *ngIf="!disableFields && !user?.is_vendor"
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="material-icons-filled"
                      class="cursor-pointer fs-5"
                      >add
                    </mat-icon>
                    Add Goal
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="bottom-fixed-button" *ngIf="goalsPresentStatus && !user?.is_vendor">
  <mat-card-actions class="border-top footer-align mb-0 py-3">
    <div class="row">
      <div class="col-md-12">
        <button
          id="buttons_back"
          mat-button
          mat-stroked-button
          color="primary"
          (click)="undoProcess()"
        >
          <span id="buttons_back_new" class="m-0">
            {{ staticText?.buttons.undo_btn }}
          </span>
        </button>
        <button
          id="save_btn"
          mat-button
          mat-flat-button
          color="primary"
          class="bg-primary text-white float-right"
          (click)="saveGoal()"
        >
          {{ staticText?.buttons?.save_btn }}
        </button>
      </div>
    </div>
  </mat-card-actions>
</div>

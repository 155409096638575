<div class="row field-adjust" id="custom-height">
    <div *ngIf="!programDetail" style="height: calc(100vh - 200px);">
        <app-loading></app-loading>
    </div>
    <div class="col-md-12" *ngIf="programDetail">
        <div class="row">
            <div class="title-holder">
                <p id="title" class="fw-bold fs-24 mb-0">
                    {{headerName}}
                </p>
                <p class="m-0 mb-1" style="font-size: 13px;">{{projectdetail?.project_id}} | {{programDetail?.portfolio_name}} | {{programDetail?.subportfolio_name}} | {{programDetail?.program_name}}
                    | {{programDetail?.manager}}
                </p>
                <app-tag *ngIf="projectdetail" [dataObj]="getTagObject()" [tagsIds]="projectdetail?.tag_ids"></app-tag>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row field-adjust" id="custom-height">
  <app-view-page-header [headerName]="headerText"></app-view-page-header>
</div>
<div class="row w-100 m-0 workfce">
  <div class="col-md-auto p-0 px-0" style="width: 100%">
    <div class="mb-4">
      <app-filter-card
        [pagetype]="'workforce'"
        [pageTab]="activeTab == 2 ? 'attrition' : 'workforce'"
      ></app-filter-card>
    </div>
    <!--Tabs Started -->
    <mat-tab-group
      mat-align-tabs="start"
      class="head"
      (selectedTabChange)="changeTab($event)"
    >
      <mat-tab
        label="{{ staticText?.delivery.resources.table }} ({{
          allResourceList?.count
        }})"
      >
        <div *ngIf="activeTab == 0">
          <div class="custom-table fixed-table-header">
            <table class="table table-bordered" style="min-height: 100px">
              <thead>
                <tr>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">#</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.resource_id
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.resrc_name
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.worker_cat
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.job_title
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">OU/GDP</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">Contract Type</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex justify-content-center">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="cursor-pointer"
                *ngIf="allResourceList?.data?.length"
              >
                <tr
                  *ngFor="
                    let tableRecords of allResourceList?.data;
                    index as tableIndex
                  "
                >
                  <td scope="row">
                    <span class="d-flex fs-13">
                      {{ startValue + tableIndex + 1 }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex color-blue fs-13">
                      {{ tableRecords?.resource_id || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.resource_name || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.job_category || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.job_title || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.company_name || "-" }}
                    </span>
                  </td>
                  <td style="width: 25px">
                    <span
                      class="d-flex fs-13"
                      *ngIf="tableRecords?.contract_type"
                    >
                      {{ tableRecords?.contract_type }}
                    </span>
                    <span *ngIf="!tableRecords?.contract_type">
                      <mat-form-field
                        *ngIf="
                          !user?.is_vendor &&
                          !tableRecords?.job_category?.includes('EMPLOYEE')
                        "
                        appearance="outline"
                        style="width: 65px; z-index: 0"
                      >
                        <mat-select
                          (ngModelChange)="
                            loadResourceContentType(
                              $event,
                              tableIndex,
                              tableRecords
                            )
                          "
                          style="height: 20px"
                          [(ngModel)]="tableRecords.contract_type"
                        >
                          <mat-option
                            *ngFor="let option of contractTypes"
                            [value]="option.name"
                            >{{ option.name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </span>
                  </td>
                  <td>
                    <div
                      class="d-flex justify-content-around align-items-center"
                    >
                      <img
                        src="./assets/images/pages/workforce/edit.svg"
                        alt="edit"
                        (click)="
                          editWorkForceDetails(
                            tableRecords?.id,
                            'resource',
                            editWorkforcePopUp
                          )
                        "
                        *ngIf="!user?.is_vendor"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <ng-container *ngIf="!allResourceList?.data?.length">
                <div
                  class="noRecords d-flex justify-content-center text-center"
                >
                  <span>
                    {{ staticText.common.no_records_found }}
                  </span>
                </div>
              </ng-container>
            </table>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngIf="allResourceList?.count > 0"
          >
            <div class="p-1">
              <div
                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
              >
                <span class="p-1">Displaying</span>
                <select
                  class="form-select form-select-sm table-sort-filter"
                  aria-label="Default select example"
                  [(ngModel)]="sortRecords"
                  (ngModelChange)="getRecords()"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
                <span class="p-1">{{ allResourceList?.count }}</span>
                <span class="p-1">records</span>
              </div>
            </div>
            <div class="p-1">
              <ngb-pagination
                [pageSize]="sortRecords"
                [collectionSize]="allResourceList?.count"
                [maxSize]="2"
                [rotate]="true"
                [(page)]="CurrentPage"
                [boundaryLinks]="true"
                (pageChange)="getRecords()"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
        <!--Workforce ends-->
        <!-- </div> -->
      </mat-tab>
      <mat-tab
        label="{{ staticText?.delivery.resources.kkr }} ({{
          allKRRList?.count
        }})"
      >
        <div *ngIf="activeTab == 1">
          <div class="custom-table fixed-table-header">
            <table class="table table-bordered" style="min-height: 100px">
              <thead>
                <tr>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">#</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.resource_id
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.resrc_name
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.worker_cat
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.job_title
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">OU/GDP</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">Contract Type</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.country
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.classification
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex justify-content-center">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody class="cursor-pointer" *ngIf="allKRRList.data?.length">
                <tr
                  *ngFor="
                    let tableRecords of allKRRList.data;
                    index as tableIndex
                  "
                >
                  <td scope="row">
                    <span class="d-flex fs-13">
                      {{ tableIndex + 1 }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex color-blue fs-13">
                      {{ tableRecords?.resource_id || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.resource_name || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.job_category || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.job_title || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.company_name || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.contract_type || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.country || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.krr_classification }}
                    </span>
                  </td>
                  <td>
                    <div
                      class="d-flex justify-content-around align-items-center"
                    >
                      <img
                        src="./assets/images/pages/workforce/edit.svg"
                        alt="edit"
                        *ngIf="!user?.is_vendor"
                        (click)="
                          editWorkForceDetails(
                            tableRecords?.id,
                            'krr',
                            editWorkforcePopUp
                          )
                        "
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <ng-container *ngIf="!allKRRList?.data?.length">
                <div
                  class="noRecords d-flex justify-content-center text-center"
                >
                  <span>
                    {{ staticText.common.no_records_found }}
                  </span>
                </div>
              </ng-container>
            </table>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngIf="allKRRList?.count > 0"
          >
            <div class="p-1">
              <div
                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
              >
                <span class="p-1">Displaying</span>
                <select
                  class="form-select form-select-sm table-sort-filter"
                  aria-label="Default select example"
                  [(ngModel)]="sortRecords"
                  (ngModelChange)="getRecords(true)"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
                <span class="p-1">{{ allKRRList?.count }}</span>
                <span class="p-1">records</span>
              </div>
            </div>
            <div class="p-1">
              <ngb-pagination
                [pageSize]="sortRecords"
                [collectionSize]="allKRRList?.count"
                [maxSize]="2"
                [rotate]="true"
                [(page)]="CurrentPage"
                [boundaryLinks]="true"
                (pageChange)="getRecords(true)"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        label="{{ staticText?.delivery.resources.attrition }} ({{
          allAttritionList?.count
        }})"
      >
        <div *ngIf="activeTab == 2">
          <div class="custom-table fixed-table-header">
            <table class="table table-bordered" style="min-height: 100px">
              <thead>
                <tr>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">#</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.resource_id
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.resrc_name
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">{{
                      staticText.workforce_dashboard.job_title
                    }}</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">Assignment start date</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">Assignment end date</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">Reason</span>
                  </th>
                  <th
                    class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                    scope="col"
                  >
                    <span class="d-flex">OU/GDP</span>
                  </th>
                </tr>
              </thead>
              <tbody class="cursor-pointer">
                <tr
                  *ngFor="
                    let tableRecords of allAttritionList?.data;
                    index as tableIndex
                  "
                >
                  <td scope="row">
                    <span class="d-flex fs-13">
                      {{ startValue + tableIndex + 1 }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex color-blue fs-13">
                      {{
                        tableRecords?.resource_id
                          ? tableRecords?.resource_id
                          : tableRecords?.ppr_num
                          ? tableRecords?.ppr_num
                          : "-"
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{
                        tableRecords?.resource_name
                          ? tableRecords?.resource_name
                          : tableRecords?.cwr_name
                          ? tableRecords?.cwr_name
                          : "-"
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.job_title || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{
                        tableRecords?.assignment_start_date || "Not Available"
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.assignment_end_date || "Not Available" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{
                        tableRecords?.end_of_assignment_reason ||
                          "Not Available"
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex fs-13">
                      {{ tableRecords?.vendor || "-" }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <ng-container *ngIf="!allAttritionList?.data?.length">
                <div
                  class="noRecords d-flex justify-content-center text-center"
                >
                  <span>
                    {{ staticText.common.no_records_found }}
                  </span>
                </div>
              </ng-container>
            </table>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngIf="allAttritionList?.count > 0"
          >
            <div class="p-1">
              <div
                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
              >
                <span class="p-1">Displaying</span>
                <select
                  class="form-select form-select-sm table-sort-filter"
                  aria-label="Default select example"
                  [(ngModel)]="sortRecords"
                  (ngModelChange)="getRecords(true)"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
                <span class="p-1">{{ allAttritionList?.count }}</span>
                <span class="p-1">records</span>
              </div>
            </div>
            <div class="p-1">
              <ngb-pagination
                [pageSize]="sortRecords"
                [collectionSize]="allAttritionList?.count"
                [maxSize]="2"
                [rotate]="true"
                [(page)]="CurrentPage"
                [boundaryLinks]="true"
                (pageChange)="getRecords(true)"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
        <!--Workforce ends-->
        <!-- </div> -->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #editWorkforcePopUp>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Resource Details'">
      </app-modal-header-common>
      <div
        class="modal-body p-0 p-3 incident-tracker"
        style="overflow-y: auto; height: 50vh"
      >
        <form [formGroup]="workForceResourceForm">
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Resource Id</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="resource_id"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Resource Name</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="resource_name"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Job Category</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="job_category"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Job Title</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="job_title"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>OU/GDP</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="company_name"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Classification</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="classification"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Country</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="country"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>KRR/TT</h6>
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>KRR Classification</mat-label>
                <!-- <input matInput type="text" status="basic"  formControlName="Status"> -->
                <mat-select formControlName="krr_classification">
                  <mat-option value="KRR">KRR</mat-option>
                  <mat-option value="Top Talent">Top Talent</mat-option>
                  <mat-option value="All Other">All Other</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>KRR Risk Mitigation Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="krr_risk_mitigation_plan"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>Work Permit</h6>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="work_permit"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Expiry Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker1"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker1.open()"
                  formControlName="work_permit_expiry_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker1">
                </mat-datepicker-toggle>
                <mat-datepicker #picker1> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Renewal Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="work_permit_renewal_plan"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Contingency Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="wp_contingency_plan"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>Data Access</h6>
          <div class="row">
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception Production access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_production_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt1">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt2" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception Generic ID</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_generic_id"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt3">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt4" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception PII access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_pii_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt6" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception PCI access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_pci_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt5" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception PHI access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_phi_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt5" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception cross border data access (without
                GDPR compliance)</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_cross_border_data_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt5" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>SOW Model</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="sow_model"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>Resignation Details</h6>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Approved by DL Manager</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="approved_by_dl_manager"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Expiry Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker2.open()"
                  formControlName="resignation_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker2">
                </mat-datepicker-toggle>
                <mat-datepicker #picker2> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>DL notified Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker3"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker3.open()"
                  formControlName="dl_notified_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker3">
                </mat-datepicker-toggle>
                <mat-datepicker #picker3> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4 d-flex flex-row">
              <div
                [ngClass]="
                  workForceResourceForm.get('lwd').value ? 'w-75' : 'w-100'
                "
              >
                <mat-form-field class="px-2 w-100" appearance="outline">
                  <mat-label>Last Working Day</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker11"
                    readonly
                    [ngModelOptions]="{ standalone: true }"
                    fullWidth
                    status="basic"
                    (click)="picker11.open()"
                    formControlName="lwd"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker11">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker11> </mat-datepicker>
                </mat-form-field>
              </div>
              <div
                class="w-25 d-flex justify-content-center align-items-center"
                *ngIf="workForceResourceForm.get('lwd').value"
              >
                <mat-checkbox formControlName="is_existed">Exited</mat-checkbox>
              </div>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Notice Period</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="notice_period"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Replacement Status </mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="replacement_status"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Replacement Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker4"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker4.open()"
                  formControlName="replacement_start_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker4">
                </mat-datepicker-toggle>
                <mat-datepicker #picker4> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Replacement Name</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="replacement_name"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>KT Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="kt_plan"
                />
              </mat-form-field>
            </div>
          </div>

          <h6>Training Details</h6>
          <div class="custom-table">
            <table class="table table-bordered">
              <thead style="position: sticky; top: 0; z-index: 1">
                <tr>
                  <th class="fixed-header" scope="col">#</th>
                  <th class="fixed-header" scope="col" class="w-50">
                    Training
                  </th>
                  <th class="fixed-header" scope="col" class="w-25">Status</th>
                  <th class="fixed-header text-center col-2" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let option of workForceResourceForm?.controls
                      .selected_trainings.controls;
                    let i = index
                  "
                >
                  <tr
                    [formGroup]="
                      workForceResourceForm?.controls.selected_trainings
                        .controls[i]
                    "
                  >
                    <td class="align-middle">{{ i + 1 }}</td>
                    <td class="align-middle w-25">
                      <mat-form-field
                        class="example-full-width"
                        style="width: 100%"
                        appearance="outline"
                      >
                        <mat-label></mat-label>
                        <input
                          type="text"
                          placeholder="Select Training"
                          matInput
                          formControlName="training_id"
                          [matAutocomplete]="autoFunction"
                          (ngModelChange)="searchTraining(i)"
                          (input)="onInputChanged($event.target.value)"
                          name="training_name"
                        />
                        <mat-autocomplete
                          #autoFunction="matAutocomplete"
                          (optionSelected)="onOptionSelectionChanged($event)"
                          [displayWith]="getTrainingName.bind(this)"
                        >
                          <mat-option
                            *ngFor="let item of getTrainingList(i)"
                            [value]="item.id"
                            style="font-size: 0.8rem; height: 30px"
                          >
                            {{ item.training_name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-select formControlName="status">
                          <mat-option value="Open">Open</mat-option>
                          <mat-option value="Closed">Closed</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td class="align-middle text-center">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-around;
                        "
                      >
                        <span
                          class="cursor-pointer"
                          (click)="removeTraining(i)"
                        >
                          <span class="cursor-pointer">
                            <img
                              src="./assets/images/comment-delete.svg"
                              style="width: 16px"
                            />
                          </span>
                        </span>

                        <span
                          class="cursor-pointer"
                          (click)="addTrainingItems(i)"
                          *ngIf="
                            workForceResourceForm?.get('selected_trainings')
                              ?.controls.length -
                              1 ===
                            i
                          "
                        >
                          <mat-icon
                            aria-hidden="false"
                            aria-label="material-icons-filled"
                            class="cursor-pointer fs-5"
                            style="margin-top: 10px"
                            >add
                          </mat-icon>
                        </span>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <tr
                  *ngIf="
                    workForceResourceForm.get('selected_trainings')?.controls
                      ?.length <= 0
                  "
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="w-25">
                    <a class="add-btton" (click)="addTrainingItems()">
                      <mat-icon
                        aria-hidden="false"
                        aria-label="material-icons-filled"
                        class="cursor-pointer fs-5"
                        >add
                      </mat-icon>
                      Add Training
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="d-flex justify-content-between">
            <button
              (click)="updateWorkForceDetails()"
              class="mr-2 ml-2"
              mat-flat-button
              color="primary"
            >
              Update
            </button>
            <button (click)="cancel()" mat-flat-button color="secondary">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

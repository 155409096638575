<div class="budget-toolbar">
  <div class="right-bar">
    <button
      mat-button
      class="bg-primary text-white"
      mat-button
      mat-flat-button
      (click)="addBudgetRecord()"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="material-icons-filled "
        class="cursor-pointer fs-5"
        >add
      </mat-icon>
      New Budget
    </button>
  </div>
</div>
<div class="custom-table fixed-table-header">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">#</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.budget_name
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.cost_type
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.cost_head
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.valid_from
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.valid_to
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.amount
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.owner
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.ou_gdp
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.budget_approver
          }}</span>
        </th>
        <th
          class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
          scope="col"
        >
          <span class="d-flex">{{
            staticText.delivery.financials.budget_table.action
          }}</span>
        </th>
      </tr>
    </thead>
    <tbody class="cursor-pointer" *ngIf="budgetRecords?.length">
      <tr *ngFor="let tableRecords of budgetRecords; index as tableIndex">
        <td scope="row">
          <span class="d-flex fs-13">
            {{ startValue + tableIndex + 1 }}
          </span>
        </td>
        <td scope="row">
          <span class="d-flex fs-13">
            {{ tableRecords?.name || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex color-blue fs-13">
            {{ tableRecords?.cost_type || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.cost_head || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.valid_from || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.valid_to || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.amount | currency:'USD':'symbol' || "-" }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.owner?.first_name }}
            {{ tableRecords?.owner?.last_name }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.ou_gdp?.name }}
          </span>
        </td>
        <td>
          <span class="d-flex fs-13">
            {{ tableRecords?.approver?.first_name }}
            {{ tableRecords?.approver?.last_name }}
          </span>
        </td>
        <td>
          <div class="d-flex justify-content-around align-items-center">
            <img
              (click)="editBudgetRecord(tableRecords)"
              src="./assets/images/pages/workforce/edit.svg"
              alt="edit"
            />
            <img
              src="./assets/images/comment-delete.svg"
              alt="delete"
              (click)="deleteBudgetRecord(tableRecords)"
            />
          </div>
        </td>
      </tr>
    </tbody>
    <ng-container *ngIf="!budgetRecords?.length">
      <div class="noRecords d-flex justify-content-center text-center">
        <span>
          {{ staticText.common.no_records_found }}
        </span>
      </div>
    </ng-container>
  </table>
</div>
<div
  class="d-flex align-items-center justify-content-between"
  *ngIf="budgetRecords?.count > 0"
>
  <div class="p-1">
    <div
      class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
    >
      <span class="p-1">Displaying</span>
      <select
        class="form-select form-select-sm table-sort-filter"
        aria-label="Default select example"
        [(ngModel)]="sortRecords"
        (ngModelChange)="getRecords()"
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="150">150</option>
        <option value="200">200</option>
      </select>
      <span class="p-1">{{ budgetRecords?.count }}</span>
      <span class="p-1">records</span>
    </div>
  </div>
  <div class="p-1">
    <ngb-pagination
      [pageSize]="sortRecords"
      [collectionSize]="budgetRecords?.count"
      [maxSize]="2"
      [rotate]="true"
      [(page)]="CurrentPage"
      [boundaryLinks]="true"
      (pageChange)="getRecords()"
    >
    </ngb-pagination>
  </div>
</div>

<div class="modal-dialog m-0" style="max-width: 100%">
  <div class="modal-content" style="border: none">
    <app-modal-header-common
      [title]="mode == 'add' ? 'Add Spend' : 'Edit Spend'"
    >
    </app-modal-header-common>
    <div
      class="modal-body p-0 p-3 incident-tracker"
      style="overflow-y: auto; max-height: 50vh"
    >
      <form [formGroup]="spendForm">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Cost Type</mat-label>
              <mat-select formControlName="cost_type">
                <mat-option *ngFor="let option of costType" [value]="option">{{
                  option
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Cost Head</mat-label>
              <mat-select formControlName="cost_head">
                <mat-option *ngFor="let option of costHead" [value]="option">{{
                  option
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Payment Mode</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="payment_mode"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Invoice Ref</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="invoice_ref"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type">
                <mat-option
                  *ngFor="let option of spendType"
                  [value]="option.id"
                  >{{ option.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Description</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="type_description"
              />
            </mat-form-field>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>OU/GDP</mat-label>
              <mat-select formControlName="ou_gdp">
                <mat-option *ngFor="let option of ouGdp" [value]="option.id">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Budget</mat-label>
              <mat-select formControlName="budget">
                <mat-option
                  *ngFor="let option of budgets"
                  [value]="option.id"
                  >{{ option.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Payee Name</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="payee_name"
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Spend Amount</mat-label>
              <input
                type="text"
                matInput
                status="basic"
                formControlName="spend"
              />
            </mat-form-field>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Billing Date</mat-label>
              <input
                matInput
                [matDatepicker]="pickerBillingDate"
                readonly
                fullWidth
                status="basic"
                (click)="pickerBillingDate.open()"
                formControlName="billing_date"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerBillingDate">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerBillingDate> </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="col-12 px-2" appearance="outline">
              <mat-label>Payment Date</mat-label>
              <input
                matInput
                [matDatepicker]="pickerPaymentDate"
                readonly
                fullWidth
                status="basic"
                (click)="pickerPaymentDate.open()"
                formControlName="payment_date"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerPaymentDate">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerPaymentDate> </mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="d-flex justify-content-between">
          <button
            class="mr-2 ml-2"
            mat-flat-button
            color="primary"
            *ngIf="mode == 'edit'"
            (click)="editSpend()"
          >
            Update
          </button>
          <button
            class="mr-2 ml-2"
            mat-flat-button
            color="primary"
            *ngIf="mode == 'add'"
            (click)="addSpend()"
          >
            Add
          </button>
          <button (click)="closeDialog()" mat-flat-button color="secondary">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

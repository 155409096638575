import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  constructor(private http: HttpClient, private transferState: TransferState) { }

  getUserList() {
    return this.http.get('users/user-list/')
  }

  getOpenReqStatusOptions() {
    return this.http.get('base_value/?type=open_reqs_status')
  }

  getVendorList() {
    return this.http.get('delivery/get-complete-vendor-list/')
  }

  addOpenReq(projectId: any, data: any) {
    return this.http.post(`delivery/open-reqs/create_ors/${projectId}/`, data)
  }

  editOpenReq(openReqId: any, data: any) {
    return this.http.patch(`delivery/open-reqs/${openReqId}/`, data)
  }

  deleteOpenReq(openReqId: any) {
    return this.http.delete(`delivery/open-reqs/${openReqId}/`)
  }

}
